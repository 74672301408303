import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-delete-quiz-dialog',
  templateUrl: './delete-quiz-dialog.component.html',
  styleUrls: ['./delete-quiz-dialog.component.scss'],
})
export class DeleteQuizDialogComponent implements OnInit {
  userDetail: any;
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<DeleteQuizDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteQuiz() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Quiz Activity',
      value: this.data['quiz'],
    });
  }
  deleteQuizQuestion() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Quiz Question',
      value: this.data['quiz'],
    });
  }
  deleteQuizCategory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Quiz Category',
      value: this.data['quiz'],
    });
  }
  
  deleteQuizAuthor() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Author',
      value: this.data['author'],
    });
  }
}
