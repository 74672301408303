import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteQuizDialogComponent } from '../delete-quiz-dialog/delete-quiz-dialog.component';

export interface Author {
  _id: string;
  name: String;
  image: string;
  isActive: boolean;
}

@Component({
  selector: 'app-list-quiz-author',
  templateUrl: './list-quiz-author.component.html',
  styleUrls: ['./list-quiz-author.component.scss']
})
export class ListQuizAuthorComponent implements OnInit {

  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = false;
  listOfAuthor: Array<Author> = [];
  listOfAuthorToDisplay: Array<Author> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    this.fetchAuthors();
  }

  searchData(event: any) {}

  async fetchAuthors() {
    this.isLoading = true;
    this.listOfAuthor = [];
    let param = {
      allQuiz: true,
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllQuizAuthor}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((author: any) => {
            this.listOfAuthor.push({
              _id: author['_id'],
              image: author['image'],
              name: author['name'],
              isActive: author['isActive'],
            });
          });
          this.listOfAuthorToDisplay = this.listOfAuthor;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
  }

  addNewAuthor() {
    this.toggleOpened();
  }

  goToQuiz(){
    this.router.navigate(['/admin/quiz'])
  }

  deleteAuthor(author: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { author: author, source: 'Delete Author' };

    let dialogRef = this.matDialog.open(
      DeleteQuizDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          authorId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusAuthor
        }`;
        await this.appService
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfAuthorToDisplay.forEach((author: any, i: number) => {
                if (author['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfAuthorToDisplay.splice(index, 1);
              this.listOfAuthor = [...this.listOfAuthorToDisplay];

              this.showSuccessToaster('Bannr Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting magazine. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  viewEmployeeDetails(employee: any) {}

  editAuthor(employee: any) {
    this.dataToEdit = employee;
    this.toggleOpened();
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing author details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding author details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfAuthorToDisplay.findIndex(
          (author: any) => author['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfAuthorToDisplay.push(event);
          this.listOfAuthor = [...this.listOfAuthorToDisplay];
          this.showSuccessToaster('Author added successfully', 'Success');
        } else {
          this.listOfAuthorToDisplay[index] = event;
          this.listOfAuthor = [...this.listOfAuthorToDisplay];
          this.showSuccessToaster('Author Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
