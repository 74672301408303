import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListContestComponent } from './list-contest/list-contest.component';
import { RouterModule, Routes } from '@angular/router';
import { ViewVideosComponent } from './view-videos/view-videos.component';
import { ListContestCategoryComponent } from './list-contest-category/list-contest-category.component';



const routes: Routes = [
  {
    path: '',
    component: ListContestCategoryComponent,
  },
  {
    path: 'list-contests',
    component: ListContestComponent,
  },
  {
    path: 'videos',
    component: ViewVideosComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/contests' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BabyofweekRoutingModule { }
