import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mehndi',
  templateUrl: './mehndi.component.html',
  styleUrls: ['./mehndi.component.scss']
})
export class MehndiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
