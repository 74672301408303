import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DropDownItem } from '../1component/dropdown/dropdown.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header-sidebar.scss'],
})
export class HeaderComponent implements OnInit {
  _opened: boolean = false;



  dropDownItem: Array<DropDownItem> = [
    {
      label: 'Profile',
      value: 'Profile',
      prefixIcon: '../../../assets/img/user.png',
      suffixText: '',
      defaultSelected: false,
    },

    {
      label: 'Notification',
      value: 'Notification',
      prefixIcon: '../../../assets/img/setting.png',
      suffixText: '',
      defaultSelected: false,
    },

    {
      label: 'Logout',
      value: 'Logout',
      prefixIcon: '../../../assets/img/logout.svg',
      suffixText: '',
      defaultSelected: false,
      isSeperator: true,
    },
  ];
  dropDownStyle: any = {
    width: '200px',
  };
  suffixStyle: any = {
    'background-color': '#1cbb8c',
    color: '#00000',
  };
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService
  ) {
    if (this.appService.userDetail != null) {
      // this.as.setSideBarState(window.location.href.split('/'));
    } else {
      // this.router.navigate(['/layout/course']);
    }
  }

  ngOnInit(): void { }

  _toggleOpened(): void {
    this._opened = !this._opened;
  }
  callBackHandler(event: any) {
    //console.log(event);
    switch (event) {
      case 'Profile':
        // this.gotoMyProfile();
        break;
      case 'Notification':
        // this.gotoMyNotification();
        break;
      case 'Logout':
        //console.log('Logout Clicked');
        // this.appService.listOfSideBar.forEach((element: any) => {
        //   element['isSelected'] = false;
        // });
        localStorage.removeItem('MERI-SAHELI-WEB');
        this.router.navigate(['/auth/login']);

        break;

      default:
        break;
    }
  }
}
