<div id="sidebar">
    <div id="row1">
        <div id="sidebarHeader">
            <div class="avatarContainer">
                <img style="border-radius: 10rem;" src="../../assets/images/appicon.png" />
            </div>
            <h3 class="heading">MERI SAHELI</h3>
            <!-- <div class="actionsContainer">
          <img src="../../assets/images/statusIcon.svg" />
          <img src="../../assets/images/chatIcon.svg" />
          <img src="../../assets/images/moreVertIcon.svg" />
        </div> -->
        </div>
        <div id="searchBox">
            <img width="19" src="../../assets/images/searchIcon.svg" />
            <input type="text" [(ngModel)]="searchText" placeholder="Search or start the new chat"
                (input)="searchData($event.target)" />
        </div>
    </div>

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>

    <div id="sidebarContent" *ngIf="!isLoading">
        <!-- <div id="noConversations">All chats are archived</div> -->
        <div *ngIf="listOfChatsToDisplay.length==0"
            style="text-align: center;height: 60vh;  display: flex;  justify-content: center;align-items: center; color: grey; font-size: 15px;">
            No Chat found.
        </div>
        <div *ngIf="listOfChatsToDisplay.length>0">

            <div class="conversation"
                *ngFor="let chat of listOfChatsToDisplay | paginate: { itemsPerPage: count, currentPage:currentPage }"
                (click)=" gotoConversation(chat)">
                <div class="picture">
                    <img src="../../assets/images/noPic.svg" />
                </div>
                <div class="content">
                    <div class="conversationHeader">
                        <div class="name">
                            {{chat.user1._id==this.as.meriSaheliId?chat.user2.name==null?chat.user2.phone:chat.user2.name:chat.user1.name==null?chat.user1.phone:chat.user1.name}}
                        </div>
                        <!-- <div *ngIf="chat.lastMessageId!=null" class="time">{{ chat.lastMessageId.createdAt|date: 'dd/MM/yyyy' }}</div> -->
                        <div class="time">{{ chat.updatedAt|date: 'dd/MM/yyyy' }}</div>
                    </div>
                    <div *ngIf="chat.lastMessageId!=null" class="message">
                        <div *ngIf="chat.lastMessageId['type']=='template'" style="display: flex;align-items: center;">
                            <div *ngFor="let contentData of chat.lastMessageId.templateId['components']">


                                <div *ngIf="contentData['format']=='IMAGE' && contentData['type']=='HEADER'">
                                    <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='sent'"
                                        src="../../assets/images/singletick.svg" />
                                    <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='delivered'"
                                        src="../../assets/images/doubleTick.svg" />
                                    <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='read'"
                                        src="../../assets/images/doubleTickBlue.svg" />

                                    <img *ngIf="contentData['format']=='IMAGE' && contentData['type']=='HEADER'"
                                        src="../../../../../assets/images/wa-img/gallery.png" alt="" srcset=""
                                        style="width: 15px">
                                </div>
                                <div *ngIf="contentData['format']=='VIDEO' && contentData['type']=='HEADER'">
                                </div>
                                <div *ngIf="contentData['format']=='DOCUMENT' && contentData['type']=='HEADER'">
                                </div>
                                <div *ngIf="contentData['format']=='AUDIO' && contentData['type']=='HEADER'">
                                </div>
                                <div *ngIf="contentData['type']=='BODY'"
                                    style="overflow: hidden; word-break: break-all;  white-space: nowrap; text-overflow: ellipsis;max-width: 19vw;    margin-left: 5px;">
                                    {{getTextContentData(chat.lastMessageId,'body',contentData['text'])}}
                                </div>
                                <div *ngIf="contentData['type']=='FOOTER'">
                                </div>
                                <div *ngIf="contentData['type']=='BUTTON'">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="chat.lastMessageId['type']=='individual'">
                            <div *ngIf="chat.lastMessageId['contentType']=='text'"
                                style="overflow: hidden;  word-break: break-all; white-space: nowrap;text-overflow: ellipsis;max-width: 19vw;    margin-left: 5px;">
                                {{chat.lastMessageId['content']['body']}}
                            </div>
                            <div *ngIf="chat.lastMessageId['contentType']=='image'">
                                <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='sent'"
                                    src="../../assets/images/singletick.svg" />
                                <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='delivered'"
                                    src="../../assets/images/doubleTick.svg" />
                                <img style="height: 13px;" *ngIf="chat.lastMessageId.status=='read'"
                                    src="../../assets/images/doubleTickBlue.svg" />
                                <img src="../../../../../assets/images/wa-img/gallery.png" alt="" srcset=""
                                    style="width: 15px">
                            </div>
                            <div
                                *ngIf="chat.lastMessageId['contentType']!='text' && chat.lastMessageId['contentType']!='image'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 10em;">
        </div>
    </div>
    <div class="pagination-div">
        <div *ngIf="!isLoadingExtraData" class="text-right" style="margin: 1em;">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
        </div>

        <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
</div>