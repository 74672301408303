import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class MainDashboardComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;

  selectedDuration: string = 'Monthly';
  totalSubscription: number = 0;
  bookCount: number = 0;
  listOfSubscription: any = [];
  earningFromSubscription: number = 0;
  earningFromBooks: number = 0;
  totalUsers: number = 0;
  listOfBooks: any = [];

  startDate: any;
  endDate: any;

  clientDetail: any;
  activeUserMonthDetail: any;
  keys: any;

  couponSummary: any;

  listOfDuration: any = [
    'Today',
    'Yesterday',
    'Weekly',
    'Monthly',
    'Current Quarter',
    'Yearly',
  ];

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    this.fetchActiveUserCountByClientId();
  }
  async fetchActiveUserCountByClientId() {
    this.isLoading = true;
    this.activeUserMonthDetail = null;
    try {
      let param = {
        phone: 8976802882,
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${WA_API.domain + WA_API.endPoint.fetchActiveUserCountByClientId}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.activeUserMonthDetail = data['result'];
            this.keys = Object.keys(data['result']);
            this.fetchCouponSummary();
          } else {
            this.fetchCouponSummary();
          }
        });
    } catch (error) {
      this.fetchCouponSummary();
    }
  }
  async fetchCouponSummary() {
    this.isLoading = true;
    this.couponSummary = null;
    try {
      let param = {
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.fetchCouponSummary}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.couponSummary = data['result'];
            this.getClientDetail();
          } else {
            this.getClientDetail();
          }
        });
    } catch (error) {
      this.getClientDetail();
    }
  }
  async getClientDetail() {
    this.isLoading = true;
    this.clientDetail = null;
    try {
      let param = {
        phone: 8976802882,
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${WA_API.domain + WA_API.endPoint.getClientDetail}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clientDetail = data['result'][0];
            this.fetchDashboardDetails(null, null);
          } else {
            this.fetchDashboardDetails(null, null);
          }
        });
    } catch (error) {
      this.fetchDashboardDetails(null, null);
    }
  }
  changeDuration(event: any) {
    this.selectedDuration = event.value;
    this.fetchDashboardDetails(null, null);
  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };
  async fetchDashboardDetails(startDate: any, endDate: any) {
    this.isLoading = true;
    try {
      let URL = '';
      this.totalSubscription = 0;
      this.earningFromBooks = 0;
      this.earningFromSubscription = 0;
      this.bookCount = 0;
      this.listOfSubscription = [];
      this.listOfBooks = [];
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchDashboardDetails}`;
      const param = {
        duration: this.selectedDuration,
        startDateFilter: startDate,
        endDateFilter: endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isLoading = false;
            this.earningFromBooks = data['earningFromBooks'];
            this.earningFromSubscription = data['earningFromSubscription'];
            this.totalUsers = data['totalUsers'];
            this.bookCount = data['bookCount'];
            this.totalSubscription = data['subscription'].length;
            let indexForSubscription = 10;
            let indexForBook = 5;
            this.startDate = data['startDate'];
            this.endDate = data['endDate'];
            if (data['subscription'].length <= 9) {
              indexForSubscription = data['subscription'].length;
            }
            if (data['books'].length <= 4) {
              indexForBook = data['books'].length;
            }
            for (let i = 0; i < indexForSubscription; i++) {
              this.listOfSubscription.push(data['subscription'][i]);
            }
            for (let i = 0; i < indexForBook; i++) {
              this.listOfBooks.push(data['books'][i]);
            }
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
  }
  dateChanged(type: any, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      var difference = this.calculateDiff(this.startDate, this.endDate);
      if (difference == 30 || difference == 29) {
        this.selectedDuration = 'Monthly';
      } else if (difference == 0) {
        this.selectedDuration = 'Today';
      } else if (difference == 1) {
        this.selectedDuration = 'Yesterday';
      } else if (difference >= 89 && difference <= 95) {
        this.selectedDuration = 'Current Quarter';
      } else if (difference == 365 || difference == 366) {
        this.selectedDuration = 'Yearly';
      } else {
        this.selectedDuration = 'Custom';
      }
      this.fetchDashboardDetails(this.startDate, this.endDate);
    }
  }

  calculateDiff(startDate: Date, endDate: Date) {
    startDate = new Date(startDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
      (1000 * 60 * 60 * 24)
    );
  }
}
