<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Banner
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addBannerForm" [formGroup]="addBannerForm">
        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" class="input-style">
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="typeSelected($event)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Article">Article</option>
                    <option value="Banner">Banner</option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Route</label>
                <select id="type" name="type" class="input-style" formControlName="route"
                    (change)="routeChangeHandler($event.target)">
                    <option value="Select Route" selected disabled>Select Route</option>
                    <option *ngFor="let route of listOfRoutes" [value]="route">{{route}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Type</label>
                <select id="type" name="type" class="input-style" formControlName="language"
                    (change)="languageSelected($event)">
                    <option value="Select Language" selected disabled>Select Language</option>
                    <!-- <option value="All">All</option> -->
                    <!-- <option value="English">English</option> -->
                    <option value="Hindi">Hindi</option>
                    <option value="Marathi">Marathi</option>
                </select>
            </div>
        </div>

        <div class="row" style="margin-bottom: 1rem;margin-top: .5rem;" *ngIf="selectedType=='Article'">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <label for="isSponsored" class="label " style="margin-bottom: 0px;margin-right: 1em;">Is Sponsored
                    ?</label>
                <input type="checkbox" placeholder="Is Sponsored?" id="isSponsored" [checked]="sponsored"
                    (change)="isSponsored($event)">
            </div>
        </div>
        <div class="row" *ngIf="sponsored">
            <div class="col-md-6">
                <label for="title" class="label required" style="margin-bottom: 1rem;">Title</label>
                <input type="text" placeholder="Title" id="title" formControlName="title" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="link" class="label required" style="margin-bottom: 1rem;">Navigation Link</label>
                <input type="text" placeholder="Navigation Link" id="link" formControlName="link" class="input-style">
            </div>
        </div>
        <div class="row" *ngIf="sponsored">
            <div class="col-md-12">
                <label for="note" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="note" formControlName="description"
                    class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <label for="Image" class="label required">Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultbannerImage!=null" src="{{defaultbannerImage}}" class="proofImage">
                                <label *ngIf="defaultbannerImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultbannerImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addBannerForm.invalid|| defaultbannerImage==null || selectedRoute=='' || selectedLanguage==''  ,'save-btn':addBannerForm.valid  && defaultbannerImage!=null && selectedRoute!='' && selectedLanguage!=''}"
                value="{{'Save'}}" [disabled]="addBannerForm.invalid || defaultbannerImage==null || selectedRoute=='' || selectedLanguage=='' "
                (click)="addEditBrand()" />
        </div>
    </form>
</div>