import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from '../../authentication/admin-dashboard/admin-dashboard.component';
import { UserComponent } from './user/user.component';
import { EmployeeManagementComponent } from './employee-management/employee-management.component';
import { BrandManagementComponent } from './brand-management/brand-management.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { NotificationModuleComponent } from './notification-module/notification-module.component';
import { PlanManagementComponent } from './plan-management/plan-management.component';
import { AdminReportComponent } from './admin-report/admin-report.component';
import { CampaignManagementModule } from './campaign-management/campaign-management.module';
import { CampaignManagementComponent } from './campaign-management/campaign-management.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuizzesComponent } from './quizzes/quizzes.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { GameManagementComponent } from './game-management/game-management.component';
import { MehndiComponent } from './mehndi/mehndi.component';
import { NotificationComponent } from './notification/notification.component';
import { OfferManagementComponent } from './offer-management/offer-management.component';
import { ReferralComponent } from './referral/referral.component';
import { InfluencerComponent } from './influencer/influencer.component';
import { InfluencerDashboardComponent } from './influencer-dashboard/influencer-dashboard.component';
import { SubInfluencerComponent } from './sub-influencer/sub-influencer.component';
import { UtmComponent } from './utm/utm.component';
import { TransactionLogComponent } from './transaction-log/transaction-log.component';
import { BabyofweekComponent } from './babyofweek/babyofweek.component';
import { ImageSharingComponent } from './image-sharing/image-sharing.component';
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
    ],
  },
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: '',
        loadChildren: './user/user.module#UserModule',
      },
    ],
  },
  {
    path: 'mehndi',
    component: MehndiComponent,
    children: [
      {
        path: '',
        loadChildren: './mehndi/mehndi.module#MehndiModule',
      },
    ],
  },
  {
    path: 'employee',
    component: EmployeeManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './employee-management/employee-management.module#EmployeeManagementModule',
      },
    ],
  },
  {
    path: 'brand',
    component: BrandManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './brand-management/brand-management.module#BrandManagementModule',
      },
    ],
  },
  {
    path: 'quiz',
    component: QuizzesComponent,
    children: [
      {
        path: '',
        loadChildren: './quizzes/quizzes.module#QuizzesModule',
      },
    ],
  },
  {
    path: 'buildby-chat',
    component: WhatsappComponent,
    children: [
      {
        path: '',
        loadChildren: './whatsapp/whatsapp.module#WhatsappModule',
      },
    ],
  },
  {
    path: 'report',
    component: AdminReportComponent,
    children: [
      {
        path: '',
        loadChildren: './admin-report/admin-report.module#AdminReportModule',
      },
    ],
  },
  {
    path: 'offer',
    component: OfferManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './offer-management/offer-management.module#OfferManagementModule',
      },
    ],
  },
  {
    path: 'referral',
    component: ReferralComponent,
    children: [
      {
        path: '',
        loadChildren:
          './referral/referral.module#ReferralModule',
      },
    ],
  },
  {
    path: 'influencer',
    component: InfluencerComponent,
    children: [
      {
        path: '',
        loadChildren:
          './influencer/influencer.module#InfluencerModule',
      },
    ],
  },
  {
    path: 'transaction',
    component: TransactionLogComponent,
    children: [
      {
        path: '',
        loadChildren: './transaction-log/transaction-log.module#TransactionLogModule',
      },
    ],
  },
  {
    path: 'contests',
    component: BabyofweekComponent,
    children: [
      {
        path: '',
        loadChildren: './babyofweek/babyofweek.module#BabyofweekModule',
      },
    ],
  },
  {
    path: 'voucher',
    component: VoucherManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './voucher-management/voucher-management.module#VoucherManagementModule',
      },
    ],
  },
  {
    path: 'magazine',
    component: ContentManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './content-management/content-management.module#ContentManagementModule',
      },
    ],
  },
  {
    path: 'plan',
    component: PlanManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './plan-management/plan-management.module#PlanManagementModule',
      },
    ],
  },
  {
    path: 'video',
    component: PlanManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './video-management/video-management.module#VideoManagementModule',
      },
    ],
  },

  {
    path: 'compliance',
    component: ComplianceComponent,
    children: [
      {
        path: '',
        loadChildren: './compliance/compliance.module#ComplianceModule',
      },
    ],
  },

  {
    path: 'collection',
    component: ComplianceComponent,
    children: [
      {
        path: '',
        loadChildren:
          './book-collection/book-collection.module#BookCollectionModule',
      },
    ],
  },

  {
    path: 'notification',
    component: NotificationModuleComponent,
    children: [
      {
        path: '',
        loadChildren:
          './notification-module/notification-module.module#NotificationModuleModule',
      },
    ],
  },
  {
    path: 'notification-1',
    component: NotificationComponent,
    children: [
      {
        path: '',
        loadChildren: './notification/notification.module#NotificationModule',
      },
    ],
  },
  {
    path: 'campaign',
    component: CampaignManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './campaign-management/campaign-management.module#CampaignManagementModule',
      },
    ],
  },
  {
    path: 'game',
    component: GameManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './game-management/game-management.module#GameManagementModule',
      },
    ],
  },
  {
    path: 'influencerDashboard',
    component: InfluencerDashboardComponent,
    children: [
      {
        path: '',
        loadChildren:
          './influencer-dashboard/influencer-dashboard.module#InfluencerDashboardModule',
      },
    ],
  },
  {
    path: 'subInfluencer',
    component: SubInfluencerComponent,
    children: [
      {
        path: '',
        loadChildren:
          './sub-influencer/sub-influencer.module#SubInfluencerModule',
      },
    ],
  },

  {
    path: 'utm',
    component: UtmComponent,
    children: [
      {
        path: '',
        loadChildren: './utm/utm.module#UtmModule',
      },
    ],
  },
  {
    path: 'imageSharing',
    component: ImageSharingComponent,
    children: [
      {
        path: '',
        loadChildren: './image-sharing/image-sharing.module#ImageSharingModule',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
