import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-game-option',
  templateUrl: './list-game-option.component.html',
  styleUrls: ['./list-game-option.component.scss'],
})
export class ListGameOptionComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  moveToCategory() {
    this.router.navigate(['/admin/games-category']);
  }

  moveToGame() {
    this.router.navigate(['/admin/all-games']);
  }
}
