import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-contest',
  templateUrl: './add-edit-contest.component.html',
  styleUrls: ['./add-edit-contest.component.scss']
})
export class AddEditContestComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() categoryId: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  dynamicFieldArr!: FormArray;
  minStartDate: any;
  minEndDate: any;
  maxStartDate: any;
  maxEndDate: any;

  selectedRights: any = [];
  showFranchiseSelection: boolean = false;
  showAreaManagerSelection: boolean = false;
  isLoading: boolean = false;

  // Contest Image
  selectedContestImage: any = null;
  selectedContestImageName: any = null;
  defaultContestImage: any;

  myHeaders = new Headers();
  formdata = new FormData();

  winnerAnnouncerNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  // titleControl = new FormControl('', [
  //   Validators.required,
  //   Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  // ]);
  startDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  endDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  resultDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  descriptionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  prize1Control = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  prize2Control = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  prize3Control = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  hemaChoicePrizeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  // contestTypeControl = new FormControl('', [
  //   Validators.required,
  //   Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  // ]);

  optionControl = new FormControl('Image', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
  sizeLimitControl = new FormControl('5', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
  yearLimitControl = new FormControl('5', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
  videoDurationLimitControl = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);

  addContestForm: FormGroup = new FormGroup({
    winnerAnnouncerName: this.winnerAnnouncerNameControl,
    startDate: this.startDateControl,
    // title: this.titleControl,
    endDate: this.endDateControl,
    resultDate: this.resultDateControl,
    description: this.descriptionControl,
    prize1: this.prize1Control,
    prize2: this.prize2Control,
    prize3: this.prize3Control,
    hemaChoicePrize: this.hemaChoicePrizeControl,
    // contestType: this.contestTypeControl,
    option: this.optionControl,
    sizeLimit: this.sizeLimitControl,
    dynamicField: this.formBuilder.array([]),
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {

    this.addContestForm = this.formBuilder.group({
      winnerAnnouncerName: this.winnerAnnouncerNameControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl,
      resultDate: this.resultDateControl,
      description: this.descriptionControl,
      prize1: this.prize1Control,
      prize2: this.prize2Control,
      prize3: this.prize3Control,
      hemaChoicePrize: this.hemaChoicePrizeControl,
      // contestType: this.contestTypeControl,
      option: this.optionControl,
      sizeLimit: this.sizeLimitControl,
      dynamicField: this.dataToEdit != null ? this.formBuilder.array([]) : this.formBuilder.array([this.createDynamicField()]),
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addContestForm.get('winnerAnnouncerName')?.setValue(this.dataToEdit['winnerAnnouncerName']);
      this.addContestForm.get('startDate')?.setValue(formatDate(new Date(this.dataToEdit['startDate']), 'yyyy-MM-ddTHH:mm', 'en-GB'));
      this.addContestForm.get('endDate')?.setValue(formatDate(new Date(this.dataToEdit['endDate']), 'yyyy-MM-ddTHH:mm', 'en-GB'));
      this.addContestForm.get('resultDate')?.setValue(new Date(this.dataToEdit['resultDate']).toISOString().slice(0, 10));
      this.addContestForm.get('description')?.setValue(this.dataToEdit['description']);
      this.addContestForm.get('prize1')?.setValue(this.dataToEdit['prize1']);
      this.addContestForm.get('prize2')?.setValue(this.dataToEdit['prize2']);
      this.addContestForm.get('prize3')?.setValue(this.dataToEdit['prize3']);
      this.addContestForm.get('hemaChoicePrize')?.setValue(this.dataToEdit['hemaChoicePrize']);
      // this.addContestForm.get('contestType')?.setValue(this.dataToEdit['contestType']);
      this.addContestForm.get('option')?.setValue(this.dataToEdit['option']);
      this.defaultContestImage = this.dataToEdit['image'] == null ? null : this.dataToEdit['image'];

      //New Changes
      this.addContestForm.get('sizeLimit')?.setValue(this.dataToEdit['sizeLimit'] == 0 ? 5 : this.dataToEdit['sizeLimit']);
      if (this.dataToEdit['option'] == 'Video') {
        this.addContestForm.addControl('videoDurationLimit', this.videoDurationLimitControl);
        this.addContestForm.get('videoDurationLimit')?.setValue(this.dataToEdit['videoDurationLimit'] ?? 30);
      }

      if (this.dataToEdit['formFields'] != null && this.dataToEdit['formFields'] != undefined) {
        for (
          let index = 0;
          index < this.dataToEdit['formFields'].length;
          index++
        ) {
          const element = this.dataToEdit['formFields'][index];
          this.dynamicFieldArr = this.addContestForm.get('dynamicField') as FormArray;

          let groupObject: any = {
            name: new FormControl(element['name'], [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
            dataType: new FormControl(element['dataType'], [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
            optional: new FormControl(element['optional'] ? 'Yes' : 'No', [Validators.required]),
          };
          if (element['dataType'] == 'Date') {
            groupObject['yearLimit'] = new FormControl(element['yearLimit'] ?? 0, [Validators.required]);
          }
          this.dynamicFieldArr.push(
            this.formBuilder.group(groupObject)
          );

        }
      }
    } else {
      this.addContestForm.get('sizeLimit')?.setValue(5);
    }
  }
  typeChange(event: any) {
    console.log(event.target.value);
    if (event.target.value == 'Video') {
      this.addContestForm.addControl('videoDurationLimit', this.videoDurationLimitControl);
      this.addContestForm.get('sizeLimit')?.setValue(5);
      this.addContestForm.get('videoDurationLimit')?.setValue(30);
    } else {
      this.addContestForm.removeControl('videoDurationLimit');
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  startDateChange(event: any) {
    console.log(event.target.value);
    this.minEndDate = event.target.value;
  }
  endDateChange(event: any) {
    console.log(event.target.value);
    this.maxStartDate = event.target.value;
  }

  displayContestImage(event: any) {
    this.selectedContestImage = event.target.files[0];
    this.selectedContestImageName = this.selectedContestImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultContestImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedContestImage);
  }

  addDynamicField() {
    this.dynamicFieldArr = this.addContestForm.get('dynamicField') as FormArray;
    this.dynamicFieldArr.push(this.createDynamicField());
  }
  createDynamicField() {
    return this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$'),
      ]),
      dataType: new FormControl('Text', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$'),
      ]),
      optional: new FormControl('No', [Validators.required]),
      // yearLimit: new FormControl('0', [Validators.required]),
    });
  }

  dataTypeChangeHandler(event: any, index: number) {
    let formArray: any = (this.addContestForm.get('dynamicField') as FormArray).controls;
    if (event.target.value == 'Date') {
      formArray[index].addControl('yearLimit', new FormControl('', [Validators.required]));
    } else {
      formArray[index].removeControl('yearLimit');
    }
  }

  getDynamicFieldControls() {
    return (this.addContestForm.get('dynamicField') as FormArray).controls;
  }
  deleteDynamicField(i: number) {
    this.dynamicFieldArr = this.addContestForm.get('dynamicField') as FormArray;
    this.dynamicFieldArr.removeAt(i);
  }


  addEditEmployee() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('contestId', this.dataToEdit['_id']);
      this.formdata.set('image', JSON.stringify(this.dataToEdit['image']));
    } else {
      this.formdata.set('contestId', '0');
    }

    if (this.selectedContestImage != null) {
      this.formdata.set('image', this.selectedContestImage);
    }

    this.formdata.set('winnerAnnouncerName', this.addContestForm.get('winnerAnnouncerName')?.value);
    this.formdata.set('option', this.addContestForm.get('option')?.value);
    this.formdata.set('startDate', this.addContestForm.get('startDate')?.value);
    this.formdata.set('endDate', this.addContestForm.get('endDate')?.value);
    this.formdata.set('resultDate', this.addContestForm.get('resultDate')?.value);
    this.formdata.set('description', this.addContestForm.get('description')?.value);
    this.formdata.set('prize1', this.addContestForm.get('prize1')?.value);
    this.formdata.set('prize2', this.addContestForm.get('prize2')?.value);
    this.formdata.set('prize3', this.addContestForm.get('prize3')?.value);
    // this.formdata.set('contestType', this.addContestForm.get('contestType')?.value);
    this.formdata.set('hemaChoicePrize', this.addContestForm.get('hemaChoicePrize')?.value);
    this.formdata.set('sizeLimit', this.addContestForm.get('sizeLimit')?.value);
    if (this.addContestForm.get('option')?.value == 'Video') {
      this.formdata.set('videoDurationLimit', this.addContestForm.get('videoDurationLimit')?.value);
    } else {
      this.formdata.set('videoDurationLimit', '0');
    }

    this.formdata.set('category', this.categoryId);

    let formFields: any = [];

    this.dynamicFieldArr = this.addContestForm.get('dynamicField') as FormArray;
    this.getDynamicFieldControls().forEach((element: any) => {
      console.log(element);
      formFields.push({
        name: element.get('name').value,
        dataType: element.get('dataType').value,
        optional: element.get('optional') == 'Yes',
        yearLimit: element.get('dataType').value == 'Date' ? element.get('yearLimit').value ?? 5 : 0,
        gender: element.get('dataType').value == 'Gender' ? ['Boy', 'Girl'] : [],
      });
    });

    this.formdata.set('formFields', JSON.stringify(formFields));

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.dataToEdit != null) {
      URL = `${webApi.domain + webApi.endPoint.addEditContest}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.addEditContest}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addContestForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  downloadImage(url: any) {
    window.open(url);
  }

}
