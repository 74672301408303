import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListImagesComponent } from './list-images/list-images.component';
import { MehndiComponent } from './mehndi.component';
import { MehndiRoutingModule } from './mehndi-routing.module';
import { AddImageComponent } from './add-image/add-image.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeleteImageDialogComponent } from './delete-image-dialog/delete-image-dialog.component';
import { AddCategoryDialogComponent } from './add-category-dialog/add-category-dialog.component';
import { MainDesignOptionComponent } from './main-design-option/main-design-option.component';
import { ListCategoryComponent } from './list-category/list-category.component';

@NgModule({
  declarations: [
    ListImagesComponent,MehndiComponent, AddImageComponent, DeleteImageDialogComponent, AddCategoryDialogComponent, MainDesignOptionComponent, ListCategoryComponent
  ],
  imports: [
    CommonModule,MehndiRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
  ]
})
export class MehndiModule { }
