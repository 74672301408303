import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { MessageInfo, MessageService } from '../message.service';
// export interface Message {
//   _id: string;
//   messageId: string;
//   sender: string;
//   sender_wa_id: string;
//   recipient: string;
//   recipient_wa_id: string;
//   contentType: string;
//   content: any;
//   type: string;
//   templateId: any;
//   status: string;
//   sentDate: Date;
//   deliverDate: Date;
//   readDate: Date;
//   createdAt: Date;
//   campaignId: string;
//   clientId: string;
// }
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  chat: any;
  showTemplate: boolean = false;
  showTemplateBody: boolean = false;

  showHeaderOfTemplate: boolean = false;
  showHeaderMediaOfTemplate: boolean = false;
  showHeaderTextOfTemplate: boolean = false;
  showBodyOfTemplate: boolean = false;
  showFooterOfTemplate: boolean = false;
  showButtonOfTemplate: boolean = false;
  bodyVariableCount: number = 0;

  myHeaders = new Headers();
  formdata = new FormData();

  userDetails: any;
  selectedTemplate: any;

  listOfTemplate: any = [];
  listOfTemplateToDisplay: any = [];

  // Image For Header Media
  selectedHeaderMedia: any = null;
  selectedHeaderMediaName: any = null;
  defaultHeaderMedia: any;

  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImage: any;

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Input()
  set componentChanged(value: boolean) {
    this.chat = value;
    // this.listOfMessage;
    this.fetchChats();
  }
  emojiPickerVisible: any;
  message: any = '';
  isLoading: boolean = false;
  isTemplateLoading: boolean = false;
  isComponentChanged: boolean = false;
  listOfMessage$: Observable<MessageInfo[]> | undefined;

  sendTemplateForm: FormGroup = new FormGroup({});

  constructor(public as: AppService, public ms: MessageService) {
    this.fetchChats();
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.fetchChats();
  }
  fetchChats() {
    this.listOfMessage$ = this.ms.getAllMessages();
    this.isLoading = false;
  }
  // async fetchChats() {
  //   this.isLoading = true;
  //   this.listOfMessage = [];
  //   try {
  //     let URL = '';

  //     URL = `${WA_API.domain + WA_API.endPoint.fetchMessagesByChatId}/${this.chat._id}`;
  //     // const param = {
  //     //   chat: this.chat._id,
  //     // };
  //     await this.as.fetchDataFromDBGet( URL).then((data: any) => {
  //       if (data['success']) {
  //         this.listOfMessage = data['result'];
  //         this.isLoading = false;
  //       } else {
  //         this.listOfMessage = [];
  //         this.isLoading = false;
  //       }
  //     });
  //   } catch (error) {
  //     this.listOfMessage = [];
  //     this.isLoading = false;
  //   }
  // }
  searchTemplateData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTemplateToDisplay = [];

      this.listOfTemplate.forEach((tempate: any, index: number) => {
        if (
          String(tempate['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(this.getTemplateBody(tempate))
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(tempate);
        }
      });
      this.listOfTemplateToDisplay = [];
      this.listOfTemplateToDisplay = tempSearchList;
    } else {
      this.listOfTemplateToDisplay = this.listOfTemplate;
    }
  }

  emojiClicked(event: any) {
    this.message += event.emoji.native;
  }

  getDuration(createdAt: any) {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    );
    const messageDate = new Date(createdAt);
    const chatDate = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth() + 1,
      messageDate.getDate()
    );

    let differenceInDays = this.differenceDays(today, chatDate);

    if (differenceInDays == 0) {
      return 'TODAY';
    } else if (differenceInDays == 1) {
      return 'YESTERDAY';
    } else {
      return `${
        chatDate.getDate() <= 9
          ? `0${chatDate.getDate()}`
          : `${chatDate.getDate()}`
      }/${
        chatDate.getMonth() + 1 <= 9
          ? `0${chatDate.getMonth() + 1}`
          : `${chatDate.getMonth() + 1}`
      }/${chatDate.getFullYear()}`;
    }
  }
  getContentData(message: any, contentOf: string, index: number): any {
    let i = 0;

    message.content.forEach((content: any, ind: number) => {
      if (content['type'] == contentOf) {
        i = ind;
      }
    });
    return message.content[i]['parameters'][index];
  }
  getTextContentData(message: any, contentOf: string, text: string) {
    let i = 0;
    let ogText = text;
    message.content.forEach((content: any, index: any) => {
      if (content['type'] == contentOf) {
        i = index;
      }
    });
    let varCount = text.split('{{').length - 1;
    let val = 0;
    while (varCount > 0) {
      ogText = ogText.replace(
        `{{${0 + 1}}}`,
        message.content[i]['parameters'][val]['text']
      );
      val++;
      varCount--;
    }
    return ogText;
  }

  differenceDays(date1: Date, date2: Date) {
    // To calculate the time difference of two dates
    var differenceInTime = date1.getTime() - date2.getTime();

    // To calculate the no. of days between two dates
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  }
  is24HrWindowClosed(expireDate: Date) {
    var OneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    if (OneDay > new Date(expireDate).getTime()) {
      return false;
    } else {
      // The expireDate time is more than 1 days from now
      return true;
    }
  }
  handleKeyDownEvent(e: any) {
    if (e.key == 'Enter' && !e.shiftKey) {
      // prevent default behavior
      e.preventDefault();
    }
  }
  inputChange(event: any) {
    console.log(event);
  }
  getTemplateBody(template: any) {
    let toReturn = '';
    if (template.components.length > 0) {
      template.components.forEach((comp: any) => {
        if (comp['type'] == 'BODY') {
          toReturn = comp['text'];
        }
      });
    }
    return toReturn;
  }
  selectTemplate(template: any) {
    this.selectedTemplate = template;
    this.showHeaderOfTemplate = false;
    this.showHeaderMediaOfTemplate = false;
    this.showHeaderTextOfTemplate = false;
    this.showBodyOfTemplate = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.selectedTemplate['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate = true;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate = true;
          this.showHeaderTextOfTemplate = false;
        } else {
          this.showHeaderMediaOfTemplate = false;
          this.showHeaderTextOfTemplate = true;
          this.sendTemplateForm.addControl(
            'headerText',
            new FormControl('', [
              Validators.required,
              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
            ])
          );
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate = true;
        this.bodyVariableCount = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount;
        if (variableCount > 0) {
          while (variableCount > 0) {
            this.sendTemplateForm.addControl(
              `bodyText${variableCount}`,
              new FormControl('', [
                Validators.required,
                Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
              ])
            );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = true;
      } else {
        this.showButtonOfTemplate = true;
      }
    });
    this.showTemplate = false;
    this.showTemplateBody = true;
  }
  displayHeaderMedia(event: any) {
    this.selectedHeaderMedia = event.target.files[0];
    this.selectedHeaderMediaName = this.selectedHeaderMedia['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      if (this.selectedHeaderMedia['type'].split('/')[0] == 'image') {
        this.defaultHeaderMedia = event1.target.result;
      } else {
        let extension =
          this.selectedHeaderMedia['name'].split('.')[
            this.selectedHeaderMedia['name'].split('.').length - 1
          ];
        if (extension == 'mp4' || extension == 'mp4') {
          this.defaultHeaderMedia = '../../../../../assets/img/video.jpg';
        } else if (
          extension == 'doc' ||
          extension == 'docx' ||
          extension == 'pdf'
        ) {
          this.defaultHeaderMedia = '../../../../../assets/img/doc.png';
        } else if (extension == 'mp3') {
          this.defaultHeaderMedia = '../../../../../assets/img/audio.jpg';
        } else {
          this.defaultHeaderMedia = '../../../../../assets/img/excel.png';
        }
      }
    };
    reader.readAsDataURL(this.selectedHeaderMedia);
  }
  counter(i: number) {
    return new Array(i);
  }
  getTemplateList() {
    try {
      this.showTemplate = false;
      this.showTemplateBody = false;
      this.fetchAllTemplate();
    } catch (error) {
      this.showTemplate = false;
      this.showTemplateBody = false;
    }
  }
  async fetchAllTemplate() {
    this.isTemplateLoading = true;
    this.listOfTemplate = [];
    try {
      let param = {
        phone: '8976802882',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTemplate = data['result'];
          this.listOfTemplate.forEach((template: any) => {
            template['nameWithLanguage'] =
              template['name'] + ' (language :- ' + template['language'] + ')';
          });
          this.listOfTemplateToDisplay = this.listOfTemplate;

          this.isTemplateLoading = false;
          this.showTemplate = true;
        } else {
          this.listOfTemplate = [];
          this.listOfTemplateToDisplay = this.listOfTemplate;

          this.isTemplateLoading = false;
          this.showTemplate = false;
          this.showTemplateBody = false;
        }
      });
    } catch (error) {
      this.listOfTemplate = [];
      this.listOfTemplateToDisplay = this.listOfTemplate;

      this.isTemplateLoading = false;
      this.showTemplate = false;
      this.showTemplateBody = false;
    }
  }

  hideTemplate() {
    this.showTemplate = false;
    this.showTemplateBody = false;
  }
  hideTemplateBody() {
    this.listOfTemplateToDisplay = this.listOfTemplate;
    this.showTemplate = true;
    this.showTemplateBody = false;
  }
  sendMessage1() {}
  async sendMessage() {
    // this.isLoading = true;

    if (this.message == '' && !this.showTemplate && !this.showTemplateBody) {
      return false;
    }
    let messageDetail = this.message;
    this.message = '';
    if (this.selectedImage != null) {
      this.formdata.set('image', this.selectedImage);
    }
    if (this.selectedHeaderMedia != null) {
      this.formdata.set('headerMedia', this.selectedHeaderMedia);
    }
    this.formdata.set('from', '8976802882');
    this.formdata.set(
      'to',
      this.chat.user1._id == this.as.meriSaheliId
        ? this.chat.user2.phone
        : this.chat.user1.phone
    );
    this.formdata.set(
      'toName',
      this.chat.user1._id == this.as.meriSaheliId
        ? this.chat.user2.name
        : this.chat.user1.name
    );
    this.formdata.set('contentType', 'text');

    if (this.selectedTemplate != null) {
      this.formdata.set('type', 'template');
      this.formdata.set('template', this.selectedTemplate['_id']);
      this.formdata.set('templateName', this.selectedTemplate['name']);
      this.formdata.set('templateLanguage', this.selectedTemplate['language']);
    } else {
      this.formdata.set('type', 'individual');
      this.formdata.set('textMessage', messageDetail);
    }
    // this.formdata.set('contextMessageId', this.message);

    let components: any = [];
    if (this.showHeaderOfTemplate && this.showHeaderMediaOfTemplate) {
      components.push({
        type: 'header',
        parameters: [
          {
            type: 'image',
            image: {
              link: '',
            },
          },
        ],
      });
    }
    if (this.showHeaderOfTemplate && this.showHeaderTextOfTemplate) {
      components.push({
        type: 'header',
        parameters: [
          {
            type: 'text',
            text: {
              link: this.sendTemplateForm.get('headerText')!.value,
            },
          },
        ],
      });
    }
    if (this.showBodyOfTemplate) {
      components.push({
        type: 'body',
        parameters: [],
      });
      for (let index = 0; index < components.length; index++) {
        if (components[index]['type'] == 'body') {
          let variableCount = this.bodyVariableCount;
          if (variableCount > 0) {
            while (variableCount > 0) {
              components[index]['parameters'].push({
                type: 'text',
                text: this.sendTemplateForm.get(`bodyText${variableCount}`)!
                  .value,
              });

              variableCount--;
            }
          }
        }
      }
    }
    this.formdata.set('whatsAppContent', JSON.stringify(components));

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.sendMessage}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.showTemplate = false;
                this.showTemplateBody = false;
                this.showHeaderOfTemplate = false;
                this.showHeaderMediaOfTemplate = false;
                this.showHeaderTextOfTemplate = false;
                this.showBodyOfTemplate = false;
                this.showFooterOfTemplate = false;
                this.showButtonOfTemplate = false;
                this.selectedTemplate = null;
                this.message = '';
                this.sendTemplateForm.reset();
              } else {
                this.isLoading = false;
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.isLoading = false;
    }
    return;
  }
}
