import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteQuizDialogComponent } from '../../quizzes/delete-quiz-dialog/delete-quiz-dialog.component';
import { AddCategoryDialogComponent } from '../add-category-dialog/add-category-dialog.component';
import { DeleteImageDialogComponent } from '../delete-image-dialog/delete-image-dialog.component';

export interface IMehndiDesign {
  _id: string;
  id: string;
  name: string;
  position: string;
  image: string;
  createdAt: Date;
  isActive: boolean;
  isDeleted: boolean;
}

@Component({
  selector: 'app-list-images',
  templateUrl: './list-images.component.html',
  styleUrls: ['./list-images.component.scss']
})
export class ListImagesComponent implements OnInit {

  userDetails: any;
  dataToEdit: any;

  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  selectedType: any = 'Mehendi';

  selectedCategory: any = 'All';

  constructor(
    private router: Router,
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.fetchAllImages();
  }

  isLoading: boolean = true;
  listOfCategory: any = [];
  listOfDesignImageOriginal: Array<IMehndiDesign> = [];
  listOfDesignImage: Array<IMehndiDesign> = [];

  async fetchAllImages() {
    this.isLoading = true;
    this.listOfDesignImage = [];
    try {
      const param = { admin: true, selectedType: 'Mehendi' };
      const URL = `${webApi.domain + webApi.endPoint.fetchDesigns}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfDesignImageOriginal = data['result'];
          this.listOfDesignImage = this.listOfDesignImageOriginal;

          this.fetchDesignCategory();
        } else {
          this.listOfDesignImageOriginal = [];
          this.listOfDesignImage = this.listOfDesignImageOriginal;

          this.fetchDesignCategory();
        }
      });
    } catch (error) {
      this.listOfDesignImageOriginal = [];
      this.listOfDesignImage = this.listOfDesignImageOriginal;

      this.fetchDesignCategory();
    }
  }
  async fetchDesignCategory() {
    this.isLoading = true;
    this.listOfCategory = [];
    let param = { admin: true };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.fetchDesignCategory}?selectedType=Mehendi`;
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCategory = data['result'];
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  onCategorySelectionChange(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    this.listOfDesignImage = [];
    this.selectedCategory = event.target.value;
    if (this.selectedCategory == 'All') {
      this.listOfDesignImage = this.listOfDesignImageOriginal;
    } else {
      this.listOfDesignImageOriginal.forEach((image: any) => {
        if (image['designCategory']['_id'] == this.selectedCategory) {
          tempSearchList.push(image);
        }
      });
      this.listOfDesignImage = tempSearchList;
    }
  }
  addNewImages() {
    this.toggleOpened();
  }
  addCategory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.width = '30vw';
    dialogConfig.maxHeight = '40vh';
    let dialogRef = this.matDialog.open(AddCategoryDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        this.fetchDesignCategory();
      }
    });
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while adding image details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding image details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        this.fetchAllImages();
        this.showSuccessToaster(
          'Image added successfully',
          'Success'
        );
      } else {
        this.fetchAllImages();
        this.showSuccessToaster(
          'Image added successfully',
          'Success'
        );
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }
  deleteImage(image: any, source: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { image: image, source: source };
    let dialogRef = this.matDialog.open(
      DeleteImageDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {

        return;
      } else {
        if (res['action'] == 'Delete Image') {
          const param = {
            imageId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteDesignImage}`;
          await this.as
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfDesignImageOriginal.forEach((image: any, index: number) => {
                  if (image['_id'] == res['value']['_id']) {
                    this.listOfDesignImageOriginal.splice(index, 1);
                  }
                });
                this.listOfDesignImage.forEach((image: any, index: number) => {
                  if (image['_id'] == res['value']['_id']) {
                    this.listOfDesignImage.splice(index, 1);
                  }
                });

                this.showSuccessToaster(
                  'Image Delete Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while deleteing Image. Try again',
                  'Warning'
                );
              }
            });
        } else if (res['action'] == 'Hide Image') {
          const param = {
            imageId: res['value']['_id'],
            isActive: res['value']['isActive'] ? false : true,
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.hideDesignImage}`;
          await this.as
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfDesignImageOriginal.forEach((image: any, index: number) => {
                  if (image['_id'] == res['value']['_id']) {
                    this.listOfDesignImageOriginal[index]['isActive'] = res['value']['isActive'] ? false : true;
                  }
                });

                this.showSuccessToaster(
                  'Image Status changed Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while changing status of Image. Try again',
                  'Warning'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }


  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
