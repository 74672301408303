<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Link
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addUtmForm" [formGroup]="addUtmForm">
        <div class="row">
            <div class="col-md-6">
                <label for="medium" class="label required">Medium</label>
                <input type="text" placeholder="Medium" id="medium" formControlName="medium" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="source" class="label required">Source</label>
                <input type="text" placeholder="Source" id="source" formControlName="source" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="campaign" class="label required">Campaign</label>
                <input type="text" placeholder="Campaign" id="campaign" formControlName="campaign" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="content" class="label required">Content</label>
                <input type="text" placeholder="Content" id="content" formControlName="content" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="term" class="label required">Term</label>
                <input type="text" placeholder="Term" id="term" formControlName="term" class="input-style">
            </div>
            <!-- <div class="col-md-6">
                <label for="route" class="label required" style="margin-bottom: 1rem;">Select Route</label>
                <select id="route" name="route" class="input-style" formControlName="route"
                    (change)="typeSelected($event)">
                    <option value="Select Route" selected disabled>Select Route</option>
                    <option value="Collection">Collection</option>
                    <option value="Subscription">Subscription</option>
                </select>
            </div> -->
        </div>

        <!-- <div style="display: flex;justify-content: space-between;margin-top: 1rem;">
            <label class="label">Other Parameters</label>
            <div class="circle" (click)="addRemoveParameter(true,0)">
                <mat-icon class="clear-icon" style="font-size: 24px;">add</mat-icon>
            </div>
        </div> -->

        <!-- <div *ngFor="let paramter of otherParameters">

            <div class="row">
                <div class="col-md-6">
                    <label for="key" class="label required">Key:</label>
                    <input type="text" placeholder="Key" id="key" formControlName="course"
                        [(ngModel)]="paramter['key']" class="input-style">
                </div>
                <div class="col-md-6">
                    <label for="value" class="label required">Value:</label>
                    <input type="text" placeholder="Value" id="value" formControlName="course"
                        [(ngModel)]="paramter['value']" class="input-style">
                </div>
            </div>

        </div> -->

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addUtmForm.invalid   ,'save-btn':addUtmForm.valid }"
                value="{{'Save'}}" [disabled]="addUtmForm.invalid   "
                (click)="addEditUTM()" />
        </div>
    </form>
</div>