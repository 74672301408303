<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-utm *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-edit-utm>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;margin-bottom: 2.5rem;">
                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        {{utmData['route']}} - {{utmData['name']}}
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">

                        <div class="col-md-3" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewUTM()">
                                Create Link
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfUTMToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No UTM found
                </div>
            </div>
            <div *ngIf="listOfUTMToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Medium</th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Source</th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Campaign
                        </th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">Content
                        </th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">Term
                        </th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">Click Count
                        </th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">Purchase Count
                        </th>

                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">Copy Link
                        </th>

                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Created Date</th>
                        <!-- <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            View Details</th> -->

                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let utm of listOfUTMToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">

                            <th class="table-data-content">{{utm.medium}}
                            </th>
                            <th class="table-data-content">{{utm.source}}</th>
                            <th style="max-width:12rem" class="table-data-content">{{utm.campaign}}
                            </th>
                            <th style="max-width:12rem" class="table-data-content">{{utm.content}}
                            </th>
                            <th class="table-data-content">{{utm.term}}
                            </th>
                            <th class="table-data-content">{{utm.clickCount}}</th>
                            <th class="table-data-content">{{utm.purchaseCount}}</th>
                            <th (click)="createLink(utm)"
                                style="text-decoration: underline;color: blue;cursor: pointer;"
                                class="table-data-content">Copy Link</th>
                            <th class="table-data-content">{{utm.createdAt |date}}</th>
                            <!-- <th style="max-width:12rem" class="table-data-content">
                                <img (click)="viewDetails(utm)"
                                    style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../../assets/img/view.png'>
                            </th> -->
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>