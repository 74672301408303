import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-plan',
  templateUrl: './add-edit-plan.component.html',
  styleUrls: ['./add-edit-plan.component.scss'],
})
export class AddEditPlanComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  rzpPlanIdControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  priceControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  listingPriceControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  durationControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  creditControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);

  addPlanForm: FormGroup = new FormGroup({
    rzpPlanId: this.rzpPlanIdControl,
    price: this.priceControl,
    listingPrice: this.listingPriceControl,
    duration: this.durationControl,
    creditToGive: this.creditControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addPlanForm = this.formBuilder.group({
      rzpPlanId: this.rzpPlanIdControl,
      price: this.priceControl,
      listingPrice: this.listingPriceControl,
      duration: this.durationControl,
      creditToGive: this.creditControl,
    });
    if (this.dataToEdit != null) {
      this.addPlanForm.get('rzpPlanId')?.setValue(this.dataToEdit['rzpPlanId']);
      this.addPlanForm
        .get('listingPrice')
        ?.setValue(this.dataToEdit['listingPrice']);
      this.addPlanForm.get('price')?.setValue(this.dataToEdit['price']);
      this.addPlanForm.get('creditToGive')?.setValue(this.dataToEdit['creditToGive']);
      this.addPlanForm.get('duration')?.setValue(this.dataToEdit['duration']);
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('planId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('planId', '0');
    }

    this.formdata.set('rzpPlanId', this.addPlanForm.get('rzpPlanId')?.value);
    this.formdata.set('price', this.addPlanForm.get('price')?.value);
    this.formdata.set(
      'listingPrice',
      this.addPlanForm.get('listingPrice')?.value
    );
    this.formdata.set('duration', this.addPlanForm.get('duration')?.value);
    this.formdata.set('creditToGive', this.addPlanForm.get('creditToGive')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.createAndUpdatePlan}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addPlanForm.reset();
                // this.defaultCoverImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
