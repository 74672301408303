import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { webApi } from 'src/app/api';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
    userRole: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
  });

  userDetail: any;
  isAdmin: boolean = true;
  isValidUser: boolean = false;
  isContinueClicked: boolean = false;
  selectedRole: any;
  windowRef: any;
  termsCondition: any = true;

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetail != null) {
      // this.as.gotoDefault(this.userDetail);
      this.as.sideBar();
    }
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
      userRole: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
    });
  }

  onRoleSelectionChange(event: any) {
    console.log(event.target.value);
    this.selectedRole = event.target.value;
    if (event.target.value == 'Admin') {
      this.isAdmin = true;
      this.loginForm = this.formBuilder.group({
        email: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
        password: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
        userRole: new FormControl('Select Role', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
      });
    } else {
      this.isAdmin = false;
      this.loginForm = this.formBuilder.group({
        mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
        userRole: new FormControl('Select Role', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
      });
    }
    this.termsCondition = true;
  }

  async checkForValidUser() {
    this.isContinueClicked = true;
    if (this.loginForm.invalid) {
      this.isContinueClicked = false;
      return false;
    }
    let param;
    let URL;
    if (this.isAdmin) {
      param = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,
      };
      URL = `${webApi.domain + webApi.endPoint.verifyAdminUser}`;
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['type']) {
          this.isValidUser = true;
          localStorage.setItem(
            'MERI-SAHELI-WEB',
            JSON.stringify({
              accessToken: data['accessToken'],
              name:
                data['result']['firstName'] + ' ' + data['result']['lastName'],
              phone: data['result']['phone'],
              role: 'Admin',
            })
          );
          this.isContinueClicked = false;
          this.as.sidebarOptions = this.as.sidebarOptionsForAdmin;
          this.router.navigate([this.as.sidebarOptionsForAdmin[0]['route']]);
        } else {
          this.isValidUser = false;
          this.isContinueClicked = false;

          this.showErrorToaster(
            'Invalid Email id or password. Kindly check and try again',
            'Invalid User'
          );
        }
      });
    } else {
      param = {
        mobileNo: this.loginForm.get('mobileNo')?.value,
        userRole: this.selectedRole,
      };
      URL = `${webApi.domain + webApi.endPoint.checkForEmployeeExistance}`;
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['type']) {
          this.isValidUser = true;
          this.gotoConfirmOtp();
          this.isContinueClicked = false;
        } else {
          this.isContinueClicked = false;
          this.isValidUser = false;
          this.showErrorToaster(
            `Not a valid ${this.selectedRole}`,
            'Invalid User'
          );
        }
      });
    }
    return;
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  async gotoConfirmOtp() {
    if (this.loginForm.invalid) {
      this.isContinueClicked = false;
      return false;
    }
    const param = {
      mobileNo: String(this.loginForm.get('mobileNo')!.value),
    };
    const URL = `${webApi.domain + webApi.endPoint.sendOTPtoUser}`;
    await this.as.fetchDataFromDB(param, URL).then((data: any) => {
      if (data['success']) {
        this.router.navigate(['auth/verify-otp'], {
          queryParams: {
            mobileNo: this.loginForm.get('mobileNo')!.value,
            role: this.selectedRole,
          },
        });
      } else {
        this.showErrorToaster('Something went wrong', 'Alert');
      }
    });

    return;
  }

  termsAndCondition(event: any) {
    this.termsCondition = event.checked;
  }

  moveToInfluencerTermsAndCondition() {
    this.router.navigate([`/influencer-tnc`]);
  }
}
