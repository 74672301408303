<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Employee
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addEmployeeForm" [formGroup]="addEmployeeForm">
        <div class="row">
            <div class="col-md-6">
                <label for="firstName" class="label required">First name</label>
                <input type="text" placeholder="First name" id="firstName" formControlName="firstName" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="lastName" class="label required">Last name</label>
                <input type="text" placeholder="Last name" id="lastName" formControlName="lastName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="phone" class="label required">Phone Number</label>
                <input type="text" placeholder="Phone Number" id="phone" formControlName="phone" class="input-style" maxlength="10">
            </div>
            <div class="col-md-6">
                <label for="email" class="label required">Email</label>
                <input type="text" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="department" class="label required">Department</label>
                <select class="input-field" name="department" id="department" aria-placeholder="Select Department" formControlName="department" class="input-style">
                    <option value="Select Department" selected disabled>Select Department</option>
                    <option value="Content">Content</option>

                </select>
            </div>

            <div class="col-md-6">
                <label for="role" class="label required">Role</label>

                <select class="input-field" name="role" id="role" aria-placeholder="Select Role" formControlName="role" class="input-style" (change)="onRoleSelectionChange($event)">
                    <option value="Select Role" selected disabled>Select Role</option>
                    <option value="Unassigned">Unassigned</option>
                    <option value="Author">Author</option>
                    <option value="Content Publisher">Content Publisher</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="gender" class="label required">Gender</label>
                <select id="gender" name="gender" class="input-style" formControlName="gender">
                    <option value="Select Gender" selected disabled>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="dob" class="label required">Date of birth</label>
                <input type="date" id="dob" formControlName="dob" class="input-style">

            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="franchise" class="label required" style="margin-bottom: 1em;">Select Access List</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Access List'" [ngModelOptions]="{standalone: true}" [settings]="lngDropdownSettings" [data]="listOfRights" [(ngModel)]="selectedRights" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="row">

            <div class="col-md-12">
                <label for="address" class="label required" style="margin-top: 1em;">Address </label>
                <input type="text" placeholder="Address" id="address" formControlName="address" class="input-style">
            </div>

        </div>
        <div class="row">

            <div class="col-md-6">
                <label for="pincode" class="label required">Pincode</label>
                <input type="text" placeholder="Pincode" id="pincode" formControlName="pincode" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="city" class="label required">City</label>
                <input type="text" placeholder="City" id="city" formControlName="city" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="state" class="label required">State</label>
                <input type="text" placeholder="State" id="state" formControlName="state" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="Adhaar Card" class="label">Adhaar Card</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultAdhaarImage!=null" src="{{defaultAdhaarImage}}" class="proofImage">
                                <label *ngIf="defaultAdhaarImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultAdhaarImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultAdhaarImage!=null && defaultAdhaarImage.includes('https')" class="imageLabelDownload" (click)="downloadImage(defaultAdhaarImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="PAN Card" class="label">PAN Card</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultPANImage!=null" src="{{defaultPANImage}}" class="proofImage">
                                <label *ngIf="defaultPANImage==null" class="imageLabel"> Upload
                                    <input (change)="displayPAN($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultPANImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayPAN($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultPANImage!=null  && defaultPANImage.includes('https')" class="imageLabelDownload" (click)="downloadImage(defaultPANImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':addEmployeeForm.invalid|| addEmployeeForm.get('role')!.value=='Select Role'  ,'save-btn':addEmployeeForm.valid  && addEmployeeForm.get('role')!.value!='Select Role'}" value="{{'Save'}}" [disabled]="addEmployeeForm.invalid || addEmployeeForm.get('role')!.value=='Select Role' "
                (click)="addEditEmployee()" />
        </div>
    </form>
</div>