import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListComplianceComponent } from './list-compliance/list-compliance.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BannerManagementComponent } from './banner-management/banner-management.component';
import { QuizTncComponent } from './quiz-tnc/quiz-tnc.component';
import { QuizHindiTncComponent } from './quiz-hindi-tnc/quiz-hindi-tnc.component';
import { InfluencerTcComponent } from './influencer-tc/influencer-tc.component';
import { BabyOfTheWeekTcComponent } from './baby-of-the-week-tc/baby-of-the-week-tc.component';




const routes: Routes = [
  {
    path: '',
    component: ListComplianceComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
  },
  {
    path: 'quiz-terms-and-condition',
    component: QuizTncComponent,
  },
  {
    path: 'quiz-hindi-terms-and-condition',
    component: QuizHindiTncComponent,
  },
  {
    path: 'influencer-terms-and-condition',
    component: InfluencerTcComponent,
  },
  {
    path: 'baby-of-the-week-tc',
    component: BabyOfTheWeekTcComponent,
  },
  {
    path: 'banner-management',
    component: BannerManagementComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'admin/compliance' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComplianceRoutingModule { }
