import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-main-notification',
  templateUrl: './main-notification.component.html',
  styleUrls: ['./main-notification.component.scss'],
})
export class MainNotificationComponent implements OnInit {
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}
  dropDownFormControl = new FormControl('Select Criteria', [
    Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  subjectControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  bodyControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);

  formdata = new FormData();
  myHeaders = new Headers();
  userDetails: any;
  notification: FormGroup = new FormGroup({
    dropdown: this.dropDownFormControl,
    subject: this.subjectControl,
    body: this.bodyControl,
  });
  ngOnInit(): void {
    this.notification = this.formBuilder.group({
      dropdown: this.dropDownFormControl,
      subject: this.subjectControl,
      body: this.bodyControl,
    });
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  isLoading: boolean = false;
  listOfCourseOriginal: any = [];
  defaultCoverImage: string =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

  listOfCourse: any = [];
  selectedCourse: String = '';
  searchCourse: String = '';
  listOfUser: any = [];
  radioSelectedValue: String = '';
  expirySelectedValue: String = '';
  dropDownValue: any;
  image: String = '';
  selectedFile: any = null;
  selectedFileName: any = '';
  message: String = 'Choose criteria';

  listOfPushNotification: any = [
    { title: 'App' },
    // { title: 'SMS' },
    { title: 'Email' },
  ];

  notificationTypes: any = [];

  selectNotification(event: any) {
    const index = this.notificationTypes.findIndex(
      (notify: any) => notify == event['title']
    );
    if (index == -1) {
      this.notificationTypes.push(event['title']);
    } else {
      this.notificationTypes.splice(index, 1);
    }
  }

  displayPhoto(event: any) {
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
  async sendNotification() {
    this.isLoading = true;
    if (
      this.defaultCoverImage !=
      'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg'
    ) {
      this.formdata.set('image', this.selectedFile);
    } else {
      this.formdata.set('image', '');
    }
    this.formdata.set('subject', this.notification.get('subject')?.value);
    this.formdata.set('body', this.notification.get('body')?.value);
    this.formdata.set('type', this.dropDownValue);
    this.formdata.set('platform', JSON.stringify(this.notificationTypes));

    var requestOptions: RequestInit = {
      method: 'POST',
      // headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };

    const URL = `${webApi.domain + webApi.endPoint.sendNotification}`;

    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.notificationTypes = [];
                this.notification.reset();
                this.dropDownValue = null;
                this.defaultCoverImage =
                  'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
                this.selectedFile = null;
                this.selectedFileName = null;
                this.showSuccessToaster('Message Sent Successfully', 'Success');
              } else {
                this.showErrorToaster(
                  'Error Occurred while sending message. Try again',
                  'Error'
                );
                this.notification.reset();
                this.resetValue();
                this.defaultCoverImage =
                  'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
                this.selectedFile = null;
                this.selectedFileName = null;
                this.isLoading = false;
                this.dropDownValue = null;
                this.notificationTypes = [];
              }
            });
          }
        })
        .catch((error) => {
          this.showErrorToaster(
            'Error Occurred while sending message. Try again',
            'Error'
          );
          this.notification.reset();
          this.resetValue();
          this.isLoading = false;
          this.dropDownValue = null;
          this.notificationTypes = [];
        });
      return;
    } catch (error) {
      this.showErrorToaster(
        'Error Occurred while sending message. Try again',
        'Error'
      );
      this.notification.reset();
      this.resetValue();
      this.isLoading = false;
      this.notificationTypes = [];
    }
  }

  resetValue() {
    this.isLoading = false;
    this.image = '';
    this.selectedFile = '';
    this.selectedFileName = '';
    this.notificationTypes = [];
    this.notification.reset();
    this.defaultCoverImage =
      'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

    this.notification.get('dropdown')?.setValue('Select Criteria');
  }

  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    console.log(this.radioSelectedValue);
  }
  expiryChangeHandler(event: any) {
    this.expirySelectedValue = event.target.value;
    console.log(this.expirySelectedValue);
  }

  dropDownChangeHandler(event: any) {
    this.notification.get('dropdown')?.setValue(event.target.value);
    this.dropDownValue = event.target.value;
    console.log(this.dropDownValue);
  }
  selectedCourseChangeHandler(event: any) {
    this.selectedCourse = event.target.value;
    console.log(this.selectedCourse);
  }
  searchCourseChangeHandler(event: any) {
    this.searchCourse = event.target.value;
    console.log(this.searchCourse);
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
