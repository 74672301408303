<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Influencer
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addInfluencerForm" [formGroup]="addInfluencerForm">

        <div class="row">
            <div class="col-md-12">
                <label for="firstName" class="label required">First Name</label>
                <input type="text" placeholder="First Name" id="firstName" formControlName="firstName"
                    class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="lastName" class="label required">Last Name</label>
                <input type="text" placeholder="Last Name" id="lastName" formControlName="lastName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="phone" class="label required">Phone</label>
                <input type="text" placeholder="Phone" id="phone" formControlName="phone" class="input-style"
                    maxlength="10">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="email" class="label required">Email</label>
                <input type="email" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-md-6">
                <label for="commissionInPercent" class="label required">Commission in Percentage</label>
                <input type="text" placeholder="Enter commission in percent" id="commissionInPercent" formControlName="commissionInPercent"
                    maxlength="2" class="input-style">
            </div> -->
            <div class="col-md-6">
                <label for="commissionInAmount" class="label required">Commission in Rupeess</label>
                <input type="text" placeholder="Enter commission in amount" id="commissionInAmount" formControlName="commissionInAmount"
                    maxlength="3" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <input type="checkbox" class="input-style" [(ngModel)]="sendMessage"
                    [ngModelOptions]="{standalone: true}" style="height: 1em;width: auto;margin-right: 1em;">
                <label for="email" class="label">Send WhatsApp Message</label>
            </div>
        </div>

        <div class="save" style="float: right;">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addInfluencerForm.invalid  ,'save-btn':addInfluencerForm.valid }"
                value="{{'Save'}}" [disabled]="addInfluencerForm.invalid " (click)="addEditUser()" />
        </div>

    </form>
</div>