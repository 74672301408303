import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-employee-sidebar',
  templateUrl: './add-employee-sidebar.component.html',
  styleUrls: ['./add-employee-sidebar.component.scss'],
})
export class AddEmployeeSidebarComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  listOfRights: any = [
    { name: 'Dashboard' },
    { name: 'User Management' },
    { name: 'Employee Management' },
    { name: 'Brand Management' },
    { name: 'Quiz' },
    { name: 'Magazine Management' },
    { name: 'Banner Management' },
    { name: 'Campaign' },
    { name: 'Referral' },
    { name: 'Influencer' },
    { name: 'Notification Management' },
    { name: 'Offer Management' },
    { name: 'Video Management' },
    { name: 'Plan Management' },
    { name: 'Report' },
    { name: 'Transaction' },
    { name: 'Compliance' },
    { name: 'Mehndi' },
  ];

  selectedRights: any = [];
  showFranchiseSelection: boolean = false;
  showAreaManagerSelection: boolean = false;
  isLoading: boolean = false;

  // Adhaar Image
  selectedAdhaarImage: any = null;
  selectedAdhaarImageName: any = null;
  defaultAdhaarImage: any;
  // PAN Image
  selectedPANImage: any = null;
  selectedPANImageName: any = null;
  defaultPANImage: any;

  lngDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  myHeaders = new Headers();
  formdata = new FormData();

  firstNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  lastNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  emailControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  phoneControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    Validators.maxLength(10),
    Validators.pattern('^[0-9]*$'),
  ]);
  dobControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  genderControl = new FormControl('Select Gender', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  departmentControl = new FormControl('Select Department', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  roleControl = new FormControl('Select Role', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  addressControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  cityControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  stateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  pincodeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  addEmployeeForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    dob: this.dobControl,
    department: this.departmentControl,
    gender: this.genderControl,
    role: this.roleControl,
    address: this.addressControl,
    city: this.cityControl,
    state: this.stateControl,
    pincode: this.pincodeControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addEmployeeForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      dob: this.dobControl,
      gender: this.genderControl,
      role: this.roleControl,
      department: this.departmentControl,
      address: this.addressControl,
      city: this.cityControl,
      state: this.stateControl,
      pincode: this.pincodeControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addEmployeeForm = this.formBuilder.group({
        firstName: this.firstNameControl,
        lastName: this.lastNameControl,
        email: this.emailControl,
        phone: this.phoneControl,
        dob: this.dobControl,
        gender: this.genderControl,
        role: this.roleControl,
        department: this.departmentControl,
        address: this.addressControl,
        city: this.cityControl,
        state: this.stateControl,
        pincode: this.pincodeControl,
      });

      this.addEmployeeForm
        .get('firstName')
        ?.setValue(this.dataToEdit['firstName']);
      this.addEmployeeForm
        .get('lastName')
        ?.setValue(this.dataToEdit['lastName']);
      // this.addEmployeeForm.get('status')?.setValue(this.dataToEdit['status']);
      this.addEmployeeForm
        .get('department')
        ?.setValue(this.dataToEdit['department']);
      this.addEmployeeForm.get('role')?.setValue(this.dataToEdit['role']);
      this.addEmployeeForm.get('email')?.setValue(this.dataToEdit['email']);
      this.addEmployeeForm.get('phone')?.setValue(this.dataToEdit['phone']);
      this.addEmployeeForm
        .get('dob')
        ?.setValue(
          formatDate(new Date(this.dataToEdit['dob']), 'yyyy-MM-dd', 'en-US')
        );
      this.addEmployeeForm.get('gender')?.setValue(this.dataToEdit['gender']);
      this.addEmployeeForm
        .get('address')
        ?.setValue(this.dataToEdit['address']['addressLine']);
      this.addEmployeeForm
        .get('city')
        ?.setValue(this.dataToEdit['address']['city']);
      this.addEmployeeForm
        .get('state')
        ?.setValue(this.dataToEdit['address']['state']);
      this.addEmployeeForm
        .get('pincode')
        ?.setValue(this.dataToEdit['address']['zipcode']);
      this.defaultAdhaarImage =
        this.dataToEdit['proof'] == null
          ? null
          : this.dataToEdit['proof']['aadhaarUrl'];
      this.defaultPANImage =
        this.dataToEdit['proof'] == null
          ? null
          : this.dataToEdit['proof']['panCardUrl'];
      this.dataToEdit['privilegeList'].forEach((element: any) => {
        this.selectedRights.push({ name: element });
      });
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  onRoleSelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;

    this.addEmployeeForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      dob: this.dobControl,
      gender: this.genderControl,
      role: this.roleControl,
      department: this.departmentControl,
      address: this.addressControl,
      city: this.cityControl,
      state: this.stateControl,
      pincode: this.pincodeControl,
    });
    this.showAreaManagerSelection = false;
    this.showFranchiseSelection = false;
  }

  displayAdhaar(event: any) {
    this.selectedAdhaarImage = event.target.files[0];
    this.selectedAdhaarImageName = this.selectedAdhaarImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultAdhaarImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedAdhaarImage);
  }
  displayPAN(event: any) {
    this.selectedPANImage = event.target.files[0];
    this.selectedPANImageName = this.selectedPANImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultPANImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedPANImage);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  addEditEmployee() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('employeeId', this.dataToEdit['_id']);
      this.formdata.append('proof', JSON.stringify(this.dataToEdit['proof']));
    } else {
      this.formdata.append('employeeId', '0');
    }

    if (this.selectedPANImage != null) {
      this.formdata.append('panCardUrl', this.selectedPANImage);
    }

    if (this.selectedAdhaarImage != null) {
      this.formdata.append('aadhaarUrl', this.selectedAdhaarImage);
    }

    this.formdata.append(
      'firstName',
      this.addEmployeeForm.get('firstName')?.value
    );

    this.formdata.append(
      'lastName',
      this.addEmployeeForm.get('lastName')?.value
    );
    let rights: any = [];
    this.selectedRights.forEach((element: any) => {
      rights.push(element['name']);
    });
    this.formdata.append('privilegeList', JSON.stringify(rights));
    this.formdata.append('phone', this.addEmployeeForm.get('phone')?.value);
    this.formdata.append('dob', this.addEmployeeForm.get('dob')?.value);
    this.formdata.append('email', this.addEmployeeForm.get('email')?.value);
    this.formdata.append('gender', this.addEmployeeForm.get('gender')?.value);
    this.formdata.append('role', this.addEmployeeForm.get('role')?.value);
    if (this.addEmployeeForm.get('role')?.value == 'FOS') {
      this.formdata.append('target', this.addEmployeeForm.get('target')?.value);
    }

    this.formdata.append(
      'department',
      this.addEmployeeForm.get('department')?.value
    );

    this.formdata.append(
      'addressLine',
      this.addEmployeeForm.get('address')?.value
    );
    this.formdata.append('city', this.addEmployeeForm.get('city')?.value);
    this.formdata.append('state', this.addEmployeeForm.get('state')?.value);
    this.formdata.append('zipcode', this.addEmployeeForm.get('pincode')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.dataToEdit != null) {
      URL = `${webApi.domain + webApi.endPoint.registerEmployee}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.registerEmployee}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEmployeeForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  downloadImage(url: any) {
    window.open(url);
  }
}
