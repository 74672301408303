import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  styleUrls: ['./play-video.component.scss']
})
export class PlayVideoComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<PlayVideoComponent>,
    private toastr: ToastrService, protected _sanitizer: DomSanitizer,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // dialogRef.disableClose = true;
  }
  ngOnInit(): void { }
  getLink() {
    return this.data['video']['video'];
  }
}
