<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <!-- [dataToEdit]="gameDataToEdit" -->
            <app-create-quiz-contest *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)">
            </app-create-quiz-contest>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;margin-bottom: .5rem;">
                <div class="col-md-4 top-heading" style="cursor: pointer;" (click)="goToQuiz()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span style="font-size: large;color: #1f5ce1;cursor: pointer;">
                        Quiz Contest
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">

                        <div class="col-md-2" style="text-align: center;margin-right: 2.2rem;">
                            <button class="add-new-emp" (click)="addNewQuizContest()" style="width: 12rem;">
                                Add New Contest
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfQuizContestToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No QuizContest found
                </div>
            </div>
            <div *ngIf="listOfQuizContestToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content" style="text-align: left;">
                            Quiz Name</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Top Winners Count</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Start Date</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            End Date</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Winners</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Created Date
                        </th>

                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let quiz of listOfQuizContestToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content" style="word-break: break-all;width: 26rem;text-align: left;">
                                {{quiz.quiz['title']}}</th>
                            <th class="table-data-content">{{quiz.numberOfWinners}}</th>
                            <th class="table-data-content">{{quiz.startDate |date}}</th>
                            <th class="table-data-content">{{quiz.endDate |date}}</th>
                            <!-- <th class="table-data-content">{{quiz.userList.length}}</th> -->
                            <th class="table-data-content">
                                {{quiz.userList.length}}&nbsp;&nbsp;<img (click)="viewUserDetails(quiz)"
                                    style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/view.png'>
                            </th>
                            <th class="table-data-content">{{quiz.createdAt |date}}</th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10"></div>
            </div>


        </div>
    </ng-sidebar-container>
</div>