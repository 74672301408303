import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewQuizComponent } from './add-new-quiz/add-new-quiz.component';
import { ListQuizAuthorComponent } from './list-quiz-author/list-quiz-author.component';
import { ListQuizCategoryComponent } from './list-quiz-category/list-quiz-category.component';
import { ListQuizContestComponent } from './list-quiz-contest/list-quiz-contest.component';
import { ListQuizOptionsComponent } from './list-quiz-options/list-quiz-options.component';
import { ListQuizComponent } from './list-quiz/list-quiz.component';

const routes: Routes = [
  {
    path: '',
    component: ListQuizOptionsComponent,
  },
  {
    path: 'quiz-category',
    component: ListQuizCategoryComponent,
  },
  {
    path: 'quiz-author',
    component: ListQuizAuthorComponent,
  },
  {
    path: 'quiz-detail',
    component: ListQuizComponent,
  },
  {
    path: 'add-edit-quiz',
    component: AddNewQuizComponent,
  },

  {
    path: 'quiz-contest',
    component: ListQuizContestComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/quiz' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuizzesRoutingModule {}
