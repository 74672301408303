<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-referral-product *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit" [listOfProduct]="listOfProduct">
            </app-add-referral-product>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <div class="row align-center" style="justify-content: space-between;">

                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;cursor: pointer;">
                        Referral Product
                    </span>
                </div>
                <div class="col-md-8" style="text-align-last: end;">
                    <div class="row align-center">
                        <div class="col-md-4" style="text-align: center;">
                            <button class="add-new-emp" (click)="addProduct()">
                                Add Product
                            </button>
                        </div>
                    </div>
                </div>

            </div>


            <!-- Tabel Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfProductToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Product found
                </div>
            </div>
            <div *ngIf="listOfProductToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" class="table-heading-content">
                            Type</th>
                        <th style="cursor: pointer;" class="table-heading-content">
                            Product</th>

                        <th style="cursor: pointer;" class="table-heading-content">
                            L1 Commission</th>
                        <th style="cursor: pointer;" class="table-heading-content">
                            L2 Commission</th>
                        <th style="cursor: pointer;" data-name="createdAt" scope="col" class="table-heading-content">
                            Created Date
                        </th>
                        <th style="cursor: pointer;" class="table-heading-content">Active
                        </th>

                        <th class="table-heading-content">Action</th>

                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let product of listOfProductToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                            <th class="table-data-content">{{product.type}} </th>
                            <th class="table-data-content">
                                {{product.type=='BOOK'?product.book['title']:(product.type=='COLLECTION'?product.bookCollection['name']:product.plan['price'])}}
                            </th>

                            <th class="table-data-content">
                                {{product.singleLvlCommission==null?'N.A.':getCommissionValue(product.singleLvlCommission)}}
                            </th>
                            <th class="table-data-content">
                                {{product.doubleLvlCommission==null?'N.A.':getCommissionValue(product.doubleLvlCommission)}}
                            </th>

                            <th class="table-data-content">{{product.createdAt |date}}</th>
                            <th class="table-data-content">
                                <img *ngIf="product.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(product,false)" slot="start" />
                                <img *ngIf="!product.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(product,true)" slot="start" />
                            </th>



                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editProduct(product)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deleteProduct(product)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10">

                </div>
            </div>


        </div>
    </ng-sidebar-container>
</div>