import { Injectable, OnDestroy } from '@angular/core';
import { Observable, timer, Subscription, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap, share, retry, takeUntil } from 'rxjs/operators';
import { WA_API } from 'src/app/api';
export interface MessageInfo {
  _id: string;
  messageId: string;
  sender: string;
  sender_wa_id: string;
  recipient: string;
  recipient_wa_id: string;
  contentType: string;
  content: any;
  type: string;
  templateId: any;
  status: string;
  sentDate: Date;
  deliverDate: Date;
  readDate: Date;
  createdAt: Date;
  campaignId: string;
  clientId: string;
}
@Injectable()
export class MessageService implements OnDestroy {
  chat: any;
  allMessages$!: Observable<MessageInfo[]>;
  private stopPolling = new Subject();
  constructor(private http: HttpClient) {
    if (this.chat != null && this.chat != undefined) {
      this.allMessages$ = timer(1, 3000).pipe(
        switchMap(() =>
          http.get<MessageInfo[]>(
            `${WA_API.domain + WA_API.endPoint.fetchMessagesByChatId}/${
              this.chat._id
            }`
          )
        ),
        retry(),
        share(),
        takeUntil(this.stopPolling)
      );
    }
  }
  getMessageInitially() {
    this.allMessages$ = timer(1, 3000).pipe(
      switchMap(() =>
        this.http.get<MessageInfo[]>(
          `${WA_API.domain + WA_API.endPoint.fetchMessagesByChatId}/${
            this.chat._id
          }`
        )
      ),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }
  getAllMessages(): Observable<MessageInfo[]> {
    return this.allMessages$!;
  }
  ngOnDestroy() {
    this.stopPolling.next();
  }
}
