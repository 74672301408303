<div *ngIf="data['source']=='Delete Game'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this game?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteGame()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Active Game'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['game']['isActive']?'active': 'inactive'}} this game?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeGame()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete GameCategory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this game category?
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteGameCategory()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Active GameCategory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['game']['isActive']?'active': 'inactive'}} this game category?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeGameCategory()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Collection'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this collection?
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteCollection()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Active Collection'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['collection']['isActive']?'active': 'inactive'}} this collection?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeCollection()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete ContestCategory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this category?
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteContestCategory()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Active ContestCategory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['category']['isActive']?'active': 'inactive'}} this category?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeContestCategory()">Yes</button>
    </div>
</div>