import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { MessageService } from '../message.service';
export interface Chat {
  _id: string;
  user1: any;
  user1_wa_id: string;
  user2: any;
  user2_wa_id: string;
  conversationId: string;
  windowExpireTime: Date;
  lastMessageId: any;
  lastMessage: string;
  lastMessageDate: Date;
  createdAt: Date;
  updatedAt: Date;
  clientId: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() conversationClicked: EventEmitter<any> = new EventEmitter();
  searchText: string = '';
  isLoading: boolean = false;
  isLoadingExtraData: boolean = false;
  listOfChats: Array<Chat> = [];
  listOfChatsToDisplay: Array<Chat> = [];
  currentPage: number = 1;
  count: any = 15;
  limit: any = 150;
  skip: any = 0;
  totalCount: any = 0;

  get filteredConversations() {
    return this.listOfChats.filter((conversation: any) => {
      return (
        conversation.name
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        conversation.latestMessage
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    });
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public ms: MessageService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.fetchChats();
  }

  async fetchChats() {
    this.isLoading = true;
    this.listOfChats = [];
    try {
      let URL = '';

      URL = `${WA_API.domain + WA_API.endPoint.fetchAllChats}`;
      const param = {
        user: this.as.meriSaheliId,
        skip: 0,
        limit: 150,
        clientPhone: "8976802882"
      };
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['success']) {
          this.listOfChats = data['result'];
          this.totalCount = data['count'];

          this.listOfChatsToDisplay = this.listOfChats;
          this.isLoading = false;
        } else {
          this.listOfChats = [];
          this.listOfChatsToDisplay = this.listOfChats;
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfChats = [];
      this.isLoading = false;
    }
  }
  async fetchExtraChats(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let URL = '';

      URL = `${WA_API.domain + WA_API.endPoint.fetchAllChats}`;
      const param = {
        user: this.as.meriSaheliId,
        skip: this.skip,
        limit: this.limit,
        clientPhone: "8976802882"
      };
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['success']) {
          data['result'].forEach((chat: any) => {
            this.listOfChats.push(chat);
          });
          this.totalCount = data['count'];
          this.listOfChatsToDisplay = this.listOfChats;
          this.isLoadingExtraData = false;
        } else {
          this.listOfChatsToDisplay = this.listOfChats;
          this.isLoadingExtraData = false;
        }
      });
    } catch (error) {
      this.isLoadingExtraData = false;
    }
  }
  async searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      this.listOfChatsToDisplay = [];
      this.listOfChatsToDisplay = await this.searchChat(serachQuery);
    } else {
      this.listOfChatsToDisplay = this.listOfChats;
    }
  }
  async searchChat(serachQuery: string) {
    try {
      let URL = '';
      let dataReturn: any = [];
      URL = `${WA_API.domain + WA_API.endPoint.searchChat}`;
      const param = {
        searchedString: serachQuery,
        clientPhone: "8976802882"
      };
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['success']) {
          dataReturn = data['result'];
        } else {
          return dataReturn;
        }
      });
      return dataReturn;
    } catch (error) {
      return [];
    }
  }
  getContentData(message: any, contentOf: string, index: number): any {
    let i = 0;
    if (message.content != null && message.content != undefined) {
      message.content.forEach((content: any, ind: number) => {
        if (content['type'] == contentOf) {
          i = ind;
        }
      });
      return message.content[i]['parameters'][index];
    } else {
      return '';
    }
  }
  getTextContentData(message: any, contentOf: string, text: string) {
    let i = 0;
    let ogText = text;
    if (message.content != null && message.content != undefined) {
      message.content.forEach((content: any, index: any) => {
        if (content['type'] == contentOf) {
          i = index;
        }
      });
      let varCount = text.split('{{').length - 1;
      let val = 0;
      while (varCount > 0) {
        ogText = ogText.replace(
          `{{${0 + 1}}}`,
          message.content[i]['parameters'][val]['text']
        );
        val++;
        varCount--;
      }
    }

    return ogText;
  }
  gotoConversation(chat: any) {
    this.ms.chat = chat;
    this.ms.getMessageInitially();
    this.conversationClicked.emit(chat);
  }
  onPageChange(event: any) {
    this.currentPage = event;
    console.log(event);
    if (
      this.currentPage * this.count >=
      this.listOfChats.length - this.count * 3
    ) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraChats(false);
    } else if (this.currentPage * this.count >= this.listOfChats.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraChats(true);
    }
  }
}
