import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-referral-product',
  templateUrl: './add-referral-product.component.html',
  styleUrls: ['./add-referral-product.component.scss']
})
export class AddReferralProductComponent implements OnInit {

  @Input() dataToEdit: any;
  @Input() listOfProduct: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = true;
  isProductExist: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  listOfPlan: any = [];
  selectedPlan: any = [];

  listOfBook: any = [];
  selectedBook: any = [];

  listOfBookCollection: any = [];
  selectedBookCollection: any = [];


  bookDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true, limitSelection: 1,
  };
  planDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'price',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true, limitSelection: 1,
  };
  collectionDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true, limitSelection: 1,
  };


  typeControl = new FormControl('Select Type', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);


  singleLvlCommissionModeControl = new FormControl('Select Mode', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  singleLvlCommissionValueControl = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);

  doubleLvlCommissionModeControl = new FormControl('Select Mode', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  doubleLvlCommissionValueControl = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);

  addEditProductForm: FormGroup = new FormGroup({
    type: this.typeControl,

    singleLvlCommissionMode: this.singleLvlCommissionModeControl,
    singleLvlCommissionValue: this.singleLvlCommissionValueControl,

    doubleLvlCommissionMode: this.doubleLvlCommissionModeControl,
    // doubleLvlCommissionValue: this.doubleLvlCommissionValueControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addEditProductForm = this.formBuilder.group({
      type: this.typeControl,

      singleLvlCommissionMode: this.singleLvlCommissionModeControl,
      singleLvlCommissionValue: this.singleLvlCommissionValueControl,

      doubleLvlCommissionMode: this.doubleLvlCommissionModeControl,
      // doubleLvlCommissionValue: this.doubleLvlCommissionValueControl,
    });
    this.fetchMasterProduct();
  }
  async fetchMasterProduct() {
    this.isLoading = true;
    this.listOfPlan = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchMasterProduct}`;
      const param = {};
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfPlan = data['plan'];
          this.listOfBook = data['book'];
          this.listOfBookCollection = data['bookCollection'];
          this.assignValueToEdit();
        } else {
          this.listOfPlan = [];
          this.assignValueToEdit();
        }
      });
    } catch (error) {
      this.listOfPlan = [];
      this.assignValueToEdit();
    }
  }
  assignValueToEdit() {
    if (this.dataToEdit != null) {

      this.addEditProductForm.get('type')?.setValue(this.dataToEdit['type']);

      if (this.dataToEdit['singleLvlCommission'] != null && this.dataToEdit['singleLvlCommission'] != undefined) {
        this.addEditProductForm.get('singleLvlCommissionMode')?.setValue(this.dataToEdit['singleLvlCommission']['type']);
        this.addEditProductForm.get('singleLvlCommissionValue')?.setValue(this.dataToEdit['singleLvlCommission']['value']);
      }
      if (this.dataToEdit['doubleLvlCommission'] != null && this.dataToEdit['doubleLvlCommission'] != undefined) {
        this.addEditProductForm.get('doubleLvlCommissionMode')?.setValue(this.dataToEdit['doubleLvlCommission']['type']);

        this.addEditProductForm.addControl('doubleLvlCommissionValue', this.doubleLvlCommissionValueControl);
        this.addEditProductForm.get('doubleLvlCommissionValue')?.setValue(this.dataToEdit['doubleLvlCommission']['value']);
      }

      if (this.dataToEdit['type'] == 'BOOK') {
        this.listOfBook.forEach((element: any) => {
          if (element['_id'] == this.dataToEdit['book']['_id']) {
            this.selectedBook.push(element);
          }
        });
      } else if (this.dataToEdit['type'] == 'COLLECTION') {
        this.listOfBookCollection.forEach((element: any) => {
          if (element['_id'] == this.dataToEdit['bookCollection']['_id']) {
            this.selectedBookCollection.push(element);
          }
        });
      } else {
        this.listOfPlan.forEach((element: any) => {
          if (element['_id'] == this.dataToEdit['plan']['_id']) {
            this.selectedPlan.push(element);
          }
        });
      }



    }
    this.isLoading = false;
  }
  onTypeSelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    // if (selectedVal == 'Book') {
    //   this.addEditProductForm.addControl('book', this.bookControl);
    //   this.addEditProductForm.removeControl('plan');
    //   this.addEditProductForm.removeControl('bookCollection');
    // } else if (selectedVal == 'Book Collection') {

    //   this.addEditProductForm.addControl('bookCollection', this.bookCollectionControl);
    //   this.addEditProductForm.removeControl('plan');
    //   this.addEditProductForm.removeControl('book');
    // } else {
    //   this.addEditProductForm.addControl('plan', this.planControl);
    //   this.addEditProductForm.removeControl('bookCollection');
    //   this.addEditProductForm.removeControl('book');
    // }
  }
  onLevel2SelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    if (selectedVal != 'NONE') {
      this.addEditProductForm.addControl('doubleLvlCommissionValue', this.doubleLvlCommissionValueControl);
    } else {
      this.addEditProductForm.removeControl('doubleLvlCommissionValue');
    }
  }
  onItemSelect(item: any) {
    console.log(item);
    this.checkSameProductLogic(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
    this.isProductExist = false;
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  checkSameProductLogic(item:any) {
    let isExists = false;
    let isSameProduct = false;
    this.isProductExist = false;
    if (this.addEditProductForm.get('type')?.value == 'BOOK') {
      this.listOfProduct.forEach((element: any) => {
        if (element['type'] == 'BOOK') {
          if (element['book']['_id'] == item['_id']) {
            isExists = true;
          }
        }
      });
      if (this.dataToEdit != null && this.dataToEdit != undefined) {
        if (this.dataToEdit['type'] == 'BOOK') {
          if (this.dataToEdit['book']['_id'] == item['_id']) {
            isSameProduct = true;
          }
        }
        if (isExists && !isSameProduct) {
          this.isProductExist = true;
        }
      } else {
        if (isExists) {
          this.isProductExist = true;
        }
      }
    }
    else if (this.addEditProductForm.get('type')?.value == 'COLLECTION') {
      this.listOfProduct.forEach((element: any) => {
        if (element['type'] == 'COLLECTION') {
          if (element['bookCollection']['_id'] == item['_id']) {
            isExists = true;
          }
        }
      });
      if (this.dataToEdit != null && this.dataToEdit != undefined) {
        if (this.dataToEdit['type'] == 'COLLECTION') {
          if (this.dataToEdit['bookCollection']['_id'] == item['_id']) {
            isSameProduct = true;
          }
        }
        if (isExists && !isSameProduct) {
          this.isProductExist = true;
        }
      } else {
        if (isExists) {
          this.isProductExist = true;
        }
      }
    }
    else {
      this.listOfProduct.forEach((element: any) => {
        if (element['type'] == 'SUBSCRIPTION') {
          if (element['plan']['_id'] == item['_id']) {
            isExists = true;
          }
        }
      });
      if (this.dataToEdit != null && this.dataToEdit != undefined) {
        if (this.dataToEdit['type'] == 'SUBSCRIPTION') {
          if (this.dataToEdit['plan']['_id'] == item['_id']) {
            isSameProduct = true;
          }
        }
        if (isExists && !isSameProduct) {
          this.isProductExist = true;
        }
      } else {
        if (isExists) {
          this.isProductExist = true;
        }
      }
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditProduct() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('productId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('productId', '0');
    }

    this.formdata.set('type', this.addEditProductForm.get('type')?.value);


    if (this.addEditProductForm.get('type')?.value == 'BOOK') {
      this.formdata.set('book', this.selectedBook[0]['_id']);
    }
    else if (this.addEditProductForm.get('type')?.value == 'COLLECTION') {
      this.formdata.set('bookCollection', this.selectedBookCollection[0]['_id']);
    }
    else {
      this.formdata.set('plan', this.selectedPlan[0]['_id']);
    }


    let singleLvlCommission: any = {
      type: this.addEditProductForm.get('singleLvlCommissionMode')?.value,
      value: this.addEditProductForm.get('singleLvlCommissionValue')?.value,
    };
    this.formdata.set('singleLvlCommission', JSON.stringify(singleLvlCommission));

    let doubleLvlCommission: any = {};
    if (this.addEditProductForm.get('doubleLvlCommissionMode')?.value != 'NONE' && this.addEditProductForm.get('doubleLvlCommissionMode')?.value != 'Select Mode') {
      doubleLvlCommission = {
        type: this.addEditProductForm.get('doubleLvlCommissionMode')?.value,
        value: this.addEditProductForm.get('doubleLvlCommissionValue')?.value,
      };
      this.formdata.set('doubleLvlCommission', JSON.stringify(doubleLvlCommission));
    }

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.addEditReferralProduct}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEditProductForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
