import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceComponent } from './compliance.component';
import { ListComplianceComponent } from './list-compliance/list-compliance.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SidebarModule } from 'ng-sidebar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ComplianceRoutingModule } from './compliance-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { QuillModule } from 'ngx-quill';
import { BannerManagementComponent } from './banner-management/banner-management.component';
import { AddEditBannerComponent } from './add-edit-banner/add-edit-banner.component';
import { QuizTncComponent } from './quiz-tnc/quiz-tnc.component';
import { QuizHindiTncComponent } from './quiz-hindi-tnc/quiz-hindi-tnc.component';
import { InfluencerTcComponent } from './influencer-tc/influencer-tc.component';
import { BabyOfTheWeekTcComponent } from './baby-of-the-week-tc/baby-of-the-week-tc.component';

@NgModule({
  declarations: [
    ListComplianceComponent,
    ComplianceComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    BannerManagementComponent,
    AddEditBannerComponent,
    QuizTncComponent,
    QuizHindiTncComponent,
    InfluencerTcComponent,
    BabyOfTheWeekTcComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    ComplianceRoutingModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link'],
        ],
      },
    }),
  ],
})
export class ComplianceModule {}
