import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-quiz-options',
  templateUrl: './list-quiz-options.component.html',
  styleUrls: ['./list-quiz-options.component.scss'],
})
export class ListQuizOptionsComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  moveToCategory() {
    this.router.navigate(['/admin/quiz-category']);
  }
  moveToAuthor() {
    this.router.navigate(['/admin/quiz-author']);
  }

  moveToAllQuiz() {
    this.router.navigate(['/admin/quiz-detail'], {
      queryParams: { categoryId: '' },
    });
  }

  moveToQuizContest() {
    this.router.navigate(['/admin/quiz-contest'], {
      queryParams: { categoryId: '' },
    });
  }
}
