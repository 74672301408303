import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-add-edit-game-category',
  templateUrl: './add-edit-game-category.component.html',
  styleUrls: ['./add-edit-game-category.component.scss'],
})
export class AddEditGameCategoryComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  nameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  positionControl = new FormControl('', [Validators.required]);

  addGameCategoryForm: FormGroup = new FormGroup({
    name: this.nameControl,
    position: this.positionControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addGameCategoryForm = this.formBuilder.group({
      name: this.nameControl,
      position: this.positionControl,
    });
    if (this.dataToEdit != null) {
      this.addGameCategoryForm.get('name')?.setValue(this.dataToEdit['name']);
      this.addGameCategoryForm
        .get('position')
        ?.setValue(this.dataToEdit['position']);
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('gameCategoryId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('gameCategoryId', '0');
    }

    this.formdata.set('name', this.addGameCategoryForm.get('name')?.value);
    this.formdata.set(
      'position',
      this.addGameCategoryForm.get('position')?.value
    );

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.createAndUpdateGameCategory}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addGameCategoryForm.reset();
                // this.defaultCoverImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
