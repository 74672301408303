import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-list-compliance',
  templateUrl: './list-compliance.component.html',
  styleUrls: ['./list-compliance.component.scss'],
})
export class ListComplianceComponent implements OnInit {
  userDetails: any;
  isLoading: boolean = true;
  isPayuLoading: boolean = false;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public router: Router,
    public activateRoute: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    // if (this.userDetails['role'] != 'Admin') {
    // this.router.navigate([this.appService.sidebarOptions[0]['route']]);
    // }
  }

  ngOnInit(): void {
    this.isLoading = false;
  }
  moveToPrivacyPolicy() {
    this.router.navigate([`/admin/privacy-policy`]);
  }
  moveToTermsAndCondition() {
    this.router.navigate([`/admin/terms-and-condition`]);
  }
  moveToQuizTermsAndCondition() {
    this.router.navigate([`/admin/quiz-terms-and-condition`]);
  }
  moveToQuizHindiTermsAndCondition() {
    this.router.navigate([`/admin/quiz-hindi-terms-and-condition`]);
  }

  moveToInfluencerTermsAndCondition() {
    this.router.navigate([`/admin/influencer-terms-and-condition`]);
  }

  moveToBabyOfTheWeek() {
    this.router.navigate([`/admin/baby-of-the-week-tc`]);
  }

  moveToBanner() {
    this.router.navigate([`/admin/banner-management`]);
  }
  async payuPay() {
    this.isPayuLoading = true;
    // try {
    //   const param = {
    //     amount: 19.99,
    //     user: "62e2547d73b55b6987b5a238"
    //   };
    //   const httpHeaders = new HttpHeaders({
    //     Authorization: `Bearer ${this.userDetails['accessToken']}`,
    //   });
    //   const URL = `${webApi.domain + webApi.endPoint.createPayuTransaction}`;
    //   await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
    //     if (data['success']) {
    //       window.open(`${data['url']}&pg=CC`);
    //       this.isPayuLoading = false;
    //     } else {
    //       this.isPayuLoading = false;
    //     }
    //   });
    // } catch (error) {
    //   this.isPayuLoading = false;
    // }
  }
}
