<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Product
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addEditProductForm" [formGroup]="addEditProductForm">

        <div class="row">
            <div class="col-md-6">
                <label for="type" class="label required">Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="onTypeSelectionChange($event)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="BOOK">Book</option>
                    <option value="COLLECTION">Book Collection</option>
                    <option value="SUBSCRIPTION">Subcription Plan</option>
                </select>
            </div>


        </div>
        <div class="row" *ngIf="this.addEditProductForm.get('type')?.value=='BOOK'">
            <div class="col-md-12">
                <label for="book" class="label required" style="margin-bottom: 1em;">Select Book</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Book'" [disabled]="dataToEdit!=null && dataToEdit!=undefined"
                    [ngModelOptions]="{standalone: true}" [settings]="bookDropdownSettings" [data]="listOfBook"
                    [(ngModel)]="selectedBook" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                <div *ngIf="isProductExist" style="font-size: 12px;margin-bottom: 0.5rem;
                color: #ff2323;">
                    Product Already Exist.You can't add same Product.

                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.addEditProductForm.get('type')?.value=='COLLECTION'">
            <div class="col-md-12">
                <label for="collection" class="label required" style="margin-bottom: 1em;">Select Collection</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Collection'"
                    [ngModelOptions]="{standalone: true}" [settings]="collectionDropdownSettings" [disabled]="dataToEdit!=null && dataToEdit!=undefined"
                    [data]="listOfBookCollection" [(ngModel)]="selectedBookCollection" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                <div *ngIf="isProductExist" style="font-size: 12px;margin-bottom: 0.5rem;
                color: #ff2323;">
                    Product Already Exist.You can't add same Product.

                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.addEditProductForm.get('type')?.value=='SUBSCRIPTION'">
            <div class="col-md-12">
                <label for="plan" class="label required" style="margin-bottom: 1em;">Select Plan</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Plan'" [disabled]="dataToEdit!=null && dataToEdit!=undefined"
                    [ngModelOptions]="{standalone: true}" [settings]="planDropdownSettings" [data]="listOfPlan"
                    [(ngModel)]="selectedPlan" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                <div *ngIf="isProductExist" style="font-size: 12px;margin-bottom: 0.5rem;
                color: #ff2323;">
                    Product Already Exist.You can't add same Product.

                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12">
                <label for="creditToGive" class="label" style="font-size: 16px;font-weight:600;">Level 1
                    Commission</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="singleLvlCommissionMode" class="label required">Select Mode</label>
                <select id="singleLvlCommissionMode" name="singleLvlCommissionMode" class="input-style"
                    formControlName="singleLvlCommissionMode">
                    <option value="Select Mode" selected disabled>Select Mode</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                    <option value="AMOUNT">AMOUNT</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="singleLvlCommissionValue" class="label required">Value</label>
                <input type="number" placeholder="Enter Commission value" id="singleLvlCommissionValue"
                    formControlName="singleLvlCommissionValue" class="input-style">
            </div>

        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="creditToGive" class="label" style="font-size: 16px;font-weight:600;">Level 2
                    Commission</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="doubleLvlCommissionMode" class="label required">Select Mode</label>
                <select id="doubleLvlCommissionMode" name="doubleLvlCommissionMode" class="input-style"
                    (change)="onLevel2SelectionChange($event)" formControlName="doubleLvlCommissionMode">
                    <option value="Select Mode" selected disabled>Select Mode</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                    <option value="AMOUNT">AMOUNT</option>
                    <option value="NONE">NONE</option>
                </select>
            </div>
            <div class="col-md-6"
                *ngIf="this.addEditProductForm.get('doubleLvlCommissionMode')?.value!='NONE' && this.addEditProductForm.get('doubleLvlCommissionMode')?.value!='Select Mode'">
                <label for="doubleLvlCommissionValue" class="label required">Value</label>
                <input type="number" placeholder="Enter Commission value" id="doubleLvlCommissionValue"
                    formControlName="doubleLvlCommissionValue" class="input-style">
            </div>

        </div>
        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addEditProductForm.invalid || isProductExist ,'save-btn':addEditProductForm.valid && !isProductExist }"
                value="{{'Save'}}" [disabled]="addEditProductForm.invalid || isProductExist"
                (click)="addEditProduct()" />
        </div>

    </form>
</div>