<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="margin-top: 1em;" *ngIf="!isLoading">
        <div ng-sidebar-content style="overflow: hidden;padding: 1em;margin-bottom: 1.5rem;">
            <div class="row top-row-sb">
                <div class="user-heading-text">
                    Create Notification
                </div>
            </div>
        </div>

        <div *ngIf="!isLoading">
            <form name="notification" [formGroup]="notification">
                <div style=" margin-left: 1rem;">
                    <div class="row" style="align-items: baseline;">
                        <div class="col-md-8">
                            <label for="type" style="font-size: 1rem;font-weight: 600;margin-right: 1rem;">Choose
                                Criteria:</label>
                            <select name="type" id="cars"
                                style="    height: 1.6rem;border-radius: 4px;width: 9.5rem;font-size: 1rem;"
                                (change)="dropDownChangeHandler($event)" formControlName="dropdown">
                                <option value="Select Criteria" selected disabled>Select Criteria</option>
                                <option value="Subscribed Users">Subscribed Users</option>
                                <option value="Non Subscribed Users">Non Subscribed Users</option>
                                <option value="All Users">All Users</option>
                            </select>
                        </div>
                        <!-- <div class="col-md-3" *ngIf="dropDownValue!=''" style="text-align: end;">
                            <button class="add-new-emp" (click)="fetchUser()">
                                Fetch Users
                            </button>
                        </div> -->
                    </div>
                    <div style="margin-top: 2rem; height: 90vh;">
                        <div style="display: flex; margin-bottom:3rem; margin-left:-2rem">
                            <div *ngFor="let push of listOfPushNotification" style="margin-left: 2rem; ">
                                <input type="checkbox" class="checkbox" [id]="push['title']" [value]="push['title']"
                                    (click)="selectNotification(push)">
                                <label class="courseName" style="font-size: 1.2rem;"
                                    [for]="push">{{push['title']}}</label><br>
                            </div>
                        </div>

                        <!-- <div class="no-course " *ngIf="listOfUser.length==0">
                            <h3>{{message}}</h3>
                        </div> -->


                        <div style="width: 90%;">

                            <div>
                                <div style="margin-bottom: 2rem;">
                                    <label for="app-subject" class="label required"
                                        style="font-size: 1rem;">Notification Subject</label>
                                    <input type="text" id="app-subject" placeholder="Min 2 and Max 200 characters"
                                        maxlength="200" id="title" style="width: 96%;height: 3rem; border-radius: 4px;"
                                        class="title-input" formControlName="subject">
                                </div>
                                <div class="row">
                                    <div class="col-md-7" style="margin-right:3rem;">
                                        <label class="label required"
                                            style="font-size: 1rem; margin-bottom: 1.7rem;">Notification
                                            Body</label>
                                        <div style="height: 11rem;">
                                            <quill-editor class="content-editor" placeholder="" formControlName="body">
                                            </quill-editor>
                                        </div>
                                    </div>
                                    <div class="col-md-4" style="margin-bottom: 2rem;">
                                        <label for="app-subject" class="label" style="font-size: 1rem;">Notification
                                            Image (only for application)
                                            <span style=" margin-left:.2rem;font-size: .7rem;color: red;">Image size
                                                should
                                                be
                                                less than 300kb</span></label>
                                        <div class="course">
                                            <img src="{{defaultCoverImage}}" class="courseImage">
                                            <label class="imageLabel"> {{defaultCoverImage ==
                                                'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg'?
                                                'Upload\
                                                Image':'Edit\
                                                Image'}}
                                                <input (change)="displayPhoto($event)" type="file" accept="image/*">
                                            </label>


                                        </div>
                                        <div class="row">
                                            <div class="save">
                                                <input type="submit"
                                                    [ngClass]="{'save-btn-disabled':notification.invalid || notificationTypes.length==0 || dropDownValue==null  ,'save-btn':notification.valid && notificationTypes.length!=0 && dropDownValue!=null }"
                                                    value="{{'Send'}}"
                                                    [disabled]="notification.invalid || notificationTypes.length==0 || dropDownValue==null"
                                                    (click)="sendNotification()" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>