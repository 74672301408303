import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-influencer',
  templateUrl: './add-edit-influencer.component.html',
  styleUrls: ['./add-edit-influencer.component.scss']
})
export class AddEditInfluencerComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  sendMessage: boolean = false;
  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  phoneControl = new FormControl('', [Validators.required]);
  // commissionInPercentControl = new FormControl('', [Validators.required]);
  commissionInAmountControl = new FormControl('', [Validators.required]);

  addInfluencerForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    commissionInAmount: this.commissionInAmountControl,
  });

  userDetails: any;
  constructor(
    public as: AppService,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService

  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addInfluencerForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      
      commissionInAmount: this.commissionInAmountControl,
    });
    if (this.dataToEdit != null) {
      this.addInfluencerForm.get('firstName')?.setValue(this.dataToEdit['firstName']);
      this.addInfluencerForm.get('email')?.setValue(this.dataToEdit['email']);
      this.addInfluencerForm.get('lastName')?.setValue(this.dataToEdit['lastName']);
      this.addInfluencerForm.get('phone')?.setValue(this.dataToEdit['phone']);
      // this.addInfluencerForm.get('commissionInPercent')?.setValue(this.dataToEdit['commissionInPercent']);
      this.addInfluencerForm.get('commissionInAmount')?.setValue(this.dataToEdit['commissionInAmount']);
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  async addEditUser() {
    this.isLoading = true;

    let param: any = {
      firstName: this.addInfluencerForm.get('firstName')?.value,
      lastName: this.addInfluencerForm.get('lastName')?.value,
      email: this.addInfluencerForm.get('email')?.value,
      phone: this.addInfluencerForm.get('phone')?.value,
      // commissionInPercent: this.addInfluencerForm.get('commissionInPercent')?.value,
      commissionInAmount: this.addInfluencerForm.get('commissionInAmount')?.value,
      sendMessage: this.sendMessage,
      role: 'INFLUENCER',
    };

    if (this.dataToEdit != null) {
      param['influencerId'] = this.dataToEdit['_id'];
    } else {
      param['influencerId'] = '0';
    }

    // formdata.set('firstName', this.addInfluencerForm.get('firstName')?.value);
    // formdata.set('lastName', this.addInfluencerForm.get('lastName')?.value);
    // formdata.set('email', this.addInfluencerForm.get('email')?.value);
    // formdata.set('phone', this.addInfluencerForm.get('phone')?.value);
    // formdata.set('sendMessage', this.sendMessage.toString());
    // formdata.set('role', 'INFLUENCER');

    var URL;

    if (this.dataToEdit == null) {
      URL = `${webApi.domain + webApi.endPoint.createInfluencer}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.updateInfluencer}`;
    }
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.addInfluencerForm.reset();
          this.cancel(data['result']);
        } else {
          this.isLoading = false;
          this.showErrorToaster('Phone number is already in use', 'Error');
        }
      });

    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Phone number is already in use', 'Error');
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
