<div style="width: 35em;" *ngIf="!isView">
    <div class="row">
        <div class="col-md-12" style="display: flex; align-items: center;justify-content: space-between;">
            <div>
                <h1>Upload Winner Video</h1>
            </div>
            <div>
                <img src="../../../../../assets/images/wa-img/close.png" style="height: 1.5em;cursor: pointer;"
                    (click)="close()">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="Contest Image" class="label">Winner Video</label>
            <div class="row">
                <div class="col-md-12">
                    <div class="image">
                        <div class="proof">
                            <img *ngIf="defaultContestWinner!=null" src="{{defaultContestWinner}}" class="proofImage">
                            <label *ngIf="defaultContestWinner==null" class="imageLabel"> Upload
                                <input (change)="displayContestWinnerVideo($event)" type="file"
                                    accept="video/mp4,video/x-m4v,video/*">
                            </label>
                            <label *ngIf="defaultContestWinner!=null" class="imageLabelEdit">
                                <mat-icon class="matIcon">edit</mat-icon>
                                <input (change)="displayContestWinnerVideo($event)" type="file"
                                    accept="video/mp4,video/x-m4v,video/*">
                            </label>
                            <label *ngIf="defaultContestWinner!=null && defaultContestWinner.includes('https')"
                                class="imageLabelDownload" (click)="downloadWinnerVideo(defaultContestWinner)">
                                <mat-icon class="matIcon">download</mat-icon>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="save">
        <input type="submit"
            [ngClass]="{'save-btn-disabled':selectedContestWinner==null,'save-btn':selectedContestWinner!=null}"
            value="{{'Save'}}" [disabled]="selectedContestWinner==null" (click)="uploadVideo()" />
    </div>
</div>
<div style="width: 35em;" *ngIf="isView">
    <div class="row">
        <div class="col-md-12" style="display: flex; align-items: center;justify-content: space-between;">
            <div>
                <h1>Winner Video</h1>
            </div>
            <div>
                <img src="../../../../../assets/images/wa-img/close.png" style="height: 1.5em;cursor: pointer;"
                    (click)="close()">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="Contest Image" class="label">Winner Video</label>
            <div class="row">
                <div class="col-md-12">
                    <video style="height: 40em;max-width: 30em;" class="video" oncontextmenu="return false;" controls
                        controlsList="nodownload" autoplay>
                        <source [src]="getLink()" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>

    </div>

    <div class="save">
        <input type="submit"
            class="save-btn"
            value="{{'Edit'}}" (click)="editVideo()" />
    </div>
</div>