import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteQuizDialogComponent } from '../delete-quiz-dialog/delete-quiz-dialog.component';

@Component({
  selector: 'app-list-quiz',
  templateUrl: './list-quiz.component.html',
  styleUrls: ['./list-quiz.component.scss'],
})
export class ListQuizComponent implements OnInit {
  userDetails: any;
  categoryId: any;

  constructor(
    public routes: ActivatedRoute,

    private router: Router,
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      let orderObj: any = { ...params.keys, ...params };
      this.categoryId = orderObj['params']['categoryId'];
    });
    this.fetchQuiz(this.categoryId);
  }

  isLoading: boolean = true;
  listOfQuizOriginal: any = [];
  listOfQuiz: any = [];
  listOfQuizCategory: any = [];

  async fetchQuiz(categoryId: any) {
    this.isLoading = true;
    this.listOfQuizOriginal = [];
    this.listOfQuiz = [];
    try {
      const param = {
        categoryId: categoryId,
      };
      const URL = `${
        webApi.domain + webApi.endPoint.fetchQuizForAdminCategoryWise
      }`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfQuiz = data['result'];
          this.listOfQuiz.sort((a: any, b: any) =>
            a['position'] > b['position']
              ? 1
              : b['position'] > a['position']
              ? -1
              : 0
          );
          // console.log(this.listOfQuiz);
          this.listOfQuizOriginal = this.listOfQuiz;
          this.listOfQuiz.forEach((qz: any) => {
            qz['isChangingStatus'] = false;
            qz['isDeleting'] = false;
          });
          this.fetchAllCategory();
        } else {
          this.listOfQuiz = [];
          this.listOfQuizOriginal = this.listOfQuiz;
          this.fetchAllCategory();
        }
      });
    } catch (error) {
      this.listOfQuiz = [];
      this.listOfQuizOriginal = this.listOfQuiz;
      this.fetchAllCategory();
    }
  }
  async fetchAllCategory() {
    this.isLoading = true;
    this.listOfQuizCategory = [];
    try {
      const param = {};
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCategory}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfQuizCategory = data['result'];
          this.as.listOfQuizCategory = this.listOfQuizCategory;
          this.isLoading = false;
        } else {
          this.listOfQuizCategory = [];
          this.as.listOfQuizCategory = this.listOfQuizCategory;

          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfQuizCategory = [];
      this.as.listOfQuizCategory = this.listOfQuizCategory;

      this.isLoading = false;
    }
  }
  searchQuiz(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let newCourseList: any = [];
    this.listOfQuiz = [...this.listOfQuizOriginal];
    if (serachQuery != '') {
      this.listOfQuiz.forEach((course: any) => {
        if (
          String(course['title'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newCourseList.push(course);
        }
      });
      this.listOfQuiz = [...newCourseList];
    } else {
      this.listOfQuiz = [...this.listOfQuizOriginal];
    }
  }
  deleteQuiz(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Quiz Module', quiz: quiz };
    let dialogRef = this.matDialog.open(
      DeleteQuizDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfQuiz.forEach((qz: any) => {
          qz['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          quizId: res['value']['_id'],
        };
        const URL = `${webApi.domain + webApi.endPoint.deleteQuiz}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.as.deleteData(param, URL, httpHeaders).then((data: any) => {
          if (data['success']) {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                this.listOfQuiz.splice(index, 1);
              }
            });
            this.listOfQuiz.sort((a: any, b: any) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            );
            this.showSuccessToaster('Quiz Delete Successfully', 'Success');
          } else {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                quiz['isDeleting'] = false;
              }
            });
            this.listOfQuiz.sort((a: any, b: any) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            );
            this.showErrorToaster(
              'Error Occurred while deleteing quiz. Try again',
              'Error'
            );
          }
        });
      }
    });
  }

  async updateQuizStatus(quiz: any) {
    this.listOfQuiz.forEach((qz: any) => {
      if (qz['_id'] == quiz['_id']) {
        qz['isChangingStatus'] = true;
      }
    });
    const param = {
      quizId: quiz['_id'],
      status: quiz['isActive'] ? false : true,
    };
    const URL = `${webApi.domain + webApi.endPoint.updateQuizStatus}`;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.as.deleteData(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfQuiz.forEach((qz: any) => {
          if (qz['_id'] == quiz['_id']) {
            qz['isActive'] = quiz['isActive'] ? false : true;
            qz['isChangingStatus'] = false;
          }
        });
        this.listOfQuiz.sort((a: any, b: any) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
        this.showSuccessToaster(
          !quiz['isActive']
            ? 'Quiz Unpublished Successfully'
            : 'Quiz Published Successfully',
          'Success'
        );
      }
    });
  }
  // async deleteQuiz(quiz: any) {
  //   this.listOfQuiz.forEach((crs: any) => {
  //     if (crs['_id'] == quiz['_id']) {
  //       crs['isDeleting'] = true;
  //     }
  //   });
  //   const param = {
  //     "quizId": quiz['_id'],
  //   };
  //   try {

  //     const URL = `${webApi.domain + webApi.endPoint.deleteQuizAlong}`;
  //     await this.as.deleteSingleModuleFromService(param, URL,).then((data: any) => {
  //       if (data['success']) {
  //         this.listOfQuiz.forEach((qz: any, index: number) => {
  //           if (qz['_id'] == quiz['_id']) {
  //             this.listOfQuiz.splice(index, 1);
  //           }
  //         });
  //         this.showSuccessToaster('Quiz Deleted Successfully', 'Success');
  //       }
  //     });
  //   } catch (error) {
  //     this.listOfQuiz.forEach((qz: any, index: number) => {
  //       if (qz['_id'] == quiz['_id']) {
  //         qz['isDeleting'] = false;
  //       }
  //     });
  //     this.showErrorToaster('Error Occurred while deleting quiz, try again', 'Error');

  //   }
  // }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  addNewQuiz(quizDetails: any) {
    this.as.listOfQuizToEditFromQuizSection = quizDetails;
    this.router.navigate(['/admin/add-edit-quiz']);
  }

  goToQuizCategory() {
    this.router.navigate(['/admin/quiz-category']);
  }
}
