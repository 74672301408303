import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';

export interface UTM {
  _id: string;
  createdAt: Date;
  isActive: boolean;
  medium: string;
  source: string;
  campaign: string;
  content: string;
  term: string;
  route: string;
  otherParameter: any;
  collectionId: any;
  book: any;
  link: string;
  previewLink: string;
  clickCount: number;
  purchaseCount: number;
}

@Component({
  selector: 'app-list-utm',
  templateUrl: './list-utm.component.html',
  styleUrls: ['./list-utm.component.scss']
})
export class ListUtmComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;
  utmData: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });

    if (this.userDetails != null) {
      this.utmData = this.as.utmData;
      this.fetchUTM();
    } else {
      this.router.navigate(['/auth/login']);
      return;
    }
  }

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfUTMToDisplay: Array<UTM> = [];
  listOfUTM: Array<UTM> = [];
  listOfUTMOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchUTM() {
    this.isLoading = true;
    this.listOfUTMToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchUtmByRoute}`;

      let param = {};

      if (this.utmData != null) {
        param = this.utmData;
      }

      await this.as
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfUTMToDisplay = data['result'];
            this.listOfUTM = [...this.listOfUTMToDisplay];
            this.isLoading = false;
          } else {
            this.listOfUTMToDisplay = [];
            this.listOfUTM = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfUTMToDisplay = [];
      this.listOfUTM = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfUTMToDisplay = [];
      this.listOfUTM.forEach((utm: any, index: number) => {
        if (
          String(utm.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(utm.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(utm);
        }
      });
      this.listOfUTMToDisplay = tempSearchList;
    } else {
      this.listOfUTMToDisplay = [];
      this.listOfUTMToDisplay = this.listOfUTM;
    }
  }


  addNewUTM() {
    this.toggleOpened();
  }

  editUTM(magazine: any) {
    let magazineToEdit;
    this.listOfUTMToDisplay.forEach((mgz: any) => {
      if (mgz['_id'] == magazine._id) {
        magazineToEdit = mgz;
      }
    });
    this.dataToEdit = magazineToEdit;
    this.toggleOpened();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing utm details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding utm details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfUTMToDisplay.findIndex(
          (utm: any) => utm['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfUTMToDisplay.push(event);
          this.listOfUTM = [...this.listOfUTMToDisplay];
          this.showSuccessToaster('UTM added successfully', 'Success');
        } else {
          this.listOfUTMToDisplay[index] = event;
          this.listOfUTM = [...this.listOfUTMToDisplay];
          this.showSuccessToaster('UTM Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  async createLink(utm: any) {
    try {
      if (utm.link == '') {
        let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksForUTM}`;
        await this.as
          .postMethodWithToken({ utmId: utm._id }, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.clipboard.copy(data['result']['shortLink']);
              this.showSuccessToaster('Link Copied', 'Success');
            } else {
              this.showErrorToaster('Error occur while creating link', 'Error');
            }
            this.isLoading = false;
          });
      } else {
        this.clipboard.copy(utm.link);
        this.showSuccessToaster('Link Copied', 'Success')
      }

    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }


  viewDetails(utm: UTM) { }
}
