import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-active-delete-dialog-content',
  templateUrl: './active-delete-dialog-content.component.html',
  styleUrls: ['./active-delete-dialog-content.component.scss']
})
export class ActiveDeleteDialogContentComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ActiveDeleteDialogContentComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteContent() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Content',
      value: this.data['content'],
    });
  }
  deleteMagazine() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Magazine',
      value: this.data['magazine'],
    });
  }
  deleteBanner() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Banner',
      value: this.data['banner'],
    });
  }


  activeBanner() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Banner',
      value: this.data['banner'],
    });
  }
  activeProduct() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Product',
      value: this.data['product'],
    });
  }
  deleteProduct() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Product',
      value: this.data['product'],
    });
  }
  activeContent() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Content',
      value: this.data['content'],
    });
  }
  deleteBrand() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Plan',
      value: this.data['brand'],
    });
  }
  activeBrand() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Brand',
      value: this.data['brand'],
    });
  }
  deletePlan() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Plan',
      value: this.data['plan'],
    });
  }
  activePlan() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Plan',
      value: this.data['plan'],
    });
  }
  activeVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Video',
      value: this.data['video'],
    });
  }
  deleteVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Video',
      value: this.data['video'],
    });
  }

  activeInfluencer() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Influencer',
      value: this.data['influencer'],
    });
  }
  deleteInfluencer() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Influencer',
      value: this.data['influencer'],
    });
  }

  activeCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Coupon',
      value: this.data['coupon'],
    });
  }
  deleteCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Coupon',
      value: this.data['coupon'],
    });
  }
}
