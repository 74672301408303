<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-offer *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit"
                [listOfOffer]="listOfOffer">
            </app-add-edit-offer>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top Data -->
            <div class="row align-center" style="width: 100%;">
                <div class="top-heading col-md-3">
                    Offer List
                </div>
                <div class="col-md-9">
                    <div class="row align-center">

                        <div class="col-md-5" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <button class="add-new-emp" (click)="addNewOffer()">
                                Add New Offer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfOfferToDisplay.length==0">
                <div class="no-available">
                    No offer detail available.
                </div>
            </div>
            <div *ngIf="listOfOfferToDisplay.length!=0">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" dat class="table-heading-content">CODE</th>
                        <th style="cursor: pointer;" class="table-heading-content">TYPE</th>
                        <th style="cursor: pointer;" class="table-heading-content">MODE</th>
                        <th style="cursor: pointer;" class="table-heading-content">OFFER TYPE</th>
                        <th style="cursor: pointer;" data class="table-heading-content">START DATE</th>
                        <th style="cursor: pointer;" data class="table-heading-content">END DATE</th>
                        <th class="table-heading-content">ACTION</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let offer of listOfOfferToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">{{offer.code}}</th>
                            <th class="table-data-content">{{offer.discountType}}</th>
                            <th class="table-data-content">{{offer.offerMode}}</th>
                            <th class="table-data-content">{{offer.offerType}}</th>
                            <!-- <th class="table-data-content">{{offer.role}}</th> -->
                            <th class="table-data-content">
                                {{offer.startDate | date}}
                            </th>
                            <th class="table-data-content">
                                {{offer.endDate | date}}
                            </th>
                            <th class="table-data-content ">
                                <div style="display: contents;">
                                    <img title="Edit" (click)="editOffer(offer)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/img/editing.png'>
                                    <img *ngIf="!offer['isActive']" (click)="publishUnpublishOffer(offer)"
                                        class="status-icon-off"
                                        style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-off.svg'>
                                    <img *ngIf="offer['isActive']" (click)="publishUnpublishOffer(offer)"
                                        class="status-icon-on" style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-on.svg'>
                                    <img title="Delete" (click)="deleteOffer(offer)"
                                        style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10"></div>

            </div>
        </div>
    </ng-sidebar-container>

</div>