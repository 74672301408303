<div class="sidebar">
    <div class="display-flex align-items-center justify-content-center p-3">
        <img src="../../../assets/logo/logo.jpg" style="height: 2em;" alt="" srcset="">
    </div>
    <div class="components">
        <div *ngFor="let data of this.appService.sidebarOptions">

            <div *ngIf="data['label']!='Logout'" [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}" >
                <div class="div-style" [routerLink]="data['route']" (click)="navigateHandler(data)">
                    <img [src]="data['img']" class="img" alt="" srcset=""><span>{{data['label']}}</span>
                </div>
            </div>
            <div *ngIf="data['label']=='Logout'" style="position: absolute;bottom: 20px;" [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}" >
                <div class="div-style" [routerLink]="data['route']" (click)="navigateHandler(data)">
                    <img [src]="data['img']" class="img" alt="" srcset=""><span>{{data['label']}}</span>
                </div>
            </div>
        </div>
    </div>

</div>