import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AcceptRejectOrderDialogComponent } from '../accept-reject-order-dialog/accept-reject-order-dialog.component';
import { DeleteEmployeeDialogComponent } from '../delete-employee-dialog/delete-employee-dialog.component';
export interface IEmployee {
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  phone: number;
  email: string;
  role: string;
  target: string;
  franchise: any;
  department: string;
  status: string;
  dob: string;
  gender: string;
  address: Object;
  privilegeList: any;
  proof: Object;
  areaManager: string;
}
@Component({
  selector: 'app-list-employee',
  templateUrl: './list-employee.component.html',
  styleUrls: ['./list-employee.component.scss'],
})
export class ListEmployeeComponent implements OnInit {
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  listOfEmployee: Array<IEmployee> = [];
  listOfEmployeeToDisplay: Array<IEmployee> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    this.fetchAllEmployees();
  }
  getInitialLetter(str: string) {
    var matches = str.match(/\b(\w)/g);
    return matches?.join('').toUpperCase();
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfEmployeeToDisplay = [];

      this.listOfEmployee.forEach((employee: any, index: number) => {
        if (
          String(employee['firstName'] + ' ' + employee['lastName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['lastName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['phone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['email'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(employee);
        }
      });
      this.listOfEmployeeToDisplay = [];
      this.listOfEmployeeToDisplay = tempSearchList;
    } else {
      this.listOfEmployeeToDisplay = this.listOfEmployee;
    }
  }
  onEmployeeRoleSelectionChange(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    this.listOfEmployeeToDisplay = [];
    this.selectedRole = event.target.value;
    if (this.selectedRole == 'All') {
      this.listOfEmployeeToDisplay = this.listOfEmployee;
    } else {
      this.listOfEmployee.forEach((employee: any) => {
        if (employee['role'] == this.selectedRole) {
          tempSearchList.push(employee);
        }
      });
      this.listOfEmployeeToDisplay = tempSearchList;
    }
  }
  async fetchAllEmployees() {
    this.isLoading = true;
    this.listOfEmployee = [];
    let param = {};

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllEmployees}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((emp: any) => {
            this.listOfEmployee.push({
              _id: emp['_id'],
              id: emp['_id'].slice(-5),
              firstName: emp['firstName'],
              lastName: emp['lastName'],
              phone: emp['phone'],
              email: emp['email'],
              role: emp['role'],
              franchise: emp['franchise'],
              privilegeList: emp['privilegeList'],
              department: emp['department'],
              status: emp['employeeStatus'],
              dob: emp['dob'],
              target: emp['target'],
              gender: emp['gender'],
              address: emp['address'],
              proof: emp['proof'],
              areaManager: emp['areaManager'],
            });
          });
          this.listOfEmployeeToDisplay = this.listOfEmployee;

          this.isLoading = false;
        } else {
        }
      });
  }
  addNewEmployee() {
    this.toggleOpened();
  }
  viewEmployeeDetails(employee: any) {}
  editEmployee(employee: any) {
    this.dataToEdit = employee;
    this.toggleOpened();
  }
  actionAcceptDelete(value: any, source: any) {
    const dialogConfig = new MatDialogConfig();
    if (source == 'Accept') {
      dialogConfig.data = {
        source: 'Accept Employee',
        employee: value,
      };
    } else {
      dialogConfig.data = { source: 'Reject Employee', employee: value };
    }
    let dialogRef = this.matDialog.open(
      AcceptRejectOrderDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Accept Employee') {
          const param = {
            employeeId: res['value']['_id'],
            status: 'ACTIVE',
            role: res['moreVal'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.updateEmployeeStatus}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfEmployee.forEach((employee: any, index: number) => {
                  if (employee['_id'] == res['value']['_id']) {
                    this.listOfEmployee[index] = {
                      _id: data['result']['_id'],
                      id: data['result']['_id'].slice(-5),
                      firstName: data['result']['firstName'],
                      lastName: data['result']['lastName'],
                      phone: data['result']['phone'],
                      target: data['result']['target'],
                      email: data['result']['email'],
                      franchise: data['result']['franchise'],
                      role: data['result']['role'],
                      privilegeList: data['result']['privilegeList'],
                      department: data['result']['department'],
                      status: data['result']['employeeStatus'],
                      dob: data['result']['dob'],
                      gender: data['result']['gender'],
                      address: data['result']['address'],
                      proof: data['result']['proof'],
                      areaManager: data['result']['areaManager'],
                    };
                    // this.listOfEmployee[index] = data['result'];
                  }
                });
                this.listOfEmployeeToDisplay = this.listOfEmployee;

                this.showSuccessToaster(
                  'Employee Accepted Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while accepting Employee. Try again',
                  'Error'
                );
              }
            });
        } else if (res['action'] == 'Reject Employee') {
          const param = {
            employeeId: res['value']['_id'],
            status: 'REJECTED',
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.updateEmployeeStatus}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfEmployee.forEach((employee: any, index: number) => {
                  if (employee['_id'] == res['value']['_id']) {
                    this.listOfEmployee[index] = {
                      _id: data['result']['_id'],
                      id: data['result']['_id'].slice(-5),
                      firstName: data['result']['firstName'],
                      lastName: data['result']['lastName'],
                      phone: data['result']['phone'],
                      email: data['result']['email'],
                      franchise: data['result']['franchise'],
                      role: data['result']['role'],
                      privilegeList: data['result']['privilegeList'],

                      target: data['result']['target'],
                      department: data['result']['department'],
                      status: data['result']['employeeStatus'],
                      dob: data['result']['dob'],
                      gender: data['result']['gender'],
                      address: data['result']['address'],
                      proof: data['result']['proof'],
                      areaManager: data['result']['areaManager'],
                    };
                    // this.listOfEmployee[index] = data['result'];
                  }
                });
                this.listOfEmployeeToDisplay = this.listOfEmployee;

                this.showSuccessToaster(
                  'Employee rejected Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while rejecting Employee. Try again',
                  'Error'
                );
              }
            });
        }
      }
    });
  }
  deleteEmployee(employee: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { employee: employee };

    let dialogRef = this.matDialog.open(
      DeleteEmployeeDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Employee') {
          const param = {
            employeeId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteEmployees}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfEmployee.forEach((employee: any, index: number) => {
                  if (employee['_id'] == res['value']['_id']) {
                    this.listOfEmployee.splice(index, 1);
                  }
                });

                this.showSuccessToaster(
                  'Employee Delete Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while deleteing employee. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing employee details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding employee details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfEmployee.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfEmployee[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              firstName: event['firstName'],
              lastName: event['lastName'],
              target: event['target'],
              phone: event['phone'],
              franchise: event['franchise'],
              email: event['email'],
              role: event['role'],
              privilegeList: event['privilegeList'],

              department: event['department'],
              status: event['employeeStatus'],
              dob: event['dob'],
              gender: event['gender'],
              address: event['address'],
              proof: event['proof'],
              areaManager: event['areaManager'],
            };
            // this.listOfEmployee[index] = event;
            isExist = true;
          }
        });
        this.listOfEmployeeToDisplay.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfEmployeeToDisplay[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              target: event['target'],
              firstName: event['firstName'],
              lastName: event['lastName'],
              phone: event['phone'],
              franchise: event['franchise'],
              email: event['email'],
              role: event['role'],
              privilegeList: event['privilegeList'],
              department: event['department'],
              status: event['employeeStatus'],
              dob: event['dob'],
              gender: event['gender'],
              address: event['address'],
              proof: event['proof'],
              areaManager: event['areaManager'],
            };
            // isExist = true;
          }
        });
        if (!isExist) {
          this.listOfEmployee.push({
            _id: event['_id'],
            id: event['_id'].slice(-5),
            firstName: event['firstName'],
            lastName: event['lastName'],
            phone: event['phone'],
            email: event['email'],
            privilegeList: event['privilegeList'],
            target: event['target'],
            role: event['role'],
            franchise: event['franchise'],
            department: event['department'],
            status: event['employeeStatus'],
            dob: event['dob'],
            gender: event['gender'],
            address: event['address'],
            proof: event['proof'],
            areaManager: event['areaManager'],
          });
        }
        // this.listOfEmployeeToDisplay = this.listOfEmployee;
        this.showSuccessToaster('Employee Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfEmployee.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfEmployee[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              firstName: event['firstName'],
              lastName: event['lastName'],
              target: event['target'],
              franchise: event['franchise'],
              phone: event['phone'],
              email: event['email'],
              privilegeList: event['privilegeList'],
              role: event['role'],
              department: event['department'],
              status: event['employeeStatus'],
              dob: event['dob'],
              gender: event['gender'],
              address: event['address'],
              proof: event['proof'],
              areaManager: event['areaManager'],
            };
            isExist = true;
          }
        });
        this.listOfEmployeeToDisplay.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfEmployeeToDisplay[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              firstName: event['firstName'],
              franchise: event['franchise'],
              lastName: event['lastName'],
              phone: event['phone'],
              target: event['target'],
              privilegeList: event['privilegeList'],
              email: event['email'],
              role: event['role'],
              department: event['department'],
              status: event['employeeStatus'],
              dob: event['dob'],
              gender: event['gender'],
              address: event['address'],
              proof: event['proof'],
              areaManager: event['areaManager'],
            };
            // isExist = true;
          }
        });
        if (!isExist) {
          this.listOfEmployee.push({
            _id: event['_id'],
            id: event['_id'].slice(-5),
            firstName: event['firstName'],
            lastName: event['lastName'],
            phone: event['phone'],
            franchise: event['franchise'],
            email: event['email'],
            role: event['role'],
            target: event['target'],
            privilegeList: event['privilegeList'],
            department: event['department'],
            status: event['employeeStatus'],
            dob: event['dob'],
            gender: event['gender'],
            address: event['address'],
            proof: event['proof'],
            areaManager: event['areaManager'],
          });
        }
        // this.listOfEmployeeToDisplay = this.listOfEmployee;

        this.showSuccessToaster('Employee Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
