<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Author
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addAuthorForm" [formGroup]="addAuthorForm">
        <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required">Author Name</label>
                <input type="text" placeholder="Name" id="name" formControlName="name" class="input-style">
            </div>

        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="Author Image" class="label required">Author Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultAuthorImage!=null" src="{{defaultAuthorImage}}" class="proofImage">
                                <label *ngIf="defaultAuthorImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultAuthorImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addAuthorForm.invalid|| defaultAuthorImage==null   ,'save-btn':addAuthorForm.valid  && defaultAuthorImage!=null}"
                value="{{'Save'}}" [disabled]="addAuthorForm.invalid || defaultAuthorImage==null  "
                (click)="addEditBrand()" />
        </div>
    </form>
</div>