<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Quiz Category
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addQuizCategoryForm" [formGroup]="addQuizCategoryForm">

        <div class="row">
            <div class="col-md-6">
                <label for="name" class="label required">Quiz Category Name</label>
                <input type="text" placeholder="Enter category name" id="name" formControlName="name" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="position" class="label required">Position</label>
                <input type="number" placeholder="Enter position" id="position" formControlName="position" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="Category Image" class="label">Category Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultCategoryImage!=null" src="{{defaultCategoryImage}}" class="proofImage">
                                <label *ngIf="defaultCategoryImage==null" class="imageLabel"> Upload
                                    <input (change)="displayCategory($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultCategoryImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayCategory($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultCategoryImage!=null && defaultCategoryImage.includes('https')" class="imageLabelDownload" (click)="downloadImage(defaultCategoryImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':addQuizCategoryForm.invalid  ,'save-btn':addQuizCategoryForm.valid }" value="{{'Save'}}" [disabled]="addQuizCategoryForm.invalid " (click)="addEditUser()" />
        </div>

    </form>
</div>