import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-user-sidebar',
  templateUrl: './add-edit-user-sidebar.component.html',
  styleUrls: ['./add-edit-user-sidebar.component.scss'],
})
export class AddEditUserSidebarComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  firstNameControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  lastNameControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  emailControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  phoneControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  dobControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  genderControl = new FormControl('Select Gender', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);

  addUserForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    dob: this.dobControl,
    gender: this.genderControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addUserForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      dob: this.dobControl,
      gender: this.genderControl,
    });
    if (this.dataToEdit != null) {
      this.addUserForm.get('firstName')?.setValue(this.dataToEdit['firstName']);
      this.addUserForm.get('lastName')?.setValue(this.dataToEdit['lastName']);
      this.addUserForm.get('email')?.setValue(this.dataToEdit['email']);
      this.addUserForm.get('phone')?.setValue(this.dataToEdit['phone']);
      if (this.dataToEdit['dob'] != undefined) {
        this.addUserForm
          .get('dob')
          ?.setValue(
            formatDate(new Date(this.dataToEdit['dob']), 'yyyy-MM-dd', 'en-US')
          );
      }
      if (this.dataToEdit['gender'] != undefined) {
        this.addUserForm.get('gender')?.setValue(this.dataToEdit['gender']);
      }
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('userId', this.dataToEdit['_id']);
    }

    this.formdata.set('firstName', this.addUserForm.get('firstName')?.value);
    this.formdata.set('lastName', this.addUserForm.get('lastName')?.value);
    this.formdata.set('dob', this.addUserForm.get('dob')?.value);
    this.formdata.set('phone', this.addUserForm.get('phone')?.value);
    this.formdata.set('email', this.addUserForm.get('email')?.value);
    this.formdata.set('gender', this.addUserForm.get('gender')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.dataToEdit != null) {
      URL = `${webApi.domain + webApi.endPoint.editUser}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.register}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addUserForm.reset();
                // this.defaultCoverImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
