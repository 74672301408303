import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-quiz-author',
  templateUrl: './add-edit-quiz-author.component.html',
  styleUrls: ['./add-edit-quiz-author.component.scss'],
})
export class AddEditQuizAuthorComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  selectedAuthorImage: any = null;
  selectedAuthorImageName: any = null;
  defaultAuthorImage: any;

  isLoading: boolean = false;
  listOfRoutes = [];

  myHeaders = new Headers();
  formdata = new FormData();

  nameControl = new FormControl('', [Validators.required]);

  addAuthorForm: FormGroup = new FormGroup({
    name: this.nameControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  async ngOnInit(): Promise<void> {
    this.fetchRoutes();
    this.addAuthorForm = this.formBuilder.group({
      name: this.nameControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addAuthorForm = this.formBuilder.group({
        name: this.nameControl,
      });

      this.addAuthorForm.get('name')?.setValue(this.dataToEdit['name']);
      this.defaultAuthorImage =
        this.dataToEdit['image'] == null ? null : this.dataToEdit['image'];
    }
  }

  async fetchRoutes() {
    this.isLoading = true;
    this.listOfRoutes = [];
    let param = {
      type: 'BannerWeb',
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAppConfig}`;
    await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfRoutes = data['result']['value'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  routeChangeHandler(event: any) {
    this.addAuthorForm.get('route')?.setValue(event.value);
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayAdhaar(event: any) {
    this.selectedAuthorImage = event.target.files[0];
    this.selectedAuthorImageName = this.selectedAuthorImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultAuthorImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedAuthorImage);
  }

  addEditBrand() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('authorId', this.dataToEdit['_id']);
      this.formdata.append('image', JSON.stringify(this.dataToEdit['image']));
    } else {
      this.formdata.append('authorId', '0');
    }

    if (this.selectedAuthorImage != null) {
      this.formdata.append('image', this.selectedAuthorImage);
    }

    this.formdata.append('name', this.addAuthorForm.get('name')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateQuizAuthor}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addAuthorForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
