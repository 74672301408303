<div style="width: 45em;">
    <div class="row mx-1" style="justify-content: space-between;">
        <h2 mat-dialog-title style="font-weight: 500;">Winners Details:</h2>
        <mat-icon (click)="close()" style="cursor: pointer;">close</mat-icon>
    </div>
    <hr>
    <mat-dialog-content>
        <div *ngIf="data['userList'].length>0">
            <div *ngFor="let userDetails of data['userList']">
                <div class="col-md-12 data">
                    <p>
                        Score: {{userDetails['correct']}}
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        User Name: {{userDetails['user']['firstName']}} {{userDetails['user']['lastName']}}
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        Email: {{userDetails['user']['email']}}
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        Phone: {{userDetails['user']['phone']}}
                    </p>
                </div>
                <div class="col-md-12 data" *ngIf="userDetails['user']['address']==null">
                    <p>
                        Address: N.A
                    </p>
                </div>
                <div class="col-md-12 data" *ngIf="userDetails['user']['address']!=null">
                    <p>
                        Address: {{userDetails['user']['address']['addressLine1']}},
                        {{userDetails['user']['address']['addressLine2']}},
                        {{userDetails['user']['address']['landmark']}}, {{userDetails['user']['address']['city']}},
                        {{userDetails['user']['address']['state']}}, {{userDetails['user']['address']['pincode']}}
                    </p>
                </div>
                <hr>
            </div>

        </div>
        <div *ngIf="data['userList'].length==0">
            <p class="noUsers">Users not found.</p>
        </div>
    </mat-dialog-content>
</div>