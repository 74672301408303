<div class="main-container">
    <div class="header">
        <div class="row" style="justify-content: space-between;align-items: center;">
            <div class="col-md-6">
                <div class="back-button">
                    <div class="back-text">{{detailToEdit != null? 'Edit':'Add'}} Quiz</div>
                </div>
            </div>
            <div *ngIf="detailToEdit != null" class="col-md-6" style="text-align: end;">
                <button class="save-btn-outline" (click)="editQuizMetaData()">Save</button>

            </div>
        </div>
        <!-- <div class="save">
            <button class="save-btn" (click)="insertEditQuiz()">
                Save
            </button>
        </div> -->
    </div>
    <form name="quizForm" [formGroup]="quizForm">
        <div class="row title">
            <div class="quizText">
                <label for="quizTitle" class="label required">Title:</label>
                <input type="text" placeholder="Enter a title" id="quizTitle" formControlName="title" class="quizTitle"
                    maxlength="300" required>
                <label for="quizTitle" class="label">Sucess Criteria:</label>
                <div style="display: grid;grid-template-columns: 1fr 1fr;margin-top: -2rem;">
                    <div>
                        <label for="message1" class="label required" style="font-size: 1rem;">1% to 25%:</label>
                        <input type="text" placeholder="Enter message" id="message1" class="quizTitle"
                            formControlName="message1" maxlength="300" required>
                    </div>
                    <div>
                        <label for="message2" class="label required" style="font-size: 1rem;">25% to 50%:</label>
                        <input type="text" placeholder="Enter message" id="message2" class="quizTitle"
                            formControlName="message2" maxlength="300" required>
                    </div>
                    <div>
                        <label for="message3" class="label required" style="font-size: 1rem;">50% to 75%</label>
                        <input type="text" placeholder="Enter message" id="message3" class="quizTitle"
                            formControlName="message3" maxlength="300" required>
                    </div>
                    <div>
                        <label for="message4" class="label required" style="font-size: 1rem;">75% to 100%:</label>
                        <input type="text" placeholder="Enter message" id="message4" class="quizTitle"
                            formControlName="message4" maxlength="300" required>
                    </div>
                    <div>
                        <!-- <div class="col-md-12"> -->
                        <label for="quizTitle" class="label required" style="font-size: 1rem;">Select Category</label>
                        <select id="category" name="category" class="quizTitle" formControlName="category">
                            <option value="Select Categoryr" selected disabled>Select Categoryr</option>
                            <option *ngFor="let data of listOfQuizCategory" [value]="data['_id']">{{data['name']}}
                            </option>
                        </select>
                        <!-- </div> -->
                    </div>
                    <div>
                        <label for="position" class="label required" style="font-size: 1rem;">Enter Position</label>
                        <input type="number" placeholder="Enter Position" id="position" class="quizTitle"
                            formControlName="position" maxlength="300" required>
                    </div>
                    <div>
                        <!-- <div class="col-md-12"> -->
                        <label for="quizTitle" class="label required" style="font-size: 1rem;">Select Author</label>
                        <select id="category" name="category" class="quizTitle" formControlName="author">
                            <option value="Select Author" selected disabled>Select Author</option>
                            <option *ngFor="let data of listOfAuthor" [value]="data['_id']">{{data['name']}}
                            </option>
                        </select>
                        <!-- </div> -->
                    </div>
                </div>


            </div>

            <div class="quizFile">
                <label for="quizImage" class="label">Quiz Image (750x422):</label>
                <div class="image">
                    <div class="course">
                        <img src="{{defaultCoverImage }}" class="courseImage">
                        <label class="imageLabel"> {{defaultCoverImage ==
                            'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg'? 'Upload Image':'Edit\
                            Image'}}
                            <input (change)="displayPhoto($event)" type="file" accept="image/*">
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row question-details" *ngIf="addMoreQuizes" id="addQuizSection">
            <div class="question">
                <div class="questionText">
                    <label for="questionTitle" class="label1 required">Question:</label>
                    <!-- <textarea id="questionTitle" placeholder="Enter a question" formControlName="question"
                        itemid="questionTitle" class="questionTitle"></textarea> -->
                    <quill-editor class="content-editor" placeholder="Enter a question" name="aboutUsText"
                        formControlName="question">
                    </quill-editor>
                </div>
                <div class="answerText">
                    <div class="answerTitle required">Answers:</div>
                    <div class="answer-row">
                        <input type="radio" class="radio" name="correctAnswerRadio" value="0"
                            (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==0">
                        <input type="text" placeholder="Add an answer" formControlName="answer1" class="answer"
                            maxlength="150">
                    </div>
                    <div class="answer-row">
                        <input type="radio" class="radio" name="correctAnswerRadio" value="1"
                            (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==1">
                        <input type="text" placeholder="Add an answer" formControlName="answer2" class="answer"
                            maxlength="150">
                    </div>
                    <div class="answer-row">
                        <input type="radio" class="radio" name="correctAnswerRadio" value="2"
                            (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==2">
                        <input type="text" placeholder="Add an answer" formControlName="answer3" class="answer"
                            maxlength="150">
                    </div>
                    <div class="answer-row">
                        <input type="radio" class="radio" name="correctAnswerRadio" value="3"
                            (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==3">
                        <input type="text" placeholder="Add an answer" formControlName="answer4" class="answer"
                            maxlength="150">
                    </div>
                    <div style="display: flex;justify-content: flex-end;margin: 1em;">
                        <button style="font-size: small;" class="cancel-btn" (click)="closeQuizAddEditSection()">
                            {{'Close'}}
                        </button>
                        <button style="font-size: small;"
                            [ngClass]="{'save-btn-disabled':quizForm.invalid,'save-btn-enabled':quizForm.valid}"
                            [disabled]="quizForm.invalid" (click)="insertEditQuiz()">
                            {{isEditingExistingQuiz?'Save':'Save'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top:1em;" *ngIf="listOfQuiz.length>0">
            <div class="row quiz-div" *ngFor="let quiz of listOfQuiz; let i = index">
                <div class="col-md-10"
                    style="align-self: center;font-size: 14px;font-weight: 500; word-break: break-all; ">
                    <span style="font-weight: 600;font-size: medium;" [innerHTML]="quiz['question']"></span>

                </div>
                <div class="col-md-2" style="display: flex;justify-content: space-around;">
                    <div class="edit-delete-div" (click)="editQuiz(quiz)">
                        <mat-icon class="edit-delete-icon">edit</mat-icon>
                    </div>
                    <div *ngIf="!quiz['isDeleting']" class="edit-delete-div" (click)="deleteQuiz(quiz)">
                        <mat-icon class="edit-delete-icon">delete</mat-icon>
                    </div>
                    <div *ngIf="quiz['isDeleting']" class="delete-spinner">
                        <mat-progress-spinner mode="indeterminate" diameter="20"
                            style="margin:0 auto;height:20px;width:20px;color: white;">
                        </mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!addMoreQuizes" class="row add-more-div" (click)="addMoreQuiz('addQuizSection')">
            <mat-icon class="edit-delete-icon">add</mat-icon>
            <div class="add-more">
                Add new Question
            </div>
        </div>
    </form>
    <div style="height: 10em;">

    </div>
</div>