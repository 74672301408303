<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;">
        <div class="col-md-4 top-heading" style="cursor: pointer;">
            Mehndi Design Category
        </div>
        <div class="col-md-8">
            <div class="row align-center">

                <div class="col-md-6" style="text-align: end;">
                    <button class="add-category" (click)="addCategory()">
                        Add Category
                    </button>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div class="main-content-div" *ngIf="!isLoading">

        <div *ngIf="listOfCategory.length==0">
            <div class="no-available">
                No image available.
            </div>
        </div>
        <div *ngIf="listOfCategory.length!=0">
            <table class="table-style">
                <thead class="table-heading">
                    <th style="cursor: pointer;" scope="col" class="table-heading-content">ID</th>
                    <th style="cursor: pointer;" class="table-heading-content">NAME</th>

                    <th style="cursor: pointer;" class="table-heading-content">CREATED DATE</th>
                    <th class="table-heading-content">ACTION</th>
                </thead>
                <tbody>
                    <tr class="table-data" *ngFor="let category of listOfCategory">
                        <th class="table-data-content">{{category.id}}</th>
                        <th class="table-data-content">{{category.name}}</th>

                        <th class="table-data-content">{{category.createdAt |date}}</th>
                        <th class="table-data-content ">
                            <div style="display: contents;">                              
                                <img title="Delete" (click)="deleteCategory(category,'Delete Category')"
                                    style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/delete.png'>
                            </div>

                        </th>
                    </tr>

                </tbody>
            </table>
          

            <div class="space-10">

            </div>
        </div>

    </div>
</div>

  <!-- <img title="Edit" (click)="editCategory(category)"
                                    style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/editing.png'> -->