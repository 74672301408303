import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  addEditPrivacyPolicyForm: FormGroup = new FormGroup({
    contentText: new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
  });
  isLoading: boolean = true;
  isAddingData: boolean = false;
  userDetails: any;
  privacyPolicyDetails: any;
  constructor(
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addEditPrivacyPolicyForm = this.formBuilder.group({
      contentText: new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
    });
    this.fetchAboutUs();
  }
  async fetchAboutUs() {
    const param = {
      state: 'PRIVACYPOLICY',
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchInfo}`;
    await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
      if (data['type']) {
        this.addEditPrivacyPolicyForm
          .get('contentText')
          ?.setValue(data['result']['content']);
        this.privacyPolicyDetails = data['result'];
        this.isLoading = false;
      } else {
        this.addEditPrivacyPolicyForm.get('contentText')?.setValue('');
        this.isLoading = false;
      }
    });
  }
  cancel() {}
  async saveAboutUs() {
    this.isAddingData = true;
    if (this.privacyPolicyDetails == null) {
      const param = {
        state: 'PRIVACYPOLICY',
        content: this.addEditPrivacyPolicyForm.get('contentText')?.value,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.createInfo}`;
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['type']) {
          this.addEditPrivacyPolicyForm
            .get('contentText')
            ?.setValue(data['result']['content']);
          this.isLoading = false;
          this.showSuccessToaster('Detail Added Successfully', 'Success');
        } else {
          this.addEditPrivacyPolicyForm.get('contentText')?.setValue('');
          this.isLoading = false;
          this.showErrorToaster(
            'Error Occurred while adding details. Try again',
            'Error'
          );
        }
        this.isAddingData = false;
      });
    } else {
      const param = {
        infoId: this.privacyPolicyDetails['_id'],
        content: this.addEditPrivacyPolicyForm.get('contentText')?.value,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.updateInfo}`;
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['type']) {
          this.addEditPrivacyPolicyForm
            .get('contentText')
            ?.setValue(data['result']['content']);
          this.isLoading = false;
          this.showSuccessToaster('Detail Updated Successfully', 'Success');
        } else {
          this.addEditPrivacyPolicyForm.get('contentText')?.setValue('');
          this.isLoading = false;
          this.showErrorToaster(
            'Error Occurred while editing details. Try again',
            'Error'
          );
        }
        this.isAddingData = false;
      });
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
