import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListInfluencerComponent } from './list-influencer/list-influencer.component';
import { ViewInfluencerDetailComponent } from './view-influencer-detail/view-influencer-detail.component';




const routes: Routes = [
  {
    path: '',
    component: ListInfluencerComponent,
  },
  {
    path: 'influencer-detail',
    component: ViewInfluencerDetailComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/influencer' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InfluencerRoutingModule { }
