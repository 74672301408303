<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-contest *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit" [categoryId]="categoryId">
            </app-add-edit-contest>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top Data -->
            <div class="row align-center" style="width: 100%;">
                <div class="top-heading col-md-3">
                    {{categoryName}} Contest List
                </div>
                <div class="col-md-9">
                    <div class="row align-center">
                        <!-- <div class="col-md-4" style="text-align: right;display: flex;align-items: center;">
                            Select role: <select style="width: 60%;" class="input-field" name="region" id="region"
                                aria-placeholder="Select Role" (change)="onEmployeeRoleSelectionChange($event)">
                                <option value="Select Role" selected disabled>Select Role</option>
                                <option value="All" [selected]="selectedRole=='All'">All</option>
                                <option value="Unassigned">Unassigned</option>
                                <option value="Author">Author</option>
                                <option value="Content Publisher">Content Publisher</option>
                            </select>
                        </div> -->
                        <div class="col-md-5" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <button class="add-new-emp" (click)="addNewContest()">
                                Add New Contest
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfContestToDisplay.length==0">
                <div class="no-available">
                    No contest detail available.
                </div>
            </div>
            <div *ngIf="listOfContestToDisplay.length!=0">
                <table class="table-style">
                    <thead class="table-heading">

                        <!-- <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-name="starDate" scope="col"
                            class="table-heading-content">Title</th> -->
                        <!-- <th class="table-heading-content">Type</th> -->
                        <th class="table-heading-content">Option</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="starDate" scope="col" class="table-heading-content">Start Date</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="endDate" scope="col" class="table-heading-content">End Date</th>
                        <!-- <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="winnerAnnouncerName" scope="col" class="table-heading-content">Announcer</th> -->
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="resultDate" scope="col" class="table-heading-content">Result Date</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="resultDate" scope="col" class="table-heading-content">Hema's Choice Winner</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="resultDate" scope="col" class="table-heading-content">One Lucky Winner</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="resultDate" scope="col" class="table-heading-content">Second Lucky Winner</th>
                        <th style="cursor: pointer;" [appSort]="listOfContestToDisplay" data-order="desc"
                            data-name="resultDate" scope="col" class="table-heading-content">Third Lucky Winner</th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Copy Link
                        </th>
                        <!-- <th class="table-heading-content">Winner Video</th> -->
                        <th class="table-heading-content">Participants</th>
                        <th class="table-heading-content">ACTION</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let contest of listOfContestToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <!-- <th class="table-data-content">{{contest.contestType}}</th> -->
                            <th class="table-data-content">{{contest.option}}</th>
                            <!-- <th class="table-data-content">{{contest.title}}</th> -->
                            <th class="table-data-content">{{contest.startDate | date:'dd MMM yyyy, HH:mm a'}}</th>
                            <th class="table-data-content">{{contest.endDate | date : 'dd MMM yyyy, HH:mm a'}}</th>
                            <!-- <th class="table-data-content">{{contest.winnerAnnouncerName}}</th> -->
                            <th class="table-data-content">{{contest.resultDate | date}}</th>
                            <th class="table-data-content">{{'\u20b9'+contest.hemaChoicePrize}}</th>
                            <th class="table-data-content">{{'\u20b9'+contest.prize1}}</th>
                            <th class="table-data-content">{{'\u20b9'+contest.prize2}}</th>
                            <th class="table-data-content">{{'\u20b9'+contest.prize3}}</th>
                            <th (click)="createLink(contest)"
                                style="text-decoration: underline;color: blue;cursor: pointer;"
                                class="table-data-content">Copy Link</th>
                            <!-- <th class="table-data-content" (click)="uploadWinnerVideo(contest,contest.winnerPrizeVideo!=null
                            && contest.winnerPrizeVideo!=undefined &&
                            contest.winnerPrizeVideo!=''?'View':'Upload')"><span
                                    style="color: blue;cursor: pointer;text-decoration: underline;">{{contest.winnerPrizeVideo!=null
                                    && contest.winnerPrizeVideo!=undefined &&
                                    contest.winnerPrizeVideo!=''?'View':'Upload'}}</span>
                            </th> -->
                            <th class="table-data-content" (click)="viewVideos(contest)"><span
                                    style="color: blue;cursor: pointer;text-decoration: underline;">View</span></th>

                            <th class="table-data-content ">
                                <div style="display: contents;">
                                    <img title="Edit" (click)="editContest(contest)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img *ngIf="!contest['isActive']" (click)="publishUnpublishContest(contest)"
                                        class="status-icon-off"
                                        style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-off.svg'>
                                    <img *ngIf="contest['isActive']" (click)="publishUnpublishContest(contest)"
                                        class="status-icon-on" style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-on.svg'>
                                    <img title="Delete" (click)="deleteContest(contest)"
                                        style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10"></div>

            </div>
        </div>
    </ng-sidebar-container>

</div>