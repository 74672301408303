import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-quiz-tnc',
  templateUrl: './quiz-tnc.component.html',
  styleUrls: ['./quiz-tnc.component.scss']
})
export class QuizTncComponent implements OnInit {

  addEditTnCForm: FormGroup = new FormGroup({
    contentText: new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
  });
  isLoading: boolean = true;
  isAddingData: boolean = false;
  userDetails: any;
  tncDetails: any;
  constructor(
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }
  ngOnInit(): void {
    this.addEditTnCForm = this.formBuilder.group({
      contentText: new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]),
    });
    this.fetchAboutUs();
  }
  async fetchAboutUs() {
    const param = {
      state: 'QUIZTERMSANDCONDITION',
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchInfo}`;
    await this.as.addData(param, URL, httpHeaders).then((data: any) => {
      if (data['type']) {
        this.addEditTnCForm
          .get('contentText')
          ?.setValue(data['result']['content']);
        this.tncDetails = data['result'];
        this.isLoading = false;
      } else {
        this.addEditTnCForm.get('contentText')?.setValue('');
        this.isLoading = false;
      }
    });
  }
  cancel() {}
  async saveAboutUs() {
    this.isAddingData = true;

    if (this.tncDetails == null) {
      const param = {
        state: 'QUIZTERMSANDCONDITION',
        content: this.addEditTnCForm.get('contentText')?.value,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.createInfo}`;
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['type']) {
          this.addEditTnCForm
            .get('contentText')
            ?.setValue(data['result']['content']);
          this.isLoading = false;
          this.showSuccessToaster('Detail Added Successfully', 'Success');
        } else {
          this.addEditTnCForm.get('contentText')?.setValue('');
          this.isLoading = false;
          this.showErrorToaster(
            'Error Occurred while adding details. Try again',
            'Error'
          );
        }
        this.isAddingData = false;
      });
    } else {
      const param = {
        infoId: this.tncDetails['_id'],
        content: this.addEditTnCForm.get('contentText')?.value,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.updateInfo}`;
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['type']) {
          this.addEditTnCForm
            .get('contentText')
            ?.setValue(data['result']['content']);
          this.isLoading = false;
          this.showSuccessToaster('Detail Updated Successfully', 'Success');
        } else {
          this.addEditTnCForm.get('contentText')?.setValue('');
          this.isLoading = false;
          this.showErrorToaster(
            'Error Occurred while editing details. Try again',
            'Error'
          );
        }
        this.isAddingData = false;

      });
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
