<div class="dialog-content">

    <div *ngIf="userToView==null"
        style="height: 60vh;display: flex;justify-content: center;align-items: center;font-size: medium;">
        No user found.
    </div>
    <div *ngIf="userToView!=null">
        <div *ngIf="userToDisplay==null">
            <div class="row" *ngFor="let user of userToView" (click)="openSelectedUser(user)" style="cursor: pointer;">
                <div class="col-md-10" style="margin-bottom: 1em;">
                    <div class="delivery-hist-card">
                        <div style="width: 10em;">
                            <div class="heading-div">
                                Name:
                            </div>
                            <div class="div-content">
                                {{user['firstName']+' '+user['lastName']}}
                            </div>
                        </div>
                        <div style="width: 10em;">
                            <div class="heading-div">
                                Phone:
                            </div>
                            <div class="div-content">
                                {{user['phone']}}
                            </div>
                        </div>
                        <div style="width: 20em;">
                            <div class="heading-div">
                                Email:
                            </div>
                            <div class="div-content">
                                {{user['email']}}
                            </div>
                        </div>
                        <div style="width: 10em;">
                            <div class="heading-div">
                                DOJ:
                            </div>
                            <div class="div-content">
                                {{user['createdAt']| date}}
                            </div>
                        </div>
                        <div style="width: 10em;">
                            <div class="heading-div">
                                Active Subscription:
                            </div>
                            <div class="div-content">
                                {{user['activeSubscription']!=null?'Yes':'No'}}
                            </div>
                        </div>
                        <div style="width: 10em;">
                            <div class="heading-div">
                                Address:
                            </div>
                            <div *ngIf="user['address']!=null" class="div-content">
                                {{user['address']['city']}}, {{user['address']['state']}}
                            </div>
                            <div *ngIf="user['address']==null && user['fullAddress']==null" class="div-content">
                                N.A.
                            </div>
                            <div *ngIf="user['fullAddress']!=null" class="div-content">
                                {{user['fullAddress']}}
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <div style="height: 10em;"> </div>
        </div>
        <div *ngIf="userToDisplay!=null">
            <mat-tab-group mat-align-tabs="allDetail">
                <mat-tab label="Details">

                    <div>

                        <div class="row" style="width: 100%;">
                            <div class="col-md-6">
                                <h3 mat-dialog-title style="font-weight: 500;margin-top: 1em;">Personal Details:</h3>

                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            ID:
                                        </div>
                                        <div class="content-value">
                                            #{{userToDisplay['_id'].substring(userToDisplay['_id'].length-7)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            Name:
                                        </div>
                                        <div class="content-value">
                                            {{userToDisplay['firstName'] + ' '+userToDisplay['lastName']}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            Phone:
                                        </div>
                                        <div class="content-value">
                                            {{userToDisplay['phone'] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            Date of joining:
                                        </div>
                                        <div class="content-value">
                                            {{userToDisplay['createdAt'] | date}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            App Version:
                                        </div>
                                        <div *ngIf="userToDisplay['appVersion']!=null" class="content-value">
                                            {{userToDisplay['appVersion']['version']}}
                                        </div>
                                        <div *ngIf="userToDisplay['appVersion']==null" class="content-value">
                                            N.A.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            OS:
                                        </div>
                                        <div *ngIf="userToDisplay['appVersion']!=null" class="content-value">
                                            {{userToDisplay['appVersion']['os']}}
                                        </div>
                                        <div *ngIf="userToDisplay['appVersion']==null" class="content-value">
                                            N.A.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            Active Subscription:
                                        </div>
                                        <div class="content-value">
                                            {{userToDisplay['activeSubscription']!=null?'Yes':'No'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display: flex;">
                                        <div class="content-heading">
                                            Address:
                                        </div>
                                        <div *ngIf="userToDisplay['address']!=null" class="content-value">
                                            {{userToDisplay['address']['addressLine1']}},
                                            {{userToDisplay['address']['addressLine2']}},
                                            {{userToDisplay['address']['landmark']}},
                                            {{userToDisplay['address']['city']}}, {{userToDisplay['address']['state']}},
                                            {{userToDisplay['address']['pincode']}}
                                        </div>

                                        <div *ngIf="userToDisplay['address']==null && userToDisplay['fullAddress']==null"
                                            class="content-value">
                                            N.A.
                                        </div>
                                        <div *ngIf="userToDisplay['fullAddress']!=null" class="content-value">
                                            {{userToDisplay['fullAddress']}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userToDisplay['activeSubscription']!=null">
                                <h3 mat-dialog-title style="font-weight: 500;margin-top: 1em;">Subscription Details:
                                </h3>

                                <div>


                                    <div class="row">
                                        <div class="col-md-12" style="display: flex;">
                                            <div class="content-heading">
                                                Subscription Id:
                                            </div>
                                            <div class="content-value">
                                                {{userToDisplay['activeSubscription']['_id']}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" style="display: flex;">
                                            <div class="content-heading">
                                                Start Date:
                                            </div>
                                            <div class="content-value">
                                                {{userToDisplay['activeSubscription']['startDate']|date}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" style="display: flex;">
                                            <div class="content-heading">
                                                End Date:
                                            </div>
                                            <div class="content-value">
                                                {{userToDisplay['activeSubscription']['endDate']|date}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" style="display: flex;">
                                            <div class="content-heading">
                                                Duration:
                                            </div>
                                            <div class="content-value">
                                                {{userToDisplay['activeSubscription']['plan']['duration']}} months
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" style="display: flex;">
                                            <div class="content-heading">
                                                Price:
                                            </div>
                                            <div class="content-value">
                                                &#8377;{{userToDisplay['activeSubscription']['plan']['price'] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- <mat-tab label="Subscription">
                    <div *ngIf="userToDisplay['subscriptionId'].length==0">
                        <div *ngIf="userToView==null" style="    height: 60vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: medium;
                    ">
                            No subscription available.
                        </div>
                    </div>
                    <div *ngIf="userToDisplay['subscriptionId'].length!=0" class="row" style="width: 100%;justify-content: space-between; ">
                        <div class="col-md-5">
                            <div class="row" *ngFor="let subs of userToDisplay['subscriptionId']" style="cursor: pointer;">
                                <div class="col-md-12" style="margin-bottom: 1em;">
                                    <div class="delivery-hist-card">
                                        <div>
                                            <div class="heading-div">
                                                Start Date:
                                            </div>
                                            <div class="div-content">
                                                {{subs['startDate']|date}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="heading-div">
                                                Product:
                                            </div>
                                            <div class="div-content">
                                                {{subs['item']['name']}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="heading-div">
                                                Quantity:
                                            </div>
                                            <div class="div-content">
                                                {{subs['item']['quantity'] +' Pkt. / ' +subs['item']['quantity']/2 +' ltr.'}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="heading-div">
                                                Deliveries Left:
                                            </div>
                                            <div class="div-content">
                                                {{subs['packetCredit'] +' Pkt. /\ '+(subs['packetCredit']==0?'0':subs['packetCredit']/2) +' ltr.'}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="heading-div">
                                                Action:
                                            </div>
                                            <div class="row">
                                                <img (click)="openSelectedSubscriptionView(subs)" style="height: 1.2rem;margin-left: 1em;cursor: pointer;" src='../../../assets/img/view.png'>
                                                <div style="display: contents;">
                                                    <img (click)="openSelectedSubscriptionEdit(subs)" style="height: 1rem;margin-left: 5px;cursor: pointer;" src='../../../assets/img/editing.png'>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div *ngIf="subsToDisplay==null" class="row" style="justify-content: center;align-items: center; height: 40vh; margin-top: 1em;">
                                No Subscription selected.
                            </div>
                            <div *ngIf="subsToDisplay!=null" class="row">
                                <div *ngIf="isLoading" class="col-md-12" style="display: flex;justify-content: center; align-items: center; height: 70vh; margin-top: 1em;">
                                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </div>
                                <div *ngIf="!isLoading" class="col-md-12" style=" overflow: auto; height: 70vh;">
                                    <h3 mat-dialog-title style="font-weight: 500;margin-top: 1em;">Subscription Detail
                                    </h3>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">
                                                    <div style="width: 7em;">
                                                        Date:
                                                    </div>
                                                    {{subsToDisplay['startDate'] | date}}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        ID:
                                                    </div>
                                                    <div style="width: 9em;font-size: 80%;">
                                                        {{subsToDisplay['_id'] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Coupon:
                                                    </div>
                                                    {{subsToDisplay['coupon']==null?(subsToDisplay['couponCode']==null? 'NA':subsToDisplay['couponCode']):subsToDisplay['coupon']['name'] }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Paid Credit:
                                                    </div>
                                                    {{'\u20b9'+totalPaidCredit }}
                                                </div>
                                            </div>
                                        
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Packets:
                                                    </div>
                                                    {{totalPacketCredit +' Pkt /'+ (totalPacketCredit/2)+' ltr.' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">
                                                    <div style="font-weight: 500;">
                                                        {{userToDisplay['firstName']+' '+userToDisplay['lastName']}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">
                                                    <div>
                                                        {{subsToDisplay['address']['fullAddress']}}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <h3 style="font-weight: 500;">Remaining Delivery Detail</h3>
                                        <div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Paid Credit:
                                                    </div>
                                                    {{'\u20b9'+subsToDisplay['paidCredit'] }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Free Credit:
                                                    </div>
                                                    {{'\u20b9'+subsToDisplay['freeCredit'] }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;">

                                                    <div style="width: 7em;">
                                                        Packets:
                                                    </div>
                                                    {{subsToDisplay['packetCredit'] +' Pkt /'+ (subsToDisplay['packetCredit'] /2)+' ltr.' }}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <h3 style="font-weight: 500;">Products</h3>
                                        <div>
                                            <div class="row">
                                                <div class="col-md-12" style="display: flex;align-items: center;">
                                                    <img class="prod-img" [src]="subsToDisplay['item']['imageUrl']" alt="">
                                                    <div>
                                                        <div class="prod-name">
                                                            {{subsToDisplay['item']['name']}}
                                                        </div>
                                                        <div class="prod-qty">
                                                            {{subsToDisplay['item']['quantity'] +'QTY.'}} x {{'\u20b9'+subsToDisplay['item']['itemPrice']}} = {{ '\u20b9'+subsToDisplay['item']['quantity']*subsToDisplay['item']['itemPrice']}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <h3 style="font-weight: 500;margin-bottom: 4px;">Status</h3>
                                        <div style="font-weight: 600;font-size: 13px;">
                                            {{subsToDisplay['status']}}
                                        </div>
                                    </div>
                                    <hr>
                                   
                                    <div *ngIf="subsToDisplay['isEditing']">

                                        <div class="row" *ngIf="subsToDisplay['status']=='ACTIVE'">

                                            <div class="col-md-12" style="display: flex;align-items: center;">

                                                <input type="radio" name="radio-btn" id="Adjust" name="Adjust" [checked]="isAdjustOpted" (change)="radioChangeHandler($event)">
                                                <label for="Adjust" class="lbl-radio">Adjust Packet</label>
                                                <input type="radio" name="radio-btn" id="Cancel" name="Cancel" [checked]="isCancelOpted" (change)="radioChangeHandler($event)">
                                                <label for="Cancel" class="lbl-radio">Cancel Subscription</label>

                                            </div>
                                        </div>
                                        <div *ngIf="isAdjustOpted">

                                            <div class="pd-btm-2em" *ngIf="subsToDisplay['status']=='ACTIVE'">
                                                <hr>
                                                <div style="font-weight: 500;">
                                                    Available Packet: {{subsToDisplay['packetCredit']}}
                                                </div>
                                                <div>
                                                    <form name="editSubscriptionForm" [formGroup]="editSubscriptionForm">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <label for="packetCount" class="label required">Enter
                                                                    packet</label>
                                                                <input type="text" placeholder="Enter packet" id="packetCount" formControlName="packetCount" class="input-style">
                                                            </div>


                                                        </div>
                                                    </form>
                                                </div>

                                                <div mat-dialog-actions>
                                                    <button class="no-btn" mat-button (click)="close()">No</button>
                                                    <button *ngIf="!isSubscriptionChanging" class="yes-btn" mat-button [disabled]="editSubscriptionForm.invalid" (click)="editSubscription(subsToDisplay)">Yes</button>
                                                    <button *ngIf="isSubscriptionChanging" class="yes-btn" mat-button [disabled]="editSubscriptionForm.invalid">
                                                        <mat-progress-spinner mode="indeterminate" diameter="10"
                                                            style="margin:0 auto;">
                                                        </mat-progress-spinner>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isCancelOpted">

                                            <div class="pd-btm-2em" *ngIf="subsToDisplay['status']=='ACTIVE'">
                                                <hr>
                                                <div style="font-weight: 500;">
                                                    Refundable Amount: {{'\u20b9'+subsToDisplay['paidCredit']}}
                                                </div>
                                                <div>
                                                    <form name="editSubscriptionForm" [formGroup]="editSubscriptionForm">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <label for="creditToRefund" class="label required">Credit to
                                                                    refund</label>
                                                                <input type="text" placeholder="Credit to refund" id="creditToRefund" formControlName="creditToRefund" class="input-style">
                                                            </div>

                                                            <div class="col-md-8">
                                                                <label for="remark" class="label required">Remark</label>
                                                                <input type="text" placeholder="Remark" id="remark" formControlName="remark" class="input-style">
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div mat-dialog-actions>
                                                    <button class="no-btn" mat-button (click)="close()">No</button>
                                                    <button *ngIf="!isSubscriptionChanging" class="yes-btn" mat-button [disabled]="editSubscriptionForm.invalid" (click)="editSubscription(subsToDisplay)">Yes</button>
                                                    <button *ngIf="isSubscriptionChanging" class="yes-btn" mat-button [disabled]="editSubscriptionForm.invalid">
                                                        <mat-progress-spinner mode="indeterminate" diameter="10"
                                                            style="margin:0 auto;">
                                                        </mat-progress-spinner>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="pd-btm-2em" *ngIf="subsToDisplay['isViewing']">
                                        <h3 style="font-weight: 500;">Delivery History</h3>
                                        <div *ngIf="listOfSubscriptionOrders.length==0">
                                            No Order History available for this subscription. </div>
                                        <div *ngIf="listOfSubscriptionOrders.length!=0">
                                            <div class="row" *ngFor="let order of listOfSubscriptionOrders">
                                                <div class="col-md-12" style="margin-bottom: 1em;">
                                                    <div class="delivery-hist-card">

                                                        <div>
                                                            <div class="heading-div">
                                                                Date:
                                                            </div>
                                                            <div class="div-content">
                                                                {{order['shipmentOrderedDate']|date}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="heading-div">
                                                                Status:
                                                            </div>
                                                            <div class="div-content">
                                                                {{order['status']}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="heading-div">
                                                                Expected Delivery:
                                                            </div>
                                                            <div class="div-content">
                                                                {{order['item'][0]['quantity'] +' Pkt. / ' +order['item'][0]['quantity']/2 +' ltr.'}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="heading-div">
                                                                Actual Delivery:
                                                            </div>
                                                            <div class="div-content">
                                                                {{order['item'][0]['deliveredQuantity'] +' Pkt. / ' +order['item'][0]['deliveredQuantity']/2 +' ltr.'}}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="heading-div">
                                                                Verification Image:
                                                            </div>
                                                            <div (click)="openImageInWindow(order['verificationImage']['url'])" class="div-content" style="cursor: pointer;">
                                                                <img class="image-verification" [src]="order['verificationImage']['url']" alt="verificationImage" srcset="">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                

                                </div>

                            </div>

                        </div>

                    </div>


                </mat-tab> -->
                <mat-tab label="Books">
                    <div *ngIf="listOfUsersBook.length==0">
                        <div
                            style="height: 60vh;display: flex;justify-content: center;align-items: center;font-size: medium;">
                            No Books available.
                        </div>
                    </div>
                    <div *ngIf="listOfUsersBook.length!=0" class="row" style="width: 100%;">
                        <div class="row" *ngFor="let book of listOfUsersBook" style="width: 60%;
                        margin-left: 1em;">
                            <div class="col-md-12" style="margin-bottom: 1em;">
                                <div class="delivery-hist-card">
                                    <div style="width: 20em;">
                                        <div class="heading-div">
                                            Name:
                                        </div>
                                        <div class="div-content">
                                            {{book['contentId']['title']}}
                                        </div>
                                    </div>
                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Category:
                                        </div>
                                        <div class="div-content">
                                            {{book['contentId']['categoryId']['name']}}
                                        </div>
                                    </div>
                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Amount:
                                        </div>
                                        <div class="div-content">
                                            &#8377;{{book['amount']}}
                                        </div>
                                    </div>
                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Medium:
                                        </div>
                                        <div class="div-content">
                                            {{book['transaction']['transactionMedium']}}
                                        </div>
                                    </div>

                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Date:
                                        </div>
                                        <div class="div-content">
                                            {{book['createdAt']|date}}
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Transactions">
                    <div *ngIf="listOfTransactions.length==0">
                        <div
                            style="height: 60vh;display: flex;justify-content: center;align-items: center;font-size: medium;">
                            No Transactions available.
                        </div>
                    </div>
                    <div *ngIf="listOfTransactions.length!=0" class="row" style="width: 100%;">
                        <div class="row" *ngFor="let trans of listOfTransactions" style="width: 60%;
                        margin-left: 1em;">
                            <div class="col-md-12" style="margin-bottom: 1em;">
                                <div class="delivery-hist-card">


                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Amount:
                                        </div>
                                        <div class="div-content">
                                            &#8377;{{trans['amount']}}
                                        </div>
                                    </div>
                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Type:
                                        </div>
                                        <div class="div-content">
                                            {{trans['transactionType']}}
                                        </div>
                                    </div>
                                    <div style="width: 5em;">
                                        <div class="heading-div">
                                            Medium:
                                        </div>
                                        <div class="div-content">
                                            {{trans['transactionMedium'] }}
                                        </div>
                                    </div>
                                    <div style="width: 7em;">
                                        <div class="heading-div">
                                            Date:
                                        </div>
                                        <div class="div-content">
                                            {{trans['createdAt']|date}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>