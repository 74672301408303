<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Plan
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addPlanForm" [formGroup]="addPlanForm">

        <div class="row">
            <div class="col-md-6">
                <label for="rzpPlanId" class="label required">Razorpay Plan Id</label>
                <input type="text" placeholder="Razorpay Plan Id" id="rzpPlanId" formControlName="rzpPlanId" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="duration" class="label required">Duration</label>
                <input type="number" placeholder="Duration" id="duration" formControlName="duration" class="input-style" maxlength="10">
            </div>

        </div>
        <div class="row">

            <div class="col-md-6">
                <label for="price" class="label required">Selling Price </label>
                <input type="number" placeholder="Selling Price" id="price" formControlName="price" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="price" class="label required">Listing Price </label>
                <input type="number" placeholder="Listing Price" id="price" formControlName="listingPrice" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="creditToGive" class="label required">Free credit for user</label>
                <input type="number" placeholder="Free credit for user" id="creditToGive" formControlName="creditToGive" class="input-style">
            </div>

        </div>
        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':addPlanForm.invalid  ,'save-btn':addPlanForm.valid }" value="{{'Save'}}" [disabled]="addPlanForm.invalid " (click)="addEditUser()" />
        </div>

    </form>
</div>