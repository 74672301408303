<div id="container">
    <div id="header">
        <div class="avatarSection">
            <div class="picture">
                <img src="../../assets/images/noPic.svg" />
            </div>

            <div class="nameSection">
                {{chat.user1._id==this.as.meriSaheliId?chat.user2.name==null?chat.user2.phone:chat.user2.name:chat.user1.name==null?chat.user1.phone:chat.user1.name}}

            </div>
        </div>
        <div class="actionButtons">
            <!-- <img src="../../assets/images/attached.svg" /> -->
            <!-- <img src="../../assets/images/moreVertIcon.svg" /> -->
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div id="body" *ngIf="!isLoading">
        <div *ngIf="listOfMessage$ | async as listOfMessage">
            <div class="message" [ngClass]="{ me: this.as.meriSaheliId==message.sender }" *ngFor="let message of listOfMessage;let i=index">
                <!-- <div class="duration">
                <h3
                    style="background-color: rgb(128 128 128 / 70%);color: white;padding: .3rem .6rem;border-radius: 8px">
                    {{getDuration(message.createdAt)}}</h3>
            </div> -->
                <div class="messageContent">
                    <div *ngIf="message.type=='individual'">
                        <div *ngIf="message['contentType']=='text'" style="white-space: pre-wrap;max-width: 25em;">
                            {{message['content']['body']}}
                        </div>
                        <div *ngIf="message['contentType']=='image'">
                            <img  [src]="message['content']['linkS3']" alt="" srcset="" style="width: 25em;">
                        </div>
                        <div *ngIf="message['contentType']!='image' && message['contentType']!='text'">

                        </div>

                    </div>
                    <div *ngIf="message.type=='template'">
                        <div *ngFor="let contentData of message.templateId['components']">
                            <img *ngIf="contentData['format']=='IMAGE' && contentData['type']=='HEADER'" [src]="getContentData(message,'header',0)['image']['linkS3']" alt="" srcset="" style="width: 25em;">
                            <div *ngIf="contentData['format']=='VIDEO' && contentData['type']=='HEADER'">
                            </div>
                            <div *ngIf="contentData['format']=='DOCUMENT' && contentData['type']=='HEADER'">
                            </div>
                            <div *ngIf="contentData['format']=='AUDIO' && contentData['type']=='HEADER'">
                            </div>
                            <div *ngIf="contentData['type']=='BODY'" style="margin-top: 1em;max-width: 25em;white-space: pre-wrap;">
                                <!-- {{getTextContentData(message,'body',contentData['text'])}} -->
                                <p [innerHtml]="getTextContentData(message,'body',contentData['text'])">

                                </p>
                            </div>
                            <div *ngIf="contentData['type']=='FOOTER'">
                            </div>
                            <div *ngIf="contentData['type']=='BUTTON'">
                            </div>

                        </div>

                    </div>
                    <div *ngIf="message.contentType=='image'">
                        <img [src]="message.content.link" alt="" srcset="" style="width: 20em;">
                    </div>
                    <div class="timeStatus">
                        <div class="time">{{ message.sentDate | date:'hh:mm' }}</div>
                        <img style="height: 13px;" *ngIf="message.status=='sent'" src="../../assets/images/singletick.svg" />
                        <img style="height: 13px;" *ngIf="message.status=='delivered'" src="../../assets/images/doubleTick.svg" />
                        <img style="height: 13px;" *ngIf="message.status=='read'" src="../../assets/images/doubleTickBlue.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showTemplate || showTemplateBody" class="template-list footer">
        <div *ngIf="isTemplateLoading">
            <div class="template-loading">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="!isTemplateLoading">
            <div *ngIf="showTemplate && !showTemplateBody">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>
                        <img src="../../../../../assets/images/wa-img/close.png" alt="" srcset="" style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;" (click)="hideTemplate()">
                        <span style="font-size: 18px;font-weight: 500;">
                            Choose Template
                        </span>
                    </div>
                    <div>
                        <input type="text" class="search-box" placeholder="Search template" (input)="searchTemplateData($event.target)">
                    </div>
                </div>
                <hr>
                <div *ngFor="let template of listOfTemplateToDisplay">
                    <div (click)="selectTemplate(template)" style="cursor: pointer;">
                        <span style="font-weight: 500;">{{template['nameWithLanguage']}}</span>
                        <div style="margin-top: 10px;">
                            {{getTemplateBody(template)}}
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
            <div *ngIf="!showTemplate && showTemplateBody">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>
                        <span style="font-size: 18px;font-weight: 500;">
                            Edit Custom Fields
                        </span>
                    </div>
                    <div>
                        <img src="../../../../../assets/images/wa-img/close.png" alt="" srcset="" style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;" (click)="hideTemplateBody()">
                    </div>
                </div>
                <hr>
                <div>
                    <div style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                        <span style="font-weight: 500;">{{selectedTemplate['nameWithLanguage']}}</span>
                        <div style="margin-top: 10px;">
                            {{getTemplateBody(selectedTemplate)}}
                        </div>
                    </div>
                    <hr>
                    <div>
                        <form name="sendTemplateForm" [formGroup]="sendTemplateForm">

                            <div class="row" *ngIf="showHeaderOfTemplate" style="width: 100%;">
                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate">
                                    <label for="" class="label required">Herder Media:</label>
                                    <div class="row" style="width: 100%;">
                                        <div class="col-md-12">
                                            <div class="image">
                                                <div class="proof">
                                                    <img *ngIf="defaultHeaderMedia!=null" src="{{defaultHeaderMedia}}" class="proofImage" style="height: 10em;">
                                                    <div *ngIf="defaultHeaderMedia!=null" style="font-size: 10px;
                                                        margin: 1em;
                                                        word-break: break-word;text-align: center;
                                                        white-space: nowrap;
                                                        text-overflow: ellipsis;
                                                        overflow: hidden;" [title]="selectedHeaderMediaName">
                                                        {{selectedHeaderMediaName}}
                                                    </div>
                                                    <label *ngIf="defaultHeaderMedia==null" class="imageLabel">
                                                        Upload
                                                        <input (change)="displayHeaderMedia($event)" type="file"
                                                            accept=".xlsx,video/*,image/*">
                                                    </label>
                                                    <label *ngIf="defaultHeaderMedia!=null" class="imageLabelEdit">
                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                        <input (change)="displayHeaderMedia($event)" type="file"
                                                            accept=".xlsx,video/*,image/*">
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate">
                                    <div style="margin-bottom: 1.5rem;">
                                        <label for="header-text" class="label required">Header Text:</label>
                                        <quill-editor class="content-editor" style="height: 12rem;" placeholder="Body" name="header-text" formControlName="headerText">
                                        </quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="showBodyOfTemplate" style="margin-top: 1em;width: 100%;">
                                <div class="col-md-6" *ngFor='let val of counter(bodyVariableCount) ;let i = index'>
                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                    <input type="text" [placeholder]="'Enter body text '+(i+1)" id="name" [formControlName]="'bodyText'+(i+1)" class="input-style">
                                </div>
                            </div>
                            <div class="row" *ngIf="showFooterOfTemplate" style="width: 100%;">

                            </div>
                            <div class="row" *ngIf="showButtonOfTemplate" style="width: 100%;">

                            </div>
                        </form>
                    </div>

                    <hr>
                    <div style="text-align: end;">
                        <button class="back-btn" (click)="hideTemplateBody()">Back</button>
                        <button class="send-btn" (click)="sendMessage()" [ngClass]="{'send-disabled-btn':sendTemplateForm.invalid|| (defaultHeaderMedia==null && showHeaderOfTemplate) ,'send-enabled-btn':sendTemplateForm.valid  && (defaultHeaderMedia!=null || !showHeaderOfTemplate)}"
                            [disabled]="sendTemplateForm.invalid || (defaultHeaderMedia==null && showHeaderOfTemplate)">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="footer" *ngIf="!isLoading && !showTemplate && !showTemplateBody">
        <!-- <div id="smileyIcon">
            <img (click)="emojiPickerVisible = !emojiPickerVisible" src="../../assets/images/smileIcon.svg" />
            <emoji-mart *ngIf="emojiPickerVisible" style="position: absolute; bottom: 50px" [darkMode]="false" (emojiClick)="emojiClicked($event)" title="Pick your emoji…" emoji="point_up" [showPreview]="false">
            </emoji-mart>
        </div> -->
        <div *ngIf="is24HrWindowClosed(chat.windowExpireTime)" class="closed-window">
            <div>
                <div style="font-size: 20px;font-weight: 500;margin-bottom: 10px;">
                    Conversation is closed.
                </div>
                <div style="font-size: 13px;font-weight: 400;margin-top: 10px;color: grey;">
                    You Cannot reply to this chat as 24 hour window of customer service has been closed
                </div>
            </div>
            <div class="send-template-btn" (click)="getTemplateList()">
                Send Template message
            </div>
        </div>
        <div *ngIf="!is24HrWindowClosed(chat.windowExpireTime)" style="display: flex;width: 100%;align-items: center;">
            <div id="textarea">
                <textarea [(ngModel)]="message" [placeholder]="'Write your message here....'" (keydown)="handleKeyDownEvent($event)" (keyup.enter)="sendMessage()" (input)="inputChange($event)" [disabled]="is24HrWindowClosed(chat.windowExpireTime)"></textarea>
            </div>
            <div>
                <!-- <div id="micIcon"> -->
                <!-- <img src="../../assets/images/send-message.png" /> -->
                <button class="send-btn" [ngClass]="{'send-disabled-btn':message=='' ,'send-enabled-btn':message!=''}" [disabled]="message==''" (click)="sendMessage()">Send</button>

            </div>
        </div>
    </div>
</div>