import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { GameCategory } from '../list-game-category/list-game-category.component';

@Component({
  selector: 'app-add-edit-game',
  templateUrl: './add-edit-game.component.html',
  styleUrls: ['./add-edit-game.component.scss'],
})
export class AddEditGameComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  selectedGameImage: any = null;
  selectedGameImageName: any = null;
  defaultGameImage: any;

  listOfGameCategoryToDisplay: Array<GameCategory> = [];
  listOfGameCategory: Array<GameCategory> = [];

  nameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  linkControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  categoryControl = new FormControl('Select Category', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  positionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  addGameForm: FormGroup = new FormGroup({
    name: this.nameControl,
    link: this.linkControl,
    category: this.categoryControl,
    position: this.positionControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addGameForm = this.formBuilder.group({
      name: this.nameControl,
      link: this.linkControl,
      category: this.categoryControl,
      position: this.positionControl,
    });
    this.fetchGameCategories();
    if (this.dataToEdit != null) {
      this.addGameForm.get('name')?.setValue(this.dataToEdit['name']);
      this.addGameForm.get('link')?.setValue(this.dataToEdit['link']);
      this.addGameForm.get('position')?.setValue(this.dataToEdit['position']);
      this.addGameForm.get('category')?.setValue(this.dataToEdit['category']['_id']);
      this.defaultGameImage = this.dataToEdit['image'];
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  async fetchGameCategories() {
    this.listOfGameCategoryToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchAllGameCategory}`;
      const param = {
        admin: true,
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfGameCategoryToDisplay = data['result'];
          this.listOfGameCategory = [...this.listOfGameCategoryToDisplay];
          this.isLoading = false;
        } else {
          this.listOfGameCategoryToDisplay = [];
          this.listOfGameCategory = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfGameCategoryToDisplay = [];
      this.listOfGameCategory = [];
      this.isLoading = false;
    }
  }

  displayAdhaar(event: any) {
    this.selectedGameImage = event.target.files[0];
    this.selectedGameImageName = this.selectedGameImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultGameImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedGameImage);
  }

  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('gameId', this.dataToEdit['_id']);
      this.formdata.append('image', JSON.stringify(this.dataToEdit['image']));
    } else {
      this.formdata.set('gameId', '0');
    }

    if (this.selectedGameImage != null) {
      this.formdata.append('image', this.selectedGameImage);
    }

    this.formdata.set('name', this.addGameForm.get('name')?.value);
    this.formdata.set('category', this.addGameForm.get('category')?.value);
    this.formdata.set('link', this.addGameForm.get('link')?.value);
    this.formdata.set('position', this.addGameForm.get('position')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.createAndUpdateGame}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addGameForm.reset();
                // this.defaultCoverImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
