<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Game
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addGameForm" [formGroup]="addGameForm">

        <div class="row">
            <div class="col-md-6">
                <label for="name" class="label required">Game Name</label>
                <input type="text" placeholder="Game Name" id="name" formControlName="name" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="quizTitle" class="label required" style="font-size: 1rem;">Select Category</label>
                <select id="category" name="category" class="input-style" formControlName="category">
                    <option value="Select Category" selected disabled>Select Category</option>
                    <option *ngFor="let data of listOfGameCategory" [value]="data['_id']">{{data['name']}}
                    </option>
                </select>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="position" class="label required">Position</label>
                <input type="text" placeholder="Position" id="position" formControlName="position" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="link" class="label required">Game Link </label>
                <input type="text" placeholder="Game Link" id="link" formControlName="link" class="input-style">
            </div>

        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="Game Image" class="label required">Game Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultGameImage!=null" src="{{defaultGameImage}}" class="proofImage">
                                <label *ngIf="defaultGameImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultGameImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addGameForm.invalid || defaultGameImage==null  ,'save-btn':addGameForm.valid && defaultGameImage!=null}"
                value="{{'Save'}}" [disabled]="addGameForm.invalid || defaultGameImage==null" (click)="addEditUser()" />
        </div>

    </form>
</div>