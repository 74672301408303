import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteEmployeeDialogComponent } from '../../employee-management/delete-employee-dialog/delete-employee-dialog.component';
import { ChangeContestStatusAndDeleteComponent } from '../change-contest-status-and-delete/change-contest-status-and-delete.component';
import { UploadWinnerVideoComponent } from '../upload-winner-video/upload-winner-video.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';

@Component({
  selector: 'app-list-contest',
  templateUrl: './list-contest.component.html',
  styleUrls: ['./list-contest.component.scss']
})
export class ListContestComponent implements OnInit {
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  httpHeaders: any;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  listOfContest: any = [];
  listOfContestToDisplay: any = [];

  categoryId: any;
  categoryName: any = '';
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this._activatedRoute.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.categoryId = allParam['params']['id'];
      this.categoryName = allParam['params']['name'];
    });
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    if (this.categoryId) {
      this.fetchContestByCategory();
    } else {
      this._location.back();
    }

  }
  getInitialLetter(str: string) {
    var matches = str.match(/\b(\w)/g);
    return matches?.join('').toUpperCase();
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfContestToDisplay = [];

      this.listOfContest.forEach((employee: any, index: number) => {
        if (
          String(employee['winnerAnnouncerName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['description'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(employee);
        }
      });
      this.listOfContestToDisplay = [];
      this.listOfContestToDisplay = tempSearchList;
    } else {
      this.listOfContestToDisplay = this.listOfContest;
    }
  }

  async fetchContestByCategory() {
    try {

      this.isLoading = true;
      this.listOfContest = [];
      let param = {};

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.fetchContestByCategory}?category=${this.categoryId}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfContest = data['result'];
            this.listOfContestToDisplay = this.listOfContest;
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  addNewContest() {
    this.toggleOpened();
  }
  editContest(contest: any) {
    this.dataToEdit = contest;
    this.toggleOpened();
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing contest details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding contest details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfContest.forEach((contest: any, index: number) => {
          if (contest['_id'] == event['_id']) {
            this.listOfContest[index] = event;
            isExist = true;
          }
        });
        this.listOfContestToDisplay.forEach((contest: any, index: number) => {
          if (contest['_id'] == event['_id']) {
            this.listOfContestToDisplay[index] = event;
          }
        });
        if (!isExist) {
          this.listOfContest.push(event);
        }
        this.listOfContestToDisplay = this.listOfContest;
        this.showSuccessToaster('Contest Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfContest.forEach((contest: any, index: number) => {
          if (contest['_id'] == event['_id']) {
            this.listOfContest[index] = event;
            isExist = true;
          }
        });
        this.listOfContestToDisplay.forEach((contest: any, index: number) => {
          if (contest['_id'] == event['_id']) {
            this.listOfContestToDisplay[index] = event;
          }
        });
        if (!isExist) {
          this.listOfContest.push(event);
        }
        this.listOfContestToDisplay = this.listOfContest;

        this.showSuccessToaster('Contest Added Successfully', 'Success');
      }
    }
    this.dataToEdit = null;
    this.isLoading = false;
  }
  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  viewVideos(contest: any) {
    this.router.navigate([
      '/admin/contests/videos',
      {
        id: contest._id,
        contestType: contest.contestType,
        randomWinner: contest.randomWinner == null || contest.randomWinner == undefined ? '' : contest.randomWinner
      }
    ]);

  }
  uploadWinnerVideo(contest: any, source: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { contest: contest, source: source };

    let dialogRef = this.matDialog.open(
      UploadWinnerVideoComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {

        this.toggleClose(null);
      }
    });
  }
  deleteContest(contest: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { contest: contest, source: 'Delete Contest' };

    let dialogRef = this.matDialog.open(
      ChangeContestStatusAndDeleteComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Contest') {
          const param = {
            contestId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteContest}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfContest.forEach((contest: any, index: number) => {
                  if (contest['_id'] == res['value']['_id']) {
                    this.listOfContest.splice(index, 1);
                  }
                });
                this.listOfContestToDisplay = this.listOfContest;

                this.showSuccessToaster(
                  'Contest Delete Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while deleteing Contest. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  publishUnpublishContest(contest: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { contest: contest, source: 'PublishUnPublish Contest' };

    let dialogRef = this.matDialog.open(
      ChangeContestStatusAndDeleteComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Update Contest Status') {
          const param = {
            contestId: res['value']['_id'],
            status: res['status']
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.publishUnpublishContest}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfContest.forEach((contest: any, index: number) => {
                  if (contest['_id'] == res['value']['_id']) {
                    this.listOfContest[index]['isActive'] = res['status'];
                  }
                });
                this.listOfContestToDisplay = this.listOfContest;

                this.showSuccessToaster(
                  'Contest Status Updated Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while updating Contest status. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  async updateContestStatus(contestId: any, status: any, action: any) {
    const param = {
      contestId: contestId,
      status: status,
      action: action,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.updateContestStatus}`;
    await this.appService
      .updateDataViaPut(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfContest.forEach((contest: any, index: number) => {
            if (contest['_id'] == contestId) {
              if (action == 'ContestEnd') {
                this.listOfContest[index]['contestEnd'] = status;
              } else {
                this.listOfContest[index]['hideFromHome'] = status;
              }
            }
          });
          this.listOfContestToDisplay = this.listOfContest;

          this.showSuccessToaster(
            'Contest Updated Successfully',
            'Success'
          );
        } else {
          this.showWarningToaster(
            'Error Occurred while updating contest. Try again',
            'Error'
          );
        }
      });
  }

  async createLink(contest: any) {
    try {
      if (contest.link == '') {
        let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksForContest}`;
        await this.appService
          .postMethodWithToken({ contestId: contest._id }, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.clipboard.copy(data['result']['shortLink']);
              this.showSuccessToaster('Link Copied', 'Success');
            } else {
              this.showErrorToaster('Error occur while creating link', 'Error');
            }
            this.isLoading = false;
          });
      } else {
        this.clipboard.copy(contest.link);
        this.showSuccessToaster('Link Copied', 'Success')
      }

    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
