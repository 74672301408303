import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-design-option',
  templateUrl: './main-design-option.component.html',
  styleUrls: ['./main-design-option.component.scss']
})
export class MainDesignOptionComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void { }

  moveToCategory() {
    this.router.navigate(['/admin/mehndi/category']);
  }
  moveToImages() {
    this.router.navigate(['/admin/mehndi/images'], { queryParams: { type: "Mehendi" } });
  }

  moveToAllQuiz() {
    this.router.navigate(['/admin/quiz-detail'], {
      queryParams: { categoryId: '' },
    });
  }

  moveToQuizContest() {
    this.router.navigate(['/admin/quiz-contest'], {
      queryParams: { categoryId: '' },
    });
  }

}
