import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteQuizDialogComponent } from '../delete-quiz-dialog/delete-quiz-dialog.component';
import { Author } from '../list-quiz-author/list-quiz-author.component';

@Component({
  selector: 'app-add-new-quiz',
  templateUrl: './add-new-quiz.component.html',
  styleUrls: ['./add-new-quiz.component.scss'],
})
export class AddNewQuizComponent implements OnInit {
  isChecked: boolean = false;
  isLoading: boolean = false;
  listOfQuizCategory: any = [];
  listOfQuiz: any = [];
  detailToEdit: any;
  listOfQuestion: any = [];
  addMoreQuizes: boolean = false;
  isEditingExistingQuiz: boolean = false;
  editingQuiz: any;
  selectedFile: any = null;
  selectedFileName: any = 'No File Selected';
  myHeaders = new Headers();
  // myHeaders = new HttpHeaders({'content-type': 'application/json'});
  formdata = new FormData();
  radioSelectedValue: any;
  userDetails: any;
  isReadOnly: boolean = true;
  defaultCoverImage =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

  listOfAuthor: Array<Author> = [];

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  titleFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  categoryFormControl = new FormControl('Select Category', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  authorFormControl = new FormControl('Select Author', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  questionFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  positionFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  answer1FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  answer2FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  answer3FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  answer4FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  radioFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  message1FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  message2FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  message3FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  message4FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  quizForm: FormGroup = new FormGroup({
    title: this.titleFormControl,
    author: this.authorFormControl,
    category: this.categoryFormControl,
    question: this.questionFormControl,
    position: this.positionFormControl,
    answer1: this.answer1FormControl,
    answer2: this.answer2FormControl,
    answer3: this.answer3FormControl,
    answer4: this.answer4FormControl,
    message1: this.message1FormControl,
    message2: this.message2FormControl,
    message3: this.message3FormControl,
    message4: this.message4FormControl,
  });
  listOfCourseOriginal: any = [];
  listOfCourse: any = [];
  selectedCourseName: String = '';
  ngOnInit(): void {
    this.fetchAuthors();
    if (this.as.listOfQuizCategory != null) {
      this.listOfQuizCategory = this.as.listOfQuizCategory;
    }
    this.quizForm = this.formBuilder.group({
      title: this.titleFormControl,
      author: this.authorFormControl,
      category: this.categoryFormControl,
      position: this.positionFormControl,
      question: this.questionFormControl,
      answer1: this.answer1FormControl,
      answer2: this.answer2FormControl,
      answer3: this.answer3FormControl,
      answer4: this.answer4FormControl,
      message1: this.message1FormControl,
      message2: this.message2FormControl,
      message3: this.message3FormControl,
      message4: this.message4FormControl,
    });
    if (this.as.listOfQuizToEditFromQuizSection != null) {
      this.quizForm
        .get('title')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['title']);
      this.quizForm
        .get('author')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['author']);
      this.quizForm
        .get('position')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['position']);
      this.quizForm
        .get('category')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['category']);
      this.defaultCoverImage = this.as.listOfQuizToEditFromQuizSection['image'];

      this.quizForm
        .get('message1')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['message1']);
      this.quizForm
        .get('message2')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['message2']);
      this.quizForm
        .get('message3')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['message3']);
      this.quizForm
        .get('message4')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['message4']);
      if (
        this.defaultCoverImage == undefined ||
        this.defaultCoverImage == '' ||
        this.defaultCoverImage == null
      ) {
        this.defaultCoverImage =
          'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
      }
      this.detailToEdit = this.as.listOfQuizToEditFromQuizSection;

      this.listOfQuiz = this.as.listOfQuizToEditFromQuizSection['content'];
      this.listOfQuiz.forEach((quiz: any) => {
        quiz['isDeleting'] = false;
      });
      this.listOfQuiz.sort(
        (b: any, a: any) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      this.as.listOfQuizToEditFromQuizSection = null;
    } else {
      this.addMoreQuizes = true;
    }
  }

  async fetchAuthors() {
    this.isLoading = true;
    this.listOfAuthor = [];
    let param = {
      allQuiz: true,
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllQuizAuthor}`;
    await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        data['result'].forEach((author: any) => {
          this.listOfAuthor.push({
            _id: author['_id'],
            image: author['image'],
            name: author['name'],
            isActive: author['isActive'],
          });
        });
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  addMoreQuiz(id: any) {
    let el = document.getElementById(id);
    el?.scrollIntoView();
    this.addMoreQuizes = true;
  }

  selectionChange(deviceValue: any) {
    this.selectedCourseName = deviceValue.value;
  }

  backToQuiz() {
    this.router.navigate(['/catalog/quizzes']);
  }

  displayPhoto(event: any) {
    console.log('test');
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];

    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }

  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
  }

  closeQuizAddEditSection() {
    if (this.editingQuiz != null) {
      this.listOfQuiz.push(this.editingQuiz);
      this.editingQuiz = null;
    }
    this.listOfQuiz.sort(
      (b: any, a: any) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    this.addMoreQuizes = false;
    this.isEditingExistingQuiz = false;
    this.quizForm.get('question')?.setValue('');
    this.quizForm.get('answer1')?.setValue('');
    this.quizForm.get('answer2')?.setValue('');
    this.quizForm.get('answer3')?.setValue('');
    this.quizForm.get('answer4')?.setValue('');
    this.radioSelectedValue = null;
  }
  editQuiz(quiz: any) {
    if (this.editingQuiz != null) {
      this.listOfQuiz.push(this.editingQuiz);
      this.editingQuiz = null;
    }
    this.addMoreQuizes = true;
    this.editingQuiz = quiz;
    this.listOfQuiz.forEach((qz: any, index: number) => {
      if (qz['question'] == quiz['question']) {
        this.listOfQuiz.splice(index, 1);
      }
    });
    this.isEditingExistingQuiz = true;
    this.quizForm.get('question')?.setValue(quiz['question']);
    this.quizForm.get('answer1')?.setValue(quiz['options'][0]['option']);
    this.quizForm.get('answer2')?.setValue(quiz['options'][1]['option']);
    this.quizForm.get('answer3')?.setValue(quiz['options'][2]['option']);
    this.quizForm.get('answer4')?.setValue(quiz['options'][3]['option']);
    quiz['options'].forEach((option: any, index: number) => {
      if (option['isCorrect']) {
        this.radioSelectedValue = index;
        this.quizForm.get('correctAnswerRadio')?.setValue(index);
      }
    });
  }

  async insertEditQuiz() {
    this.isLoading = true;
    var URL;
    var content;
    if (this.detailToEdit != null) {
      this.formdata = new FormData();
      var question = {
        question: this.quizForm.get('question')?.value,
        options: [
          {
            isSelected: 'false',
            isCorrect: this.radioSelectedValue == '0' ? true : false,
            optionId: 1,
            option: this.quizForm.get('answer1')?.value,
          },
          {
            isSelected: 'false',
            isCorrect: this.radioSelectedValue == '1' ? true : false,
            optionId: 2,
            option: this.quizForm.get('answer2')?.value,
          },
          {
            isSelected: 'false',
            isCorrect: this.radioSelectedValue == '2' ? true : false,
            optionId: 3,
            option: this.quizForm.get('answer3')?.value,
          },
          {
            isSelected: 'false',
            isCorrect: this.radioSelectedValue == '3' ? true : false,
            optionId: 4,
            option: this.quizForm.get('answer4')?.value,
          },
        ],
      };
      this.formdata.set('quizId', this.detailToEdit['_id']);
      if (this.selectedFile == null) {
        if (this.detailToEdit['image'] == undefined) {
          this.formdata.set('image', '');
        } else {
          this.formdata.set('image', this.detailToEdit['image']);
        }
      } else {
        this.formdata.set('image', this.selectedFile);
      }
      this.formdata.set('title', this.quizForm.get('title')?.value);
      this.formdata.set('position', this.quizForm.get('position')?.value);
      this.formdata.set('category', this.quizForm.get('category')?.value);
      this.formdata.set('author', this.quizForm.get('author')?.value);
      this.formdata.set(
        'isEditingExistingQuiz',
        this.isEditingExistingQuiz.toString()
      );

      this.formdata.set('message1', this.quizForm.get('message1')?.value);
      this.formdata.set('message2', this.quizForm.get('message2')?.value);
      this.formdata.set('message3', this.quizForm.get('message3')?.value);
      this.formdata.set('message4', this.quizForm.get('message4')?.value);

      this.formdata.set('content', JSON.stringify(question));
      URL = `${webApi.domain + webApi.endPoint.updateQuiz}`;
    } else {
      this.formdata = new FormData();
      var questions: any = [];
      if (this.quizForm.get('question')?.value != '') {
        questions = [
          {
            question: this.quizForm.get('question')?.value,
            options: [
              {
                isSelected: 'false',
                isCorrect: this.radioSelectedValue == '0' ? true : false,
                optionId: 1,
                option: this.quizForm.get('answer1')?.value,
              },
              {
                isSelected: 'false',
                isCorrect: this.radioSelectedValue == '1' ? true : false,
                optionId: 2,
                option: this.quizForm.get('answer2')?.value,
              },
              {
                isSelected: 'false',
                isCorrect: this.radioSelectedValue == '2' ? true : false,
                optionId: 3,
                option: this.quizForm.get('answer3')?.value,
              },
              {
                isSelected: 'false',
                isCorrect: this.radioSelectedValue == '3' ? true : false,
                optionId: 4,
                option: this.quizForm.get('answer4')?.value,
              },
            ],
          },
        ];
      }

      if (this.selectedFile != null) {
        this.formdata.set('image', this.selectedFile);
      }
      this.formdata.set('title', this.quizForm.get('title')?.value);
      this.formdata.set('position', this.quizForm.get('position')?.value);
      this.formdata.set('category', this.quizForm.get('category')?.value);
      this.formdata.set('author', this.quizForm.get('author')?.value);
      this.formdata.set('content', JSON.stringify(questions));

      this.formdata.set('message1', this.quizForm.get('message1')?.value);
      this.formdata.set('message2', this.quizForm.get('message2')?.value);
      this.formdata.set('message3', this.quizForm.get('message3')?.value);
      this.formdata.set('message4', this.quizForm.get('message4')?.value);

      URL = `${webApi.domain + webApi.endPoint.insertQuiz}`;
    }
    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    fetch(URL, requestOptions)
      .then((result) => {
        this.formdata.forEach((key: any) => {
          this.formdata.delete(key);
        });
        if (result) {
          let data = result.json();

          data.then((res) => {
            if (res['success']) {
              this.quizForm.reset();
              this.quizForm.get('title')?.setValue(res['data']['title']);
              this.quizForm.get('position')?.setValue(res['data']['position']);
              this.quizForm.get('message1')?.setValue(res['data']['message1']);
              this.quizForm.get('message2')?.setValue(res['data']['message2']);
              this.quizForm.get('message3')?.setValue(res['data']['message3']);
              this.quizForm.get('message4')?.setValue(res['data']['message4']);
              this.quizForm.get('category')?.setValue(res['data']['category']);
              this.quizForm.get('author')?.setValue(res['data']['author']);
              this.listOfQuiz = [];
              this.selectedFile = null;
              this.formdata.forEach((key: any) => {
                this.formdata.delete(key);
              });
              this.listOfQuiz = res['data']['content'];
              this.listOfQuiz.sort(
                (b: any, a: any) =>
                  new Date(b.createdDate).getTime() -
                  new Date(a.createdDate).getTime()
              );

              this.listOfQuiz.forEach((quiz: any) => {
                quiz['isDeleting'] = false;
              });
              this.addMoreQuizes = false;
              this.selectedFileName = 'No File Selected';
              this.defaultCoverImage = res['data']['image'];
              this.detailToEdit = res['data'];
              if (this.isEditingExistingQuiz) {
                this.showSuccessToaster('Quiz Updated Successfully', 'Success');
              } else {
                this.showSuccessToaster('Quiz Added Successfully', 'Success');
              }
              this.isEditingExistingQuiz = false;
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        this.formdata.forEach((key: any) => {
          this.formdata.delete(key);
        });
      });
    return;
  }
  async editQuizMetaData() {
    this.isLoading = true;
    var URL;
    if (this.detailToEdit != null) {
      this.formdata = new FormData();

      this.formdata.set('quizId', this.detailToEdit['_id']);
      if (this.selectedFile == null) {
        if (this.detailToEdit['image'] == undefined) {
          this.formdata.set('image', '');
        } else {
          this.formdata.set('image', this.detailToEdit['image']);
        }
      } else {
        this.formdata.set('image', this.selectedFile);
      }
      this.formdata.set('title', this.quizForm.get('title')?.value);
      this.formdata.set('position', this.quizForm.get('position')?.value);
      this.formdata.set('author', this.quizForm.get('author')?.value);
      this.formdata.set('category', this.quizForm.get('category')?.value);

      this.formdata.set('message1', this.quizForm.get('message1')?.value);
      this.formdata.set('message2', this.quizForm.get('message2')?.value);
      this.formdata.set('message3', this.quizForm.get('message3')?.value);
      this.formdata.set('message4', this.quizForm.get('message4')?.value);
    }
    URL = `${webApi.domain + webApi.endPoint.updateOnlyMetaDataOfQuiz}`;
    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    fetch(URL, requestOptions)
      .then((result) => {
        this.formdata.forEach((key: any) => {
          this.formdata.delete(key);
        });
        if (result) {
          let data = result.json();

          data.then((res) => {
            if (res['success']) {
              this.quizForm.reset();
              this.quizForm.get('title')?.setValue(res['data']['title']);
              this.quizForm.get('position')?.setValue(res['data']['position']);
              this.quizForm.get('message1')?.setValue(res['data']['message1']);
              this.quizForm.get('message2')?.setValue(res['data']['message2']);
              this.quizForm.get('message3')?.setValue(res['data']['message3']);
              this.quizForm.get('message4')?.setValue(res['data']['message4']);
              this.quizForm.get('category')?.setValue(res['data']['category']);
              this.quizForm.get('author')?.setValue(res['data']['author']);
              this.listOfQuiz = [];
              this.selectedFile = null;
              this.formdata.forEach((key: any) => {
                this.formdata.delete(key);
              });
              this.listOfQuiz = res['data']['content'];
              this.listOfQuiz.sort(
                (b: any, a: any) =>
                  new Date(b.createdDate).getTime() -
                  new Date(a.createdDate).getTime()
              );

              this.listOfQuiz.forEach((quiz: any) => {
                quiz['isDeleting'] = false;
              });
              this.addMoreQuizes = false;
              this.selectedFileName = 'No File Selected';
              this.defaultCoverImage = res['data']['image'];
              this.detailToEdit = res['data'];
              this.showSuccessToaster('Quiz Updated Successfully', 'Success');

              this.isEditingExistingQuiz = false;
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        this.formdata.forEach((key: any) => {
          this.formdata.delete(key);
        });
      });
    return;
  }

  deleteQuiz(quiz: any) {
    this.openAlertWhenContactNotConfirmed(quiz);
  }
  openAlertWhenContactNotConfirmed(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Quiz Question', quiz: quiz };
    let dialogRef = this.matDialog.open(
      DeleteQuizDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        this.listOfQuiz.forEach((quiz: any) => {
          quiz['isDeleting'] = false;
        });
        return;
      } else {
        if (res['action'] == 'Quiz Question') {
          const param = {
            quizId: this.detailToEdit['_id'],
            contentId: res['value']['_id'],
          };
          const URL = `${webApi.domain + webApi.endPoint.deleteQuizContent}`;
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          await this.as.deleteData(param, URL, httpHeaders).then((data: any) => {
            if (data['success']) {
              this.listOfQuiz.forEach((quiz: any, index: number) => {
                if (quiz['_id'] == res['value']['_id']) {
                  this.listOfQuiz.splice(index, 1);
                }
              });
              this.showSuccessToaster('Quiz Deleted Successfully', 'Success');
            } else {
              this.listOfQuiz.forEach((quiz: any, index: number) => {
                if (quiz['_id'] == res['value']['_id']) {
                  quiz['isDeleting'] = false;
                }
              });
              this.showErrorToaster(
                'Error Occurred while deleteing quiz. Try again',
                'Error'
              );
            }
          });
        }
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
