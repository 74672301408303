import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-referral-dashboard',
  templateUrl: './referral-dashboard.component.html',
  styleUrls: ['./referral-dashboard.component.scss']
})
export class ReferralDashboardComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;

  selectedDuration: string = 'Monthly';

  p: any = 1;
  count: any = 50;
  startDate: any;
  endDate: any;
  listOfInfluencer: any = [];
  productDetails: any = [];
  amountDetails: any = [];

  listOfDuration: any = [
    'Today',
    'Yesterday',
    'Weekly',
    'Monthly',
    'Current Quarter',
    'Yearly',
  ];

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    this.fetchDashboardDetails(null, null);
  }

  changeDuration(event: any) {
    this.selectedDuration = event.value;
    this.fetchDashboardDetails(null, null);
  }

  async fetchDashboardDetails(startDate: any, endDate: any) {
    this.isLoading = true;
    try {
      let URL = '';
      this.listOfInfluencer = [];
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchReferralDashboardDetails}`;
      const param = {
        duration: this.selectedDuration,
        startDateFilter: startDate,
        endDateFilter: endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isLoading = false;
            this.listOfInfluencer = data['influencer'];
            this.productDetails = data['productDetails'];
            this.amountDetails = data['amountDetails'];
            this.startDate = data['startDate'];
            this.endDate = data['endDate'];
          } else {
            this.isLoading = false;
          }
        });
      this.isLoading = false;

    } catch (error) {
      this.isLoading = false;
    }
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
  }
  dateChanged(type: any, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.fetchDashboardDetails(this.startDate, this.endDate);
    }
  }

  calculateDiff(startDate: Date, endDate: Date) {
    startDate = new Date(startDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
      (1000 * 60 * 60 * 24)
    );
  }


  gotoManageProduct() {
    this.router.navigate(['/admin/referral/manage-product']);
  }

  getCount(type: string) {
    var index = this.productDetails.findIndex((data: any) => data._id == type);
    if (index == -1) {
      return 0;
    } else {
      return this.productDetails[index]['count'];
    }
  }

  getAmount(type: string) {
    var index = this.productDetails.findIndex((data: any) => data._id == type);
    if (index == -1) {
      return 0;
    } else {
      return this.productDetails[index]['totalAmount'].toFixed(0);
    }
  }

  gotoInfluencerDetail(influencer: any) {
    this.appService.influencerToView = influencer;
    this.appService.index = 1;
    this.router.navigate(['/admin/influencer/influencer-detail']);
  }
}
