import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListInfluencerComponent } from './list-influencer/list-influencer.component';
import { InfluencerComponent } from './influencer.component';
import { InfluencerRoutingModule } from './influencer-routing.module';
import { AddEditInfluencerComponent } from './add-edit-influencer/add-edit-influencer.component';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'ng-sidebar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { ViewInfluencerDetailComponent } from './view-influencer-detail/view-influencer-detail.component';

@NgModule({
  declarations: [
    ListInfluencerComponent,
    InfluencerComponent,
    AddEditInfluencerComponent,
    ViewInfluencerDetailComponent
  ],
  imports: [
    CommonModule,
    InfluencerRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule,
  ]
})
export class InfluencerModule { }
