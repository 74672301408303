<div style="height: 100vh;">
    <ng-sidebar-container style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-quiz-category *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-quiz-category>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">



            <!--Top Data  -->
            <div class="row align-center" style="justify-content: space-between;">

                <div class="col-md-4 top-heading" style="cursor: pointer;" (click)="goToQuiz()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span style="font-size: large;
                    color: #1f5ce1;cursor: pointer;">
                        Quiz Categories
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <div class="col-md-2" style="text-align: center;margin-right: 1.2rem;">
                            <button class="add-new-emp" (click)="addNewQuizCategory()">
                                Add New Category
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfQuizCategory.length==0 && !isLoading">
                <div class="no-available">
                    No quiz category detail available.
                </div>
            </div>
            <div *ngIf="listOfQuizCategory.length!=0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" scope="col" class="table-heading-content">ID</th>
                        <th style="cursor: pointer;" class="table-heading-content">NAME</th>
                        <th style="cursor: pointer;" class="table-heading-content">POSITION</th>
                        <th style="cursor: pointer;" class="table-heading-content">STATUS</th>
                        <th style="cursor: pointer;" class="table-heading-content">CREATED DATE</th>
                        <th class="table-heading-content">ACTION</th>
                    </thead>
                    <tbody>
                        <tr class="table-data" *ngFor="let category of listOfQuizCategory">
                            <th class="table-data-content">{{category.id}}</th>
                            <th class="table-data-content">{{category.name}}</th>
                            <th class="table-data-content">{{category.position}}</th>
                            <th class="table-data-content">{{category.isActive ?'Active':'InActive'}}</th>
                            <th class="table-data-content">{{category.createdAt |date}}</th>
                            <th class="table-data-content ">
                                <div style="display: contents;">
                                    <img title="View Quiz" (click)="gotoQuiz(category)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/view.png'>
                                    <img title="Edit" (click)="editQuizCategory(category)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img title="Delete" (click)="deleteQuizCategory(category)"
                                        style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </ng-sidebar-container>

</div>