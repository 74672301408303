import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AddCategoryDialogComponent } from '../add-category-dialog/add-category-dialog.component';
import { DeleteImageDialogComponent } from '../delete-image-dialog/delete-image-dialog.component';
import { IMehndiDesign } from '../list-images/list-images.component';

@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {

  userDetails: any;
  dataToEdit: any;



  selectedCategory: any = 'All';

  constructor(
    private router: Router,
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.fetchDesignCategory();
  }

  isLoading: boolean = true;
  listOfCategory: any = [];
  async fetchDesignCategory() {
    this.isLoading = true;
    this.listOfCategory = [];
    let param = { admin: true };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.fetchDesignCategory}?selectedType=Mehendi`;
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCategory = data['result'];
          this.listOfCategory.forEach((element: any) => {
            element['id'] = element['_id'].slice(-5);
          });
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
    }
  }


  addCategory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { designType: "Mehendi" };
    dialogConfig.width = '30vw';
    dialogConfig.maxHeight = '40vh';
    let dialogRef = this.matDialog.open(AddCategoryDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        this.fetchDesignCategory();
      }
    });
  }

  deleteCategory(category: any, source: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { category: category, source: source };
    let dialogRef = this.matDialog.open(
      DeleteImageDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {

        return;
      } else {
        if (res['action'] == 'Delete Category') {
          const param = {
            categoryId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteDesignCategory}`;
          await this.as
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfCategory.forEach((category: any, index: number) => {
                  if (category['_id'] == res['value']['_id']) {
                    this.listOfCategory.splice(index, 1);
                  }
                });


                this.showSuccessToaster(
                  'Category Delete Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while deleteing Category. Try again',
                  'Warning'
                );
              }
            });
        } else {
        }
      }
    });
  }


  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
