import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-view-influencer-detail',
  templateUrl: './view-influencer-detail.component.html',
  styleUrls: ['./view-influencer-detail.component.scss']
})
export class ViewInfluencerDetailComponent implements OnInit {

  isLoading: boolean = true;
  influencerDetail: any;
  userDetails: any;
  listOfProduct: any = [];
  listOfUser: any = [];
  listOfSubInfluencer: any = [];

  selectedIndex: number = 0;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute, private _location: Location,
    private router: Router,
    public as: AppService,
    private clipboard: Clipboard,

  ) {
    this.userDetails = JSON.parse(
      localStorage.getItem('MERI-SAHELI-WEB')!
    );
  }
  ngOnInit(): void {
    if (this.appService.influencerToView != null) {
      this.influencerDetail = this.appService.influencerToView;
    } else {
      // this.appService.gotoComponent('FOS', this.userDetails['role']);
    }
    this.selectedIndex = this.as.index;
    this.fetchReferralProduct();
  }
  async fetchReferralProduct() {
    this.isLoading = true;
    this.listOfProduct = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchReferralProduct}`;
      param = {};
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfProduct = data['result'];

            console.log(this.listOfProduct);
            this.fetchReferredUser();
          } else {
            this.listOfProduct = [];
            this.fetchReferredUser();
          }
        });
    } catch (error) {
      this.listOfProduct = [];
      this.fetchReferredUser();
    }
  }
  async fetchReferredUser() {
    this.isLoading = true;
    this.listOfUser = [];
    try {
      let param = {
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchReferredUser}`;
      param = { influencerId: this.influencerDetail['_id'] };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfUser = data['result'];

            console.log(this.listOfUser);
            this.fetchSubInfluencer();
          } else {
            this.listOfUser = [];
            this.fetchSubInfluencer();
          }
        });
    } catch (error) {
      this.listOfUser = [];
      this.fetchSubInfluencer();
    }
  }
  async fetchSubInfluencer() {
    this.isLoading = true;
    this.listOfSubInfluencer = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSubInfluencer}`;
      param = { influencerId: this.influencerDetail['_id'] };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSubInfluencer = data['result'];

            console.log(this.listOfSubInfluencer);
            this.isLoading = false;
          } else {
            this.listOfSubInfluencer = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfSubInfluencer = [];
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.appService.influencerToView = null;
  }
  goBack() {
    this._location.back();
  }
  getClassOfCircleDiv(str: string) {
    if (str.toLowerCase().charCodeAt(1) - 96 == 1) {
      return 'avatar-initial-1';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 2) {
      return 'avatar-initial-2';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 3) {
      return 'avatar-initial-3';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 4) {
      return 'avatar-initial-4';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 5) {
      return 'avatar-initial-5';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 6) {
      return 'avatar-initial-6';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 7) {
      return 'avatar-initial-7';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 8) {
      return 'avatar-initial-8';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 9) {
      return 'avatar-initial-9';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 10) {
      return 'avatar-initial-10';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 11) {
      return 'avatar-initial-11';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 12) {
      return 'avatar-initial-12';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 13) {
      return 'avatar-initial-13';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 14) {
      return 'avatar-initial-14';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 15) {
      return 'avatar-initial-15';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 16) {
      return 'avatar-initial-16';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 17) {
      return 'avatar-initial-17';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 18) {
      return 'avatar-initial-18';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 19) {
      return 'avatar-initial-19';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 20) {
      return 'avatar-initial-20';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 21) {
      return 'avatar-initial-21';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 22) {
      return 'avatar-initial-22';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 23) {
      return 'avatar-initial-23';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 24) {
      return 'avatar-initial-24';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 25) {
      return 'avatar-initial-25';
    } else if (str.toLowerCase().charCodeAt(1) - 96 == 26) {
      return 'avatar-initial-26';
    } else {
      return 'avatar-initial';
    }
  }


  getCommissionValue(data: any) {
    if (data['type'] == 'PERCENTAGE') {
      return `${data['value']}%`;
    } else {
      return `\u20b9${data['value']}`;
    }
  }

  getInitialLetters(str: string) {
    return str
      .split(' ')
      .map((item) => item.toUpperCase().substring(0, 1))
      .join('')
      .substring(0, 2);
  }


  async createLink(productId: string) {
    try {
      let URL = `${webApi.domain + webApi.endPoint.createDynamicLinkForInfluencer
        }`;

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });

      let param: any = { productId, influencerId: this.influencerDetail['_id'] };

      await this.as
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clipboard.copy(data['result']['shortLink']);
            this.showSuccessToaster('Link Copied', 'Success');
          } else {
            this.showErrorToaster('Error occur while creating link', 'Error');
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  getProductTitle(product: any) {
    if (product['productType'] == 'BOOK') {
      return product['book']['title'];
    }
    else if (product['productType'] == 'SUBSCRIPTION') {
      return `${product['plan']['duration']} Months Subscription`
    }
    else if (product['productType'] == 'COLLECTION') {
      return product['bookCollection']['name'];
    } else {
      return 'N.A';
    }
  }
}
