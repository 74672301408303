import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-offer',
  templateUrl: './add-edit-offer.component.html',
  styleUrls: ['./add-edit-offer.component.scss']
})
export class AddEditOfferComponent implements OnInit {

  @Input() listOfOffer: any;
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  showPercentage: boolean = false;
  showCard: boolean = false;
  showWallet: boolean = false;

  isCodeAlreadyExists: boolean = false;
  offerType: any;
  bookCount: any;

  show2ndOperation: boolean = true;

  is1stOperationAssigned: boolean = false;
  is2ndOperationAssigned: boolean = false;

  listOfCard: any = [
    { name: 'HDFC' },
    { name: 'ICIC' },
    { name: 'SBIN' },
    { name: 'SRCB' },
  ];

  selectedCard: any = [];

  listOfWallet: any = [
    { name: 'GPAY' },
    { name: 'PHONEPAY' },
    { name: 'PAYTM' },
  ];

  selectedWallet: any = [];

  isLoading: boolean = false;


  // PAN Image
  selectedPANImage: any = null;
  selectedPANImageName: any = null;
  defaultPANImage: any;

  cardDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  walletDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  myHeaders = new Headers();
  formdata = new FormData();

  codeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  discountTypeControl = new FormControl('Select Type', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  percentageControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    Validators.pattern('^[0-9]*$'),
  ]);
  uptoAmountControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    Validators.pattern('^[0-9]*$'),
  ]);
  offerTypeControl = new FormControl('Select Offer Type', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  modeControl = new FormControl('Select Mode', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  walletControl = new FormControl('Select Wallet', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  cardControl = new FormControl('Select Card', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  operation1Control = new FormControl('Select Opertaion', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  val1Control = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  operation2Control = new FormControl('Select Opertaion', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  val2Control = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  startDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  endDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  descriptionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  termsAndConditionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);


  offerTypeSelected(event: any) {
    this.offerType = event.target.value;
  }
  bookCountSelected(event: any) {
    this.bookCount = event.target.value;
  }

  addEditOfferForm: FormGroup = new FormGroup({
    code: this.codeControl,
    discountType: this.discountTypeControl,
    uptoAmount: this.uptoAmountControl,
    offerType: this.offerTypeControl,
    mode: this.modeControl,
    operation1: this.operation1Control,
    value1: this.val1Control,
    operation2: this.operation2Control,
    value2: this.val2Control,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
    description: this.descriptionControl,
    termsAndCondition: this.termsAndConditionControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addEditOfferForm = this.formBuilder.group({
      code: this.codeControl,
      discountType: this.discountTypeControl,
      uptoAmount: this.uptoAmountControl,
      offerType: this.offerTypeControl,
      mode: this.modeControl,
      operation1: this.operation1Control,
      value1: this.val1Control,
      operation2: this.operation2Control,
      value2: this.val2Control,
      startDate: this.startDateControl,
      endDate: this.endDateControl,
      description: this.descriptionControl,
      termsAndCondition: this.termsAndConditionControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {


      this.addEditOfferForm
        .get('code')
        ?.setValue(this.dataToEdit['code']);
      this.addEditOfferForm
        .get('discountType')
        ?.setValue(this.dataToEdit['discountType']);

      if (this.dataToEdit['discountType'] == 'PERCENTAGE') {
        this.showPercentage = true;
        this.addEditOfferForm.addControl('percentage', this.percentageControl);
        this.addEditOfferForm
          .get('percentage')
          ?.setValue(this.dataToEdit['discountValue']['percentage']);
        this.addEditOfferForm
          .get('uptoAmount')
          ?.setValue(this.dataToEdit['discountValue']['uptoAmount']);
      } else {
        this.showPercentage = false;
        this.addEditOfferForm
          .get('uptoAmount')
          ?.setValue(this.dataToEdit['discountValue']['uptoAmount']);
      }

      this.offerType = this.dataToEdit['offerType'];
      if (this.offerType == 'COLLECTION') {
        this.bookCount = this.dataToEdit['bookCount'];
      }

      if (this.dataToEdit['criteria'] != null && this.dataToEdit['criteria'] != undefined) {
        for (const key in this.dataToEdit['criteria']) {
          if (key == 'equalTo') {
            this.show2ndOperation = false;
            this.is1stOperationAssigned = true;
            this.addEditOfferForm.removeControl('operation2');
            this.addEditOfferForm.removeControl('value2');
            this.addEditOfferForm
              .get('operation1')
              ?.setValue(key);
            this.addEditOfferForm
              .get('value1')
              ?.setValue(this.dataToEdit['criteria'][key]);
          } else if (key == 'greaterThan') {
            this.show2ndOperation = true;
            if (!this.is1stOperationAssigned) {
              this.is1stOperationAssigned = true;
              this.addEditOfferForm
                .get('operation1')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value1')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
            if (!this.is2ndOperationAssigned) {
              this.is2ndOperationAssigned = true;
              this.addEditOfferForm
                .get('operation2')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value2')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
          } else if (key == 'lessThan') {
            this.show2ndOperation = true;
            if (!this.is1stOperationAssigned) {
              this.is1stOperationAssigned = true;
              this.addEditOfferForm
                .get('operation1')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value1')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
            if (!this.is2ndOperationAssigned) {
              this.is2ndOperationAssigned = true;
              this.addEditOfferForm
                .get('operation2')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value2')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
          } else if (key == 'greaterThanEqualTo') {
            this.show2ndOperation = true;
            if (!this.is1stOperationAssigned) {
              this.is1stOperationAssigned = true;
              this.addEditOfferForm
                .get('operation1')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value1')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
            if (!this.is2ndOperationAssigned) {
              this.is2ndOperationAssigned = true;
              this.addEditOfferForm
                .get('operation2')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value2')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
          } else if (key == 'lessThanEqualTo') {
            this.show2ndOperation = true;
            if (!this.is1stOperationAssigned) {
              this.is1stOperationAssigned = true;
              this.addEditOfferForm
                .get('operation1')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value1')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
            if (!this.is2ndOperationAssigned) {
              this.is2ndOperationAssigned = true;
              this.addEditOfferForm
                .get('operation2')
                ?.setValue(key);
              this.addEditOfferForm
                .get('value2')
                ?.setValue(this.dataToEdit['criteria'][key]);
              continue;
            }
          }
        }
      }

      this.addEditOfferForm
        .get('offerType')
        ?.setValue(this.dataToEdit['offerType']);

      this.addEditOfferForm
        .get('mode')
        ?.setValue(this.dataToEdit['offerMode']);

      if (this.dataToEdit['offerMode'] == 'WALLET') {
        this.showWallet = true;
        this.showCard = false;
        this.addEditOfferForm.addControl('wallet', this.walletControl);
        this.addEditOfferForm.removeControl('card');
        this.dataToEdit['wallet'].forEach((element: any) => {
          this.selectedWallet.push({ name: element });
        });
      } else if (this.dataToEdit['offerMode'] == 'CARD') {
        this.showCard = true;
        this.showWallet = false;
        this.addEditOfferForm.addControl('card', this.cardControl);
        this.addEditOfferForm.removeControl('wallet');

        this.dataToEdit['card'].forEach((element: any) => {
          this.selectedCard.push({ name: element });
        });
      } else {
        this.showCard = false;
        this.showWallet = false;
        this.addEditOfferForm.removeControl('wallet');
        this.addEditOfferForm.removeControl('card');
      }



      this.addEditOfferForm
        .get('startDate')
        ?.setValue(
          new Date(this.dataToEdit['startDate']).toISOString().slice(0, 10)
        );
      if (this.dataToEdit['endDate'] != undefined) {
        this.addEditOfferForm
          .get('endDate')
          ?.setValue(
            new Date(this.dataToEdit['endDate']).toISOString().slice(0, 10)
          );
      }

      this.addEditOfferForm
        .get('description')
        ?.setValue(this.dataToEdit['description']);
      this.addEditOfferForm.get('termsAndCondition')?.setValue(this.dataToEdit['termsAndCondition']);

      // this.addEditOfferForm
      //   .get('dob')
      //   ?.setValue(
      //     formatDate(new Date(this.dataToEdit['dob']), 'yyyy-MM-dd', 'en-US')
      //   );

      // this.defaultPANImage =
      //   this.dataToEdit['proof'] == null
      //     ? null
      //     : this.dataToEdit['proof']['panCardUrl'];

    }
  }
  codeInputData(target: any) {
    console.log(target.value);
    let codeInput = target.value;
    if (this.dataToEdit == null) {

      let isExists = false;
      this.listOfOffer.forEach((offer: any) => {
        if (offer['code'] == codeInput) {
          isExists = true;
        }
      });
      if (isExists) {
        this.isCodeAlreadyExists = true;
      } else {
        this.isCodeAlreadyExists = false;
      }
    }
    if (this.dataToEdit != null) {
      let isExists = false;
      this.listOfOffer.forEach((offer: any) => {
        if (offer['code'] == codeInput && this.dataToEdit['_id'] != offer['_id']) {
          isExists = true;
        }
      });
      if (isExists) {
        this.isCodeAlreadyExists = true;
      } else {
        this.isCodeAlreadyExists = false;
      }
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  onDiscountTypeSelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    if (selectedVal == 'PERCENTAGE') {
      this.showPercentage = true;
      this.addEditOfferForm.addControl('percentage', this.percentageControl);
    } else {
      this.showPercentage = false;
      this.addEditOfferForm.removeControl('percentage');
    }
  }
  onOperation1SelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    if (selectedVal == 'equalTo') {
      this.show2ndOperation = false;
      this.addEditOfferForm.removeControl('operation2');
      this.addEditOfferForm.removeControl('value2');
    } else {
      this.show2ndOperation = true;
      this.addEditOfferForm.addControl('operation2', this.operation2Control);
      this.addEditOfferForm.addControl('value2', this.val2Control);
    }
  }
  onOperation2SelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
  }
  onModeSelectionChange(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    if (selectedVal == 'WALLET') {
      this.showWallet = true;
      this.showCard = false;
      this.addEditOfferForm.addControl('wallet', this.walletControl);
      this.addEditOfferForm.removeControl('card');
    } else if (selectedVal == 'CARD') {
      this.showCard = true;
      this.showWallet = false;
      this.addEditOfferForm.addControl('card', this.cardControl);
      this.addEditOfferForm.removeControl('wallet');
    } else {
      this.showCard = false;
      this.showWallet = false;
      this.addEditOfferForm.removeControl('wallet');
      this.addEditOfferForm.removeControl('card');
    }
  }



  onItemSelect(item: any) {
    console.log(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  addEdiOffer() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('offerId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('offerId', '0');
    }

    // if (this.selectedPANImage != null) {
    //   this.formdata.append('panCardUrl', this.selectedPANImage);
    // }
    this.formdata.append(
      'code',
      this.addEditOfferForm.get('code')?.value
    );
    this.formdata.append(
      'discountType',
      this.addEditOfferForm.get('discountType')?.value
    );
    let discountValue: any = {};
    if (this.addEditOfferForm.get('discountType')?.value == 'PERCENTAGE') {
      discountValue['percentage'] = this.addEditOfferForm.get('percentage')?.value;
    }
    discountValue['uptoAmount'] = this.addEditOfferForm.get('uptoAmount')?.value;
    this.formdata.append('discountValue', JSON.stringify(discountValue));


    this.formdata.append('offerType', this.addEditOfferForm.get('offerType')?.value);
    this.formdata.append('bookCount', this.offerType == 'COLLECTION' ? this.bookCount : 0);

    this.formdata.append('offerMode', this.addEditOfferForm.get('mode')?.value);

    if (this.addEditOfferForm.get('mode')?.value == 'CARD') {
      let cardList: any = [];
      this.selectedCard.forEach((element: any) => {
        cardList.push(element['name']);
      });
      this.formdata.append('card', JSON.stringify(cardList));
    }
    else if (this.addEditOfferForm.get('mode')?.value == 'WALLET') {
      let walletList: any = [];
      this.selectedWallet.forEach((element: any) => {
        walletList.push(element['name']);
      });
      this.formdata.append('wallet', JSON.stringify(walletList));
    }


    let criteria: any = {};
    if (this.addEditOfferForm.get('operation1')?.value == 'equalTo') {
      criteria['equalTo'] = this.addEditOfferForm.get('value1')?.value;
    } else {
      criteria[this.addEditOfferForm.get('operation1')?.value] = this.addEditOfferForm.get('value1')?.value;
      criteria[this.addEditOfferForm.get('operation2')?.value] = this.addEditOfferForm.get('value2')?.value;
    }
    this.formdata.append('criteria', JSON.stringify(criteria));

    this.formdata.append('startDate', this.addEditOfferForm.get('startDate')?.value);
    this.formdata.append('endDate', this.addEditOfferForm.get('endDate')?.value);

    this.formdata.append('description', this.addEditOfferForm.get('description')?.value);
    this.formdata.append('termsAndCondition', this.addEditOfferForm.get('termsAndCondition')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.addEditOffer}`;

    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEditOfferForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  downloadImage(url: any) {
    window.open(url);
  }

}
