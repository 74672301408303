import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteOfferDialogComponent } from '../delete-offer-dialog/delete-offer-dialog.component';

@Component({
  selector: 'app-list-offer-management',
  templateUrl: './list-offer-management.component.html',
  styleUrls: ['./list-offer-management.component.scss']
})
export class ListOfferManagementComponent implements OnInit {

  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  listOfOffer: any = [];
  listOfOfferToDisplay: any = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    this.fetchAllOffer();
  }


  async fetchAllOffer() {
    this.isLoading = true;
    this.listOfOffer = [];
    let param = {};

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllOffer}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOffer = data['result'];
          this.listOfOfferToDisplay = this.listOfOffer;
          this.isLoading = false;
        } else {
        }
      });
  }

  getInitialLetter(str: string) {
    var matches = str.match(/\b(\w)/g);
    return matches?.join('').toUpperCase();
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfOfferToDisplay = [];

      this.listOfOffer.forEach((offer: any, index: number) => {
        if (
          String(offer['firstName'] + ' ' + offer['lastName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(offer['lastName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(offer['phone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(offer['email'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(offer);
        }
      });
      this.listOfOfferToDisplay = [];
      this.listOfOfferToDisplay = tempSearchList;
    } else {
      this.listOfOfferToDisplay = this.listOfOffer;
    }
  }
  addNewOffer() {
    this.toggleOpened();
  }
  editOffer(offer: any) {
    this.dataToEdit = offer;
    this.toggleOpened();
  }

  deleteOffer(offer: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { offer: offer, source: 'Delete' };

    let dialogRef = this.matDialog.open(
      DeleteOfferDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Offer') {
          const param = {
            offerId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteOffer}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfOffer.forEach((offer: any, index: number) => {
                  if (offer['_id'] == res['value']['_id']) {
                    this.listOfOffer.splice(index, 1);
                  }
                });
                this.listOfOfferToDisplay = this.listOfOffer;

                this.showSuccessToaster(
                  'Offer Delete Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while deleteing Offer. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  publishUnpublishOffer(offer: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { offer: offer, source: 'PublishUnPublish' };

    let dialogRef = this.matDialog.open(
      DeleteOfferDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Update Offer Status') {
          const param = {
            offerId: res['value']['_id'],
            status: res['status']
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.publishUnpublishOffer}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfOffer.forEach((offer: any, index: number) => {
                  if (offer['_id'] == res['value']['_id']) {
                    this.listOfOffer[index]['isActive'] = res['status'];
                  }
                });
                this.listOfOfferToDisplay = this.listOfOffer;

                this.showSuccessToaster(
                  'Offer Status Updated Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while updating Offer status. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing employee details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding employee details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        this.listOfOffer.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfOffer[index] = event;
          }
        });
        this.listOfOfferToDisplay = this.listOfOffer;
        this.showSuccessToaster('Offer Edited Successfully', 'Success');
      } else {
        this.listOfOffer.push(event);
        this.listOfOfferToDisplay = this.listOfOffer;
        this.showSuccessToaster('Offer Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
