import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { SidebarModule } from 'ng-sidebar';
import { AdminComponent } from './admin.component';
import { AdminDashboardComponent } from '../../authentication/admin-dashboard/admin-dashboard.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { SortDirectiveForAdmin } from './sort.directive';
import { UserModule } from './user/user.module';
import { EmployeeManagementModule } from './employee-management/employee-management.module';
import { ComplianceModule } from './compliance/compliance.module';
import { BrandManagementModule } from './brand-management/brand-management.module';
import { VoucherManagementModule } from './voucher-management/voucher-management.module';
import { ContentManagementComponent } from './content-management/content-management.component';
import { ContentManagementModule } from './content-management/content-management.module';
import { NotificationModuleComponent } from './notification-module/notification-module.component';
import { NotificationModuleModule } from './notification-module/notification-module.module';
import { PlanManagementModule } from './plan-management/plan-management.module';
import { VideoManagementComponent } from './video-management/video-management.component';
import { VideoManagementModule } from './video-management/video-management.module';
import { AdminReportModule } from './admin-report/admin-report.module';
import { CampaignManagementComponent } from './campaign-management/campaign-management.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { QuizzesModule } from './quizzes/quizzes.module';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { WhatsappModule } from './whatsapp/whatsapp.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { GameManagementModule } from './game-management/game-management.module';
import { MehndiComponent } from './mehndi/mehndi.component';
import { MehndiModule } from './mehndi/mehndi.module';
import { AuthFormComponent } from './1component/auth-form/auth-form.component';
import { AvatarComponent } from './1component/avatar/avatar.component';
import { BadgeComponent } from './1component/badge/badge.component';
import { BarChartComponent } from './1component/bar-chart/bar-chart.component';
import { BasicTableComponent } from './1component/basic-table/basic-table.component';
import { ButtonGroupComponent } from './1component/button-group/button-group.component';
import { ButtonComponent } from './1component/button/button.component';
import { CardComponent } from './1component/card/card.component';
import { DataTableComponent } from './1component/data-table/data-table.component';
import { DropdownComponent } from './1component/dropdown/dropdown.component';
import { FormChecksComponent } from './1component/form-checks/form-checks.component';
import { FormGroupComponent } from './1component/form-group/form-group.component';
import { IconComponent } from './1component/icon/icon.component';
import { InputGroupComponent } from './1component/input-group/input-group.component';
import { PieChartComponent } from './1component/pie-chart/pie-chart.component';
import { StepperComponent } from './1component/stepper/stepper.component';
import { TagComponent } from './1component/tag/tag.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { NotificationModule } from './notification/notification.module';
import { BookCollectionComponent } from './book-collection/book-collection.component';
import { BookCollectionModule } from './book-collection/book-collection.module';
import { OfferManagementModule } from './offer-management/offer-management.module';
import { ReferralComponent } from './referral/referral.component';
import { ReferralDashboardComponent } from './referral/referral-dashboard/referral-dashboard.component';
import { InfluencerComponent } from './influencer/influencer.component';
import { InfluencerModule } from './influencer/influencer.module';
import { ReferralModule } from './referral/referral.module';
import { InfluencerDashboardComponent } from './influencer-dashboard/influencer-dashboard.component';
import { InfluencerDashboardModule } from './influencer-dashboard/influencer-dashboard.module';
import { SubInfluencerComponent } from './sub-influencer/sub-influencer.component';
import { SubInfluencerModule } from './sub-influencer/sub-influencer.module';
import { UtmComponent } from './utm/utm.component';
import { UtmModule } from './utm/utm.module';
import { TransactionLogModule } from './transaction-log/transaction-log.module';
import { BabyofweekComponent } from './babyofweek/babyofweek.component';
import { BabyofweekModule } from './babyofweek/babyofweek.module';
import { ImageSharingComponent } from './image-sharing/image-sharing.component';
import { ImageSharingModule } from './image-sharing/image-sharing.module';

@NgModule({
  declarations: [
    AdminComponent,
    SortDirectiveForAdmin,
    AdminDashboardComponent,

    LeftSidebarComponent,
    HeaderComponent,
    FooterComponent,

    //  UI Component Library

    // AlertComponent,
    AvatarComponent,
    BadgeComponent,
    TagComponent,
    ButtonComponent,
    ButtonGroupComponent,
    FormGroupComponent,
    InputGroupComponent,
    FormChecksComponent,
    AuthFormComponent,
    DropdownComponent,
    IconComponent,
    CardComponent,
    BasicTableComponent,
    DataTableComponent,
    PieChartComponent,
    BarChartComponent,
    StepperComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    AdminRoutingModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    UserModule,
    EmployeeManagementModule,
    ComplianceModule,
    NotificationModule,
    NotificationModuleModule,
    PlanManagementModule,
    VideoManagementModule,
    AdminReportModule,
    DashboardModule,
    QuizzesModule,
    GameManagementModule,
    MehndiModule,
    OfferManagementModule,
    InfluencerModule,
    ReferralModule,
    InfluencerDashboardModule,
    SubInfluencerModule, TransactionLogModule,
    UtmModule,
    BabyofweekModule,
    ImageSharingModule
  ],
})
export class AdminModule { }
