import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteQuizDialogComponent } from '../delete-quiz-dialog/delete-quiz-dialog.component';
export interface IQuizCategory {
  _id: string;
  id: string;
  name: string;
  position: string;
  image: string;
  createdAt:Date;
  isActive: boolean;
  isDeleted: boolean;
}
@Component({
  selector: 'app-list-quiz-category',
  templateUrl: './list-quiz-category.component.html',
  styleUrls: ['./list-quiz-category.component.scss'],
})
export class ListQuizCategoryComponent implements OnInit {
  userDetails: any;
  dataToEdit: any;

  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  constructor(
    private router: Router,
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.fetchAllCategory();
  }

  isLoading: boolean = true;

  listOfQuizCategoryOriginal: Array<IQuizCategory> = [];
  listOfQuizCategory: Array<IQuizCategory> = [];

  async fetchAllCategory() {
    this.isLoading = true;
    this.listOfQuizCategory = [];
    try {
      const param = {};
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCategory}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          data['result'].forEach((cate: any) => {
            this.listOfQuizCategoryOriginal.push({
              _id: cate['_id'],
              id: cate['_id'].slice(-5),
              name: cate['name'],
              position: cate['position'],
              image: cate['image'],
              isActive: cate['isActive'],
              isDeleted: cate['isDeleted'],
              createdAt: cate['createdAt'],
            });
          });
          this.listOfQuizCategoryOriginal.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          );

          this.listOfQuizCategory = this.listOfQuizCategoryOriginal;
          this.listOfQuizCategory.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          );

          this.isLoading = false;
        } else {
          this.listOfQuizCategoryOriginal = [];
          this.listOfQuizCategory = this.listOfQuizCategoryOriginal;

          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfQuizCategoryOriginal = [];
      this.listOfQuizCategory = this.listOfQuizCategoryOriginal;

      this.isLoading = false;
    }
  }
  searchQuiz(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let newCategoryList: any = [];
    this.listOfQuizCategory = [...this.listOfQuizCategoryOriginal];
    if (serachQuery != '') {
      this.listOfQuizCategory.forEach((category: any) => {
        if (
          String(category['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newCategoryList.push(category);
        }
      });
      this.listOfQuizCategory = [...newCategoryList];
    } else {
      this.listOfQuizCategory = [...this.listOfQuizCategoryOriginal];
    }
  }
  addNewQuizCategory() {
    this.toggleOpened();
  }
  editQuizCategory(category: any) {
    this.dataToEdit = category;
    this.toggleOpened();
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing Quiz Category details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding Quiz Category details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfQuizCategory.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfQuizCategory[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              name: event['name'],
              position: event['position'],
              image: event['image'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
              createdAt: event['createdAt'],
            };
            isExist = true;
          }
        });
        this.listOfQuizCategory.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfQuizCategory[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              name: event['name'],
              position: event['position'],
              createdAt: event['createdAt'],
              image: event['image'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
            };
            // isExist = true;
          }
        });
        if (!isExist) {
          this.listOfQuizCategory.push({
            _id: event['_id'],
            id: event['_id'].slice(-5),
            name: event['name'],
            position: event['position'],
            createdAt: event['createdAt'],
            image: event['image'],
            isActive: event['isActive'],
            isDeleted: event['isDeleted'],
          });
        }
        this.listOfQuizCategory.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );

        this.showSuccessToaster('Quiz Category Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfQuizCategory.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfQuizCategory[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              name: event['name'],
              position: event['position'],
              createdAt: event['createdAt'],
              image: event['image'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
            };
            isExist = true;
          }
        });
        this.listOfQuizCategory.forEach((employee: any, index: number) => {
          if (employee['_id'] == event['_id']) {
            this.listOfQuizCategory[index] = {
              _id: event['_id'],
              id: event['_id'].slice(-5),
              name: event['name'],
              position: event['position'],
              createdAt: event['createdAt'],
              image: event['image'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
            };
            // isExist = true;
          }
        });
        if (!isExist) {
          this.listOfQuizCategory.push({
            _id: event['_id'],
            id: event['_id'].slice(-5),
            name: event['name'],
            position: event['position'],
            createdAt: event['createdAt'],
            image: event['image'],
            isActive: event['isActive'],
            isDeleted: event['isDeleted'],
          });
        }
        this.listOfQuizCategory.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );

        this.showSuccessToaster('Quiz Category Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }
  deleteQuizCategory(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Quiz Category', quiz: quiz };
    let dialogRef = this.matDialog.open(
      DeleteQuizDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfQuizCategory.forEach((qz: any) => {
          qz['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          quizCategoryId: res['value']['_id'],
          isDeleted: true,
        };
        const URL = `${webApi.domain + webApi.endPoint.deleteQuizCategory}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.as.deleteData(param, URL, httpHeaders).then((data: any) => {
          if (data['success']) {
            this.listOfQuizCategory.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                this.listOfQuizCategory.splice(index, 1);
              }
            });
            this.showSuccessToaster(
              'Quiz Category Delete Successfully',
              'Success'
            );
          } else {
            this.listOfQuizCategory.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                quiz['isDeleting'] = false;
              }
            });
            this.showErrorToaster(
              'Error Occurred while deleteing Quiz Category. Try again',
              'Error'
            );
          }
        });
      }
    });
  }

  async updateQuizStatus(quiz: any) {
    this.listOfQuizCategory.forEach((qz: any) => {
      if (qz['_id'] == quiz['_id']) {
        qz['isChangingStatus'] = true;
      }
    });
    const param = {
      quizCategoryId: quiz['_id'],
      isActive: quiz['isActive'] ? false : true,
    };
    const URL = `${webApi.domain + webApi.endPoint.updateQuizCategoryStatus}`;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.as.deleteData(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfQuizCategory.forEach((qz: any) => {
          if (qz['_id'] == quiz['_id']) {
            qz['isActive'] = quiz['isActive'] ? false : true;
            qz['isChangingStatus'] = false;
          }
        });
        this.showSuccessToaster(
          !quiz['isActive']
            ? 'Quiz Category Unpublished Successfully'
            : 'Quiz Category Published Successfully',
          'Success'
        );
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  gotoQuiz(quizCategoryDetails: any) {
    this.router.navigate(['/admin/quiz-detail'], {
      queryParams: { categoryId: quizCategoryDetails['_id'] },
    });
  }

  goToQuiz(){
    this.router.navigate(['/admin/quiz'])
  }
}
