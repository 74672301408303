import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteSuspendUserDialogComponent } from '../delete-suspend-user-dialog/delete-suspend-user-dialog.component';
import { UserSubscriptionComponent } from '../user-subscription/user-subscription.component';
export interface IUser {
  _id: string;
  id: string;

  firstName: any;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
  userRole: string;
  address: any;
  isSubscribed: boolean;
  susbscriptionEndDate: string;

  avatar: string;
  thirdPartyLoginId: string;
  signInWith: string;

  referredBy: any;
  referredTo: any;
  referralCode: any;
  lastActiveDate: any;
  appVersion: any;
  couponUsed: any;
  activeSubscription: any;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
}
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  isAddEditCalled: boolean = false;
  noUserFound: boolean = false;
  isSingleUser: boolean = false;
  userToView: any;
  search: boolean = false;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  selectedRole: string = 'All';
  dataToEdit: any;
  timer: any;

  isLoading: boolean = false;
  listOfUser: Array<IUser> = [];
  listOfUserToDisplay: Array<IUser> = [];
  // listOfVendor: any = [];
  // listOfDriver: any = [];
  p: any = 1;
  count: any = 50;
  userDetails: any;
  dateResult: any = formatDate(new Date(new Date()), 'yyyy-MM-dd', 'en-US');
  selectedTab: any = 0;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    // this.fetchUser(false);
  }

  async inputDataChange(target: any) {
    if (target.value == '') {
      this.noUserFound = false;
      this.listOfUser = [];
      return;
    }
  }
  async searchData(target: any) {
    console.log(target.value);
    if (target.value == '') {
      this.openSingleUserDialog(null);
      this.noUserFound = false;
      return;
    }
    this.noUserFound = false;

    clearTimeout(this.timer);
    console.log('term', target.value);
    this.isLoading = true;
    this.listOfUser = [];
    let param = {};
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.searchUserDetail}`;
    param = {
      searchedString: target.value,
    };
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfUser = data['result'];
          console.log(this.listOfUser);
          clearTimeout(this.timer);
          if (data['result'].length > 0) {
            // if (this.listOfUser.length > 1) {
            //   this.isLoading = false;
            //   this.openMultipleUserDialog(this.listOfUser);
            // } else {
            this.noUserFound = true;
            this.isLoading = false;
            this.openMultipleUserDialog(this.listOfUser);
            // }
          } else {
            this.isLoading = false;
            this.noUserFound = false;
          }
        } else {
          this.isLoading = false;
          this.noUserFound = false;

          this.openSingleUserDialog(null);
        }
      });
  }

  async searchUserWhileTyping(target: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      if (target.value != '') {
        try {
          this.isLoading = true;
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const param = {
            searchedString: target.value,
          };
          const URL = `${webApi.domain + webApi.endPoint.searchUserDetail}`;
          await this.appService
            .fetchDetail(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfUser = data['result'];
                console.log(this.listOfUser);
                if (data['result'].length > 0) {
                  this.isLoading = false;
                  this.noUserFound = true;
                  this.openMultipleUserDialog(this.listOfUser);
                } else {
                  this.isLoading = false;
                  this.noUserFound = false;
                }
              } else {
                this.isLoading = false;
                this.noUserFound = false;
                this.openSingleUserDialog(null);
              }
            });
        } catch (error) {
          this.isLoading = false;
          this.listOfUser = [];
        }
      }
    }, 1000);

    this.dateResult = null;
  }

  openSingleUserDialog(user: any) {
    this.isSingleUser = true;
    this.userToView = user;
  }
  openMultipleUserDialog(user: any) {
    this.isSingleUser = false;
    this.userToView = user;
  }

  async fetchUser(isReported: boolean) {
    this.isLoading = true;
    this.listOfUserToDisplay = [];
    this.listOfUser = [];
    let param = { isReported: isReported };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllUser}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((user: any) => {
            this.listOfUser.push({
              _id: user['_id'],
              id: String(user['_id']).slice(-5),
              firstName: user['firstName'],
              lastName: user['lastName'],
              createdAt: user['createdAt'],
              phone: user['phone'],
              isSubscribed: user['activeSubscription'] == null ? false : true,
              susbscriptionEndDate:
                user['activeSubscription'] == null
                  ? 'N.A'
                  : user['activeSubscription']['endDate'],
              email: user['email'],
              address: user['address'] ?? null,
              appVersion: user['appVersion'],
              dob: user['dob'],
              gender: user['gender'],
              userRole: user['userRole'],
              avatar: user['avatar'],
              activeSubscription: user['activeSubscription'],
              thirdPartyLoginId: user['thirdPartyLoginId'],
              signInWith: user['signInWith'],
              referredBy: user['referredBy'],
              referredTo: user['referredTo'],
              referralCode: user['referralCode'],
              lastActiveDate: user['lastActiveDate'],
              couponUsed: user['couponUsed'],
              isDeleted: user['isDeleted'],
              isActive: user['isActive'],
            });
          });
          this.listOfUserToDisplay = this.listOfUser;
          console.log(this.listOfUser);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.listOfUser = [];
          this.listOfUserToDisplay = this.listOfUser;
        }
      });
  }
  // searchData(target: any) {
  //   console.log(target.value);
  //   let serachQuery = target.value;
  //   if (serachQuery != '') {
  //     let tempSearchList: any = [];
  //     this.listOfUserToDisplay = [];
  //     this.listOfUser.forEach((user: any, index: number) => {
  //       if (
  //         String(user['firstName'] + ' ' + user['lastName'])
  //           .toLowerCase()
  //           .includes(String(serachQuery).toLowerCase()) ||
  //         String(user['lastName'])
  //           .toLowerCase()
  //           .includes(String(serachQuery).toLowerCase()) ||
  //         String(user['email'])
  //           .toLowerCase()
  //           .includes(String(serachQuery).toLowerCase()) ||
  //         String(user['phone'])
  //           .toLowerCase()
  //           .includes(String(serachQuery).toLowerCase())
  //       ) {
  //         tempSearchList.push(user);
  //       }
  //     });
  //     this.listOfUserToDisplay = tempSearchList;
  //   } else {
  //     this.listOfUserToDisplay = [];
  //     this.listOfUserToDisplay = this.listOfUser;
  //   }
  // }

  filter(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    this.listOfUserToDisplay = [];
    this.selectedRole = event.target.value;
    if (this.selectedRole == 'All') {
      this.listOfUserToDisplay = this.listOfUser;
    } else {
      this.listOfUser.forEach((user: any) => {
        if (this.selectedRole == 'Subscribed') {
          if (user.activeSubscription != null) {
            tempSearchList.push(user);
          }
        } else {
          if (user.activeSubscription == null) {
            tempSearchList.push(user);
          }
        }
      });

      this.listOfUserToDisplay = tempSearchList;
    }
  }
  tabChanged(event: any) {
    this.selectedTab = event.index;
    if (this.selectedTab == 0) {
      this.fetchUser(false);
    } else if (this.selectedTab == 1) {
      this.fetchUser(true);
    }
  }
  addNewUser() {
    this.toggleOpened();
  }
  editUser(user: any) {
    this.dataToEdit = user;
    this.toggleOpened();
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing user details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding user details.Kindly try again',
            'Error'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfUser.forEach((user: any, index: number) => {
          if (user['_id'] == event['_id']) {
            this.listOfUser[index] = {
              _id: event['_id'],
              id: String(event['_id']).slice(-5),
              firstName: event['firstName'],
              lastName: event['lastName'],
              createdAt: event['createdAt'],
              phone: event['phone'],
              isSubscribed: event['activeSubscription'] == null ? false : true,
              susbscriptionEndDate:
                event['activeSubscription'] == null
                  ? 'N.A'
                  : event['activeSubscription']['endDate'],
              email: event['email'],
              address: event['address'] ?? null,
              appVersion: event['appVersion'],
              dob: event['dob'],
              gender: event['gender'],
              userRole: event['userRole'],
              avatar: event['avatar'],
              activeSubscription: event['activeSubscription'],
              thirdPartyLoginId: event['thirdPartyLoginId'],
              signInWith: event['signInWith'],
              referredBy: event['referredBy'],
              referredTo: event['referredTo'],
              referralCode: event['referralCode'],
              lastActiveDate: event['lastActiveDate'],
              couponUsed: event['couponUsed'],

              isDeleted: event['isDeleted'],
              isActive: event['isActive'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfUser.push({
            _id: event['_id'],
            id: String(event['_id']).slice(-5),
            firstName: event['firstName'],
            lastName: event['lastName'],
            createdAt: event['createdAt'],
            phone: event['phone'],
            email: event['email'],
            dob: event['dob'],
            gender: event['gender'],
            userRole: event['userRole'],
            address: event['address'] ?? null,
            susbscriptionEndDate:
              event['activeSubscription'] == null
                ? 'N.A'
                : event['activeSubscription']['endDate'],
            avatar: event['avatar'],
            thirdPartyLoginId: event['thirdPartyLoginId'],
            signInWith: event['signInWith'],
            activeSubscription: event['activeSubscription'],
            referredBy: event['referredBy'],
            referredTo: event['referredTo'],
            referralCode: event['referralCode'],
            appVersion: event['appVersion'],
            lastActiveDate: event['lastActiveDate'],
            couponUsed: event['couponUsed'],
            isSubscribed: event['activeSubscription'] == null ? false : true,

            isDeleted: event['isDeleted'],
            isActive: event['isActive'],
          });
        }
        this.showSuccessToaster('User Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfUser.forEach((user: any, index: number) => {
          if (user['_id'] == event['_id']) {
            this.listOfUser[index] = {
              _id: event['_id'],
              id: String(event['_id']).slice(-5),
              firstName: event['firstName'],
              lastName: event['lastName'],
              createdAt: event['createdAt'],
              phone: event['phone'],
              email: event['email'],
              dob: event['dob'],
              isSubscribed: event['activeSubscription'] == null ? false : true,
              susbscriptionEndDate:
                event['activeSubscription'] == null
                  ? 'N.A'
                  : event['activeSubscription']['endDate'],
              gender: event['gender'],
              userRole: event['userRole'],
              activeSubscription: event['activeSubscription'],
              avatar: event['avatar'],
              appVersion: event['appVersion'],
              thirdPartyLoginId: event['thirdPartyLoginId'],
              signInWith: event['signInWith'],
              referredBy: event['referredBy'],
              referredTo: event['referredTo'],
              referralCode: event['referralCode'],
              lastActiveDate: event['lastActiveDate'],
              couponUsed: event['couponUsed'],
              isDeleted: event['isDeleted'],
              address: event['address'] ?? null,

              isActive: event['isActive'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfUser.push({
            _id: event['_id'],
            id: String(event['_id']).slice(-5),
            firstName: event['firstName'],
            lastName: event['lastName'],
            createdAt: event['createdAt'],
            phone: event['phone'],
            email: event['email'],
            address: event['address'] ?? null,

            dob: event['dob'],
            gender: event['gender'],
            userRole: event['userRole'],
            activeSubscription: event['activeSubscription'],
            avatar: event['avatar'],
            thirdPartyLoginId: event['thirdPartyLoginId'],
            signInWith: event['signInWith'],
            referredBy: event['referredBy'],
            referredTo: event['referredTo'],
            appVersion: event['appVersion'],
            isSubscribed: event['activeSubscription'] == null ? false : true,
            susbscriptionEndDate:
              event['activeSubscription'] == null
                ? 'N.A'
                : event['activeSubscription']['endDate'],
            referralCode: event['referralCode'],
            lastActiveDate: event['lastActiveDate'],
            couponUsed: event['couponUsed'],
            isDeleted: event['isDeleted'],
            isActive: event['isActive'],
          });
        }
        this.showSuccessToaster('User Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  subscriptionDialogBox(user: IUser) {
    this.appService.userInfo = user;

    const dialogRef = this.matDialog.open(UserSubscriptionComponent, {
      data: user,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        // console.log(res);
        if (res == null) {
          return;
        } else {
        }
      }
    );
  }

  deleteUser(user: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { user: user, source: 'Delete' };

    let dialogRef = this.matDialog.open(
      DeleteSuspendUserDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete User') {
          const param = {
            userId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteUser}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfUser.forEach((user: any, index: number) => {
                  if (user['_id'] == res['value']['_id']) {
                    this.listOfUser.splice(index, 1);
                  }
                });
                this.listOfUserToDisplay = this.listOfUser;
                this.showSuccessToaster('User Deleted Successfully', 'Success');
              } else {
                this.showErrorToaster(
                  'Error Occurred while deleting User. Try again',
                  'Error'
                );
              }
            });
        } else {
          // this.toggleClose(null);
        }
      }
    });
  }
  suspendUser(user: any, actionToUpdate: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { user: user, source: 'Suspend' };

    let dialogRef = this.matDialog.open(
      DeleteSuspendUserDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Suspend User') {
          const param = {
            userId: res['value']['_id'],
            action: actionToUpdate,
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.suspendUser}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfUser.forEach((user: any, index: number) => {
                  if (user['_id'] == res['value']['_id']) {
                    this.listOfUser[index].isActive = actionToUpdate;
                  }
                });
                this.listOfUserToDisplay = this.listOfUser;
                if (!actionToUpdate) {
                  this.showSuccessToaster(
                    'User Suspended Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'User Suspension Revoked Successfully',
                    'Success'
                  );
                }
              } else {
                this.showErrorToaster(
                  'Error Occurred while suspending User. Try again',
                  'Error'
                );
              }
            });
        } else {
          // this.toggleClose(null);
        }
      }
    });
  }
  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
