import { Component, OnInit } from '@angular/core';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-influencer-view-tnc',
  templateUrl: './influencer-view-tnc.component.html',
  styleUrls: ['./influencer-view-tnc.component.scss']
})
export class InfluencerViewTncComponent implements OnInit {

  isLoading: boolean = true;
  tncDetails: any;
  constructor(
    public as: AppService,
  ) { }

  ngOnInit(): void {
    this.fetchAboutUs();
  }

  async fetchAboutUs() {
    const param = {
      state: 'INFLUENCERTERMSANDCONDITION',
    };

    const URL = `${webApi.domain + webApi.endPoint.fetchInfoWithoutAccessToken}`;
    await this.as.postMethod(param, URL).then((data: any) => {
      if (data['type']) {
        this.tncDetails = data['result'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

}
