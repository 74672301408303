import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-quiz-category',
  templateUrl: './add-quiz-category.component.html',
  styleUrls: ['./add-quiz-category.component.scss'],
})
export class AddQuizCategoryComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  // Category Image
  selectedCategoryImage: any = null;
  selectedCategoryImageName: any = null;
  defaultCategoryImage: any;

  myHeaders = new Headers();
  formdata = new FormData();

  nameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  positionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  addQuizCategoryForm: FormGroup = new FormGroup({
    name: this.nameControl,
    position: this.positionControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addQuizCategoryForm = this.formBuilder.group({
      name: this.nameControl,
      position: this.positionControl,
    });
    if (this.dataToEdit != null) {
      this.addQuizCategoryForm.get('name')?.setValue(this.dataToEdit['name']);
      this.addQuizCategoryForm.get('position')?.setValue(this.dataToEdit['position']);
      this.defaultCategoryImage=this.dataToEdit['image'];
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayCategory(event: any) {
    this.selectedCategoryImage = event.target.files[0];
    this.selectedCategoryImageName = this.selectedCategoryImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCategoryImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedCategoryImage);
  }
  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('quizCategoryId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('quizCategoryId', '0');
    }

    this.formdata.set('name', this.addQuizCategoryForm.get('name')?.value);
    this.formdata.set('position', this.addQuizCategoryForm.get('position')?.value);
    if (this.selectedCategoryImage != null) {
      this.formdata.append('aadhaarUrl', this.selectedCategoryImage);
    }
    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.addEditQuizCategory}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addQuizCategoryForm.reset();
                this.defaultCategoryImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  downloadImage(url: any) {
    window.open(url);
  }
}
