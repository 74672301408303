<div class="main-container">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-contest-category *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-contest-category>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <div class="row top-row-sb">
                <div class="category-heading-text">
                    Categories
                </div>
                <div class="search">
                    <div class="add-category-text" (click)="addNewCategory()">
                        <mat-icon class="add-category-icon">add</mat-icon> Add Category
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div class="no-category" *ngIf="listOfCategoryToDisplay.length==0">
                No Category found
            </div>
            <div class="category-row" *ngIf="listOfCategoryToDisplay.length>0">
                <div class="category" *ngFor="let category of listOfCategoryToDisplay">
                    <div class="upper">
                        <div class="upper1">
                            <img src={{category.thumbnail}} class="category-image" alt="Quiz image">
                            <button class="edit" (click)="editCategory(category)"><img
                                    src="../../../assets/svgIcons/edit-icon.svg" class="edit-icon"></button>
                            <button class="publish" (click)="updateActiveStatus(category,!category.isActive)"><img
                                    [src]="category['isActive']? '../../../../assets/svgIcons/visibility.svg':'../../../../assets/svgIcons/hide.svg'"
                                    class="publish-icon"></button>
                            <button class="delete" (click)="deleteCategory(category)"><img
                                    [src]=" '../../../../assets/svgIcons/delete.svg'" class="delete-icon"></button>
                        </div>
                    </div>
                    <div class="category-content">
                        <h1 class="name">{{category.title}}</h1>
                        <p>{{category.description}}</p>
                        <p>{{category['isActive']?'Published':'Unpublished'}}</p>
                        <div class="contest-button" (click)="goToContest(category)"> Go to contest</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar-container>
</div>