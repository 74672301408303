import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListImagesComponent } from './list-images/list-images.component';
import { MainDesignOptionComponent } from './main-design-option/main-design-option.component';
import { ListCategoryComponent } from './list-category/list-category.component';



const routes: Routes = [
  {
    path: '',
    component: MainDesignOptionComponent,
  },
  {
    path: 'images',
    component: ListImagesComponent,
  },
  {
    path: 'category',
    component: ListCategoryComponent,
  },
  
  { path: '', pathMatch: 'full', redirectTo: 'admin/mehndi' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MehndiRoutingModule { }
