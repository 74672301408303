import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../game-management/active-delete-dialog/active-delete-dialog.component';

export interface GameCategory {
  _id: string;
  createdAt: Date;
  position: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-list-game-category',
  templateUrl: './list-game-category.component.html',
  styleUrls: ['./list-game-category.component.scss'],
})
export class ListGameCategoryComponent implements OnInit {
  brandDetails: any;

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfGameCategoryToDisplay: Array<GameCategory> = [];
  listOfGameCategory: Array<GameCategory> = [];
  listOfGameCategoryOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];
  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.fetchGameCategories();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async fetchGameCategories() {
    this.listOfGameCategoryToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchAllGameCategory}`;
      const param = {
        admin: true,
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfGameCategoryToDisplay = data['result'];
          this.listOfGameCategory = [...this.listOfGameCategoryToDisplay];
          this.isLoading = false;
        } else {
          this.listOfGameCategoryToDisplay = [];
          this.listOfGameCategory = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfGameCategoryToDisplay = [];
      this.listOfGameCategory = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfGameCategoryToDisplay = [];
      this.listOfGameCategory.forEach((game: any, index: number) => {
        if (
          String(game.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(game);
        }
      });
      this.listOfGameCategoryToDisplay = tempSearchList;
    } else {
      this.listOfGameCategoryToDisplay = [];
      this.listOfGameCategoryToDisplay = this.listOfGameCategory;
    }
  }

  addNewCategory() {
    this.toggleOpened();
  }

  deleteGame(game: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { game: game, source: 'Delete GameCategory' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          gameCategoryId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusGameCategory
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfGameCategoryToDisplay.forEach((game: any, i: number) => {
                if (game['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfGameCategoryToDisplay.splice(index, 1);
              this.listOfGameCategory = [...this.listOfGameCategoryToDisplay];

              this.showSuccessToaster('Game Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting game. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  editGame(game: any) {
    let gameToEdit;
    this.listOfGameCategoryToDisplay.forEach((ofr: any) => {
      if (ofr['_id'] == game._id) {
        gameToEdit = ofr;
      }
    });
    this.dataToEdit = gameToEdit;
    this.toggleOpened();
  }

  updateActiveStatus(game: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { game: game, source: 'Active GameCategory' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          gameCategoryId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusGameCategory
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfGameCategoryToDisplay.forEach((game: any, index: number) => {
                if (game['_id'] == res['value']['_id']) {
                  game.isActive = isActive;
                }
              });
              if (isActive) {
                this.showSuccessToaster(
                  'Game Category Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Game Category Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating game. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }

  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing game details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding game details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfGameCategoryToDisplay.findIndex(
          (game: any) => game['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfGameCategoryToDisplay.push(event);
          this.listOfGameCategory = [...this.listOfGameCategoryToDisplay];
          this.showSuccessToaster('Game added successfully', 'Success');
        } else {
          this.listOfGameCategoryToDisplay[index] = event;
          this.listOfGameCategory = [...this.listOfGameCategoryToDisplay];
          this.showSuccessToaster('Game Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  gotoGameOption() {
    this.as.gotoComponent('Games');
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
