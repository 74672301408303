<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">

        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-banner *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-banner>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->

            <div class="row align-center" style="justify-content: space-between;">
                <div class="col-md-4 top-heading">
                    Banner
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <!-- <div class="col-md-8" style="text-align: right;">
                <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                    (input)="searchData($event.target)">
            </div> -->
                        <div class="col-md-2" style="text-align: center;margin-right: 1.2rem;">
                            <button class="add-new-emp" (click)="addNewBanner()">
                                Add Banner
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfBannerToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No banner available.
                </div>
            </div>
            <div *ngIf="listOfBannerToDisplay.length!=0 && !isLoading">
                <div class="row" style="margin-top: 1em;margin-right: 2.5rem;">
                    <div class="col-md-3 brand-card" *ngFor="let banner of listOfBanner">
                        <img class="img"
                            src="{{banner.bannerImage==''? 'https://knetic.org.uk/wp-content/uploads/2020/07/Audio-Record-Placeholder.png' : banner.bannerImage}}"
                            alt="Brand Image" srcset="">
                        <div class="detail-position">
                            <span class="brandName">Route: {{banner.route}}</span><br>
                            <span class="brandName">Expiry Date: {{banner.endDate|date}}</span><br>
                        </div>
                        <div class="row">
                            <div class="brand-icon-position" (click)="editBanner(banner)">
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div class="brand-icon-position" style="top: 43px;" (click)="deleteBanner(banner)">
                                <mat-icon>delete</mat-icon>
                            </div>
                            <div *ngIf="banner.isActive" class="brand-icon-position" style="top: 82px;"
                                (click)="updateActiveStatus(banner,false)">
                                <mat-icon>visibility</mat-icon>
                            </div>
                            <div *ngIf="!banner.isActive" class="brand-icon-position" style="top: 82px;"
                                (click)="updateActiveStatus(banner,true)">
                                <mat-icon>visibility_off</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div> -->
                    <div class="space-10">

                    </div>
            </div>
        </div>
    </ng-sidebar-container>

</div>