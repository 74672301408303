import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-box-list-report',
  templateUrl: './box-list-report.component.html',
  styleUrls: ['./box-list-report.component.scss'],
})
export class BoxListReportComponent implements OnInit {
  isLoading: boolean = false;
  listOfBox: any = [
    {
      label: 'Registered User',
      route: '/admin/report/registered-user',
      routeId: 'registered-user',
      img: '../../../assets/img/RegisteredUser.png',
    },
    {
      label: 'Last Active User',
      route: '/admin/report/last-active-user',
      routeId: 'last-active-user',
      img: '../../../assets/img/UserLastActive.png',
    },
    {
      label: 'Transaction',
      route: '/admin/report/transaction',
      routeId: 'transaction',
      img: '../../../assets/img/Pickup.png',
    },
    {
      label: 'Book',
      route: '/admin/report/book',
      routeId: 'book',
      img: '../../../assets/img/magazine.png',
    },
    // {
    //   label: 'Subscription',
    //   route: '/admin/report/subscription',
    //   routeId: 'subscription',
    //   img: '../../../assets/img/Pickup.png',
    // },
  ];
  listOfFranchise: any = [];
  listOfVendor: any = [];
  isOptionSelected: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.isOptionSelected = null;

    console.log('Box list Report');
  }
  userDetails: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetails['role'] == 'Admin') {
      this.listOfBox = [
        {
          label: 'Registered User',
          route: '/admin/report/registered-user',
          routeId: 'registered-user',
          img: '../../../assets/img/RegisteredUser.png',
        },
        {
          label: 'Last Active User',
          route: '/admin/report/last-active-user',
          routeId: 'last-active-user',
          img: '../../../assets/img/UserLastActive.png',
        },
        {
          label: 'Transaction',
          route: '/admin/report/transaction',
          routeId: 'transaction',
          img: '../../../assets/img/Pickup.png',
        },
        {
          label: 'Book',
          route: '/admin/report/book',
          routeId: 'book',
          img: '../../../assets/img/magazine.png',
        },
      ];
    }
  }

  closeTab() {
    this.isOptionSelected = null;
    this.as.typeOfReport = null;
  }
  navigateToRoute(route: string) {
    let routeId;
    this.listOfBox.forEach((box: any) => {
      if (box['route'] == route) {
        this.isOptionSelected = box['label'];
        routeId = box['routeId'];
        this.as.typeOfReport = box['label'];
      }
    });
    this.router.navigate([route]);
  }
}
