import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-create-quiz-contest',
  templateUrl: './create-quiz-contest.component.html',
  styleUrls: ['./create-quiz-contest.component.scss'],
})
export class CreateQuizContestComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  listOfQuizzes = [];
  selectedQuiz: any = [];

  lngDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    limitSelection: 1,
  };

  myHeaders = new Headers();
  formdata = new FormData();

  startDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  endDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  numberOfWinnersControl = new FormControl('Select Type', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  addContestForm: FormGroup = new FormGroup({
    startDate: this.startDateControl,
    endDate: this.endDateControl,
    numberOfWinners: this.numberOfWinnersControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public quizs: ActivatedRoute,
    private quizr: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  async ngOnInit(): Promise<void> {
    this.fetchAllQuizzes();
    this.addContestForm = this.formBuilder.group({
      startDate: this.startDateControl,
      endDate: this.endDateControl,
      numberOfWinners: this.numberOfWinnersControl,
    });
  }

  async fetchAllQuizzes() {
    this.isLoading = true;
    this.listOfQuizzes = [];
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAllQuizzes}`;
    await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfQuizzes = data['result'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  generateQuizContest() {
    this.isLoading = true;

    this.formdata.append(
      'startDate',
      this.addContestForm.get('startDate')?.value
    );

    this.formdata.append('endDate', this.addContestForm.get('endDate')?.value);
    this.formdata.append('quiz', this.selectedQuiz[0]['_id']);
    this.formdata.append(
      'numberOfWinners',
      this.addContestForm.get('numberOfWinners')?.value
    );

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.generateQuizContest}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addContestForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
