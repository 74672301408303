import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogContentComponent } from '../../content-management/active-delete-dialog-content/active-delete-dialog-content.component';
import { Influencer } from '../../influencer/list-influencer/list-influencer.component';

@Component({
  selector: 'app-list-sub-influencer',
  templateUrl: './list-sub-influencer.component.html',
  styleUrls: ['./list-sub-influencer.component.scss']
})
export class ListSubInfluencerComponent implements OnInit {

  brandDetails: any;

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfSubInfluencerToDisplay: Array<Influencer> = [];
  listOfSubInfluencer: Array<Influencer> = [];
  listOfSubInfluencerOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];
  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.brandDetails = this.as.brandDetails;
    this.fetchSubInfluencers();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  async fetchSubInfluencers() {
    this.listOfSubInfluencerToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchSubInfluencer}`;
      const param = {
        role: 'INFLUENCER',
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSubInfluencerToDisplay = data['result'];
          this.listOfSubInfluencer = [...this.listOfSubInfluencerToDisplay];
          this.isLoading = false;
        } else {
          this.listOfSubInfluencerToDisplay = [];
          this.listOfSubInfluencer = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfSubInfluencerToDisplay = [];
      this.listOfSubInfluencer = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfSubInfluencerToDisplay = [];
      this.listOfSubInfluencer.forEach((influencer: any, index: number) => {
        if (
          String(influencer['offerCode'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(influencer['brand']['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(influencer);
        }
      });
      this.listOfSubInfluencerToDisplay = tempSearchList;
    } else {
      this.listOfSubInfluencerToDisplay = [];
      this.listOfSubInfluencerToDisplay = this.listOfSubInfluencer;
    }
  }

  addNewSubInfluencer() {
    this.toggleOpened();
  }

  deleteSubInfluencer(influencer: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { influencer: influencer, source: 'Delete Influencer' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          influencerId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.updateActiveOrDeleteStatuInfluencer
          }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfSubInfluencerToDisplay.forEach((influencer: any, i: number) => {
                if (influencer['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfSubInfluencerToDisplay.splice(index, 1);
              this.listOfSubInfluencer = [...this.listOfSubInfluencerToDisplay];

              this.showSuccessToaster('SubInfluencer Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting influencer. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  editSubInfluencer(influencer: any) {
    let influencerToEdit;
    this.listOfSubInfluencerToDisplay.forEach((pln: any) => {
      if (pln['_id'] == influencer._id) {
        influencerToEdit = pln;
      }
    });
    this.dataToEdit = influencerToEdit;
    this.toggleOpened();
  }

  updateActiveStatus(influencer: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { influencer: influencer, source: 'Active Influencer' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          influencerId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.updateActiveOrDeleteStatuInfluencer
          }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfSubInfluencerToDisplay.forEach((influencer: any, index: number) => {
                if (influencer['_id'] == res['value']['_id']) {
                  influencer.isActive = isActive;
                }
              });
              if (isActive) {
                this.showSuccessToaster(
                  'SubInfluencer Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'SubInfluencer Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating influencer details. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing influencer.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding influencer.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfSubInfluencerToDisplay.findIndex(
          (influencer: any) => influencer['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfSubInfluencerToDisplay.unshift(event);
          this.listOfSubInfluencer = [...this.listOfSubInfluencerToDisplay];
          this.showSuccessToaster('SubInfluencer added successfully', 'Success');
        } else {
          this.listOfSubInfluencerToDisplay[index] = event;
          this.listOfSubInfluencer = [...this.listOfSubInfluencerToDisplay];
          this.showSuccessToaster('SubInfluencer Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }
  gotoSubInfluencerDetail(influencer: any) {
    this.as.influencerToView = influencer;
    this.router.navigate(['/admin/influencer/influencer-detail']);
  }
  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

}
