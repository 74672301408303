<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Games
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

            </div>
        </div>
    </div>

    <div style="height: 79vh;margin-top: 2em;">
        <div class="row" style="margin-right: 2em;">
            <div class="col-md-3" (click)="moveToCategory()">
                <div class="main-card">
                    <img class="img-card" src='https://merisaheli2.s3.ap-south-1.amazonaws.com/cover.webp'
                        alt="State Image" srcset="">
                    <div class="name-on-card">
                        GAME CATEGORIES
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="main-card" (click)="moveToGame()">
                    <img class="img-card"
                        src='https://cf.ltkcdn.net/boardgames/images/std-xs/262084-340x219-poker-dice.jpg'
                        alt="City Image" srcset="">
                    <div class="name-on-card">
                        ALL GAMES
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>