import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from './api';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Variables

  utmData: any;
  isOtherChatComponentCalled: boolean = false;
  listOfQuizToEditFromQuizSection: any;
  influencerToView: any;
  campaignDetailToView: any;
  campaignEdit: any;
  listOfQuizCategory: any;
  typeOfReport: any;
  brandDetails: any;
  listOfCities: any = [];
  validUsersDetail: any;
  magazineToView: any;
  usersDetailForSignUp: any;
  userId: String = '';
  userDetails: any;
  userInfo: any;
  sidebarOptions: any = [];
  meriSaheliId: string = '62207e590a4c933f9caa5497';
  index: number = 0;


  sidebarOptionsForAdmin: any = [
    // {
    //   title: 'Contests',
    //   svgUrl: '../assets/img/game.png',
    //   route: '/admin/babyofweek',
    //   isSelected: false,
    // },

    {
      title: 'Dashboard',
      svgUrl: '../assets/svgIcons/analytics.svg',
      route: '/admin/dashboard',
      isSelected: false,
    },
    {
      title: 'User Management',
      svgUrl: '../assets/svgIcons/user.svg',
      route: '/admin/user',
      isSelected: false,
    },
    {
      title: 'Employee Management',
      svgUrl: '../assets/svgIcons/users.svg',
      route: '/admin/employee',
      isSelected: false,
    },
    {
      title: 'Magazine Management',
      svgUrl: '../assets/img/magazine.png',
      route: '/admin/magazine',
      isSelected: false,
    },
    {
      title: 'Book Collection',
      svgUrl: '../assets/img/magazine.png',
      route: '/admin/collection',
      isSelected: false,
    },
    {
      title: 'Brand Management',
      svgUrl: '../assets/img/offer.png',
      route: '/admin/brand',
      isSelected: false,
    },
    {
      title: 'Coupon',
      svgUrl: '../assets/img/offer.png',
      route: '/admin/coupon',
      isSelected: false,
    },
    {
      title: 'Offer Management',
      svgUrl: '../assets/img/offer.png',
      route: '/admin/offer',
      isSelected: false,
    },
    {
      title: 'Notification Management',
      svgUrl: '../assets/svgIcons/notification.svg',
      route: '/admin/notification',
      isSelected: false,
    },
    {
      title: 'Notification Management-1',
      svgUrl: '../assets/svgIcons/notification.svg',
      route: '/admin/notification-1',
      isSelected: false,
    },
    {
      title: 'Banner Management',
      svgUrl: '../assets/img/banner.png',
      route: '/admin/compliance',
      isSelected: false,
    },
    {
      title: 'Campaign',
      svgUrl: '../assets/svgIcons/campaign.svg',
      route: '/admin/campaign',
      isSelected: false,
    },
    {
      title: 'Referral',
      svgUrl: '../assets/svgIcons/referral-link.svg',
      route: '/admin/referral',
      isSelected: false,
    },
    {
      title: 'Influencer',
      svgUrl: '../assets/svgIcons/users.svg',
      route: '/admin/influencer',
      isSelected: false,
    },
    {
      title: 'BuildBy Chat',
      svgUrl: '../assets/img/buildby.png',
      route: '/buildby-chat',
      isSelected: false,
    },
    {
      title: 'Contests',
      svgUrl: '../assets/img/game.png',
      route: '/admin/babyofweek',
      isSelected: false,
    },
    {
      title: 'Games',
      svgUrl: '../assets/img/game.png',
      route: '/admin/game',
      isSelected: false,
    },
    {
      title: 'Mehndi',
      svgUrl: '../assets/img/mehndi.png',
      route: '/admin/mehndi',
      isSelected: false,
    },
    {
      title: 'Image Sharing',
      svgUrl: '../assets/img/magazine.png',
      route: '/admin/imageSharing',
      isSelected: false,
    },
    {
      title: 'Plan Management',
      svgUrl: '../assets/img/rupee.png',
      route: '/admin/plan',
      isSelected: false,
    },
    {
      title: 'Video Management',
      svgUrl: '../assets/img/video.png',
      route: '/admin/video',
      isSelected: false,
    },
    {
      title: 'Quiz',
      svgUrl: '../assets/img/quiz.svg',
      route: '/admin/quiz',
      isSelected: false,
    },
    {
      title: 'Report',
      svgUrl: '../assets/svgIcons/collection.svg',
      route: '/admin/report',
      isSelected: false,
    },
    {
      title: 'Transaction',
      svgUrl: '../assets/svgIcons/collection.svg',
      route: '/admin/transaction',
      isSelected: false,
    },
    {
      title: 'Compliance',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/admin/compliance',
      isSelected: false,
    },
  ];

  userDetail: any;
  //constructor
  constructor(
    private _httpClient: HttpClient,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetail != null) {
      if (this.userDetail['role'] == 'Admin') {
        this.sidebarOptions = this.sidebarOptionsForAdmin;
        this.sidebarOptions.forEach((ele: any, index: any) => {
          if (index == 0) {
            ele['isSelected'] = true;
          } else {
            ele['isSelected'] = false;
          }
        });
      }
    }
  }
  get windowRef() {
    return window;
  }
  gotoDefault(userDetail: any) {
    // if (userDetail['role'] == 'Admin') {
    this.sidebarOptions = this.sidebarOptionsForAdmin;
    this.sidebarOptions.forEach((ele: any, index: any) => {
      if (index == 0) {
        ele['isSelected'] = true;
      } else {
        ele['isSelected'] = false;
      }
    });
    this.router.navigate(['/admin/user']);
    // }
  }
  async sideBar() {
    this.userDetail = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetail == null) {
      this.router.navigate(['/auth/login']);
      return;
    }
    if (this.userDetail['role'] == 'Admin') {
      this.sidebarOptions = [...this.sidebarOptionsForAdmin];
    } else if (this.userDetail['role'].toString().toLowerCase() == 'influencer' || this.userDetail['role'].toString().toLowerCase() == 'subinfluencer') {
      this.sidebarOptions = [
        {
          title: 'Influencer Dashboard',
          svgUrl: '../assets/svgIcons/analytics.svg',
          route: '/admin/influencerDashboard',
          isSelected: false,
        },
        {
          title: 'Sub Influencer',
          svgUrl: '../assets/svgIcons/users.svg',
          route: '/admin/subInfluencer',
          isSelected: false,
        },
        {
          title: 'Products',
          svgUrl: '../assets/svgIcons/users.svg',
          route: '/admin/referral-product',
          isSelected: false,
        },
      ];
    } else {
      this.sidebarOptions = [];
      const param = {
        phone: this.userDetail['phone'],
        role: this.userDetail['role'],
      };
      const URL = `${webApi.domain + webApi.endPoint.isEmployeeExist}`;
      await this.fetchDataFromDB(param, URL).then(async (data: any) => {
        if (data['type']) {
          data['result']['privilegeList'].forEach((access: any) => {
            this.sidebarOptionsForAdmin.forEach((data: any) => {
              if (access == data['title']) {
                this.sidebarOptions.push(data);
              }
            });
          });
        }
      });
    }
    this.sidebarOptions.forEach((data: any) => {
      data['isSelected'] = false;
    });
    this.sidebarOptions[0]['isSelected'] = true;
    this.gotoComponent(this.sidebarOptions[0]['title']);
  }

  setSideBarState(urlPath: any) {
    var pagefound = false;
    this.sideBar();
    if (urlPath == undefined) {
      this.sidebarOptions[0].isSelected = true;
    } else {
      this.sidebarOptions.forEach((option: any) => {
        if (option.route == '/' + urlPath[3] + '/' + urlPath[4]) {
          option.isSelected = true;
          pagefound = true;
          this.gotoComponent(option['title']);
        } else {
          option.isSelected = false;
        }
      });
    }
    // if (!pagefound) {
    //   this.router.navigate([this.sidebarOptions[0]['route']]);
    // }
  }

  gotoComponent(title: String) {
    this.sidebarOptions.forEach((option: any) => {
      if (option['title'] == title) {
        option['isSelected'] = true;
      } else {
        option['isSelected'] = false;
      }
    });

    switch (title) {
      case 'Dashboard':
        this.router.navigate(['/admin/dashboard']);
        break;
      case 'User Management':
        this.router.navigate(['/admin/user']);
        break;
      case 'Employee Management':
        this.router.navigate(['/admin/employee']);
        break;
      case 'Brand Management':
        this.router.navigate(['/admin/brand']);
        break;
      case 'Mehndi':
        this.router.navigate(['/admin/mehndi']);
        break;
      case 'Image Sharing':
        this.router.navigate(['/admin/imageSharing']);
        break;
      case 'Offer Management':
        this.router.navigate(['/admin/offer']);
        break;
      case 'Coupon':
        this.router.navigate(['/admin/brand/coupon']);
        break;
      case 'Quiz':
        this.router.navigate(['/admin/quiz']);
        break;
      case 'Compliance':
        this.router.navigate(['/admin/compliance']);
        break;
      case 'Report':
        this.router.navigate(['/admin/report']);
        break;
      case 'Contests':
        this.router.navigate(['/admin/contests']);
        break;
      case 'Transaction':
        this.router.navigate(['/admin/transaction']);
        break;
      case 'Magazine Management':
        this.router.navigate(['/admin/magazine']);
        break;
      case 'Banner Management':
        this.router.navigate(['/admin/banner-management']);
        break;
      case 'Book Collection':
        this.router.navigate(['/admin/collection']);
        break;
      case 'Notification Management':
        this.router.navigate(['/admin/notification']);
        break;
      case 'Notification Management-1':
        this.router.navigate(['/admin/notification-1']);
        break;
      case 'Video Management':
        this.router.navigate(['/admin/video']);
        break;
      case 'Plan Management':
        this.router.navigate(['/admin/plan']);
        break;
      case 'BuildBy Chat':
        this.router.navigate([]).then(result => { window.open('/buildby-chat', '_blank'); });
        break;
      case 'Campaign':
        this.router.navigate(['/admin/campaign']);
        break;
      case 'Referral':
        this.router.navigate(['/admin/referral']);
        break;
      case 'Influencer':
        this.router.navigate(['/admin/influencer']);
        break;
      case 'Sub Influencer':
        this.router.navigate(['/admin/subInfluencer']);
        break;
      case 'Products':
        this.router.navigate(['/admin/referral-product']);
        break;
      case 'Influencer Dashboard':
        this.router.navigate(['/admin/influencerDashboard']);
        break;
      case 'Games':
        this.router.navigate(['/admin/game']);
        break;
      default:
        this.router.navigate(['/admin/user']);
        break;
    }
  }
  //

  fetchDetailViaGet(url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDetail(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  addData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDataFromDB(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDataFromDBGet(url: string) {
    return new Promise((resolve) => {
      this._httpClient.get(url).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateDataViaPut(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
}
