import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-campaign',
  templateUrl: './add-edit-campaign.component.html',
  styleUrls: ['./add-edit-campaign.component.scss', './stepper.scss']
})
export class AddEditCampaignComponent implements OnInit {

  isLoading: boolean = true;
  isTemplateLoading: boolean = false;

  isGetTargetLoading: boolean = false;
  targetUserCountCalled: boolean = false;
  targetUserCount: number = 0;

  isSaveCampaignLoading: boolean = false;
  showCampaignDateTime: boolean = true;

  dataToEdit: any;

  myHeaders = new Headers();
  formdata = new FormData();
  radioSelectedValue: any;


  // WhatsApp Variables

  listOfTemplate: any = [];
  listOfTemplateToDisplay: any = [];
  selectedTemplate: any = [];
  template: any;
  showTemplateBody: boolean = false;
  showHeaderOfTemplate: boolean = false;
  showHeaderMediaOfTemplate: boolean = false;
  showHeaderTextOfTemplate: boolean = false;
  showBodyOfTemplate: boolean = false;
  showFooterOfTemplate: boolean = false;
  showButtonOfTemplate: boolean = false;
  selectedCampaignMedium: any;
  bodyVariableCount: number = 0;

  // Image For WhatsApp
  selectedHeaderMedia: any = null;
  selectedHeaderMediaName: any = null;
  defaultHeaderMedia: any;


  // WhatsApp Variables
  listOfStepper: any = [
    {
      label: 'Campaign Detail',
      content: '1',
    },
    {
      label: 'Target users',
      content: '2',
    },
    {
      label: 'Content',
      content: '3',
    },

  ];
  listOfMedium: any = [
    {
      label: 'WhatsApp', content: '1',
    },
    {
      label: 'Sms', content: '1',
    },
    {
      label: 'In App', content: '1',
    },
    {
      label: 'Push Notification', content: '1',
    },
    {
      label: 'Popup', content: '1',
    },
    // {
    //   label: 'Email', content: '1',
    // }
  ];
  listOfDays: any = [
    {
      label: 'MON',
    },
    {
      label: 'TUE',
    },
    {
      label: 'WED',
    },
    {
      label: 'THU',
    },
    {
      label: 'FRI',
    },
    {
      label: 'SAT',
    },
    {
      label: 'SUN',
    },

  ];
  listOfDuartion: any = [
    {
      label: '3 Days',
      duration: 3,
    },
    {
      label: '7 Days',
      duration: 7,
    },
    {
      label: '15 Days',
      duration: 15,
    },
    {
      label: '30 Days',
      duration: 30,
    },
    {
      label: '90 Days',
      duration: 90,
    },

  ];
  listOfRange: any = [
    {
      label: '0%-10%',
      value: '0-10',
      lowerRange: 0,
      upperRange: 10,
    },
    {
      label: '10%-20%',
      value: '10-20',
      lowerRange: 10,
      upperRange: 20,
    },
    {
      label: '20%-40%',
      value: '20-40',
      lowerRange: 20,
      upperRange: 40,
    },
    {
      label: '40%-60%',
      value: '40-60',
      lowerRange: 40,
      upperRange: 60,
    },
    {
      label: '60%-80%',
      value: '60-80',
      lowerRange: 60,
      upperRange: 80,
    },
    {
      label: '80%-99%',
      value: '80-99',
      lowerRange: 80,
      upperRange: 99,
    },
  ];
  listOfBook: any = [];
  listOfArticle: any = [];
  listOfBookCollection: any = [];
  listOfMehendi: any = [];
  listOfQuiz: any = [];
  listOfContest: any = [];
  listOfMagazine: any = [];

  listOfRouteType: any = [];

  listOfMainRoutes: any = [];
  listOfRoutes: any = [];
  showRoute: boolean = false;
  // Days
  selectedDays: any = [];

  // Medium
  selectedMedium: any = [];

  // Book
  selectedBook: any = [];

  // Duration
  selectedDuration: any = [];

  // Range
  selectedRange: any = [];

  // Recommended Book
  selectedRecommendedBook: any = [];

  // Quiz
  selectedQuiz: any = [];

  // Contest
  selectedContest: any = [];

  // Mehendi
  selectedMehendi: any = [];

  // Article
  selectedArticle: any = [];

  // Magazine
  selectedMagazine: any = [];

  mediumDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  rangeDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  duartionDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'duration',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  duartion1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'duration',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  bookDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  book1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  magazineDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  quizDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  mehendiDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  articleDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };

  selectedStepper: any = 'Campaign Detail';
  selectedType: any;
  selectedScheduleFor: any;
  selectedUserTarget: any;

  // Campaign Form Controls
  nameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  campaignDateTimeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  typeControl = new FormControl('Select Type', [
    Validators.required,
  ]);
  mediumControl = new FormControl('', [
    Validators.required,
  ]);

  scheduleForControl = new FormControl('Select Schedule For', [
    Validators.required,
  ]);
  scheduleTimeControl = new FormControl('', [
    Validators.required,
  ]);
  scheduleDateControl = new FormControl('', [
    Validators.required,
  ]);
  scheduleDayControl = new FormControl('', [
    Validators.required,
  ]);


  // Target Form Controls
  targetUserControl = new FormControl('Select User Group', [
    Validators.required,
  ]);
  routeControl = new FormControl('Select Route', [
    Validators.required,
  ]);
  routeTypeControl = new FormControl('Select Screen', [
    Validators.required,
  ]);
  bookControl = new FormControl('', [
    Validators.required,
  ]);
  recommendedBookControl = new FormControl('', [
    Validators.required,
  ]);
  quizControl = new FormControl('', [
  ]);
  contestControl = new FormControl('', [
  ]);
  mehendiControl = new FormControl('', [
  ]);
  magazineControl = new FormControl('', [
  ]);
  articleControl = new FormControl('', [
  ]);
  bookCollectionControl = new FormControl('Select Book Collection', [
  ]);
  durationControl = new FormControl('', [
    Validators.required,
  ]);

  // Content Form Controls
  whatsAppMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  whatsAppMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  smsMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  smsMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  inAppMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  inAppMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  pushNotificationMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  pushNotificationMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  popupNotificationMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  popupNotificationMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  popupNotificationPrefControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  emailMessageTitleControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  emailMessageControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  selectedWhatsAppImage: any = null;
  selectedWhatsAppImageName: any = null;
  defaultWhatsAppImage: any;

  selectedInAppImage: any = null;
  selectedInAppImageName: any = null;
  defaultInAppImage: any;

  selectedPushNotificationImage: any = null;
  selectedPushNotificationImageName: any = null;
  defaultPushNotificationImage: any;

  selectedPopupNotificationImage: any = null;
  selectedPopupNotificationImageName: any = null;
  defaultPopupNotificationImage: any;

  selectedEmailImage: any = null;
  selectedEmailImageName: any = null;
  defaultEmailImage: any;

  // Campaign Form Group
  addCampaignDetail: FormGroup = new FormGroup({
    name: this.nameControl,
    type: this.typeControl,
    medium: this.mediumControl,
  });

  // Target User Form Group
  addTargetUserDetail: FormGroup = new FormGroup({
    targetUser: this.targetUserControl,
    route: this.routeControl,
    routeType: this.routeTypeControl,
    quiz: this.quizControl,
    contest: this.contestControl,
    mehendi: this.mehendiControl,
    article: this.articleControl,
    bookCollection: this.bookCollectionControl,
    magazine: this.magazineControl,
  });

  // Content Form Group
  addContentDetail: FormGroup = new FormGroup({
  });
  userDetail: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router, private toastr: ToastrService,
    public appService: AppService, private formBuilder: FormBuilder
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.selectedStepper = 'Campaign Detail';
    this.addCampaignDetail = this.formBuilder.group({
      name: this.nameControl,
      type: this.typeControl,
      medium: this.mediumControl,
    });
    this.fetchAllContentForAdmin();
  }
  async fetchAllContentForAdmin() {
    this.isLoading = true;
    this.listOfBook = [];
    try {
      const param = {
        contentType: 'Book',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllContentForAdmin}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfBook = data['result'];
          this.fetchAllBookCollection();
        } else {
          this.listOfBook = [];
          this.fetchAllBookCollection();
        }
      });
    } catch (error) {
      this.listOfBook = [];
      this.fetchAllBookCollection();

    }
  }

  async fetchAllBookCollection() {
    this.isLoading = true;

    this.listOfBookCollection = [];
    try {
      const param = {
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllBookCollection}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfBookCollection = data['result'];
          this.fetchAllRoutes();
        } else {
          this.listOfBookCollection = [];
          this.fetchAllRoutes();
        }
      });
    } catch (error) {
      this.listOfBookCollection = [];
      this.fetchAllRoutes();

    }
  }

  async fetchAllRoutes() {
    this.isLoading = true;
    this.listOfMainRoutes = [];
    this.listOfRouteType = [];
    try {
      const param = {
        type: 'Routes',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllRoutes}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfRouteType = data['type'];
          this.listOfMainRoutes = data['result'];
          this.fetchAllTemplate();
        } else {
          this.listOfMainRoutes = [];
          this.fetchAllTemplate();
        }
      });
    } catch (error) {
      this.listOfMainRoutes = [];
      this.fetchAllTemplate();

    }
  }
  async fetchAllTemplate() {
    this.isLoading = true;
    this.listOfTemplate = [];
    try {
      let param = {
        phone: '8976802882',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTemplate = data['result'];
          this.listOfTemplate.forEach((template: any) => {
            template['nameWithLanguage'] =
              template['name'] + ' (language :- ' + template['language'] + ')';
          });
          this.listOfTemplateToDisplay = this.listOfTemplate;
          this.fetchAllMehendi();
        } else {
          this.listOfTemplate = [];
          this.listOfTemplateToDisplay = this.listOfTemplate;
          this.fetchAllMehendi();
        }
      });
    } catch (error) {
      this.listOfTemplate = [];
      this.listOfTemplateToDisplay = this.listOfTemplate;
      this.fetchAllMehendi();
    }
  }
  async fetchAllMehendi() {
    this.isLoading = true;
    this.listOfMehendi = [];
    try {
      const param = {
        name: 'Mehendi',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllDesignForAdmin}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfMehendi = data['result'];
          this.fetchAllQuiz();
        } else {
          this.listOfMehendi = [];
          this.fetchAllQuiz();
        }
      });
    } catch (error) {
      this.listOfMehendi = [];
      this.fetchAllQuiz();

    }
  }
  async fetchAllQuiz() {
    this.isLoading = true;
    this.listOfQuiz = [];
    try {

      const URL = `${webApi.domain + webApi.endPoint.fetchAllQuizzes}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfQuiz = data['result'];
          this.fetchAllMagazine();
        } else {
          this.listOfQuiz = [];
          this.fetchAllMagazine();
        }
      });
    } catch (error) {
      this.listOfQuiz = [];
      this.fetchAllMagazine();

    }
  }
  async fetchAllMagazine() {
    this.isLoading = true;
    this.listOfMagazine = [];
    try {
      const param = {
        contentType: 'Magazine',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllContentForAdmin}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfMagazine = data['result'];
          this.listOfMagazine.forEach((element: any) => {
            element['title'] = 'Meri Saheli-' + new Date(element['publishedDate']).toLocaleDateString('en-us', { year: "numeric", month: "short" });
          });
          this.fetchAllContest();
        } else {
          this.listOfMagazine = [];
          this.fetchAllContest();
        }
      });
    } catch (error) {
      this.listOfMagazine = [];
      this.fetchAllContest();

    }
  }
  async fetchAllContest() {
    this.isLoading = true;
    this.listOfContest = [];
    try {
      const param = {
        contentType: 'Magazine',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllContest}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfContest = data['result'];
          this.listOfContest.forEach((element: any) => {
            element['title'] = element['category']['title'] + ' - ' + new Date(element['startDate']).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) + ' - '
              + new Date(element['endDate']).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
          });
          this.assignValueToEdit();
        } else {
          this.listOfContest = [];
          this.assignValueToEdit();
        }
      });
    } catch (error) {
      this.listOfContest = [];
      this.assignValueToEdit();

    }
  }

  assignValueToEdit() {
    if (this.appService.campaignEdit != null) {
      this.dataToEdit = this.appService.campaignEdit;
      this.appService.campaignEdit = null;

      // Campaign Detail Assignment

      this.addCampaignDetail.get('name')?.setValue(this.dataToEdit['name']);
      if (this.dataToEdit['campaignMedium'].length > 0) {
        this.dataToEdit['campaignMedium'].forEach((medium: any) => {
          this.selectedMedium.push({ label: medium });
        });
        this.addCampaignDetail.get('medium')?.setValue(this.selectedMedium);
      }
      if (this.dataToEdit['type'] == 'One-Time-Schedule' || this.dataToEdit['type'] == 'One-Time-Immediate') {
        this.selectedType = 'One-Time';
        this.addCampaignDetail.get('type')?.setValue('One-Time');

        if (this.dataToEdit['type'] == 'One-Time-Schedule') {
          this.showCampaignDateTime = true;
          this.radioSelectedValue = 'Schedule';
          this.addCampaignDetail.addControl('campaignDateTime', this.campaignDateTimeControl);

          this.addCampaignDetail
            .get('campaignDateTime')
            ?.setValue(formatDate(new Date(this.dataToEdit['campaignDateTime']), 'yyyy-MM-ddTHH:mm', 'en-GB'));
        } else {
          this.radioSelectedValue = 'Immediate';
        }
      } else {
        this.selectedType = this.dataToEdit['type'];
        this.addCampaignDetail.get('type')?.setValue(this.dataToEdit['type']);

        this.addCampaignDetail.addControl('scheduleFor', this.scheduleForControl);
        this.addCampaignDetail.get('scheduleFor')?.setValue(this.dataToEdit['campaignSchedule']['type']);
        this.selectedScheduleFor = this.dataToEdit['campaignSchedule']['type'];
        if (this.selectedScheduleFor == 'Daily') {
          this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
          let timeArray = String(this.dataToEdit['campaignSchedule']['time']).length > 4 ? String(this.dataToEdit['campaignSchedule']['time']).replace('.', ':') : (String(this.dataToEdit['campaignSchedule']['time']).length <= 2 ? String(this.dataToEdit['campaignSchedule']['time'] + '.00').replace('.', ':') : String(this.dataToEdit['campaignSchedule']['time'] + '0').replace('.', ':'));
          this.addCampaignDetail.get('scheduleTime')?.setValue(timeArray);

        } else if (this.selectedScheduleFor == 'Weekly') {
          this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
          this.addCampaignDetail.addControl('scheduleDay', this.scheduleDayControl);
          let timeArray = String(this.dataToEdit['campaignSchedule']['time']).length > 4 ? String(this.dataToEdit['campaignSchedule']['time']).replace('.', ':') : (String(this.dataToEdit['campaignSchedule']['time']).length <= 2 ? String(this.dataToEdit['campaignSchedule']['time'] + '.00').replace('.', ':') : String(this.dataToEdit['campaignSchedule']['time'] + '0').replace('.', ':'));
          this.addCampaignDetail.get('scheduleTime')?.setValue(timeArray);
          if (this.dataToEdit['campaignSchedule']['day'].length > 0) {
            this.dataToEdit['campaignSchedule']['day'].forEach((day: any) => {
              this.selectedDays.push({ label: day });
            });
            this.addCampaignDetail.get('scheduleDay')?.setValue(this.selectedDays);

          }
        } else {
          this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
          let timeArray = String(this.dataToEdit['campaignSchedule']['time']).length > 4 ? String(this.dataToEdit['campaignSchedule']['time']).replace('.', ':') : (String(this.dataToEdit['campaignSchedule']['time']).length <= 2 ? String(this.dataToEdit['campaignSchedule']['time'] + '.00').replace('.', ':') : String(this.dataToEdit['campaignSchedule']['time'] + '0').replace('.', ':'));
          this.addCampaignDetail.get('scheduleTime')?.setValue(timeArray);
          this.addCampaignDetail.addControl('scheduleDate', this.scheduleDateControl);
          this.addCampaignDetail
            .get('scheduleDate')
            ?.setValue(formatDate(new Date(this.dataToEdit['campaignSchedule']['date']), 'yyyy-MM-dd', 'en-US'));
        }
      }


      // Target User Detail Assignment
      if (Object.keys(this.dataToEdit['criteria']).length > 0) {

        // Route
        if (this.dataToEdit['criteria']['route'] != null && this.dataToEdit['criteria']['route'] != undefined) {
          this.addTargetUserDetail.addControl('routeType', this.routeTypeControl);
          this.addTargetUserDetail.addControl('route', this.routeControl);
          this.addTargetUserDetail.get('routeType')?.setValue(this.dataToEdit['criteria']['route']);
          this.showRoute = true;
          this.listOfRoutes = [];
          this.listOfMainRoutes.forEach((element: any) => {
            if (element['type'] == this.dataToEdit['criteria']['route']) {
              this.listOfRoutes = element['value'];
            }
          });


          if (this.dataToEdit['criteria']['subRoute'] != null && this.dataToEdit['criteria']['subRoute'] != undefined) {
            this.addTargetUserDetail.get('route')?.setValue(this.dataToEdit['criteria']['subRoute']);

            // Contest
            if (this.dataToEdit['criteria']['subRoute'] == 'contest' && this.dataToEdit['criteria']['route'] == 'Home') {
              this.addTargetUserDetail.addControl('contest', this.contestControl);

              this.listOfContest.forEach((contest: any) => {
                if (contest['_id'] == this.dataToEdit['criteria']['subRouteId']) {
                  this.selectedContest.push({
                    _id: contest['_id'],
                    title: contest['title']
                  });
                }
              });
            }

            // Quiz
            if (this.dataToEdit['criteria']['subRoute'] == 'quiz' && this.dataToEdit['criteria']['route'] == 'Home') {
              this.addTargetUserDetail.addControl('quiz', this.quizControl);
              // this.addTargetUserDetail.get('quiz')?.setValue(this.dataToEdit['criteria']['quiz']);

              // if (this.dataToEdit['criteria']['quiz'].length > 0) {
              this.listOfQuiz.forEach((quiz: any) => {
                // this.dataToEdit['criteria']['subRouteId'].forEach((element: any) => {
                if (quiz['_id'] == this.dataToEdit['criteria']['subRouteId']) {
                  this.selectedQuiz.push({
                    _id: quiz['_id'],
                    title: quiz['title']
                  });
                }
                // });
              });
              // }
            }
            // Mehendi
            if (this.dataToEdit['criteria']['subRoute'] == 'mehendi' && this.dataToEdit['criteria']['route'] == 'Home') {
              this.addTargetUserDetail.addControl('mehendi', this.mehendiControl);
              // this.addTargetUserDetail.get('mehendi')?.setValue(this.dataToEdit['criteria']['mehendi']);
              // if (this.dataToEdit['criteria']['subRouteId'].length > 0) {
              this.listOfMehendi.forEach((mehendi: any) => {
                // this.dataToEdit['criteria']['mehendi'].forEach((element: any) => {
                if (mehendi['_id'] == this.dataToEdit['criteria']['subRouteId']) {
                  this.selectedMehendi.push({
                    _id: mehendi['_id'],
                    name: mehendi['name']
                  });
                }
              });
              // });
              // }
            }
            // Article
            if (this.dataToEdit['criteria']['subRoute'] == 'article' && this.dataToEdit['criteria']['route'] == 'Home') {
              this.addTargetUserDetail.addControl('article', this.articleControl);
              this.addTargetUserDetail.get('article')?.setValue(this.dataToEdit['criteria']['subRouteId']);
            }
            // Magazine
            if (this.dataToEdit['criteria']['subRoute'] == 'magazine' && this.dataToEdit['criteria']['route'] == 'Magazine') {
              this.addTargetUserDetail.addControl('magazine', this.magazineControl);

              this.listOfMagazine.forEach((magazine: any) => {
                if (magazine['_id'] == this.dataToEdit['criteria']['subRouteId']) {
                  this.selectedMagazine.push({
                    _id: magazine['_id'],
                    title: magazine['title']
                  });
                }
              });
            }
            // Book Collection
            if (this.dataToEdit['criteria']['subRoute'] == 'collection' && this.dataToEdit['criteria']['route'] == 'Book') {
              this.addTargetUserDetail.addControl('bookCollection', this.bookCollectionControl);

              this.listOfBookCollection.forEach((bookCollection: any) => {
                if (bookCollection['_id'] == this.dataToEdit['criteria']['subRouteId']) {
                  this.addTargetUserDetail.get('bookCollection')?.setValue(this.dataToEdit['criteria']['subRouteId']);

                }
              });
            }

          }



        }
        if (this.dataToEdit['criteria']['allUser']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('All User');
          this.selectedUserTarget = 'All User';
        }
        else if (this.dataToEdit['criteria']['allUserWithLessThan10Vote']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('All User With less than 10 vote');
          this.selectedUserTarget = 'All User With less than 10 vote';
        }
        else if (this.dataToEdit['criteria']['subscribedUser']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('Subscribed User');
          this.selectedUserTarget = 'Subscribed User';
        } else if (this.dataToEdit['criteria']['nonSubscribedUser']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('Unsubscribed User');
          this.selectedUserTarget = 'Unsubscribed User';
        }
        else if (this.dataToEdit['criteria']['bookPurchased']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('Book Purchased');
          this.selectedUserTarget = 'Book Purchased';
          this.addTargetUserDetail.addControl('book', this.bookControl);
          if (this.dataToEdit['criteria']['book'].length > 0) {
            this.listOfBook.forEach((book: any) => {
              this.dataToEdit['criteria']['book'].forEach((element: any) => {
                if (book['_id'] == element) {
                  this.selectedBook.push({
                    _id: book['_id'],
                    title: book['title']
                  });
                }
              });
            });
          }
        }
        else if (this.dataToEdit['criteria']['bookRecommendation']) {
          this.addTargetUserDetail.addControl('book', this.bookControl);
          this.addTargetUserDetail.addControl('recommendedBook', this.recommendedBookControl);

          this.addTargetUserDetail.get('targetUser')?.setValue('Book Recommendation');
          this.selectedUserTarget = 'Book Recommendation';

          if (this.dataToEdit['criteria']['book'].length > 0) {
            this.listOfBook.forEach((book: any) => {
              this.dataToEdit['criteria']['book'].forEach((element: any) => {
                if (book['_id'] == element) {
                  this.selectedBook.push({
                    _id: book['_id'],
                    title: book['title']
                  });
                }
              });
            });
          }
          if (this.dataToEdit['criteria']['recommendedBook'].length > 0) {
            this.listOfBook.forEach((book: any) => {
              this.dataToEdit['criteria']['recommendedBook'].forEach((element: any) => {
                if (book['_id'] == element) {
                  this.selectedRecommendedBook.push({
                    _id: book['_id'],
                    title: book['title']
                  });
                }
              });
            });
          }
        }
        else if (this.dataToEdit['criteria']['inActiveUser']) {
          this.addTargetUserDetail.get('targetUser')?.setValue('Inactive User');
          this.selectedUserTarget = 'Inactive User';
          this.addTargetUserDetail.addControl('duration', this.durationControl);

          if (this.dataToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.dataToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }

      }

      // Content Detail Assignment
      if (Object.keys(this.dataToEdit['template']).length > 0) {
        this.selectedMedium.forEach((element: any) => {
          if (element['label'] == 'WhatsApp') {
            // this.addContentDetail.addControl('whatsAppMessageTitle', this.whatsAppMessageTitleControl);
            // this.addContentDetail.addControl('whatsAppMessage', this.whatsAppMessageControl);
          } else if (element['label'] == 'Sms') {
            // this.addContentDetail.addControl('smsMessageTitle', this.smsMessageTitleControl);
            this.addContentDetail.addControl('smsMessage', this.smsMessageControl);
          } else if (element['label'] == 'In App') {
            this.addContentDetail.addControl('inAppMessageTitle', this.inAppMessageTitleControl);
            this.addContentDetail.addControl('inAppMessage', this.inAppMessageControl);
          } else if (element['label'] == 'Push Notification') {
            this.addContentDetail.addControl('pushNotificationMessageTitle', this.pushNotificationMessageTitleControl);
            this.addContentDetail.addControl('pushNotificationMessage', this.pushNotificationMessageControl);
          } else if (element['label'] == 'Popup') {
            this.addContentDetail.addControl('popupNotificationMessageTitle', this.popupNotificationMessageTitleControl);
            this.addContentDetail.addControl('popupNotificationMessage', this.popupNotificationMessageControl);
            this.addContentDetail.addControl('popupNotificationPref', this.popupNotificationPrefControl);
          }
          else {
            this.addContentDetail.addControl('emailMessageTitle', this.emailMessageTitleControl);
            this.addContentDetail.addControl('emailMessage', this.emailMessageControl);
          }
        });
        try {


          for (const key in this.dataToEdit['template']) {
            if (key == 'whatsApp') {
              this.addContentDetail.get('whatsAppMessageTitle')?.setValue(this.dataToEdit['template'][key]['subject'] ?? '');
              this.addContentDetail.get('whatsAppMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');
              if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
                this.defaultWhatsAppImage = this.dataToEdit['template'][key]['image'];
              }
            } else if (key == 'sms') {
              this.addContentDetail.get('smsMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');

            } else if (key == 'app') {
              this.addContentDetail.get('inAppMessageTitle')?.setValue(this.dataToEdit['template'][key]['subject'] ?? '');
              this.addContentDetail.get('inAppMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');
              if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
                this.defaultInAppImage = this.dataToEdit['template'][key]['image'];
              }
            } else if (key == 'push') {
              this.addContentDetail.get('pushNotificationMessageTitle')?.setValue(this.dataToEdit['template'][key]['subject'] ?? '');
              this.addContentDetail.get('pushNotificationMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');
              if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
                this.defaultPushNotificationImage = this.dataToEdit['template'][key]['image'];
              }
            } else if (key == 'email') {
              this.addContentDetail.get('emailMessageTitle')?.setValue(this.dataToEdit['template'][key]['subject'] ?? '');
              this.addContentDetail.get('emailMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');
              if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
                this.defaultEmailImage = this.dataToEdit['template'][key]['image'];
              }
            }
            else if (key == 'popup') {
              this.addContentDetail.get('popupNotificationMessageTitle')?.setValue(this.dataToEdit['template'][key]['subject'] ?? '');
              this.addContentDetail.get('popupNotificationMessage')?.setValue(this.dataToEdit['template'][key]['body'] ?? '');
              this.addContentDetail.get('popupNotificationPref')?.setValue(this.dataToEdit['template'][key]['popupPref'] ?? '');
              if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
                this.defaultEmailImage = this.dataToEdit['template'][key]['image'];
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }


    }
    this.isLoading = false;
  }
  onStepperClick(label: string) {
    this.listOfStepper.forEach((element: any) => {
      if (element['label'] == label) {
        if (element['label'] == 'Content') {
          this.selectedMedium.forEach((element: any) => {
            if (element['label'] == 'WhatsApp') {
              // this.addContentDetail.addControl('whatsAppMessageTitle', this.whatsAppMessageTitleControl);
              // this.addContentDetail.addControl('whatsAppMessage', this.whatsAppMessageControl);
            } else if (element['label'] == 'Sms') {
              this.addContentDetail.addControl('smsMessageTitle', this.smsMessageTitleControl);
              this.addContentDetail.addControl('smsMessage', this.smsMessageControl);
            } else if (element['label'] == 'In App') {
              this.addContentDetail.addControl('inAppMessageTitle', this.inAppMessageTitleControl);
              this.addContentDetail.addControl('inAppMessage', this.inAppMessageControl);
            } else if (element['label'] == 'Push Notification') {
              this.addContentDetail.addControl('pushNotificationMessageTitle', this.pushNotificationMessageTitleControl);
              this.addContentDetail.addControl('pushNotificationMessage', this.pushNotificationMessageControl);
            } else if (element['label'] == 'Popup') {
              this.addContentDetail.addControl('popupNotificationMessageTitle', this.popupNotificationMessageTitleControl);
              this.addContentDetail.addControl('popupNotificationMessage', this.popupNotificationMessageControl);
              this.addContentDetail.addControl('popupNotificationPref', this.popupNotificationPrefControl);
            } else {
              this.addContentDetail.addControl('emailMessageTitle', this.emailMessageTitleControl);
              this.addContentDetail.addControl('emailMessage', this.emailMessageControl);
            }
          });
        }
        this.selectedStepper = element['label'];
      }
    });
    if (this.selectedStepper != 'Content') {
      this.addContentDetail.reset();
    }
  }
  gotoStepper(label: string) {
    this.listOfStepper.forEach((element: any) => {
      if (element['label'] == label) {
        if (element['label'] == 'Content') {
          this.selectedMedium.forEach((element: any) => {
            if (element['label'] == 'WhatsApp') {
              // this.addContentDetail.addControl('whatsAppMessageTitle', this.whatsAppMessageTitleControl);
              // this.addContentDetail.addControl('whatsAppMessage', this.whatsAppMessageControl);
            } else if (element['label'] == 'Sms') {
              // this.addContentDetail.addControl('smsMessageTitle', this.smsMessageTitleControl);
              this.addContentDetail.addControl('smsMessage', this.smsMessageControl);
            } else if (element['label'] == 'In App') {
              this.addContentDetail.addControl('inAppMessageTitle', this.inAppMessageTitleControl);
              this.addContentDetail.addControl('inAppMessage', this.inAppMessageControl);
            } else if (element['label'] == 'Push Notification') {
              this.addContentDetail.addControl('pushNotificationMessageTitle', this.pushNotificationMessageTitleControl);
              this.addContentDetail.addControl('pushNotificationMessage', this.pushNotificationMessageControl);
            } else if (element['label'] == 'Popup') {
              this.addContentDetail.addControl('popupNotificationMessageTitle', this.popupNotificationMessageTitleControl);
              this.addContentDetail.addControl('popupNotificationMessage', this.popupNotificationMessageControl);
              this.addContentDetail.addControl('popupNotificationPref', this.popupNotificationPrefControl);
            } else {
              this.addContentDetail.addControl('emailMessageTitle', this.emailMessageTitleControl);
              this.addContentDetail.addControl('emailMessage', this.emailMessageControl);
            }
          });
        }
        this.selectedStepper = element['label'];
      }
    });
    if (this.selectedStepper != 'Content') {
      // this.addContentDetail.reset();
    }
  }
  typeSelected(event: any) {
    this.selectedType = event.target.value;
    if (this.selectedType == 'One-Time') {
      this.addCampaignDetail.addControl('campaignDateTime', this.campaignDateTimeControl);

      this.addCampaignDetail.removeControl('scheduleFor');
      this.scheduleForControl.setValue('Select Schedule For');
      this.addCampaignDetail.removeControl('scheduleDay');
      this.scheduleDayControl.setValue('Select Day');
      this.addCampaignDetail.removeControl('scheduleDate');
      this.scheduleDateControl.setValue('');
      this.addCampaignDetail.removeControl('scheduleTime');
      this.scheduleTimeControl.setValue('');
      this.selectedScheduleFor = null;
    } else {
      this.addCampaignDetail.addControl('scheduleFor', this.scheduleForControl);
      this.addCampaignDetail.removeControl('campaignDateTime');
      this.campaignDateTimeControl.setValue('');
    }
  }
  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
    if (this.radioSelectedValue == 'Schedule') {
      this.showCampaignDateTime = true;
    } else {
      this.addCampaignDetail.removeControl('campaignDateTime');
      this.showCampaignDateTime = false;
    }
  }
  scheduleForSelected(event: any) {
    this.selectedScheduleFor = event.target.value;
    if (this.selectedScheduleFor == 'Daily') {
      this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
      this.addCampaignDetail.removeControl('scheduleDay');
      this.addCampaignDetail.removeControl('scheduleDate');
    } else if (this.selectedScheduleFor == 'Weekly') {
      this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
      this.addCampaignDetail.addControl('scheduleDay', this.scheduleDayControl);
      this.addCampaignDetail.removeControl('scheduleDate');
    } else {
      this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
      this.addCampaignDetail.addControl('scheduleDate', this.scheduleDateControl);
      this.addCampaignDetail.removeControl('scheduleDay');
    }
  }
  userTargetSelected(event: any) {
    this.selectedUserTarget = event.target.value;
    this.targetUserCountCalled = false;
    this.addTargetUserDetail.addControl('route', this.routeControl);

    if (this.selectedUserTarget == 'Book Purchased' || this.selectedUserTarget == 'Book Recommendation') {
      this.addTargetUserDetail.addControl('book', this.bookControl);
      this.addTargetUserDetail.removeControl('range');
      this.addTargetUserDetail.removeControl('duration');
      this.addTargetUserDetail.removeControl('recommendedBook');
    }
    if (this.selectedUserTarget == 'Book Recommendation') {
      this.addTargetUserDetail.addControl('recommendedBook', this.recommendedBookControl);
      this.addTargetUserDetail.removeControl('range');
      this.addTargetUserDetail.removeControl('duration');
    }
    // if (this.selectedUserTarget == 'Course Consumption') {
    //   this.addTargetUserDetail.addControl('range', this.rangeControl);
    //   this.addTargetUserDetail.removeControl('duration');
    //   this.addTargetUserDetail.removeControl('recommendedBook');
    // }
    // if (this.selectedUserTarget == 'Expiry') {
    //   this.addTargetUserDetail.addControl('duration', this.durationControl);
    //   this.addTargetUserDetail.removeControl('recommendedBook');
    //   this.addTargetUserDetail.removeControl('range');
    // }
    if (this.selectedUserTarget == 'Inactive User') {
      this.addTargetUserDetail.addControl('duration', this.durationControl);
      this.addTargetUserDetail.removeControl('recommendedBook');
      this.addTargetUserDetail.removeControl('range');
      this.addTargetUserDetail.removeControl('book');
    }
  }
  routeTypeChanged(event: any) {
    let data = event.target.value;
    this.showRoute = true;
    this.listOfRoutes = [];
    this.listOfMainRoutes.forEach((element: any) => {
      if (element['type'] == data) {
        this.listOfRoutes = element['value'];
      }
    });
  }
  getSelectTitle(title: any) {
    switch (title) {
      case 'Book Purchased':
        return 'Select Book';
      // case 'Course Consumption':
      //   return 'Select Course';
      case 'Book Recommendation':
        return 'Select Recommended Book';
      // case 'Wishlist':
      //   return 'Select Course';
      // case 'Expiry':
      //   return 'Select Course';
      default:
        return 'Select Book';

    }
  }
  onDaySelect(day: any) {
    console.log(day);
  }
  onDayDeSelect(day: any) {
    console.log(day);
  }
  onDaySelectAll(day: any) {
    console.log(day);
  }
  async getTargetUserCount() {
    this.isGetTargetLoading = true;
    this.targetUserCountCalled = true;
    this.targetUserCount = 0;

    let criteria: any = {};
    if (this.addTargetUserDetail.get('targetUser')?.value == 'All User') {
      criteria['allUser'] = true; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'All User With less than 10 vote') {
      criteria['allUser'] = false; criteria['allUserWithLessThan10Vote'] = true; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'Subscribed User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = true; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Unsubscribed User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = true; criteria['inActiveUser'] = false;

    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Book Purchased') {
      criteria['allUser'] = false;
      criteria['bookPurchased'] = true; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;
      criteria['book'] = [];
      this.selectedBook.forEach((element: any) => {
        criteria['book'].push(element['_id']);
      });
    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'Book Recommendation') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;
      criteria['bookRecommendation'] = true;
      criteria['book'] = [];
      this.selectedBook.forEach((element: any) => {
        criteria['book'].push(element['_id']);
      });
      criteria['recommendedBook'] = [];
      this.selectedRecommendedBook.forEach((element: any) => {
        criteria['recommendedBook'].push(element['_id']);
      });
    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Inactive User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['bookRecommendation'] = false; criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = true;
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    }
    try {
      const param = {
        criteria: criteria,
      };
      const URL = `${webApi.domain + webApi.endPoint.getTargetCount}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.targetUserCount = data['count'];
          this.isGetTargetLoading = false;
        } else {
          this.isGetTargetLoading = false;
        }
      });
    } catch (error) {
      this.isGetTargetLoading = false;

    }
  }
  // WhatsApp Function 

  async syncTemplate() {
    this.isTemplateLoading = true;

    try {
      let param = {
        clientPhone: "9152615121"
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.syncAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.fetchAllTemplateAfterSync();
        } else {
          this.fetchAllTemplateAfterSync();
        }
      });
    } catch (error) {
      this.fetchAllTemplateAfterSync();
    }
  }
  async fetchAllTemplateAfterSync() {
    this.isTemplateLoading = true;
    this.listOfTemplate = [];
    try {
      let param = {
        phone: '9152615121',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTemplate = data['result'];
          this.listOfTemplate.forEach((template: any) => {
            template['nameWithLanguage'] =
              template['name'] + ' (language :- ' + template['language'] + ')';
          });
          this.listOfTemplateToDisplay = this.listOfTemplate;
          this.isTemplateLoading = false;
        } else {
          this.listOfTemplate = [];
          this.listOfTemplateToDisplay = this.listOfTemplate;
          this.isTemplateLoading = false;
        }
      });
    } catch (error) {
      this.listOfTemplate = [];
      this.listOfTemplateToDisplay = this.listOfTemplate;
      this.isTemplateLoading = false;
    }
  }
  // onItemSelect(event: any) {
  selectTemplate(temp: any) {
    this.selectedTemplate = [];
    this.showHeaderOfTemplate = false;
    this.showHeaderMediaOfTemplate = false;
    this.showHeaderTextOfTemplate = false;
    this.showBodyOfTemplate = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.selectedTemplate.push(temp);
    console.log(this.selectedTemplate);
    this.listOfTemplate.forEach((tmplt: any) => {
      if (tmplt['_id'] == temp['_id']) {
        this.template = tmplt;
      }
    });
    this.template['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate = true;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate = true;
          this.showHeaderTextOfTemplate = false;
        } else {
          this.showHeaderMediaOfTemplate = false;
          this.showHeaderTextOfTemplate = true;
          this.addContentDetail.addControl(
            'headerText',
            new FormControl('', [
              Validators.required,
              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
            ])
          );
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate = true;
        this.bodyVariableCount = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount;
        if (variableCount > 0) {
          while (variableCount > 0) {
            this.addContentDetail.addControl(
              `bodyText${variableCount}`,
              new FormControl('', [
                Validators.required,
                Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
              ])
            );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = true;
      } else {
        this.showButtonOfTemplate = true;
      }
    });
    this.showTemplateBody = true;
  }
  searchTemplateData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTemplateToDisplay = [];

      this.listOfTemplate.forEach((tempate: any, index: number) => {
        if (
          String(tempate['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(this.getTemplateBody(tempate))
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(tempate);
        }
      });
      this.listOfTemplateToDisplay = [];
      this.listOfTemplateToDisplay = tempSearchList;
    } else {
      this.listOfTemplateToDisplay = this.listOfTemplate;
    }
  }
  counter(i: number) {
    return new Array(i);
  }
  getTemplateBody(template: any) {
    let toReturn = '';
    if (template.components.length > 0) {
      template.components.forEach((comp: any) => {
        if (comp['type'] == 'BODY') {
          toReturn = comp['text'];
        }
      });
    }
    return toReturn;
  }
  hideTemplateBody() {
    this.showHeaderOfTemplate = false;
    this.showHeaderMediaOfTemplate = false;
    this.showHeaderTextOfTemplate = false;
    this.showBodyOfTemplate = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.showTemplateBody = false;
    this.template['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate = false;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate = false;
          this.showHeaderTextOfTemplate = false;
        } else {
          this.showHeaderMediaOfTemplate = false;
          this.showHeaderTextOfTemplate = false;
          this.addContentDetail.removeControl(
            'headerText'
          );
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate = false;
        this.bodyVariableCount = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount;
        if (variableCount > 0) {
          while (variableCount > 0) {
            this.addContentDetail.removeControl(
              `bodyText${variableCount}`
            );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = false;
      } else {
        this.showButtonOfTemplate = false;
      }
    });
    this.selectedTemplate = [];
    // this.addContentDetail = this.formBuilder.group({
    //   campaignName: this.campaignNameControl,
    //   campaignMedium: this.campaignMediumControl,
    // });
    this.listOfTemplateToDisplay = this.listOfTemplate;
  }
  displayHeaderMedia(event: any) {
    this.selectedHeaderMedia = event.target.files[0];
    this.selectedHeaderMediaName = this.selectedHeaderMedia['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      if (this.selectedHeaderMedia['type'].split('/')[0] == 'image') {
        this.defaultHeaderMedia = event1.target.result;
      } else {
        let extension =
          this.selectedHeaderMedia['name'].split('.')[
          this.selectedHeaderMedia['name'].split('.').length - 1
          ];
        if (extension == 'mp4' || extension == 'mp4') {
          this.defaultHeaderMedia = '../../../../../assets/img/video.jpg';
        } else if (
          extension == 'doc' ||
          extension == 'docx' ||
          extension == 'pdf'
        ) {
          this.defaultHeaderMedia = '../../../../../assets/img/doc.png';
        } else if (extension == 'mp3') {
          this.defaultHeaderMedia = '../../../../../assets/img/audio.jpg';
        } else {
          this.defaultHeaderMedia = '../../../../../assets/img/excel.png';
        }
      }
    };
    reader.readAsDataURL(this.selectedHeaderMedia);
  }

  isWhatsAppMediumSelected() {
    let isExists = false;
    this.selectedMedium.forEach((element: any) => {
      if (element['label'] == 'WhatsApp') {
        isExists = true;
      }
    });
    return isExists;
  }

  // 
  displayWhatsApp(event: any) {
    this.selectedWhatsAppImage = event.target.files[0];
    this.selectedWhatsAppImageName = this.selectedWhatsAppImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultWhatsAppImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedWhatsAppImage);
  }
  downloadWhatsAppImage(url: any) {
    window.open(url);
  }
  displayInApp(event: any) {
    this.selectedInAppImage = event.target.files[0];
    this.selectedInAppImageName = this.selectedInAppImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultInAppImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedInAppImage);
  }
  downloadInAppImage(url: any) {
    window.open(url);
  }
  displayPopupNotification(event: any) {
    this.selectedPopupNotificationImage = event.target.files[0];
    this.selectedPopupNotificationImageName = this.selectedPopupNotificationImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultPopupNotificationImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedPopupNotificationImage);
  }
  displayPushNotification(event: any) {
    this.selectedPushNotificationImage = event.target.files[0];
    this.selectedPushNotificationImageName = this.selectedPushNotificationImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultPushNotificationImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedPushNotificationImage);
  }
  downloadPopupNotificationImage(url: any) {
    window.open(url);
  }
  downloadPushNotificationImage(url: any) {
    window.open(url);
  }
  displayEmail(event: any) {
    this.selectedEmailImage = event.target.files[0];
    this.selectedEmailImageName = this.selectedEmailImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultEmailImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedEmailImage);
  }
  downloadEmailImage(url: any) {
    window.open(url);
  }
  saveCampaign() {
    this.isSaveCampaignLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('campaignId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('campaignId', '0');
    }

    if (this.selectedHeaderMedia != null) {
      this.formdata.set('whatsApp-image', this.selectedHeaderMedia);
    }
    if (this.selectedEmailImage != null) {
      this.formdata.set('email-image', this.selectedEmailImage);
    }
    if (this.selectedInAppImage != null) {
      this.formdata.set('app-image', this.selectedInAppImage);
    }
    if (this.selectedPushNotificationImage != null) {
      this.formdata.set('push-image', this.selectedPushNotificationImage);
    }
    if (this.selectedPopupNotificationImage != null) {
      this.formdata.set('popup-image', this.selectedPopupNotificationImage);
    }
    this.formdata.set('name', this.addCampaignDetail.get('name')?.value);
    if (this.addCampaignDetail.get('type')?.value == 'One-Time') {
      if (this.showCampaignDateTime) {
        this.formdata.set('type', 'One-Time-Schedule');
      } else {
        this.formdata.set('type', 'One-Time-Immediate');
      }
    } else {
      this.formdata.set('type', this.addCampaignDetail.get('type')?.value);
    }
    if (this.selectedMedium.length > 0) {
      let medium: any = [];
      this.selectedMedium.forEach((element: any) => {
        medium.push(element['label']);
      });
      this.formdata.set('campaignMedium', JSON.stringify(medium));

      let template: any = {};
      this.selectedMedium.forEach((element: any) => {
        if (element['label'] == 'WhatsApp') {
          template['whatsApp'] = {};

          template['whatsApp']['image'] = '';
          template['whatsApp']['template'] = this.template['_id'];
          template['whatsApp']['templateName'] = this.template['name'];
          template['whatsApp']['templateLanguage'] = this.template['language'];

          let components: any = [];
          let addContent = false;
          if (this.showHeaderOfTemplate && this.showHeaderMediaOfTemplate) {
            addContent = true;

            let isImage: boolean = false;

            this.selectedTemplate[0]['components'].forEach((element: any) => {
              if (element['type'] == 'HEADER' && element['format'] == 'IMAGE') {
                isImage = true;
              }
            });

            if (isImage) {
              components.push({
                type: 'header',
                parameters: [
                  {
                    type: 'image',
                    image: {
                      mime_type: 'image/jpg',
                    },
                  },
                ],
              });
            } else {
              components.push({
                type: 'header',
                parameters: [
                  {
                    type: 'document',
                  },
                ],
              });
            }
          }
          if (this.showHeaderOfTemplate && this.showHeaderTextOfTemplate) {
            components.push({
              type: 'header',
              parameters: [
                {
                  type: 'text',
                  text: {
                    link: this.addContentDetail.get('headerText')!.value,
                  },
                },
              ],
            });
          }
          if (this.showBodyOfTemplate) {
            components.push({
              type: 'body',
              parameters: [],
            });
            for (let index = 0; index < components.length; index++) {
              if (components[index]['type'] == 'body') {
                let variableCount = this.bodyVariableCount;
                if (variableCount > 0) {
                  addContent = true;
                  while (variableCount > 0) {
                    components[index]['parameters'].push({
                      type: 'text',
                      text: this.addContentDetail.get(`bodyText${variableCount}`)!
                        .value,
                    });

                    variableCount--;
                  }
                }
              }
            }

          }
          if (addContent) {
            template['whatsApp']['whatsAppContent'] = JSON.stringify(components);
          }
        }
        if (element['label'] == 'Sms') {
          template['sms'] = {};
          template['sms']['body'] = this.addContentDetail.get('smsMessage')?.value;
        }
        if (element['label'] == 'In App') {
          template['app'] = {};
          template['app']['subject'] = this.addContentDetail.get('inAppMessageTitle')?.value;
          template['app']['body'] = this.addContentDetail.get('inAppMessage')?.value;
          template['app']['image'] = '';
        }
        if (element['label'] == 'Push Notification') {
          template['push'] = {};
          template['push']['subject'] = this.addContentDetail.get('pushNotificationMessageTitle')?.value;
          template['push']['body'] = this.addContentDetail.get('pushNotificationMessage')?.value;
          template['push']['image'] = '';
        }
        if (element['label'] == 'Email') {
          template['email'] = {};
          template['email']['subject'] = this.addContentDetail.get('emailMessageTitle')?.value;
          template['email']['body'] = this.addContentDetail.get('emailMessage')?.value;
          template['email']['image'] = '';
        }
        if (element['label'] == 'Popup') {
          template['popup'] = {};
          template['popup']['subject'] = this.addContentDetail.get('popupNotificationMessageTitle')?.value;
          template['popup']['body'] = this.addContentDetail.get('popupNotificationMessage')?.value;
          template['popup']['popupPref'] = this.addContentDetail.get('popupNotificationPref')?.value;
          template['popup']['image'] = '';
        }
      });
      if (this.dataToEdit != null) {
        for (const key in this.dataToEdit['template']) {
          if (key == 'whatsApp') {
            if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
              template['whatsApp']['image'] = this.dataToEdit['template'][key]['image'];
            }
          } else if (key == 'app') {
            if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
              template['app']['image'] = this.dataToEdit['template'][key]['image'];
            }
          } else if (key == 'push') {
            if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
              template['push']['image'] = this.dataToEdit['template'][key]['image'];
            }
          }
          else if (key == 'popup') {
            if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
              template['popup']['image'] = this.dataToEdit['template'][key]['image'];
            }
          }
          else if (key == 'email') {
            if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
              template['email']['image'] = this.dataToEdit['template'][key]['image'];
            }
          }
        }
      }
      this.formdata.set('template', JSON.stringify(template));
    }
    if (this.addCampaignDetail.get('type')?.value == 'One-Time') {
      if (this.showCampaignDateTime) {
        this.formdata.set('campaignDateTime', this.addCampaignDetail.get('campaignDateTime')?.value);
      }
    } else {
      let campaignSchedule: any = {};
      campaignSchedule['type'] = this.addCampaignDetail.get('scheduleFor')?.value;
      let timeArray = String(this.addCampaignDetail.get('scheduleTime')?.value).replace(':', '.');
      campaignSchedule['time'] = parseFloat(timeArray).toFixed(2);
      if (this.addCampaignDetail.get('scheduleFor')?.value == 'Weekly') {
        if (this.selectedDays.length > 0) {
          let days: any = [];
          this.selectedDays.forEach((element: any) => {
            days.push(element['label']);
          });
          campaignSchedule['day'] = days;
        }
      } else if (this.addCampaignDetail.get('scheduleFor')?.value == 'Monthly') {
        campaignSchedule['date'] = this.addCampaignDetail.get('scheduleDate')?.value;
      }
      this.formdata.set('campaignSchedule', JSON.stringify(campaignSchedule));
    }

    let criteria: any = {};
    if (this.addTargetUserDetail.get('targetUser')?.value == 'All User') {
      criteria['allUser'] = true; criteria['allUserWithLessThan10Vote'] = false; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'All User With less than 10 vote') {
      criteria['allUser'] = false; criteria['allUserWithLessThan10Vote'] = true; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'Subscribed User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = true; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false;

    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Unsubscribed User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['bookRecommendation'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = true; criteria['inActiveUser'] = false;

    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Book Purchased') {
      criteria['allUser'] = false;
      criteria['bookPurchased'] = true; criteria['bookRecommendation'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['book'] = [];
      this.selectedBook.forEach((element: any) => {
        criteria['book'].push(element['_id']);
      });
    }
    else if (this.addTargetUserDetail.get('targetUser')?.value == 'Book Recommendation') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false;
      criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['bookRecommendation'] = true;
      criteria['book'] = [];
      this.selectedBook.forEach((element: any) => {
        criteria['book'].push(element['_id']);
      });
      criteria['recommendedBook'] = [];
      this.selectedRecommendedBook.forEach((element: any) => {
        criteria['recommendedBook'].push(element['_id']);
      });
    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Inactive User') {
      criteria['allUser'] = false; criteria['bookPurchased'] = false; criteria['allUserWithLessThan10Vote'] = false;
      criteria['bookRecommendation'] = false; criteria['subscribedUser'] = false; criteria['nonSubscribedUser'] = false; criteria['inActiveUser'] = true;
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    }

    if (this.addTargetUserDetail.get('routeType')?.value == 'Home') {
      if (this.addTargetUserDetail.get('route')?.value == 'mehendi' && this.selectedMehendi.length > 0) {
        criteria['subRouteId'] = this.selectedMehendi[0]['_id'];
      } else if (this.addTargetUserDetail.get('route')?.value == 'quiz' && this.selectedQuiz.length > 0) {
        criteria['subRouteId'] = this.selectedQuiz[0]['_id'];
      } else if (this.addTargetUserDetail.get('route')?.value == 'contest' && this.selectedContest.length > 0) {
        criteria['subRouteId'] = this.selectedContest[0]['_id'];
      } else if (this.addTargetUserDetail.get('route')?.value == 'none') {
        criteria['subRouteId'] = undefined;
      } else if (this.addTargetUserDetail.get('route')?.value == 'ovalution') {
        criteria['subRouteId'] = undefined;
      } else {
        // if (this.selectedArticle.length > 0) {

        criteria['subRouteId'] = this.addTargetUserDetail.get('article')?.value;
        // }

      }
    }
    else if (this.addTargetUserDetail.get('routeType')?.value == 'Book' && this.selectedBook.length > 0) {
      criteria['subRouteId'] = this.selectedBook[0]['_id'];
    }
    else if (this.addTargetUserDetail.get('routeType')?.value == 'Magazine' && this.selectedMagazine.length > 0) {
      criteria['subRouteId'] = this.selectedMagazine[0]['_id'];
    } else if (this.addTargetUserDetail.get('routeType')?.value == 'Book' && this.addTargetUserDetail.get('route')?.value == 'collection') {
      criteria['subRouteId'] = this.addTargetUserDetail.get('bookCollection')?.value;
    }
    else {

    }
    criteria['route'] = this.addTargetUserDetail.get('routeType')?.value;
    criteria['subRoute'] = this.addTargetUserDetail.get('route')?.value;

    this.formdata.set('criteria', JSON.stringify(criteria));


    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateCampaign}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isSaveCampaignLoading = false;
                if (this.dataToEdit != null) {
                  this.showSuccessToaster('Campaign Edited successfully', 'Success');
                } else {
                  this.showSuccessToaster('Campaign Added successfully', 'Success');
                }
                this.router.navigate(['/admin/notification-1']);
              } else {
                this.isSaveCampaignLoading = false;
                if (this.dataToEdit != null) {
                  this.showWarningToaster('Unable to edit campaign. Try again.', 'Warning');
                } else {
                  this.showWarningToaster('Unable to add campaign. Try again.', 'Warning');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isSaveCampaignLoading = false;
          console.log('error', error);
          if (this.dataToEdit != null) {
            this.showWarningToaster('Unable to edit campaign. Try again.', 'Warning');
          } else {
            this.showWarningToaster('Unable to add campaign. Try again.', 'Warning');
          }
        });
      return;
    } catch (error) {
      this.isSaveCampaignLoading = false;
      if (this.dataToEdit != null) {
        this.showWarningToaster('Unable to edit campaign. Try again.', 'Warning');
      } else {
        this.showWarningToaster('Unable to add campaign. Try again.', 'Warning');
      }
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}