import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-delete-offer-dialog',
  templateUrl: './delete-offer-dialog.component.html',
  styleUrls: ['./delete-offer-dialog.component.scss']
})
export class DeleteOfferDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<DeleteOfferDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteOffer() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Offer',
      value: this.data['offer'],
    });
  }
  updateOfferStatus() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Update Offer Status',
      value: this.data['offer'],
      status: !this.data['offer']['isActive']
    });
  }

}
