import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralDashboardComponent } from './referral-dashboard/referral-dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import { ManageReferralProductComponent } from './manage-referral-product/manage-referral-product.component';


const routes: Routes = [
  {
    path: '',
    component: ReferralDashboardComponent,
  },
  {
    path: 'manage-product',
    component: ManageReferralProductComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/referral' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferralRoutingModule { }
