<div *ngIf="data['source']=='Delete Content'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this content?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteContent()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Magazine'">
    <h1 mat-dialog-title style="font-weight: 500; font-size:15px;">Are you sure you want to delete this magazine?</h1>
    <div style="width: 25em;font-size: 13.5px;" mat-dialog-content>Content related to this magazine will also be
        deleted.</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteMagazine()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Content'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['content']['isActive']?'active': 'inactive'}} this content?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeContent()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete Banner'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this banner?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteBanner()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Banner'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['banner']['isActive']?'active': 'inactive'}} this banner?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeBanner()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Product'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['product']['isActive']?'active': 'inactive'}} this product?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeProduct()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete Product'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this product?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteProduct()">Yes</button>
    </div>
</div>


<div *ngIf="data['source']=='Delete Brand'">
    <h1 mat-dialog-title style="font-weight: 500;font-size:15px;">Are you sure you want to delete this brand?</h1>
    <div style="width: 25em;font-size: 13.5px;" mat-dialog-content>Coupon related to this brand will also be deleted.
    </div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteBrand()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Brand'">
    <h1 mat-dialog-title style="font-weight: 500;font-size:15px;">Are you sure you want to
        {{!data['brand']['isActive']?'active': 'inactive'}} this brand?</h1>
    <div style="width: 25em;font-size: 13.5px;" mat-dialog-content>Offer related to this brand will also be
        {{!data['brand']['isActive']?'active': 'inactive'}}.
    </div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeBrand()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Plan'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['plan']['isActive']?'active': 'inactive'}} this plan?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activePlan()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Plan'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this plan?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deletePlan()">Yes</button>
    </div>
</div>


<div *ngIf="data['source']=='Active Video'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['video']['isActive']?'active': 'inactive'}} this video?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeVideo()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Video'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this video?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteVideo()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Influencer'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['influencer']['isActive']?'active': 'inactive'}} this influencer?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeInfluencer()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Influencer'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this influencer?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteInfluencer()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Active Coupon'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['coupon']['isActive']?'active': 'inactive'}} this coupon?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeCoupon()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Coupon'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this coupon?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteCoupon()">Yes</button>
    </div>
</div>