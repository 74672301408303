<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">

        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-quiz-author *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-quiz-author>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;margin-bottom: 2rem;">
                <div class="col-md-4 top-heading" style="cursor: pointer;" (click)="goToQuiz()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span style="font-size: large;
                        color: #1f5ce1;cursor: pointer;">
                        Authors
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">

                        <div class="col-md-2" style="text-align: center;margin-right: 1.2rem;">
                            <button class="add-new-emp" (click)="addNewAuthor()">
                                Add Author
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->

            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfAuthorToDisplay.length==0  && !isLoading">
                <div class="no-available">
                    No banner available.
                </div>
            </div>
            <div *ngIf="listOfAuthorToDisplay.length!=0  && !isLoading">
                <div class="row" style="    margin: 1em;width: 100%;">
                    <div class="col-md-3 brand-card" *ngFor="let banner of listOfAuthor">
                        <img class="img"
                            src="{{banner.image==''? 'https://knetic.org.uk/wp-content/uploads/2020/07/Audio-Record-Placeholder.png' : banner.image}}"
                            alt="Brand Image" srcset="">
                        <div class="detail-position">
                            <span class="brandName">Name: {{banner.name}}</span><br>
                        </div>
                        <div class="row">
                            <div class="brand-icon-position" (click)="editAuthor(banner)">
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div class="brand-icon-position" style="top: 43px;" (click)="deleteAuthor(banner)">
                                <mat-icon>delete</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
<div class="space-10">

</div>
            </div>
        </div>
    </ng-sidebar-container>

</div>