import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { UserDetailsDialogComponent } from '../user-details-dialog/user-details-dialog.component';

export interface QuizContest {
  _id: string;
  quiz: any;
  numberOfWinners: number;
  startDate: Date;
  endDate: Date;
  userList: any;
  isActive: boolean;
  createdAt: Date;
}
@Component({
  selector: 'app-list-quiz-contest',
  templateUrl: './list-quiz-contest.component.html',
  styleUrls: ['./list-quiz-contest.component.scss'],
})
export class ListQuizContestComponent implements OnInit {
  brandDetails: any;

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfQuizContestToDisplay: Array<QuizContest> = [];
  listOfQuizContest: Array<QuizContest> = [];
  listOfQuizContestOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];
  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.fetchQuizContests();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async fetchQuizContests() {
    this.listOfQuizContestToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchQuizContest}`;
      const param = {
        admin: true,
      };
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfQuizContestToDisplay = data['result'];
          this.listOfQuizContest = [...this.listOfQuizContestToDisplay];
          this.isLoading = false;
        } else {
          this.listOfQuizContestToDisplay = [];
          this.listOfQuizContest = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfQuizContestToDisplay = [];
      this.listOfQuizContest = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfQuizContestToDisplay = [];
      this.listOfQuizContest.forEach((quiz: any, index: number) => {
        if (
          String(quiz.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(quiz.category.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(quiz);
        }
      });
      this.listOfQuizContestToDisplay = tempSearchList;
    } else {
      this.listOfQuizContestToDisplay = [];
      this.listOfQuizContestToDisplay = this.listOfQuizContest;
    }
  }

  addNewQuizContest() {
    this.toggleOpened();
  }

  editQuizContest(quiz: any) {
    let quizToEdit;
    this.listOfQuizContestToDisplay.forEach((ofr: any) => {
      if (ofr['_id'] == quiz._id) {
        quizToEdit = ofr;
      }
    });
    this.dataToEdit = quizToEdit;
    this.toggleOpened();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }

  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing quiz details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while creating quiz winners details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfQuizContestToDisplay.findIndex(
          (quiz: any) => quiz['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfQuizContestToDisplay.push(event);
          this.listOfQuizContest = [...this.listOfQuizContestToDisplay];
          this.showSuccessToaster('QuizContest added successfully', 'Success');
        } else {
          this.listOfQuizContestToDisplay[index] = event;
          this.listOfQuizContest = [...this.listOfQuizContestToDisplay];
          this.showSuccessToaster(
            'QuizContest Updated successfully',
            'Success'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }
  goToQuiz() {
    this.router.navigate(['/admin/quiz']);
  }

  gotoQuizContestOption() {
    this.as.gotoComponent('QuizContests');
  }

  viewUserDetails(quiz: any) {
    const dialogRef = this.matDialog.open(UserDetailsDialogComponent, {
      data: quiz,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        // console.log(res);
        if (res == null) {
          return;
        } else {
        }
      }
    );
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
