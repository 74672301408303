import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() selectedType: any;
  @Input() listOfCategory: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = true;
  userDetails: any;

  selectedDesigns: any = [];
  selectedDesignImage: any = null;
  selectedDesignImageName: any = null;
  defaultDesignImage: any;

  myHeaders = new Headers();
  formdata = new FormData();

  categoryControl = new FormControl('Select Category', [Validators.required]);

  addMehndiImageForm: FormGroup = new FormGroup({
    category: this.categoryControl,
  });

  constructor(private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);

  }

  ngOnInit(): void {
    this.addMehndiImageForm = this.formBuilder.group({
      category: this.categoryControl,
    });
    this.isLoading = false;
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayAdhaar(event: any) {
    this.selectedDesigns = event.target.files;
    this.selectedDesignImage = event.target.files[0];
    this.selectedDesignImageName = this.selectedDesignImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultDesignImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedDesignImage);
  }

  addEditImage() {
    this.isLoading = true;


    if (this.selectedDesigns.length > 0) {
      for (let index = 0; index < this.selectedDesigns.length; index++) {
        const element = this.selectedDesigns[index];
        this.formdata.set(`image-${index + 1}`, element);
      }
    }

    this.formdata.set('designCategory', this.addMehndiImageForm.get('category')?.value);
    this.formdata.set('designType', this.selectedType);

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.bulkUploadImage}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addMehndiImageForm.reset();
                this.cancel(res);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
