<div *ngIf="isLoading">
    <!-- <div class="center-loading"> -->
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
    <!-- </div> -->
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Add Category</h2>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div>
                <label for="category" class="label required">Category name</label>
                <input type="text" id="category" class="input-style" placeholder="Category name" [(ngModel)]="category"
                    [ngModelOptions]="{standalone: true}" (input)="checkForValidCategoryName($event.target)">
            </div>
            <div *ngIf="!isValidCategory" class="error-div">
                Category with this name already exist.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button
                [ngClass]="{'pay-btn-disable':category==null || category=='' || !isValidCategory,'pay-btn-enable':category!=null && category!='' && isValidCategory,'pay-btn-white':isAddingCategoryLoading}"
                [disabled]="category==null || category=='' || !isValidCategory"
                (click)="category==null || category==''|| !isValidCategory? null:addCategory()">
                <div *ngIf="isAddingCategoryLoading">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isAddingCategoryLoading">
                    Save
                </div>
            </button>

        </div>
    </div>
</div>