<div id="background_header"></div>
<div id="background_body"></div>

<div id="chatContainer">
    <app-sidebar (conversationClicked)="onConversationSelected($event)"></app-sidebar>
    <div id="chat">
        <div id="chatPlaceholder" *ngIf="!conversation">
            <img src="../assets/images/chat.png" style="    width: 150px;
            margin-bottom: 3em;" />
            <h2>No chat selected</h2>
            <p>
                Manage customer service by providing easy solution
            </p>
            <div class="seperator"></div>

        </div>

        <app-chat [componentChanged]="conversation" *ngIf="conversation">
        </app-chat>
    </div>
</div>

<router-outlet></router-outlet>