import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from './app.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { AdminModule } from './main-components/admin/admin.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MessageService } from './main-components/admin/whatsapp/message.service';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthenticationModule,
    AdminModule,
    FormsModule,
    BrowserAnimationsModule,
    PickerModule,
  ],
  providers: [AppService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
