<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="!isLoading" class="container-fluid main-content" id="container-wrapper">
    <div class="row m-3">

        <div class="col-md-12 heading-text" style="justify-content: space-between;display: flex;align-items: baseline;">
            <div class="dashboard-div">
                Dashboard
            </div>
            <div>
                <div style="margin-right: 2rem;display: inline;">

                    <mat-form-field appearance="outline">
                        <mat-label>Pick a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [value]="startDate"
                                (dateChange)="dateChanged( 'start',$event)">
                            <input matEndDate placeholder="End date" [value]="endDate"
                                (dateChange)="dateChanged( 'end',$event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <select id="type" name="type" class="input-style"
                    style="height: 3.2rem;width:15rem;font-size: 1.1rem;;border-radius: 5px;padding-left: 0.5rem;border: solid #dcd5d5 1px;"
                    (change)="changeDuration($event.target)" [(ngModel)]="selectedDuration">
                    <option *ngFor="let duration of listOfDuration" [value]="duration">{{duration}}
                    </option>
                </select>
            </div>

        </div>

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total New Users
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{totalUsers}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/user.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Earnings (Annual) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Subscription Count
                                ({{selectedDuration}})
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{totalSubscription}}</div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/renew.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- New User Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Earning From Subscription
                                ({{selectedDuration}})
                            </div>
                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                &#8377;{{earningFromSubscription}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/money.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pending Requests Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Earning From Books
                                ({{selectedDuration}})
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                &#8377;{{earningFromBooks}}</div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/money.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Booking Table -->
        <div class="col-xl-8 col-lg-7 mb-4">
            <div class="card">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 *ngIf="listOfSubscription.length==0" class="m-0 font-weight-bold text-primary">Subscriptions
                    </h4>
                    <h4 *ngIf="listOfSubscription.length!=0" class="m-0 font-weight-bold text-primary">Top
                        {{listOfSubscription.length}} Recent Subscriptions </h4>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Duration</th>
                                <th>Price</th>
                                <th>Created Date</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listOfSubscription.length==0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                    style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                                    No subscription available yet
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listOfSubscription.length>0">
                            <tr *ngFor="let sub of listOfSubscription">
                                <td>{{sub['user']['firstName'] +' '+ sub['user']['lastName']}}</td>
                                <td>{{sub['user']['phone']}}</td>
                                <td>{{sub['startDate']|date}}</td>
                                <td>{{sub['endDate']|date}}</td>
                                <td>{{sub['plan']['duration']}}</td>
                                <td>&#8377;{{sub['plan']['price']}}</td>
                                <td>{{sub['createdAt']|date}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="card-footer"></div>
            </div>
        </div>

        <!-- Pie Chart -->
        <div class="col-xl-4 col-lg-5">
            <div class="card mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 *ngIf="listOfBooks.length==0" class="m-0 font-weight-bold text-primary">Top Selling Books</h4>
                    <h4 *ngIf="listOfBooks.length!=0" class="m-0 font-weight-bold text-primary">Top
                        {{listOfBooks.length}} Selling Books</h4>
                </div>
                <div class="card-body">

                    <div *ngIf="listOfBooks.length==0">
                        <div
                            style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                            No Books sold.
                        </div>
                    </div>
                    <div *ngIf="listOfBooks.length>0">

                        <div class="mb-3" *ngFor="let books of listOfBooks">
                            <div class="small text-gray-500"
                                style="    font-size: .95rem; font-weight: 600; margin-bottom: 0.3rem;">
                                {{books['title']}} ({{books['category']}})
                                <div class="small float-right"><b>{{books['totalCount']}} of {{bookCount}} Sold
                                    </b></div>
                            </div>
                            <div class="progress" style="height: 12px;">
                                <div class="bg-success" class="progress-bar"
                                    style="    font-size: .95rem; font-weight: 600;" role="progressbar"
                                    [ngStyle]="{'width.%': (books['totalCount']/bookCount)*100}">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!-- Coupon  Statistic-->

        <div class="col-xl-12 col-lg-12 " style="display: flex;justify-content: end;"
            *ngIf="couponSummary!=null && !isLoading">
            <div class="card" style="width: 32%;">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-primary">Coupon Summary</h4>
                </div>
                <div class="card-body">
                    <div class="customer-message align-items-center">
                        <div style="margin-right: 1em;font-weight: 600;">
                            Total Coupon: {{couponSummary['totalCoupon']}}
                        </div>
                        <hr>
                        <div style="margin-right: 1em;font-weight: 600;">
                            Sent Coupon: {{couponSummary['usedCoupon']}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- WhatsApp Available message Statistic-->

        <div class="col-xl-12 col-lg-12 " style="display: flex;justify-content: end;">
            <div class="card" style="width: 32%;margin-top: 1.5em;">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-primary">WhatsApp</h4>
                </div>
                <div class="card-body">
                    <div class="customer-message align-items-center">
                        <div *ngIf="clientDetail!=null && !isLoading" style="margin-right: 1em;font-weight: 600;">
                            BuildBy Free Conversation: {{roundTo(clientDetail['freeConversation'],2)}}
                        </div>
                        <hr>
                        <div *ngIf="clientDetail!=null && !isLoading" style="margin-right: 1em;font-weight: 600;">
                            BuildBy Chat Balance: &#x20B9; {{roundTo(clientDetail['clientId']['walletBalance'],2)}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- WhatsApp Available message Statistic-->

        <div class="col-xl-12 col-lg-12 " style="display: flex;justify-content: end;">
            <div class="card" style="width: 32%;margin-top: 1.5em;">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-primary">Active User Details On whatsApp</h4>

                </div>
                <div class="card-body">
                    <div *ngIf="activeUserMonthDetail==null ||  activeUserMonthDetail==undefined">
                        <div
                            style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                            No data available.
                        </div>
                    </div>
                    <div class="mb-3" *ngFor="let key of keys">
                        <div class="small text-gray-500"
                            style="    font-size: .95rem; font-weight: 600; margin-bottom: 0.3rem;">
                            {{key}} ({{activeUserMonthDetail[key].year}})
                            <div class="small float-right"><b>{{activeUserMonthDetail[key].count}} of {{5000}}
                                </b></div>
                        </div>
                        <div class="progress" style="height: 12px;">
                            <div class="bg-success" class="progress-bar"
                                style="    font-size: .95rem; font-weight: 600;" role="progressbar"
                                [ngStyle]="{'width.%': (activeUserMonthDetail[key].count/5000)*100}">
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
    <div style="height: 10em;">

    </div>



</div>