import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-add-edit-contest-category',
  templateUrl: './add-edit-contest-category.component.html',
  styleUrls: ['./add-edit-contest-category.component.scss']
})
export class AddEditContestCategoryComponent implements OnInit {

  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  titleControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  descriptionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  sponsorNameControl = new FormControl('');

  addContestCategoryForm: FormGroup = new FormGroup({
    title: this.titleControl,
    description: this.descriptionControl,
    sponsorName: this.sponsorNameControl,
  });

  selectedCategoryImage: any = null;
  selectedCategoryImageName: any = null;
  defaultCategoryImage: any;

  selectedVideo: any = null;
  selectedVideoName: any = null;
  defaultVideoImage: any;

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addContestCategoryForm = this.formBuilder.group({
      title: this.titleControl,
      description: this.descriptionControl,
      sponsorName: this.sponsorNameControl,
    });
    if (this.dataToEdit != null) {
      this.addContestCategoryForm.get('title')?.setValue(this.dataToEdit['title']);
      this.addContestCategoryForm.get('description')?.setValue(this.dataToEdit['description']);
      this.addContestCategoryForm
        .get('sponsorName')
        ?.setValue(this.dataToEdit['sponsorName']);

      this.defaultCategoryImage = this.dataToEdit['thumbnail'];

      this.defaultVideoImage = 'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  selectImage(event: any) {
    this.selectedCategoryImage = event.target.files[0];
    this.selectedCategoryImageName = this.selectedCategoryImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCategoryImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedCategoryImage);
  }

  selectVideo(event: any) {
    this.selectedVideo = event.target.files[0];
    this.selectedVideoName = this.selectedVideo['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultVideoImage =
        'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    };
    // reader.readAsDataURL(this.selectedContentImage);
  }


  addEditUser() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('categoryId', this.dataToEdit['_id']);
      this.formdata.set('thumbnail', this.dataToEdit['thumbnail']);
      this.formdata.set('backgroundVideo', this.dataToEdit['backgroundVideo']);
    } else {
      this.formdata.set('categoryId', '0');
    }

    this.formdata.set('title', this.addContestCategoryForm.get('title')?.value);
    this.formdata.set('description', this.addContestCategoryForm.get('description')?.value);
    this.formdata.set('sponsorName', this.addContestCategoryForm.get('sponsorName')?.value);

    if (this.selectedCategoryImage != null) {
      this.formdata.set('thumbnail', this.selectedCategoryImage);
    }

    if (this.selectedVideo != null) {
      this.formdata.set('backgroundVideo', this.selectedVideo);
    }

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.createAndUpdateContestCategory}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addContestCategoryForm.reset();
                // this.defaultCoverImage = null;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');

          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
