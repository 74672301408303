<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                Add Contest
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addContestForm" [formGroup]="addContestForm">


        <div class="row">

            <div class="col-md-6">
                <label for="quiz" class="label required" style="margin-bottom: 1rem;">Select Quiz</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Quiz'"
                    [ngModelOptions]="{standalone: true}" [settings]="lngDropdownSettings" [data]="listOfQuizzes"
                    [(ngModel)]="selectedQuiz">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-6">
                <label for="numberOfWinners" class="label required" style="margin-bottom: 1rem;">Title</label>
                <input type="number" placeholder="Number Of Winners" id="title" formControlName="numberOfWinners"
                    class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" class="input-style">
            </div>
        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addContestForm.invalid || selectedQuiz.length==0   ,'save-btn':addContestForm.valid && selectedQuiz.length!=0}"
                value="{{'Save'}}" [disabled]="addContestForm.invalid || selectedQuiz.length==0  "
                (click)="generateQuizContest()" />
        </div>
    </form>
</div>