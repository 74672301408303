<div class="main-container">
    <!-- Stepper Card -->
    <div class="main-card">
        <ol class="c-stepper">
            <li *ngFor="let data of listOfStepper" class="c-stepper__item" [attr.data-before]="[data['content']]"
                [ngStyle]="{'--featured-bgColor': 'lightgrey'}">
                <h3 class="c-stepper__title" (click)="null" style="    margin: 0px;"
                    [ngStyle]="{color:selectedStepper==data['label']?'#088ada':'black'}">{{data['label']}}</h3>
                <!-- onStepperClick(data['label']) -->
            </li>
        </ol>
    </div>
    <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
    <!-- Target User Card -->
    <div *ngIf="selectedStepper=='Campaign Detail' && !isLoading" class="main-card">
        <div class="row">
            <div class="col-md-4">
                <label class="heading">Campaign Detail</label>
            </div>
        </div>
        <form name="addCampaignDetail" [formGroup]="addCampaignDetail">
            <div class="row">
                <div class="col-md-4">
                    <label for="name" class="label required">Campaign Name</label>
                    <input type="text" placeholder="Enter Campaign Name" id="name" formControlName="name"
                        class="input-style">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="medium" class="label required">Select Medium</label>
                    <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Medium'"
                        formControlName="medium" [settings]="mediumDropdownSettings" [data]="listOfMedium"
                        [(ngModel)]="selectedMedium" (onSelect)="onDaySelect($event)"
                        (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                    </ng-multiselect-dropdown>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="type" class="label required" style="margin-top: 10px;">Select Type</label>
                    <select id="type" name="type" class="input-style" formControlName="type"
                        (change)="typeSelected($event)">
                        <option value="Select Type" selected disabled>Select Type</option>
                        <option value="Reccuring">Reccuring</option>
                        <option value="One-Time">One-Time</option>
                        <!-- <option value="Event">Event</option> -->
                    </select>
                </div>
            </div>
            <div class="row">
                <div *ngIf="selectedType=='One-Time'" class="col-md-4">
                    <label for="campaignDateTime" class="label">One-Time Type</label>
                    <div style="margin-bottom: 10px;">
                        <label for="Schedule" style="font-size:medium;margin-right: 1em;">Schedule</label>
                        <input type="radio" style="font-size:medium;margin-right: 1em;" id="Schedule" class="radio"
                            name="radio-btn" value="Schedule" (change)="radioChangeHandler($event)"
                            [checked]="radioSelectedValue=='Schedule'">
                        <label for="Immediate" style="font-size:medium;margin-right: 1em;">Immediate</label>
                        <input type="radio" style="font-size:medium;margin-right: 1em;" id="Immediate" class="radio"
                            name="radio-btn" value="Immediate" (change)="radioChangeHandler($event)"
                            [checked]="radioSelectedValue=='Immediate'">
                    </div>
                    <div *ngIf="radioSelectedValue=='Schedule'">
                        <!-- <label for="campaignDateTime" class="label required">Campaign date</label> -->
                        <input type="datetime-local" placeholder="Campaign date" id="campaignDateTime"
                            formControlName="campaignDateTime" class="input-style">
                    </div>
                </div>
                <div *ngIf="selectedType=='Reccuring'" class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="scheduleFor" class="label required">Select Schedule For</label>
                            <select id="scheduleFor" name="scheduleFor" class="input-style"
                                formControlName="scheduleFor" (change)="scheduleForSelected($event)">
                                <option value="Select Schedule For" selected disabled>Select Schedule For</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                            </select>
                        </div>
                        <div *ngIf=" selectedScheduleFor=='Weekly'" class="col-md-3">
                            <label for="scheduleDay" class="label required">Select Day</label>
                            <div style="width: 80%;">
                                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Days'"
                                    formControlName="scheduleDay" [settings]="daysDropdownSettings" [data]="listOfDays"
                                    [(ngModel)]="selectedDays" (onSelect)="onDaySelect($event)"
                                    (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div *ngIf=" selectedScheduleFor=='Monthly' " class="col-md-2">
                            <label for="scheduleDate" class="label required">Select Date</label>
                            <input type="date" id="scheduleDate" formControlName="scheduleDate" class="input-style">
                        </div>
                        <div *ngIf="selectedScheduleFor=='Daily' || selectedScheduleFor=='Weekly' || selectedScheduleFor=='Monthly'"
                            class="col-md-2">
                            <label for="scheduleTime" class="label required">Select Time</label>
                            <input type="time" id="scheduleTime" formControlName="scheduleTime" class="input-style"
                                step="900">
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="selectedStepper=='Campaign Detail' && !isLoading">
        <div class="row justify-content-end">
            <div class="col-md-12" style="text-align-last: right;">
                <button
                    [ngClass]="{'save-campaign-disabled': addCampaignDetail.invalid || addCampaignDetail.get('type')?.value=='Select Type' || addCampaignDetail.get('scheduleFor')?.value=='Select Schedule For'  ,'save-campaign-enabled': addCampaignDetail.valid && addCampaignDetail.get('type')?.value!='Select Type' && addCampaignDetail.get('scheduleFor')?.value!='Select Schedule For' }"
                    [disabled]=" addCampaignDetail.invalid || addCampaignDetail.get('type')?.value=='Select Type' || addCampaignDetail.get('scheduleFor')?.value=='Select Schedule For'  "
                    (click)=" addCampaignDetail.invalid || addCampaignDetail.get('type')?.value=='Select Type' || addCampaignDetail.get('scheduleFor')?.value=='Select Schedule For'  ? null: gotoStepper('Target users')">Next</button>

            </div>

        </div>
    </div>

    <!-- Target users Card -->
    <div *ngIf="selectedStepper=='Target users' && !isLoading" class="main-card">
        <div class="row">
            <div class="col-md-4">
                <label class="heading">Target audience</label>
            </div>
        </div>
        <form name="addTargetUserDetail" [formGroup]="addTargetUserDetail">

            <div class="row">
                <div class="col-md-2">
                    <label for="campaignDateTime" class="label required">Select User Group</label>
                    <select id="type" name="type" class="input-style" (change)="userTargetSelected($event)"
                        formControlName="targetUser">
                        <option value="Select User Group" selected disabled>Select User Group</option>
                        <option value="All User">All User</option>
                        <option value="All User With less than 10 vote">All User With less than 10 vote</option>
                        <option value="Book Purchased">Book Purchased</option>
                        <option value="Book Recommendation">Book Recommendation</option>
                        <option value="Subscribed User">Subscribed User</option>
                        <option value="Unsubscribed User">Unsubscribed User</option>
                        <option value="Inactive User">Inactive User</option>

                    </select>
                </div>
                <div class="col-md-2" *ngIf="!isLoading">
                    <label for="campaignDateTime" class="label required">Select Screen</label>
                    <select id="type" name="type" class="input-style" formControlName="routeType"
                        (change)="routeTypeChanged($event)">
                        <option value="Select Screen" selected disabled>Select Screen</option>
                        <option *ngFor="let data of listOfRouteType" [value]="data">{{data}}</option>

                    </select>
                </div>
                <div class="col-md-2" *ngIf="!isLoading && showRoute">
                    <label for="campaignDateTime" class="label required">Select Route</label>
                    <select id="type" name="type" class="input-style" formControlName="route">
                        <option value="Select Route" selected disabled>Select Route</option>
                        <option *ngFor="let data of listOfRoutes" [value]="data">{{data}}</option>

                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"
                    *ngIf="addTargetUserDetail.get('routeType')?.value=='Home' && addTargetUserDetail.get('route')?.value=='quiz'">
                    <label for="course" class="label required">Select Quiz</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Quiz'"
                            formControlName="quiz" [settings]="quizDropdownSettings" [data]="listOfQuiz"
                            [(ngModel)]="selectedQuiz" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-6"
                    *ngIf="addTargetUserDetail.get('routeType')?.value=='Home' && addTargetUserDetail.get('route')?.value=='contest'">
                    <label for="course" class="label required">Select Contest</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Contest'"
                            formControlName="contest" [settings]="quizDropdownSettings" [data]="listOfContest"
                            [(ngModel)]="selectedContest" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-6"
                    *ngIf="addTargetUserDetail.get('routeType')?.value=='Home' && addTargetUserDetail.get('route')?.value=='mehendi'">
                    <label for="course" class="label required">Select Mehendi Design</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Mehendi Design'"
                            formControlName="mehendi" [settings]="mehendiDropdownSettings" [data]="listOfMehendi"
                            [(ngModel)]="selectedMehendi" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-6"
                    *ngIf="addTargetUserDetail.get('routeType')?.value=='Magazine' && addTargetUserDetail.get('route')?.value=='magazine'">
                    <label for="course" class="label required">Select Magazine</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Magazine'"
                            formControlName="magazine" [settings]="magazineDropdownSettings" [data]="listOfMagazine"
                            [(ngModel)]="selectedMagazine" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-6"
                    *ngIf="addTargetUserDetail.get('routeType')?.value=='Home' && (addTargetUserDetail.get('route')?.value!='contest' && addTargetUserDetail.get('route')?.value!='mehendi' && addTargetUserDetail.get('route')?.value!='quiz' && addTargetUserDetail.get('route')?.value!='none' && addTargetUserDetail.get('route')?.value!='ovalution')">
                    <label for="article" class="label required">WP Article Id</label>
                    <input type="number" placeholder="Enter WP Article Id" id="article" formControlName="article"
                        class="input-style">

                </div>
                <div class="col-md-4"
                    *ngIf="(addTargetUserDetail.get('routeType')?.value=='Book' && addTargetUserDetail.get('route')?.value=='collection')">
                    <label for="article" class="label">Select Book Collection</label>
                    <select id="type" name="type" class="input-style" formControlName="bookCollection"
                        (change)="typeSelected($event)">
                        <option value="Select Book Collection" selected disabled>Select Book Collection</option>
                        <option *ngFor="let data of listOfBookCollection" [value]="data['_id']">{{data['name']}}
                        </option>
                    </select>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4"
                    *ngIf="(selectedUserTarget=='Book Purchased'  ||selectedUserTarget=='Book Recommendation' ) || (addTargetUserDetail.get('routeType')?.value=='Book' && addTargetUserDetail.get('route')?.value!='collection')">
                    <label for="course" class="label required">{{getSelectTitle(selectedUserTarget)}}</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Book'"
                            [settings]=" bookDropdownSettings" formControlName="book" [data]="listOfBook"
                            [(ngModel)]="selectedBook" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                        <!-- <ng-multiselect-dropdown
                            *ngIf="selectedUserTarget!='Course Consumption' &&  selectedUserTarget!='Expiry'"
                            style="width: 100%;" [placeholder]="'Select Course'" [settings]=" courseDropdownSettings"
                            formControlName="course" [data]="listOfBook" [(ngModel)]="selectedCourse"
                            (onSelect)="onDaySelect($event)" (onDeSelect)="onDayDeSelect($event)"
                            (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown> -->
                    </div>
                </div>
                <div class="col-md-4" *ngIf="selectedUserTarget=='Book Recommendation'">
                    <label for="course" class="label required">Recommended Book</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Course'"
                            formControlName="recommendedBook" [settings]="book1DropdownSettings" [data]="listOfBook"
                            [(ngModel)]="selectedRecommendedBook" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <!-- <div class="col-md-4" *ngIf="selectedUserTarget=='Course Consumption'">
                    <label for="course" class="label required">Select Percentage Range</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Percentage Range'"
                            [settings]="rangeDropdownSettings" [data]="listOfRange" [(ngModel)]="selectedRange"
                            formControlName="range" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div> -->
                <!-- <div class="col-md-4" *ngIf="selectedUserTarget=='Expiry' ">
                    <label for="course" class="label required">Select Duration</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Duration'"
                            formControlName="duration" [settings]="duartionDropdownSettings" [data]="listOfDuartion"
                            [(ngModel)]="selectedDuration" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div> -->
                <div class="col-md-4" *ngIf="selectedUserTarget=='Inactive User'">
                    <label for="course" class="label required">Select Duration</label>
                    <div style="width: 80%;">
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Duration'"
                            formControlName="duration" [settings]="duartion1DropdownSettings" [data]="listOfDuartion"
                            [(ngModel)]="selectedDuration" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-md-12" style="text-align-last: right;">
                    <button *ngIf="!isGetTargetLoading" class="btn get-count-button"
                        [disabled]="addTargetUserDetail.get('targetUser')?.value=='Select User Group'"
                        (click)="addTargetUserDetail.get('targetUser')?.value=='Select User Group'? null: getTargetUserCount()">Show
                        Target Count</button>
                    <button *ngIf="isGetTargetLoading" class="get-count-button">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </button>
                </div>
                <div *ngIf="targetUserCountCalled" class="col-md-12"
                    style="text-align-last: right; margin-top: 5px;font-size: 13px; ">
                    {{targetUserCount}} user will get notified under this Campaign.
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="selectedStepper=='Target users'">
        <div class="row justify-content-end">
            <div class="col-md-12" style="text-align-last: right;">
                <button class="back-button" (click)="gotoStepper('Campaign Detail')">Back</button>
                <button
                    [ngClass]="{'save-campaign-disabled': addTargetUserDetail.invalid || addTargetUserDetail.get('targetUser')?.value=='Select User Group' || addTargetUserDetail.get('routeType')?.value=='Select Screen' || addTargetUserDetail.get('route')?.value=='Select Route' ,'save-campaign-enabled': addTargetUserDetail.valid && addTargetUserDetail.get('targetUser')?.value!='Select User Group'&& addTargetUserDetail.get('routeType')?.value!='Select Screen'  && addTargetUserDetail.get('route')?.value!='Select Route' }"
                    [disabled]=" addTargetUserDetail.invalid "
                    (click)=" addTargetUserDetail.invalid || addTargetUserDetail.get('targetUser')?.value=='Select User Group' || addTargetUserDetail.get('routeType')?.value=='Select Screen' || addTargetUserDetail.get('route')?.value=='Select Route' ? null: gotoStepper('Content')">Next</button>
            </div>
        </div>
    </div>
    <!-- Content Card -->
    <div *ngIf="selectedStepper=='Content'" class="main-card">
        <div *ngIf="selectedMedium.length==0" class="display-flex align-items-center justify-content-center"
            style="height: 40vh;">
            Select Atleast one medium.
        </div>
        <div *ngIf="selectedMedium.length>0">
            <form name="addContentDetail" [formGroup]="addContentDetail">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab *ngFor="let data of selectedMedium" [label]="data['label']">
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='WhatsApp'">

                            <!-- **START** WhatsApp Campaign -->

                            <div *ngIf="isTemplateLoading">
                                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                                </mat-progress-spinner>
                            </div>
                            <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                <div class="row" style="margin-bottom: 1em;">
                                    <div class="col-md-12">
                                        <div *ngIf="!isTemplateLoading " class="template-list">
                                            <div *ngIf="!isTemplateLoading && !showTemplateBody">
                                                <div
                                                    style="display: flex;justify-content: space-between;align-items: center;">
                                                    <div>

                                                        <span style="font-size: 18px;font-weight: 500;">
                                                            Choose Template
                                                        </span>
                                                    </div>
                                                    <div style="display: flex;align-items: center;">
                                                        <input type="text" class="search-box"
                                                            placeholder="Search template"
                                                            (input)="searchTemplateData($event.target)">
                                                        <span (click)="syncTemplate()"
                                                            style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div style="overflow: auto;">

                                                    <div *ngFor="let template of listOfTemplateToDisplay">
                                                        <div (click)="selectTemplate(template)"
                                                            style="cursor: pointer;">
                                                            <span
                                                                style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                            <div style="margin-top: 10px;">
                                                                {{getTemplateBody(template)}}
                                                            </div>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!isTemplateLoading &&  showTemplateBody">
                                                <div
                                                    style="display: flex;justify-content: space-between;align-items: center;">
                                                    <div>

                                                        <span style="font-size: 18px;font-weight: 500;">
                                                            Selected Template
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <img src="../../../../../assets/img/close.png" alt="" srcset=""
                                                            style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                            (click)="hideTemplateBody()">
                                                    </div>
                                                </div>
                                                <hr>


                                                <div style="overflow: auto;max-height: 14em;">

                                                    <div
                                                        style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                        <span
                                                            style="font-weight: 600;">{{selectedTemplate[0]['nameWithLanguage']}}</span>
                                                        <div style="margin-top: 10px;">
                                                            {{getTemplateBody(selectedTemplate[0])}}
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showHeaderOfTemplate">
                                    <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate">
                                        <label for="" class="label required">Herder Media:</label>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="image">
                                                    <div class="proof">
                                                        <img *ngIf="defaultHeaderMedia!=null"
                                                            src="{{defaultHeaderMedia}}" class="proofImage"
                                                            style="height: 10em;">
                                                        <div *ngIf="defaultHeaderMedia!=null"
                                                            style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                            [title]="selectedHeaderMediaName">
                                                            {{selectedHeaderMediaName}}
                                                        </div>
                                                        <label *ngIf="defaultHeaderMedia==null" class="imageLabel">
                                                            Upload
                                                            <input (change)="displayHeaderMedia($event)" type="file"
                                                                [accept]="this.selectedTemplate['components'][0]['format'] == 'IMAGE'?'image/png, image/gif, image/jpeg':'application/pdf,application/vnd.ms-excel'">
                                                        </label>
                                                        <label *ngIf="defaultHeaderMedia!=null" class="imageLabelEdit">
                                                            <img src="../../../../../assets/img/editing.png"
                                                                style="height: 1em;">
                                                            <input (change)="displayHeaderMedia($event)" type="file"
                                                                [accept]="this.selectedTemplate['components'][0]['format'] == 'IMAGE'?'image/png, image/gif, image/jpeg':'application/pdf,application/vnd.ms-excel'">
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" *ngIf="showHeaderTextOfTemplate">
                                        <div style="margin-bottom: 1.5rem;">
                                            <label for="header-text" class="label required">Header Text:</label>
                                            <quill-editor class="content-editor" style="height: 12rem;"
                                                placeholder="Body" name="header-text" formControlName="headerText">
                                            </quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showBodyOfTemplate" style="margin-top: 1em;">
                                    <div class="col-md-12">
                                        For name enter:- <span
                                            style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                        For phone enter:- <span
                                            style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                    <div class="col-md-6" *ngFor='let val of counter(bodyVariableCount) ;let i = index'>
                                        <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                        <input type="text" [placeholder]="'Enter body text '+(i+1)" id="name"
                                            [formControlName]="'bodyText'+(i+1)" class="input-style">
                                    </div>
                                </div>
                                <div class="row" *ngIf="showFooterOfTemplate">

                                </div>
                                <div class="row" *ngIf="showButtonOfTemplate">

                                </div>
                            </div>
                            <!-- **END** WhatsApp Campaign -->


                            <!-- <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="whatsAppMessageTitle" class="label required">Message
                                                title</label>
                                            <input type="text" placeholder="Enter Message title"
                                                id="whatsAppMessageTitle" formControlName="whatsAppMessageTitle"
                                                class="input-style">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="whatsAppMessage" class="label required">Message</label>
                                            <quill-editor class="content-editor" placeholder="Enter Message"
                                                name="whatsAppMessage" formControlName="whatsAppMessage"></quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="image" class="label">Image(<span style="color: red;">Image size
                                                    should be less than 300kb</span>)</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="image">
                                                        <div class="proof">
                                                            <img *ngIf="defaultWhatsAppImage!=null"
                                                                src="{{defaultWhatsAppImage}}" class="proofImage">
                                                            <label *ngIf="defaultWhatsAppImage==null"
                                                                class="imageLabel"> Upload
                                                                <input (change)="displayWhatsApp($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label *ngIf="defaultWhatsAppImage!=null"
                                                                class="imageLabelEdit">
                                                                <mat-icon class="matIcon">edit</mat-icon>
                                                                <input (change)="displayWhatsApp($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label
                                                                *ngIf="defaultWhatsAppImage!=null  && defaultWhatsAppImage.includes('https')"
                                                                class="imageLabelDownload"
                                                                (click)="downloadWhatsAppImage(defaultWhatsAppImage)">
                                                                <mat-icon class="matIcon">download</mat-icon>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='Sms'">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="smsMessage" class="label required">Message </label>
                                    <quill-editor class="content-editor" placeholder="Enter Message" name="smsMessage"
                                        formControlName="smsMessage"></quill-editor>
                                </div>
                            </div>
                        </div>
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='In App'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="inAppMessageTitle" class="label required">Message title</label>
                                            <input type="text" placeholder="Enter Message title" id="inAppMessageTitle"
                                                formControlName="inAppMessageTitle" class="input-style">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="inAppMessage" class="label required">Message </label>
                                            <quill-editor class="content-editor" placeholder="Enter Message"
                                                name="inAppMessage" formControlName="inAppMessage"></quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="image" class="label">Image(<span style="color: red;">Image size
                                                    should be less than 300kb</span>)</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="image">
                                                        <div class="proof">
                                                            <img *ngIf="defaultInAppImage!=null"
                                                                src="{{defaultInAppImage}}" class="proofImage">
                                                            <label *ngIf="defaultInAppImage==null" class="imageLabel">
                                                                Upload
                                                                <input (change)="displayInApp($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label *ngIf="defaultInAppImage!=null"
                                                                class="imageLabelEdit">
                                                                <mat-icon class="matIcon">edit</mat-icon>
                                                                <input (change)="displayInApp($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label
                                                                *ngIf="defaultInAppImage!=null  && defaultInAppImage.includes('https')"
                                                                class="imageLabelDownload"
                                                                (click)="downloadInAppImage(defaultInAppImage)">
                                                                <mat-icon class="matIcon">download</mat-icon>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='Push Notification'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="pushNotificationMessageTitle" class="label required">Message
                                                title</label>
                                            <input type="text" placeholder="Enter Message title"
                                                id="pushNotificationMessageTitle"
                                                formControlName="pushNotificationMessageTitle" class="input-style">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="pushNotificationMessage" class="label required">Message </label>
                                            <quill-editor class="content-editor" placeholder="Enter Message"
                                                name="pushNotificationMessage"
                                                formControlName="pushNotificationMessage">
                                            </quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="image" class="label">Image(<span style="color: red;">Image size
                                                    should be less than 300kb</span>)</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="image">
                                                        <div class="proof">
                                                            <img *ngIf="defaultPushNotificationImage!=null"
                                                                src="{{defaultPushNotificationImage}}"
                                                                class="proofImage">
                                                            <label *ngIf="defaultPushNotificationImage==null"
                                                                class="imageLabel"> Upload
                                                                <input (change)="displayPushNotification($event)"
                                                                    type="file" accept="image/*">
                                                            </label>
                                                            <label *ngIf="defaultPushNotificationImage!=null"
                                                                class="imageLabelEdit">
                                                                <mat-icon class="matIcon">edit</mat-icon>
                                                                <input (change)="displayPushNotification($event)"
                                                                    type="file" accept="image/*">
                                                            </label>
                                                            <label
                                                                *ngIf="defaultPushNotificationImage!=null  && defaultPushNotificationImage.includes('https')"
                                                                class="imageLabelDownload"
                                                                (click)="downloadPushNotificationImage(defaultPushNotificationImage)">
                                                                <mat-icon class="matIcon">download</mat-icon>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='Popup'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="popupNotificationMessageTitle" class="label required">Message
                                                title</label>
                                            <input type="text" placeholder="Enter Message title"
                                                id="popupNotificationMessageTitle"
                                                formControlName="popupNotificationMessageTitle" class="input-style">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="popupNotificationPref" class="label required">Preference Count
                                            </label>
                                            <input type="number" placeholder="Enter Preference Count"
                                                id="popupNotificationPref" formControlName="popupNotificationPref"
                                                class="input-style">

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="popupNotificationMessage" class="label required">Message
                                            </label>
                                            <quill-editor class="content-editor" placeholder="Enter Message"
                                                name="popupNotificationMessage"
                                                formControlName="popupNotificationMessage">
                                            </quill-editor>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="image" class="label">Image(<span style="color: red;">Image size
                                                    should be less than 300kb</span>)</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="image">
                                                        <div class="proof">
                                                            <img *ngIf="defaultPopupNotificationImage!=null"
                                                                src="{{defaultPopupNotificationImage}}"
                                                                class="proofImage">
                                                            <label *ngIf="defaultPopupNotificationImage==null"
                                                                class="imageLabel"> Upload
                                                                <input (change)="displayPopupNotification($event)"
                                                                    type="file" accept="image/*">
                                                            </label>
                                                            <label *ngIf="defaultPopupNotificationImage!=null"
                                                                class="imageLabelEdit">
                                                                <mat-icon class="matIcon">edit</mat-icon>
                                                                <input (change)="displayPopupNotification($event)"
                                                                    type="file" accept="image/*">
                                                            </label>
                                                            <label
                                                                *ngIf="defaultPopupNotificationImage!=null  && defaultPopupNotificationImage.includes('https')"
                                                                class="imageLabelDownload"
                                                                (click)="downloadPopupNotificationImage(defaultPopupNotificationImage)">
                                                                <mat-icon class="matIcon">download</mat-icon>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding: 2rem;height: 60vh;" *ngIf="data['label']=='Email'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="emailMessageTitle" class="label required">Message
                                                Subject</label>
                                            <input type="text" placeholder="Enter Message title" id="emailMessageTitle"
                                                formControlName="emailMessageTitle" class="input-style">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="emailMessage" class="label required">Message Body</label>
                                            <quill-editor class="content-editor" placeholder="Enter Message"
                                                name="emailMessage" formControlName="emailMessage"></quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="image" class="label">Image(<span style="color: red;">Image size
                                                    should be less than 300kb</span>)</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="image">
                                                        <div class="proof">
                                                            <img *ngIf="defaultEmailImage!=null"
                                                                src="{{defaultEmailImage}}" class="proofImage">
                                                            <label *ngIf="defaultEmailImage==null" class="imageLabel">
                                                                Upload
                                                                <input (change)="displayEmail($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label *ngIf="defaultEmailImage!=null"
                                                                class="imageLabelEdit">
                                                                <mat-icon class="matIcon">edit</mat-icon>
                                                                <input (change)="displayEmail($event)" type="file"
                                                                    accept="image/*">
                                                            </label>
                                                            <label
                                                                *ngIf="defaultEmailImage!=null  && defaultEmailImage.includes('https')"
                                                                class="imageLabelDownload"
                                                                (click)="downloadEmailImage(defaultEmailImage)">
                                                                <mat-icon class="matIcon">download</mat-icon>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>
        </div>
    </div>
    <div *ngIf="selectedStepper=='Content'">
        <div class="row justify-content-end">
            <div class="col-md-12" style="text-align-last: right;">
                <button class="back-button" (click)="gotoStepper('Target users')">Back</button>

                <button *ngIf="!isSaveCampaignLoading && isWhatsAppMediumSelected()"
                    [ngClass]="{'save-campaign-disabled':addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid || selectedTemplate.length==0,'save-campaign-enabled':addCampaignDetail.valid && addTargetUserDetail.valid && addContentDetail.valid && selectedTemplate.length!=0}"
                    [disabled]="addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid || selectedTemplate.length==0"
                    (click)="addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid || selectedTemplate.length==0? null: saveCampaign()">Save
                    Campaign</button>
                <button *ngIf="!isSaveCampaignLoading && !isWhatsAppMediumSelected()"
                    [ngClass]="{'save-campaign-disabled':addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid ,'save-campaign-enabled':addCampaignDetail.valid && addTargetUserDetail.valid && addContentDetail.valid }"
                    [disabled]="addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid "
                    (click)="addCampaignDetail.invalid || addTargetUserDetail.invalid || addContentDetail.invalid ? null: saveCampaign()">Save
                    Campaign</button>

                <button *ngIf="isSaveCampaignLoading" class="get-count-button">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </button>
            </div>

        </div>
    </div>

</div>