import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-accept-reject-order-dialog',
  templateUrl: './accept-reject-order-dialog.component.html',
  styleUrls: ['./accept-reject-order-dialog.component.scss'],
})
export class AcceptRejectOrderDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  remark: any = '';
  selectedRoleControl = new FormControl('Select Role', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  approveEmployeeForm: FormGroup = new FormGroup({
    selectedRole: this.selectedRoleControl,
  });
  constructor(
    public dialogRef: MatDialogRef<AcceptRejectOrderDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.approveEmployeeForm = this.formBuilder.group({
      selectedRole: this.selectedRoleControl,
    });
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  acceptEmployee() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Accept Employee',
      value: this.data['employee'],
      moreVal: this.approveEmployeeForm.get('selectedRole')?.value,
    });
  }
  rejectEmployee() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Reject Employee',
      value: this.data['employee'],
      moreVal: this.remark,
    });
  }
}
