import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainVideoComponent } from './main-video/main-video.component';

const routes: Routes = [
  {
    path: '',
    component: MainVideoComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/video' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VideoManagementRoutingModule {}
