<div style="width: 30em;">
    <h1 mat-dialog-title style="font-weight: 500;">{{data['type']}} Report</h1>

    <div>
        <form name="generateReportForm" [formGroup]="generateReportForm">
            <label for="duration" class="label required">Select Duration</label>
            <select id="duration" name="duration" class="input-style" formControlName="duration" (change)="onDurationSelectionChange($event)">
                <option value="Select Duration" selected disabled>Select Duration</option>
                <option *ngFor="let duration of listOfDuration" [value]="duration['days']">
                    {{duration['label']}}</option>
            </select>



            <label for="type" class="label required" style="margin-top: 1em;">Start Date</label>
            <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate" [max]="maxStartDate" class="input-style" (input)="startDateChange($event)">

            <label for="type" class="label required">End Date</label>
            <input type="date" placeholder="End Date" id="endDate" [min]="minEndDate" formControlName="endDate" class="input-style" (input)="endDateChange($event)">

        </form>
    </div>
    <div mat-dialog-actions>
        <button class="cancel-btn" (click)="isLoading?null:close()">Cancel</button>

        <button *ngIf="isLoading" [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }" [disabled]="generateReportForm.invalid ">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>


        <button *ngIf="!isLoading" [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }" [disabled]="generateReportForm.invalid " (click)=" generateReport()">Save</button>

    </div>
</div>
