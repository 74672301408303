import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditCampaignComponent } from './add-edit-campaign/add-edit-campaign.component';
import { ListCampaignComponent } from './list-campaign/list-campaign.component';
import { ViewCampaignStatisticComponent } from './view-campaign-statistic/view-campaign-statistic.component';

const routes: Routes = [
  {
    path: '',
    component: ListCampaignComponent,
  },
  {
    path: 'add-edit-campaign',
    component: AddEditCampaignComponent,
  },
  {
    path: 'campaign-statistic',
    component: ViewCampaignStatisticComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/notification-1' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule { }
