import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';



@Component({
  selector: 'app-influencer-main-dashboard',
  templateUrl: './influencer-main-dashboard.component.html',
  styleUrls: ['./influencer-main-dashboard.component.scss']
})
export class InfluencerMainDashboardComponent implements OnInit {

  isLoading: boolean = false;
  userDetails: any;

  selectedDuration: string = 'Monthly';

  p: any = 1;
  count: any = 50;

  startDate: any;
  endDate: any;
  listOfProducts: any = [];
  influencerOtherDetails: any = [];
  influencerAmountDetails: any = [];
  subInfluencerOtherDetails: any = [];
  subInfluencerAmountDetails: any = [];

  listOfDuration: any = [
    'Today',
    'Yesterday',
    'Weekly',
    'Monthly',
    'Current Quarter',
    'Yearly',
  ];

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    this.fetchInfluencerDashboardDetails(null, null);
  }

  changeDuration(event: any) {
    this.selectedDuration = event.value;
    this.fetchInfluencerDashboardDetails(null, null);
  }

  async fetchInfluencerDashboardDetails(startDate: any, endDate: any) {
    this.isLoading = true;
    try {
      let URL = '';

      this.listOfProducts = [];
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchInfluencerDashboardDetails}`;
      const param = {
        duration: this.selectedDuration,
        startDateFilter: startDate,
        endDateFilter: endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isLoading = false;
            this.influencerOtherDetails = data['influencerOtherDetails'];
            this.influencerAmountDetails = data['influencerAmountDetails'];
            this.subInfluencerOtherDetails = data['subInfluencerOtherDetails'];
            this.subInfluencerAmountDetails = data['subInfluencerAmountDetails'];
            this.startDate = data['startDate'];
            this.endDate = data['endDate'];
            this.listOfProducts = data['users'];
            ('');
          } else {
            this.isLoading = false;
          }
        });
      this.isLoading = false;

    } catch (error) {
      this.isLoading = false;
    }
  }


  setDateNull() {
    this.startDate = null;
    this.endDate = null;
  }
  dateChanged(type: any, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.fetchInfluencerDashboardDetails(this.startDate, this.endDate);
    }
  }

  calculateDiff(startDate: Date, endDate: Date) {
    startDate = new Date(startDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
      (1000 * 60 * 60 * 24)
    );
  }



  downloadReferralReport() {
    var toExport: any = [];
    var index: any = 1;
    this.listOfProducts.forEach((user: any) => {
      if (user['referredTo'].length > 0) {
        toExport.push({
          SrNo: index,
          Name: user['referredBy']['fullName'],
          Email: user['referredBy']['email'],
          PhoneNumber: user['referredBy']['phone'],
          ReferralCount: user['count'],
          TotalAmount: '\u20b9' + user['totalAmount'],
          AppInstallation: new Date(user['referredBy']['createdAt'])
            .toISOString()
            .slice(0, 10),
          ReferredTo_Name: user['referredTo'][0]['user']['fullName'],
          ReferredTo_Email: user['referredTo'][0]['user']['email'],
          ReferredTo_Phone: user['referredTo'][0]['user']['phone'],
        });
        if (user['referredTo'].length > 1) {
          for (let index = 1; index < user['referredTo'].length; index++) {
            var userData: any = {};
            userData = {
              Name: '',
              Email: '',
              PhoneNumber: '',
              ReferralCount: '',
              TotalAmount: '',
              AppInstallation: '',
              ReferredTo_Name: user['referredTo'][index]['user']['fullName'],
              ReferredTo_Email: user['referredTo'][index]['user']['email'],
              ReferredTo_Phone: user['referredTo'][index]['user']['phone'],
              ReferredTo_AppInstallation: new Date(
                user['referredTo'][index]['user']['createdAt']
              )
                .toISOString()
                .slice(0, 10),
            };
            toExport.push(userData);
          }
        }
      } else {
        toExport.push({
          SrNo: index,
          Name: user['referredBy']['fullName'],
          Email: user['referredBy']['email'],
          PhoneNumber: user['referredBy']['phone'],
          ReferralCount: user['count'],
          TotalAmount: '\u20b9' + user['totalAmount'],
          AppInstallation: new Date(user['referredBy']['createdAt'])
            .toISOString()
            .slice(0, 10),
          ReferredTo_Name: '-',
          ReferredTo_Email: '-',
          ReferredTo_Phone: '-',
        });
      }
      index = index + 1;
    });
    // this.excelExport.exportAsExcelFile(toExport, 'Report Summary');
  }
  gotoManageProduct() {
    this.router.navigate(['/admin/referral/manage-product']);
  }

   getProductTitle(product: any) {
    if (product['productType'] == 'BOOK') {
      return product['book']['title'];
    }
    else if (product['productType'] == 'SUBSCRIPTION') {
      return `${product['plan']['duration']} Months Subscription`
    }
    else if (product['productType'] == 'COLLECTION') {
      return product['bookCollection']['name'];
    } else {
      return 'N.A';
    }
  }

  getCount(type: string, details: any) {
    var index = details.findIndex((data: any) => data._id == type);
    if (index == -1) {
      return 0;
    } else {
      return details[index]['count'];
    }
  }

  getAmount(type: string, details: any) {
    var index = details.findIndex((data: any) => data._id == type);
    if (index == -1) {
      return 0;
    } else {
      return details[index]['totalAmount'].toFixed(1);
    }
  }

}
