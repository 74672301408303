import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OfferManagementComponent } from './offer-management.component';
import { ListOfferManagementComponent } from './list-offer-management/list-offer-management.component';

const routes: Routes = [
  {
    path: '',
    component: ListOfferManagementComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/offer' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfferManagementRoutingModule { }
