import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-management',
  templateUrl: './game-management.component.html',
  styleUrls: ['./game-management.component.scss']
})
export class GameManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
