<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="!isLoading" class="container-fluid main-content" id="container-wrapper">
    <div class="row mb-4 mt-2">

        <div class="col-md-12 heading-text" style="justify-content: space-between;display: flex;align-items: baseline;">
            <div class="dashboard-div" style="font-size:1.4rem">
                Referral Dashboard
            </div>
            <div class="display-flex align-items-center">

                <div style="margin-right: 2rem;display: inline;">

                    <mat-form-field appearance="outline">
                        <mat-label>Pick a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [value]="startDate"
                                (dateChange)="dateChanged( 'start',$event)">
                            <input matEndDate placeholder="End date" [value]="endDate"
                                (dateChange)="dateChanged( 'end',$event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>


                <div class="manage-prd-btn" (click)="gotoManageProduct()">
                    Manage Product
                </div>
            </div>

        </div>
    </div>

    <div class="row mb-3">

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Subscription Count/Total
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{getCount('SUBSCRIPTION')}} /
                                {{'\u20b9'+getAmount('SUBSCRIPTION')}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/user.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Book Count/Total
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{getCount('BOOK')}} /
                                {{'\u20b9'+getAmount('BOOK')}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/user.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Collection Count/Total
                            </div>
                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{getCount('COLLECTION')}} /
                                {{'\u20b9'+getAmount('COLLECTION')}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/user.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total Income Generated
                            </div>
                            <div *ngIf="amountDetails.length>0" class="h5 mb-0 font-weight-bold text-gray-800"
                                style="font-size: 1.5rem;">
                                {{'\u20b9'+amountDetails[0]['totalAmount']}}
                            </div>
                            <div *ngIf="amountDetails.length==0" class="h5 mb-0 font-weight-bold text-gray-800"
                                style="font-size: 1.5rem;">
                                {{'\u20b90'}}
                            </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/money.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="dashboard-div" style="font-size:1.2rem;font-weight: bold;">
        Influencers ({{listOfInfluencer.length}})
    </div>
    <div *ngIf="listOfInfluencer.length==0 && !isLoading">
        <div class="no-available">
            No Influencer found
        </div>
    </div>
    <div *ngIf="listOfInfluencer.length>0 && !isLoading">
        <table class="table-style">
            <thead class="table-heading">
                <th class="table-heading-content">Name</th>
                <th class="table-heading-content">Email</th>
                <th class="table-heading-content">Phone</th>
                <th class="table-heading-content">Commission</th>
                <th class="table-heading-content">Referral Count</th>
                <th class="table-heading-content">Amount Generated</th>
                <th class="table-heading-content">View Details</th>
                <th class="table-heading-content">Created Date</th>
            </thead>
            <div style="height: 0.6em;"> </div>
            <tbody>
                <tr class="table-data"
                    *ngFor="let influencer of listOfInfluencer | paginate: { itemsPerPage: count, currentPage: p }">

                    <th class="table-data-content">{{influencer.influencer['firstName']}}
                        {{influencer.influencer['lastName']}}
                    </th>
                    <th class="table-data-content">{{influencer.influencer['email']}}</th>
                    <th class="table-data-content">{{influencer.influencer['phone']}}</th>
                    <th class="table-data-content">{{influencer['count']}}</th>
                    <th class="table-data-content">{{'\u20b9'+influencer['commission']}}</th>
                    <th class="table-data-content">{{'\u20b9'+influencer['totalAmount']}}</th>
                    <th class="table-data-content" style=" text-decoration: underline;color: blue;cursor: pointer;"
                        (click)="gotoInfluencerDetail(influencer.influencer)">
                        View Details
                    </th>
                    <th class="table-data-content">{{influencer['influencer']['createdAt'] |date}}</th>

                </tr>
            </tbody>
        </table>
        <div class="text-right" style="font-size: medium;margin: 1em;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
        <div class="space-10">

        </div>
    </div>

</div>