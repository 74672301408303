import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ChangeContestStatusAndDeleteComponent } from '../change-contest-status-and-delete/change-contest-status-and-delete.component';
import { PlayVideoComponent } from '../play-video/play-video.component';

@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: ['./view-videos.component.scss']
})
export class ViewVideosComponent implements OnInit {
  contestId: any;
  contestType: any;
  randomWinner: any;
  userDetails: any;
  isFileDownload: boolean = false;
  isLoading: boolean = true;

  listOfContestVideo: any = [];
  listOfContestVideoToDisplay: any = [];

  p: any = 1;
  count: any = 50;

  isListView: boolean = true;

  constructor(
    private appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private _ActivatedRoute: ActivatedRoute,
    private router: Router,

  ) {
    this.contestId = this._ActivatedRoute.snapshot.paramMap.get('id');
    this.contestType = this._ActivatedRoute.snapshot.paramMap.get('contestType');
    this.randomWinner = this._ActivatedRoute.snapshot.paramMap.get('randomWinner');
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }

  ngOnInit(): void {
    this.fetchParticularContestAllVideo();
  }
  async fetchParticularContestAllVideo() {
    try {


      this.isLoading = true;
      this.listOfContestVideo = [];
      let param = { contestId: this.contestId };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.fetchParticularContestAllVideo}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfContestVideo = data['result'];
            this.listOfContestVideoToDisplay = this.listOfContestVideo;
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfContestVideoToDisplay = [];

      this.listOfContestVideo.forEach((employee: any, index: number) => {
        if (
          String(employee['winnerAnnouncerName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['babyName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['parentName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee['description'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
          // ||
          // String(employee['winner'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) 
        ) {
          tempSearchList.push(employee);
        }
      });
      this.listOfContestVideoToDisplay = [];
      this.listOfContestVideoToDisplay = tempSearchList;
    } else {
      this.listOfContestVideoToDisplay = this.listOfContestVideo;
    }
  }
  changeView() {
    this.isListView = !this.isListView;
  }
  async downloadFile() {
    try {
      this.isFileDownload = true;
      const param = {
        contestId: this.contestId
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.downloadParticipantList}`;
      await this.appService
        .deleteData(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            if (data['result'] != null && data['result'] != undefined) {
              window.open(data['result']);
              this.showSuccessToaster(
                'Participant List Downloaded Successfully',
                'Success'
              );
            }
            this.isFileDownload = false;
          } else {
            this.isFileDownload = false;
            this.showWarningToaster(
              'Error Occurred while downloading pariticipant list. Try again',
              'Error'
            );
          }
        });
    } catch (error) {
      this.isFileDownload = false;
    }
  }
  viewVideo(data: any) {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = { video: data };

    // let dialogRef = this.matDialog.open(
    //   PlayVideoComponent,
    //   dialogConfig
    // );
    // dialogRef.updatePosition(dialogConfig.position);
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result != undefined) {
    //   }
    // });
    // dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
    //   if (res == null) {
    //     return;
    //   }
    // });


    window.open(data.thumbnail, '_blank');
  }
  deleteContestVideo(video: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Delete Video' };

    let dialogRef = this.matDialog.open(
      ChangeContestStatusAndDeleteComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Video') {
          const param = {
            pariticipantId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteParticipant}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfContestVideo.forEach((contest: any, index: number) => {
                  if (contest['_id'] == res['value']['_id']) {
                    this.listOfContestVideo.splice(index, 1);
                  }
                });
                this.listOfContestVideoToDisplay = this.listOfContestVideo;

                this.showSuccessToaster(
                  'Participant Delete Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while deleteing pariticipant. Try again',
                  'Error'
                );
              }
            });
        } else {
        }
      }
    });
  }

  setRandomWinner(winner: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { winner: winner, source: 'Random Winner' };

    let dialogRef = this.matDialog.open(
      ChangeContestStatusAndDeleteComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Random Winner') {
          var rank = 0;

          this.listOfContestVideoToDisplay.forEach((data: any, index: number) => {
            if (data['_id'] == winner._id) {
              rank = index + 1;
            }
          });
          const param = {
            winner: res['value']['_id'],
            contestId: this.contestId,
            randomWinnerRank: rank,
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.setRandomWinner}`;
          await this.appService
            .updateDataViaPut(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.randomWinner = res['value']['_id'];

                this.showSuccessToaster(
                  'Winner Selected Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while selecting winner. Try again',
                  'Error'
                );
              }
            });
        } else {
        }
      }
    });
  }

  publishUnpublishContestVideo(video: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'PublishUnPublish Video' };

    let dialogRef = this.matDialog.open(
      ChangeContestStatusAndDeleteComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Update Video Status') {
          const param = {
            pariticipantId: res['value']['_id'],
            status: res['status']
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.publishUnpublishContestParticipant}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfContestVideo.forEach((contest: any, index: number) => {
                  if (contest['_id'] == res['value']['_id']) {
                    this.listOfContestVideo[index]['isActive'] = res['status'];
                  }
                });
                this.listOfContestVideoToDisplay = this.listOfContestVideo;

                this.showSuccessToaster(
                  'Particiapant Status Updated Successfully',
                  'Success'
                );
              } else {
                this.showWarningToaster(
                  'Error Occurred while updating participant status. Try again',
                  'Error'
                );
              }
            });
        } else {
        }
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
