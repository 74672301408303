<div>
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div *ngIf="data['source']=='Delete Image'" style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure
        you want to delete this Image?</div>
    <div *ngIf="data['source']=='Hide Image'" style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you
        want to {{data['image']['isActive']?'unpublish':'publish'}} this Image?</div>
    <div *ngIf="data['source']=='Delete Category'" style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you
        want to delete this Category?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button *ngIf="data['source']=='Delete Image'" class="yes-btn" mat-button mat-dialog-close
            (click)="deleteImage()">Yes</button>
        <button *ngIf="data['source']=='Hide Image'" class="yes-btn" mat-button mat-dialog-close
            (click)="hideImage()">Yes</button>
        <button  *ngIf="data['source']=='Delete Category'" class="yes-btn" mat-button mat-dialog-close
            (click)="deleteCategory()">Yes</button>
    </div>
</div>