import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnInit {
  conversation: any;
  isOtherChatComponentCalled: boolean = false;

  constructor(public as: AppService) {}

  ngOnInit(): void {}
  onConversationSelected(conversation: any) {
    this.as.isOtherChatComponentCalled = true;
    this.conversation = conversation;
    // this.isOtherChatComponentCalled = true;
  }
}
