<div style="height: 100vh;">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-employee-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-employee-sidebar>
            </ng-sidebar>
            <div ng-sidebar-content class="sidebar-content">

                <!-- Top Data -->
                <div class="row align-center" style="width: 100%;">
                    <div class="top-heading col-md-3">
                        Employee List
                    </div>
                    <div class="col-md-9">
                        <div class="row align-center">
                            <div class="col-md-4" style="text-align: right;display: flex;align-items: center;">
                                Select role: <select style="width: 60%;" class="input-field" name="region" id="region"
                                    aria-placeholder="Select Role" (change)="onEmployeeRoleSelectionChange($event)">
                                    <option value="Select Role" selected disabled>Select Role</option>
                                    <option value="All" [selected]="selectedRole=='All'">All</option>
                                    <option value="Unassigned">Unassigned</option>
                                    <option value="Author">Author</option>
                                    <option value="Content Publisher">Content Publisher</option>
                                </select>
                            </div>
                            <div class="col-md-5" style="text-align: right;">
                                <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                    placeholder="Search Here" (input)="searchData($event.target)">
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                <button class="add-new-emp" (click)="addNewEmployee()">
                                    Add New Employee
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table Data -->
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="listOfEmployeeToDisplay.length==0">
                    <div class="no-available">
                        No employee detail available.
                    </div>
                </div>
                <div *ngIf="listOfEmployeeToDisplay.length!=0">
                    <table class="table-style">
                        <thead class="table-heading">
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="id" scope="col" class="table-heading-content">ID</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="firstName" scope="col" class="table-heading-content">NAME</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="phone" scope="col" class="table-heading-content">PHONE</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="email" scope="col" class="table-heading-content">EMAIL</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="role" scope="col" class="table-heading-content">ROLE</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="department" scope="col" class="table-heading-content">DEPARTMENT</th>
                            <th style="cursor: pointer;" [appSort]="listOfEmployeeToDisplay" data-order="desc"
                                data-name="status" scope="col" class="table-heading-content">STATUS</th>
                            <th class="table-heading-content">ACTION</th>
                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let employee of listOfEmployeeToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                                <th class="table-data-content">{{employee.id}}</th>
                                <th class="table-data-content">{{employee.firstName+' '+employee.lastName}}</th>
                                <th class="table-data-content">{{employee.phone}}</th>
                                <th class="table-data-content">{{employee.email}}</th>
                                <th class="table-data-content">{{employee.role}}</th>
                                <th class="table-data-content">
                                    {{employee.department}}
                                </th>
                                <th class="table-data-content">{{employee.status}}</th>
                                <th class="table-data-content ">
                                    <div style="display: contents;">
                                        <img title="Edit" (click)="editEmployee(employee)"
                                            style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>
                                        <img title="Delete" (click)="deleteEmployee(employee)"
                                            style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'>
                                    </div>

                                </th>
                            </tr>

                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div class="space-10"></div>

                </div>
            </div>
        </ng-sidebar-container>
    
</div>