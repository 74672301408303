import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxListReportComponent } from './box-list-report/box-list-report.component';
import { Routes, RouterModule } from '@angular/router';
import { FileListComponent } from './file-list/file-list.component';

const routes: Routes = [
  {
    path: '',
    component: BoxListReportComponent,
  },
  {
    path: 'registered-user',
    component: FileListComponent,
  },
  {
    path: 'last-active-user',
    component: FileListComponent,
  },
  {
    path: 'subscription',
    component: FileListComponent,
  },
  {
    path: 'transaction',
    component: FileListComponent,
  },
  {
    path: 'book',
    component: FileListComponent,
  },
 
  

  { path: '', pathMatch: 'full', redirectTo: 'admin/report' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminReportRoutingModule {}
