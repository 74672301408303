import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogContentComponent } from '../../content-management/active-delete-dialog-content/active-delete-dialog-content.component';

export interface Video {
  _id: string;
  videoName: string;
  videoLink: string;
  isActive: boolean;
  createdAt: Date;
}

@Component({
  selector: 'app-main-video',
  templateUrl: './main-video.component.html',
  styleUrls: ['./main-video.component.scss'],
})
export class MainVideoComponent implements OnInit {
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = false;
  listOfVideo: Array<Video> = [];
  listOfVideoToDisplay: Array<Video> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    this.fetchVideos();
  }

  searchData(event: any) {}

  async fetchVideos() {
    this.isLoading = true;
    this.listOfVideo = [];
    let param = {
      admin: true,
      videoId: 0,
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchVideo}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((emp: any) => {
            this.listOfVideo.push({
              _id: emp['_id'],
              videoLink: emp['videoLink'],
              videoName: emp['videoName'],
              isActive: emp['isActive'],
              createdAt: emp['createdAt'],
            });
          });
          this.listOfVideoToDisplay = this.listOfVideo;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
  }

  addNewVideo() {
    this.toggleOpened();
  }

  deleteVideo(video: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Delete Video' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusVideo
        }`;
        await this.appService
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfVideoToDisplay.forEach((video: any, i: number) => {
                if (video['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfVideoToDisplay.splice(index, 1);
              this.listOfVideo = [...this.listOfVideoToDisplay];

              this.showSuccessToaster('Video Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting video. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  updateActiveStatus(video: Video, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Active Video' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusVideo
        }`;
        await this.appService
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfVideoToDisplay.forEach(
                (content: any, index: number) => {
                  if (content['_id'] == res['value']['_id']) {
                    content.isActive = isActive;
                  } else {
                    content.isActive = false;
                  }
                }
              );
              this.listOfVideo = [...this.listOfVideoToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Video Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Video Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating Video. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  editVideo(video: any) {
    this.dataToEdit = video;
    this.toggleOpened();
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showWarningToaster(
            'Warning while editing video details.Kindly try again',
            'Warning'
          );
        } else {
          this.showWarningToaster(
            'Warning while adding video details.Kindly try again',
            'Warning'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfVideoToDisplay.findIndex(
          (video: any) => video['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfVideoToDisplay.push(event);
          this.listOfVideo = [...this.listOfVideoToDisplay];
          this.showSuccessToaster('Video added successfully', 'Success');
        } else {
          this.listOfVideoToDisplay[index] = event;
          this.listOfVideo = [...this.listOfVideoToDisplay];
          this.showSuccessToaster('Video Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
