import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-sub-influencer',
  templateUrl: './add-edit-sub-influencer.component.html',
  styleUrls: ['./add-edit-sub-influencer.component.scss']
})
export class AddEditSubInfluencerComponent implements OnInit {

  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  phoneControl = new FormControl('', [Validators.required]);

  addInfluencerForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
  });

  userDetails: any;
  constructor(
    public as: AppService,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService

  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.addInfluencerForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
    });
    if (this.dataToEdit != null) {
      this.addInfluencerForm.get('firstName')?.setValue(this.dataToEdit['firstName']);
      this.addInfluencerForm.get('email')?.setValue(this.dataToEdit['email']);
      this.addInfluencerForm.get('lastName')?.setValue(this.dataToEdit['lastName']);
      this.addInfluencerForm.get('phone')?.setValue(this.dataToEdit['phone']);
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditUser() {
    this.isLoading = true;

    var myHeaders = new Headers();
    var formdata = new FormData();

    if (this.dataToEdit != null) {
      formdata.set('influencerId', this.dataToEdit['_id']);
    } else {
      formdata.set('influencerId', '0');
    }

    formdata.set('firstName', this.addInfluencerForm.get('firstName')?.value);
    formdata.set('lastName', this.addInfluencerForm.get('lastName')?.value);
    formdata.set('email', this.addInfluencerForm.get('email')?.value);
    formdata.set('phone', this.addInfluencerForm.get('phone')?.value);
    formdata.set('role', 'SUBINFLUENCER');

    myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: this.dataToEdit == null ? 'POST' : 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    var URL;

    if (this.dataToEdit == null) {
      URL = `${webApi.domain + webApi.endPoint.createInfluencer}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.updateInfluencer}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addInfluencerForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.showErrorToaster('Phone number is already in use', 'Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorToaster('Phone number is already in use', 'Error');
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
