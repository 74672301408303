export const webApi = {
  // domain: 'http://localhost:6010',
  // domain: 'http://43.205.30.95:6010', // Development
  domain: 'https://api.merisaheli.com', //Production

  endPoint: {
    // Report APIs he
    generateUserRegistrationReport:
      '/api/report/generateUserRegistrationReport',
    generateUserLastActiveReport: '/api/report/generateUserLastActiveReport',
    generateUserSubscriptionReport:
      '/api/report/generateUserSubscriptionReport',
    generateUserTransactionReport: '/api/report/generateUserTransactionReport',
    generateUserBookReport: '/api/report/generateUserBookReport',
    fetchReportDetail: '/api/report/fetchReportDetail',

    // Admin Auth
    registerAdmin: '/api/adminAuth/registerAdmin',
    verifyAdminUser: '/api/adminAuth/verifyAdminUser',
    getAdminDetails: '/api/adminAuth/getAdminDetails',

    // User APIs
    fetchAllUser: '/api/user/fetchAllUser',
    deleteUser: '/api/user/deleteUser',
    suspendUser: '/api/user/suspendUser',
    register: '/api/user/register',
    editUser: '/api/user/editUser',
    searchUserDetail: '/api/user/searchUserDetail',
    fetchUserBooks: '/api/book/fetchUserBooks',

    // Employee API

    checkForEmployeeExistance: '/api/employee/checkForEmployeeExistance',
    isEmployeeExist: '/api/employee/isEmployeeExist',
    registerEmployee: '/api/employee/registerEmployee',
    fetchAllEmployees: '/api/employee/fetchAllEmployees',
    fetchRoleWiseEmployees: '/api/employee/fetchRoleWiseEmployees',

    //OTP
    sendOTPtoUser: '/api/employee/sendOTPtoUser',
    verifyOTPofUser: '/api/employee/verifyOTPofUser',

    updateEmployeeStatus: '/api/employee/updateEmployeeStatus',
    deleteEmployees: '/api/employee/deleteEmployees',

    // Mehndi Design Category

    createAndUpdateDesignCategory:
      '/api/designCategory/createAndUpdateDesignCategory',
    fetchDesignCategory: '/api/designCategory/fetchDesignCategory',
    deleteDesignCategory: '/api/designCategory/deleteDesignCategory',

    // Mehndi Design Image

    bulkUploadImage: '/api/design/bulkUploadImage',
    createAndUpdateDesign: '/api/design/createAndUpdateDesign',
    fetchDesigns: '/api/design/fetchDesigns',
    hideDesignImage: '/api/design/hideDesignImage',
    deleteDesignImage: '/api/design/deleteDesignImage',
    fetchDesignByCategory: '/api/design/fetchDesignByCategory',

    //Brand API
    fetchBrands: '/api/brand/fetchBrands',
    createAndUpdateBrand: '/api/brand/createAndUpdateBrand',
    updateActiveOrDeleteStatusBrand:
      '/api/brand/updateActiveOrDeleteStatusBrand',

    //Coupons
    createOrUpdateCoupon: '/api/coupon/createOrUpdateCoupon',
    fetchCouponsForAdmin: '/api/coupon/fetchCouponsForAdmin',
    updateActiveOrDeleteCouponStatus: "/api/coupon/updateActiveOrDeleteCouponStatus",

    //Voucher API
    fetchVouchers: '/api/voucher/fetchVouchers',
    updateActiveOrDeleteStatusVoucher:
      '/api/voucher/updateActiveOrDeleteStatusVoucher',
    createAndUpdateVoucher: '/api/voucher/createAndUpdateVoucher',

    // General Info APIs
    createInfo: '/api/appGeneralInfo/createInfo',
    updateInfo: '/api/appGeneralInfo/updateInfo',
    fetchInfo: '/api/appGeneralInfo/fetchInfo',
    fetchInfoWithoutAccessToken: "/api/appGeneralInfo/fetchInfoWithoutAccessToken",

    //Magazine Management
    createAndUpdateMagazine: '/api/magazine/createAndUpdateMagazine',
    updateActiveOrDeleteStatusMagazine:
      '/api/magazine/updateActiveOrDeleteStatusMagazine',
    fetchMagazine: '/api/magazine/fetchMagazine',

    //Content API
    fetchContentByMagazineId: '/api/content/fetchContentByMagazineId',
    updateActiveOrDeleteStatusContent:
      '/api/content/updateActiveOrDeleteStatusContent',
    createAndUpdateContent: '/api/content/createAndUpdateContent',
    fetchContent: '/api/content/fetchContent',
    fetchAllContentForAdmin: '/api/content/fetchAllContentForAdmin',

    //Category API
    fetchCategory: '/api/category/fetchCategory',

    //Notification API
    sendNotification: '/api/notification/sendNotification',

    //Banner API
    createAndUpdateBanner: '/api/banner/createAndUpdateBanner',
    updateActiveOrDeleteStatusBanner:
      '/api/banner/updateActiveOrDeleteStatusBanner',
    fetchBanner: '/api/banner/fetchBanner',

    //Plan API
    createAndUpdatePlan: '/api/plan/createAndUpdatePlan',
    updateActiveOrDeleteStatusPlan: '/api/plan/updateActiveOrDeleteStatusPlan',
    fetchPlans: '/api/plan/fetchPlans',

    //Video API
    createAndUpdateVideo: '/api/video/createAndUpdateVideo',
    updateActiveOrDeleteStatusVideo:
      '/api/video/updateActiveOrDeleteStatusVideo',
    fetchVideo: '/api/video/fetchVideo',

    //APP CONFIG
    fetchAppConfig: '/api/appConfig/fetchAppConfig',
    fetchAllRoutes: '/api/appConfig/fetchAllRoutes',

    //Campaign API
    fetchCampaigns: '/api/campaign/fetchCampaigns',
    fetchMessageDetailByCampaignId: '/api/campaign/fetchMessageDetailByCampaignId',
    createCampaign: '/api/campaign/createCampaign',

    //Campaign-New API
    createAndUpdateCampaign: '/api/campaignNew/createAndUpdateCampaign',
    changeCampaignStatus: '/api/campaignNew/changeCampaignStatus',
    fetchCampaign: '/api/campaignNew/fetchCampaign',
    getTargetCount: '/api/campaignNew/getTargetCount',
    fetchAllDesignForAdmin: '/api/campaignNew/fetchAllDesignForAdmin',
    fetchCampaignDeliveryReport: '/api/campaignNew/fetchCampaignDeliveryReport',

    //Transaction API
    fetchUserTransactions: '/api/transaction/fetchUserTransactions',

    //Dashboard API
    fetchDashboardDetails: '/api/report/fetchDashboardDetails',

    //QUIZ API
    addEditQuizCategory: '/api/quiz/addEditQuizCategory',
    fetchAllCategory: '/api/quiz/fetchAllCategory',
    updateQuizCategoryStatus: '/api/quiz/updateQuizCategoryStatus',
    deleteQuizCategory: '/api/quiz/deleteQuizCategory',
    fetchQuizForAdmin: '/api/quiz/fetchQuizForAdmin',
    fetchQuizForAdminCategoryWise: '/api/quiz/fetchQuizForAdminCategoryWise',
    deleteQuiz: '/api/quiz/deleteQuiz',
    deleteQuizContent: '/api/quiz/deleteQuizContent',
    updateQuizStatus: '/api/quiz/updateQuizStatus',
    insertQuiz: '/api/quiz/insertQuiz',
    updateQuiz: '/api/quiz/updateQuiz',
    updateOnlyMetaDataOfQuiz: '/api/quiz/updateOnlyMetaDataOfQuiz',
    fetchAllQuizzes: '/api/quiz/fetchAllQuizzes',

    //QUIZ AUTHOR
    createAndUpdateQuizAuthor: '/api/quiz/createAndUpdateQuizAuthor',
    updateActiveOrDeleteStatusAuthor:
      '/api/quiz/updateActiveOrDeleteStatusAuthor',
    fetchAllQuizAuthor: '/api/quiz/fetchAllQuizAuthor',

    //QUIZ CONTEST
    generateQuizContest: '/api/quiz/generateQuizContest',
    fetchQuizContest: '/api/quiz/fetchQuizContest',

    //GAME
    createAndUpdateGame: '/api/game/createAndUpdateGame',
    updateActiveOrDeleteStatusGame: '/api/game/updateActiveOrDeleteStatusGame',
    fetchAllGame: '/api/game/fetchAllGame',

    //GAME CATEGORY
    createAndUpdateGameCategory:
      '/api/gameCategory/createAndUpdateGameCategory',
    updateActiveOrDeleteStatusGameCategory:
      '/api/gameCategory/updateActiveOrDeleteStatusGameCategory',
    fetchAllGameCategory: '/api/gameCategory/fetchAllGameCategory',

    // BuildBy Chat API URLs
    sendMessage: '/api/buildbyChat/sendMessage',

    // Offer API URLs
    addEditOffer: '/api/offer/addEditOffer',
    fetchAllOffer: '/api/offer/fetchAllOffer',
    deleteOffer: '/api/offer/deleteOffer',
    publishUnpublishOffer: '/api/offer/publishUnpublishOffer',

    //BOOK COLLECTION
    createAndUpdateBookCollection:
      '/api/bookCollection/createAndUpdateBookCollection',
    updateActiveOrDeleteStatusBookCollection:
      '/api/bookCollection/updateActiveOrDeleteStatusBookCollection',
    fetchAllBookCollection: '/api/bookCollection/fetchAllBookCollection',
    fetchBookContent: '/api/bookCollection/fetchBookContent',
    createDynamicLinksForCollection:
      '/api/bookCollection/createDynamicLinksForCollection',


    //INFLUENCER
    createInfluencer: "/api/influencer/createInfluencer",
    updateInfluencer: "/api/influencer/updateInfluencer",
    updateActiveOrDeleteStatuInfluencer: "/api/influencer/updateActiveOrDeleteStatuInfluencer",
    fetchInfluencers: "/api/influencer/fetchInfluencers",
    fetchReferredUser: "/api/influencer/fetchReferredUser",
    fetchSubInfluencer: "/api/influencer/fetchSubInfluencer",
    isInfluencerExist: '/api/influencer/isInfluencerExist',
    fetchInfluencerDashboardDetails: '/api/influencer/fetchInfluencerDashboardDetails',

    // Referral Product
    addEditReferralProduct: '/api/referralProduct/addEditReferralProduct',
    fetchReferralProduct: '/api/referralProduct/fetchReferralProduct',
    fetchMasterProduct: '/api/referralProduct/fetchMasterProduct',
    changeReferralProductStatus: '/api/referralProduct/changeReferralProductStatus',
    deleteReferralProduct: '/api/referralProduct/deleteReferralProduct',
    createDynamicLinkForInfluencer: '/api/influencer/createDynamicLinkForInfluencer',
    fetchReferralDashboardDetails: '/api/influencer/fetchReferralDashboardDetails',

    //UTM
    createUTM: "/api/utm/createUTM",
    createDynamicLinksForUTM: "/api/utm/createDynamicLinksForUTM",
    fetchUtmByRoute: "/api/utm/fetchUtmByRoute",

    // Transaction Log
    fetchTransactionLog: '/api/transaction/fetchTransactionLog',
    updateUTMSourceOfTransaction: '/api/transaction/updateUTMSourceOfTransaction',
    generateTransactionLogReport: '/api/transaction/generateTransactionLogReport',

    // Coupon SUmmary
    fetchCouponSummary: '/api/coupon/fetchCouponSummary',

    //  Contest Video
    deleteParticipant: '/api/contestParticipant/deleteParticipant',
    publishUnpublishContestParticipant: '/api/contestParticipant/publishUnpublishContestParticipant',

    //  Contest
    addEditContest: '/api/contest/addEditContest',
    fetchAllContest: '/api/contest/fetchAllContest',
    publishUnpublishContest: '/api/contest/publishUnpublishContest',
    deleteContest: '/api/contest/deleteContest',
    fetchParticularContestAllVideo: '/api/contest/fetchParticularContestAllVideo',
    downloadParticipantList: '/api/contest/downloadParticipantList',
    updateContestStatus: '/api/contest/updateContestStatus',
    setRandomWinner: '/api/contest/setRandomWinner',
    updateContestWinnerVideo: '/api/contest/updateContestWinnerVideo',
    createDynamicLinksForContest: '/api/contest/createDynamicLinksForContest',
    fetchContestByCategory: '/api/contest/fetchContestByCategory',

    //Contest Category
    createAndUpdateContestCategory: '/api/contest/createAndUpdateContestCategory',
    activeOrDeleteContestCategory: '/api/contest/activeOrDeleteContestCategory',
    fetchContestCategories: '/api/contest/fetchContestCategories',
  },
};

export const WA_API = {
  // domain: 'http://localhost:8040', //DEV
  domain: 'https://wawebhook.buildby.me', //PROD

  endPoint: {
    //TEMPLATES
    fetchAllTemplates: '/api/template/fetchAllTemplates',
    syncAllTemplates: '/api/template/syncAllTemplates',

    //CHATS
    fetchAllChats: '/api/chats/fetchAllChats',
    searchChat: '/api/chats/searchChat',

    //MESSAGES
    fetchMessagesByChatId: '/api/message/fetchMessagesByChatId',
    fetchMessageDetailByCampaignId:
      '/api/message/fetchMessageDetailByCampaignId',
    fetchActiveUserCountByClientId:
      '/api/message/fetchActiveUserCountByClientId',

    //Client
    getClientDetail: '/api/clientNumber/getClientNumberDetail',

    //Transaction
    createOrder: '/api/transaction/createOrder',
    rechargeClient: '/api/transaction/rechargeClient',
    updateStatusOfClientPayment: '/api/transaction/updateStatusOfClientPayment',
  },
};
