import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  windowRef: any;
  userDetails: any;
  isLoading: boolean = true;
  isAddingCategoryLoading: boolean = false;
  category: any;
  isValidCategory: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    public appService: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.windowRef = this.as.windowRef;
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  ngOnInit(): void {
    this.isLoading = false;
    if (this.data['category'] != null) {
      this.category = this.data['category']['name'];
    }
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  checkForValidCategoryName(target: any) {
    let enteredText = target.value;

  }
  async addCategory() {
    if (!this.isAddingCategoryLoading) {
      this.isAddingCategoryLoading = true;

      const param = {
        designType: this.data['designType'],
        name: this.category,
        categoryId: this.data['category'] != null ? this.data['category']['_id'] : 0,
      };
      try {
        const URL = `${webApi.domain + webApi.endPoint.createAndUpdateDesignCategory}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.appService
          .deleteData(param, URL, httpHeaders)
          .then(async (data: any) => {
            if (data['success']) {
              this.showSuccessToaster(
                'Category Added successfully.',
                'Success'
              );

              this.isAddingCategoryLoading = false;
              this.dialogRef.close();
              this.closeActivityAtDialog.emit({ category: this.category });
            } else {
              this.isAddingCategoryLoading = false;
              this.showWarningToaster(
                'Some Error occured while adding category.',
                'Warning'
              );
            }
          });
      } catch (error) {
        this.isAddingCategoryLoading = false;
        this.showWarningToaster(
          'Some Error occured while adding category.',
          'Warning'
        );
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }

}
