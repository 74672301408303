import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-active-delete-dialog',
  templateUrl: './active-delete-dialog.component.html',
  styleUrls: ['./active-delete-dialog.component.scss'],
})
export class ActiveDeleteDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ActiveDeleteDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteGame() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Game',
      value: this.data['game'],
    });
  }
  activeGame() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Game',
      value: this.data['game'],
    });
  }

  deleteGameCategory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete GameCategory',
      value: this.data['game'],
    });
  }
  activeGameCategory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active GameCategory',
      value: this.data['game'],
    });
  }

  deleteCollection() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Collection',
      value: this.data['collection'],
    });
  }
  activeCollection() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Collection',
      value: this.data['collection'],
    });
  }

  deleteContestCategory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete ContestCategory',
      value: this.data['category'],
    });
  }
  activeContestCategory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active ContestCategory',
      value: this.data['category'],
    });
  }
}
