<div style="height: 100vh;">

    <div ng-sidebar-content class="sidebar-content">
        <div class="row align-center" style="justify-content: space-between;">

            <div class="col-md-4 top-heading" style="cursor: pointer;">
                <span style="font-size: 1.5rem;cursor: pointer;">
                    Products
                </span>
            </div>
        </div>


        <!-- Tabel Data -->
        <div *ngIf="isLoading">
            <div class="center-loading">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="listOfProductToDisplay.length==0 && !isLoading">
            <div class="no-available">
                No Product found
            </div>
        </div>
        <div *ngIf="listOfProductToDisplay.length>0 && !isLoading">
            <table class="table-style">
                <thead class="table-heading">
                    <th style="cursor: pointer;" class="table-heading-content">
                        Type</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Product</th>

                    <th style="cursor: pointer;" class="table-heading-content">
                        L1 Commission</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        L2 Commission</th>
                    <th class="table-heading-content">Create & Copy</th>

                    <th style="cursor: pointer;" data-name="createdAt" scope="col" class="table-heading-content">
                        Created Date
                    </th>

                </thead>
                <div style="height: 0.6em;"> </div>
                <tbody>
                    <tr class="table-data"
                        *ngFor="let product of listOfProductToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                        <th class="table-data-content">{{product.type}} </th>
                        <th class="table-data-content">
                            {{product.type=='BOOK'?product.book['title']:(product.type=='COLLECTION'?product.bookCollection['name']:product.plan['price'])}}
                        </th>

                        <th class="table-data-content">
                            {{product.singleLvlCommission==null?'N.A.':getCommissionValue(product.singleLvlCommission)}}
                        </th>
                        <th class="table-data-content">
                            {{product.doubleLvlCommission==null?'N.A.':getCommissionValue(product.doubleLvlCommission)}}
                        </th>

                        <th class="table-data-content" style=" text-decoration: underline;color: blue;cursor: pointer;"
                            (click)="createLink(product._id)">Create Link
                        </th>

                        <th class="table-data-content">{{product.createdAt |date}}</th>

                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
            <div class="space-10">

            </div>
        </div>


    </div>
</div>