import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.scss']
})
export class InfluencerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
