import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListOfImageCategoryComponent } from './list-of-image-category/list-of-image-category.component';
import { ListOfImageSharingComponent } from './list-of-image-sharing/list-of-image-sharing.component';

const routes: Routes = [
  {
    path: '',
    component: ListOfImageCategoryComponent,
  },
  {
    path: 'list-image-sharing',
    component: ListOfImageSharingComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'admin/imageSharing' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImageSharingRoutingModule { }
