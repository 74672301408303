<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Compliance
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;margin-top: 2em;" *ngIf="!isLoading">
        <div class="row" style="margin-right: 2em;">
            <div class="col-md-3" (click)="moveToPrivacyPolicy()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/privacy.jpg' alt="State Image" srcset="">
                    <div class="name-on-card">
                        Privacy Policy
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="City Image" srcset="">
                    <div class="name-on-card">
                        Terms and Condition
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToQuizTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="City Image" srcset="">
                    <div class="name-on-card">
                        Quiz Terms and Condition
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToQuizHindiTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="City Image" srcset="">
                    <div class="name-on-card">
                        Quiz Hindi Terms and Condition
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToInfluencerTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="City Image" srcset="">
                    <div class="name-on-card">
                        Influencer Terms and Condition
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToBabyOfTheWeek()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="City Image" srcset="">
                    <div class="name-on-card">
                        Baby of the week Terms and Condition
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <button (click)="payuPay()">
                    <div *ngIf="isPayuLoading">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                    <div *ngIf="!isPayuLoading">
                        Payu Payment
                    </div>
                </button>
            </div> -->
        </div>
    </div>
</div>


<!-- "https://img.freepik.com/free-vector/silhouette-skyline-illustration_53876-78786.jpg?size=626&ext=jpg" -->