<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Category
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addGameCategoryForm" [formGroup]="addGameCategoryForm">

        <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required">Name</label>
                <input type="text" placeholder="Name" id="name" formControlName="name" class="input-style">
            </div>


        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="position" class="label required">Position</label>
                <input type="number" placeholder="Position" id="position" formControlName="position" class="input-style">
            </div>

        </div>

        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':addGameCategoryForm.invalid   ,'save-btn':addGameCategoryForm.valid }"
                value="{{'Save'}}" [disabled]="addGameCategoryForm.invalid " (click)="addEditUser()" />
        </div>

    </form>
</div>