<div style="width: 30em;">
    <div class="row mx-1" style="justify-content: space-between;">
        <h2 mat-dialog-title style="font-weight: 500;margin: 0px;">User Detail</h2>
        <mat-icon (click)="close()" style="cursor: pointer;">close</mat-icon>
    </div>
    <hr>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <span class="div-label">
                    Name:-
                </span> <span class="div-value">{{ data['firstName']+' '+data['lastName']}}</span>

            </div>
            <div class="col-md-12">
                <span class="div-label">
                    Phone:-
                </span> <span class="div-value"> {{ data['phone']}}</span>

            </div>
            <div class="col-md-12">
                <span class="div-label">
                    Email:-
                </span> <span class="div-value"> {{ data['email']}}</span>

            </div>
            <div class="col-md-12" *ngIf="data['address']!=null">
                <span class="div-label">
                    Address:-
                </span> <span class="div-value"> {{
                    data['address']['addressLine1']+','+data['address']['addressLine2']+','+data['address']['landmark']+','+data['address']['city']+','+data['address']['state']+','+data['address']['pincode']}}</span>

            </div>
        </div>
        <hr>
        <div class="row" style="margin-top:1em;">
            <div class="col-md-12">
                <span style="font-size: 19px;" class="div-label"> Subscription Detail</span>
            </div>
        </div>
        <div class="row" style="margin-top: 1em;" *ngIf="data['activeSubscription']!=undefined && data['activeSubscription']!=null">
            <div class=" col-md-12">

                <span class="div-label"> Duration :</span> <span class="div-value">{{data['activeSubscription']['plan']['duration']}} months</span>

            </div>
            <div class="col-md-12">

                <span class="div-label"> Price: </span> <span class="div-value">&#8377;{{data['activeSubscription']['plan']['price']}}</span>

            </div>
            <div class="col-md-12">

                <span class="div-label"> Start Date: </span> <span class="div-value">{{data['activeSubscription']['startDate']|date}}</span>

            </div>
            <div class="col-md-12">

                <span class="div-label"> End Date: </span> <span class="div-value">
                    {{data['activeSubscription']['endDate']|date}}</span>

            </div>
            <div class="col-md-12">

                <span class="div-label"> Status: </span> <span class="div-value">{{data['activeSubscription']['subscriptionStatus']}}</span>



            </div>
        </div>
        <div class="row" *ngIf="data['activeSubscription']==undefined || data['activeSubscription']==null">
            <div class="col-md-12">
                <p class="noSubscription">No subscription found.</p>
            </div>
        </div>
    </mat-dialog-content>
</div>