<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Contest
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addContestForm" [formGroup]="addContestForm">

        <div class="row">
            <div class="col-md-6">
                <label for="winnerAnnouncerName" class="label required">Winner Announcer Name </label>
                <input type="text" placeholder="Winner Announcer Name" id="winnerAnnouncerName"
                    formControlName="winnerAnnouncerName" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="option" class="label required">Upload Option</label>
                <select class="input-field" style="margin-top: 0;" name="option" id="option" formControlName="option"
                    (change)="typeChange($event)" aria-placeholder="Upload Optione">
                    <option value="Select Option" selected disabled>Select Option</option>
                    <option value="Image">Image</option>
                    <option value="Video">Video</option>
                </select>
            </div>
        </div>



        <div class="row">
            <div class="col-md-6">
                <label for="sizeLimit" class="label required">File Size Limit (in MB)</label>
                <input type="number" placeholder="File Size Limit (in MB)" id="sizeLimit" formControlName="sizeLimit"
                    class="input-style" class="input-style">
            </div>
            <div class="col-md-6" *ngIf="this.addContestForm.get('option')?.value=='Video'">
                <label for="videoDurationLimit" class="label required">Video Duration Limit (in seconds)</label>
                <input type="number" placeholder="Video Duration Limit (in seconds)" id="videoDurationLimit"
                    formControlName="videoDurationLimit" class="input-style" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="datetime-local" placeholder="Start Date" id="startDate" formControlName="startDate"
                    [max]="maxStartDate" class="input-style" (input)="startDateChange($event)" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="datetime-local" placeholder="End Date" id="endDate" formControlName="endDate"
                    class="input-style" [min]="minEndDate" (input)="endDateChange($event)">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="resultDate" class="label required">Result Date</label>
                <input type="date" placeholder="Start Date" id="resultDate" formControlName="resultDate"
                    class="input-style" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="hemaChoicePrize" class="label required">Hema's Choice prize</label>
                <input type="number" placeholder="One lucky winner prize" id="hemaChoicePrize"
                    formControlName="hemaChoicePrize" class="input-style" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="prize1" class="label required">One lucky winner prize</label>
                <input type="number" placeholder="One lucky winner prize" id="prize1" formControlName="prize1"
                    class="input-style" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="prize2" class="label required">Second lucky winner prize</label>
                <input type="number" placeholder="Second lucky winner prize" id="prize2" formControlName="prize2"
                    class="input-style" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="prize3" class="label required">Third lucky winner prize</label>
                <input type="number" placeholder="Third lucky winner prize" id="prize3" formControlName="prize3"
                    class="input-style" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="description" class="label required" style="margin-top: 1em;">Description </label>
                <input type="text" placeholder="Description" id="description" formControlName="description"
                    class="input-style">
            </div>
        </div>

        <!-- <h3 style="margin-left: 1rem;margin-top: 1rem;font-weight: 600;">Dynamic Fields Creations:</h3> -->

        <div
            style="display: flex; justify-content: space-between; width: 100%;margin-bottom: 1.4rem;margin-top: 1rem;font-weight: 600">
            <div>Dynamic Fields for form</div>
            <div (click)="addDynamicField()" style="
            text-decoration-line: underline;
            cursor: pointer;
            display: flex;
          ">
                <img style="width: 2.2rem; margin-right: 1rem" src="../../../../../assets/svgIcons/addIcon.svg" alt=""
                    srcset="" />
                Add New Dynamic Field
            </div>
        </div>
        <div style="grid-column: 1/-1" formArrayName="dynamicField"
            *ngFor="let arr of getDynamicFieldControls(); let i = index">
            <div [formGroupName]="i" class="formArray row">
                <div class="col-md-6">
                    <label class="label required" for="">Field Name</label>
                    <input type="text" class="input-style" formControlName="name" placeholder="Enter field name" />
                </div>
                <div class="col-md-6">
                    <label class="label required" for="">Data Type of Field</label>
                    <select class="input-field" style="margin-top: 0;" name="dataType" id="dataType"
                        formControlName="dataType" aria-placeholder="Data Type of Field"
                        (change)="dataTypeChangeHandler($event,i)">
                        <option value="Text">Text</option>
                        <option value="Alphanumeric">Alphanumeric</option>
                        <option value="Number">Number</option>
                        <option value="Date">Date</option>
                        <option value="Gender">Gender</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label class="label required" for="">Make Field Optional?</label>
                    <select class="input-field" style="margin-top: 0;" name="dataType" id="dataType"
                        formControlName="optional" aria-placeholder="Make Field Optional?">
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </select>
                </div>
                <div class="col-md-6" *ngIf="arr.get('dataType')!.value == 'Date'">
                    <label class="label required" for="">Year Limit</label>
                    <input type="text" class="input-style" formControlName="yearLimit" placeholder="Enter year limit" />
                </div>
            </div>
            <div class="removeForm" (click)="deleteDynamicField(i)">
                <div *ngIf="i!=0" class="removeForm" (click)="deleteDynamicField(i)">
                    <img style="width: 1.8rem; margin-right: 1rem" src="../../../../../assets/svgIcons/removeField.svg"
                        alt="" srcset="" />Remove
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <label for="Contest Image" class="label">Contest Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultContestImage!=null" src="{{defaultContestImage}}" class="proofImage">
                                <label *ngIf="defaultContestImage==null" class="imageLabel"> Upload
                                    <input (change)="displayContestImage($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultContestImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayContestImage($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultContestImage!=null && defaultContestImage.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultContestImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save" style="float: right;">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addContestForm.invalid,'save-btn':addContestForm.valid}"
                value="{{'Save'}}" [disabled]="addContestForm.invalid" (click)="addEditEmployee()" />
        </div>
    </form>
</div>