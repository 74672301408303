<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Offer
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addEditOfferForm" [formGroup]="addEditOfferForm">

        <div class="row">
            <div class="col-md-6">
                <label for="code" class="label required">Code</label>
                <input type="text" placeholder="Enter code" id="code" formControlName="code" class="input-style"
                    (input)="codeInputData($event.target)"
                    [ngStyle]="{'margin-bottom':isCodeAlreadyExists?'0.1rem':'0.8rem'}">
                <div style="font-size: 12px;margin-bottom: 0.5rem;
                color: #ff2323;" *ngIf="isCodeAlreadyExists">
                    Code Already Exist.You can't create offer with this code.
                </div>
            </div>
            <div class="col-md-6">
                <label for="discountType" class="label required">Type</label>
                <select id="discountType" name="discountType" class="input-style" formControlName="discountType"
                    (change)="onDiscountTypeSelectionChange($event)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                    <option value="RUPEES">RUPEES</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" *ngIf="showPercentage">
                <label for="percentage" class="label required">Percentage</label>
                <input type="text" placeholder="Enter percentage" id="percentage" formControlName="percentage"
                    maxlength="2" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="uptoAmount" class="label">Up to Amount(in Rupees)</label>
                <input type="text" placeholder="Enter up to amount" id="uptoAmount" formControlName="uptoAmount"
                    maxlength="5" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="offerType" class="label required">Offer Type</label>
                <select id="offerType" name="offerType" class="input-style" formControlName="offerType"
                    (change)="offerTypeSelected($event)">
                    <option value="Select Offer Type" selected disabled>Select Offer Type</option>
                    <option value="ALL">ALL</option>
                    <option value="BOOK">BOOK</option>
                    <option value="SUBSCRIPTION">SUBSCRIPTION</option>
                    <option value="COLLECTION">COLLECTION</option>
                </select>
            </div>
            <div *ngIf="offerType=='COLLECTION'" class="col-md-6">
                <label for="offerType" class="label required">Select Book Count</label>
                <select id="offerType" name="offerType" class="input-style" (change)="bookCountSelected($event)"
                    [value]="bookCount">
                    <option value="Select Book Count" selected disabled>Select Book Count</option>
                    <option value="Any">Any</option>
                    <option value="6">6</option>
                    <option value="9">9</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="criteria" class="label required" style="font-weight: 600;">Criteria</label>
            </div>
            <div class="col-md-6">
                <label for="operation1" class="label required">Select Opertaion</label>
                <select id="operation1" name="operation1" class="input-style" formControlName="operation1"
                    (change)="onOperation1SelectionChange($event)">
                    <option value="Select Opertaion" selected disabled>Select Opertaion</option>
                    <option value="equalTo">equalTo</option>
                    <!-- <option value="greaterThan">greaterThan</option>
                    <option value="lessThan">lessThan</option>
                    <option value="greaterThanEqualTo">greaterThanEqualTo</option>
                    <option value="lessThanEqualTo">lessThanEqualTo</option> -->
                    <option value="greaterThan"
                        [disabled]="this.addEditOfferForm.get('operation2')?.value=='greaterThanEqualTo' || this.addEditOfferForm.get('operation2')?.value=='greaterThan'">
                        greaterThan</option>
                    <option value="lessThan"
                        [disabled]="this.addEditOfferForm.get('operation2')?.value=='lessThanEqualTo' || this.addEditOfferForm.get('operation2')?.value=='lessThan'">
                        lessThan</option>
                    <option value="greaterThanEqualTo"
                        [disabled]="this.addEditOfferForm.get('operation2')?.value=='greaterThan' || this.addEditOfferForm.get('operation2')?.value=='greaterThanEqualTo'">
                        greaterThanEqualTo</option>
                    <option value="lessThanEqualTo"
                        [disabled]="this.addEditOfferForm.get('operation2')?.value=='lessThan' || this.addEditOfferForm.get('operation2')?.value=='lessThanEqualTo'">
                        lessThanEqualTo</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="value1" class="label required">Value</label>
                <input type="text" placeholder="Enter value" id="value1" formControlName="value1" class="input-style">
            </div>
            <div *ngIf="show2ndOperation" class="col-md-6">
                <label for="operation2" class="label required">Select Opertaion</label>
                <select id="operation2" name="operation2" class="input-style" formControlName="operation2"
                    (change)="onOperation2SelectionChange($event)">
                    <option value="Select Opertaion" selected disabled>Select Opertaion</option>
                    <option value="equalTo" disabled>equalTo</option>
                    <option value="greaterThan"
                        [disabled]="this.addEditOfferForm.get('operation1')?.value=='greaterThanEqualTo' || this.addEditOfferForm.get('operation1')?.value=='greaterThan'">
                        greaterThan</option>
                    <option value="lessThan"
                        [disabled]="this.addEditOfferForm.get('operation1')?.value=='lessThanEqualTo' || this.addEditOfferForm.get('operation1')?.value=='lessThan'">
                        lessThan</option>
                    <option value="greaterThanEqualTo"
                        [disabled]="this.addEditOfferForm.get('operation1')?.value=='greaterThan' || this.addEditOfferForm.get('operation1')?.value=='greaterThanEqualTo'">
                        greaterThanEqualTo</option>
                    <option value="lessThanEqualTo"
                        [disabled]="this.addEditOfferForm.get('operation1')?.value=='lessThan' || this.addEditOfferForm.get('operation1')?.value=='lessThanEqualTo'">
                        lessThanEqualTo</option>
                </select>
            </div>
            <div *ngIf="show2ndOperation" class="col-md-6">
                <label for="value2" class="label required">Value</label>
                <input type="text" placeholder="Enter value" id="value2" formControlName="value2" class="input-style">
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <label for="offerMode" class="label required">Mode</label>
                <select id="offerMode" name="offerMode" class="input-style" formControlName="mode"
                    (change)="onModeSelectionChange($event)">
                    <option value="Select Mode" selected disabled>Select Mode</option>
                    <option value="CARD">CARD</option>
                    <option value="UPI">UPI</option>
                    <!-- <option value="WALLET">WALLET</option>
                    <option value="NETBANKING">NETBANKING</option> -->
                </select>
            </div>
            <div class="col-md-6" *ngIf="showCard">
                <label for="card" class="label required" style="margin-bottom: 1em;">Select Card</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Card'"
                    [ngModelOptions]="{standalone: true}" [settings]="cardDropdownSettings" [data]="listOfCard"
                    [(ngModel)]="selectedCard" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-md-6" *ngIf="showWallet">
                <label for="wallet" class="label required" style="margin-bottom: 1em;">Select Wallet</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Wallet'"
                    [ngModelOptions]="{standalone: true}" [settings]="walletDropdownSettings" [data]="listOfWallet"
                    [(ngModel)]="selectedWallet" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="description" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="description" formControlName="description"
                    class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>
            </div>
            <div class="col-md-6">
                <label for="termsAndCondition" class="label required">Terms & Condition</label>
                <textarea type="text" placeholder="Enter terms & condition" id="termsAndCondition"
                    formControlName="termsAndCondition" class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>
            </div>

        </div>

        <div class="save">

            <input *ngIf="this.offerType!='COLLECTION'" type="submit"
                [ngClass]="{'save-btn-disabled':addEditOfferForm.invalid  ,'save-btn':addEditOfferForm.valid && !isCodeAlreadyExists   }"
                value="{{'Save'}}" [disabled]="addEditOfferForm.invalid  " (click)="addEdiOffer()" />

            <input *ngIf="this.offerType=='COLLECTION'" type="submit"
                [ngClass]="{'save-btn-disabled':addEditOfferForm.invalid || offerType==null || bookCount==null  ,'save-btn':addEditOfferForm.valid && !isCodeAlreadyExists && offerType!=null && bookCount!=null   }"
                value="{{'Save'}}" [disabled]="addEditOfferForm.invalid  || offerType==null || bookCount==null "
                (click)="addEdiOffer()" />
        </div>
    </form>
</div>