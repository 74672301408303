import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferManagementComponent } from './offer-management.component';
import { ListOfferManagementComponent } from './list-offer-management/list-offer-management.component';
import { OfferManagementRoutingModule } from './offer-management-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeleteOfferDialogComponent } from './delete-offer-dialog/delete-offer-dialog.component';
import { AddEditOfferComponent } from './add-edit-offer/add-edit-offer.component';



@NgModule({
  declarations: [ListOfferManagementComponent,OfferManagementComponent, DeleteOfferDialogComponent, AddEditOfferComponent],
  imports: [
    CommonModule,OfferManagementRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
  ]
})
export class OfferManagementModule { }
