<div *ngIf="data['source']=='Suspend'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to {{!data['user']['isActive']?'revoke suspension of': 'suspend'}} this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="suspendUser()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteUser()">Yes</button>
    </div>
</div>