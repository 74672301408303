import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-upload-winner-video',
  templateUrl: './upload-winner-video.component.html',
  styleUrls: ['./upload-winner-video.component.scss']
})
export class UploadWinnerVideoComponent implements OnInit {


  // Adhaar Image
  selectedContestWinner: any = null;
  selectedContestWinnerName: any = null;
  defaultContestWinner: any;

  isLoading: boolean = false;
  isView: boolean = false;
  myHeaders = new Headers();
  formdata = new FormData();
  userDetails: any;

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<UploadWinnerVideoComponent>,
    private toastr: ToastrService, protected _sanitizer: DomSanitizer,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    if (data['source'] == 'View') {
      this.isView = true;
    } else {
      this.isView = false;
    }

  }
  ngOnInit(): void { }

  displayContestWinnerVideo(event: any) {
    this.selectedContestWinner = event.target.files[0];
    this.selectedContestWinnerName = this.selectedContestWinner['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultContestWinner = '../../../../../assets/img/video-winner.png';
      // this.defaultContestWinner = event1.target.result;
    };
    reader.readAsDataURL(this.selectedContestWinner);
  }
  downloadWinnerVideo(url: any) {
    window.open(url);
  }
  close() {
    this.dialogRef.close();
  }
  getLink() {
    return this.data['contest']['winnerPrizeVideo'];
  }
  editVideo() {
    this.isView = false;
  }
  uploadVideo() {
    this.isLoading = true;

    this.formdata.set('contestId', this.data['contest']['_id']);

    if (this.selectedContestWinner != null) {
      this.formdata.set('image', this.selectedContestWinner);
    }


    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.updateContestWinnerVideo}`;

    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.showSuccessToaster(
                  'Contest Winner video Updated Successfully',
                  'Success'
                );
                this.dialogRef.close()
              } else {
                this.isLoading = false;
                this.showWarningToaster(
                  'Error Occurred while updating Contest winner video. Try again',
                  'Error'
                );
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false; this.showWarningToaster(
            'Error Occurred while updating Contest winner video. Try again',
            'Error'
          );
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.showWarningToaster(
        'Error Occurred while updating Contest winner video. Try again',
        'Error'
      );
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
