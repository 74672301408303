<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <!-- [dataToEdit]="offerDataToEdit" -->
            <app-add-edit-plan *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-edit-plan>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;">

                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;cursor: pointer;">
                        Plans
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <!-- <div class="col-md-6" style="text-align: right;">
                <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                    (input)="searchData($event.target)">
            </div> -->
                        <div class="col-md-2" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewPlan()">
                                Add Plan
                            </button>
                        </div>
                    </div>
                </div>

            </div>


            <!-- Tabel Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfPlanToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Plan found
                </div>
            </div>
            <div *ngIf="listOfPlanToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Razorpay PlanId</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Duration</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Selling Price</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Listing Price</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Free Credit</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Created Date
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                            class="table-heading-content">Active
                        </th>
                        <th class="table-heading-content">Action</th>

                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let plan of listOfPlanToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                            <th class="table-data-content">{{plan.rzpPlanId}}</th>
                            <th class="table-data-content">{{plan.duration}} Months</th>
                            <th class="table-data-content">{{plan.price}}</th>
                            <th class="table-data-content">{{plan.listingPrice}}</th>
                            <th class="table-data-content">{{plan.creditToGive}}</th>
                            <th class="table-data-content">{{plan.createdAt |date}}</th>
                            <th class="table-data-content">
                                <img *ngIf="plan.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(plan,false)" slot="start" />
                                <img *ngIf="!plan.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(plan,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editPlan(plan)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deletePlan(plan)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10">

                </div>
            </div>


        </div>
    </ng-sidebar-container>
</div>