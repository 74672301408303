import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogContentComponent } from '../../content-management/active-delete-dialog-content/active-delete-dialog-content.component';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-manage-referral-product',
  templateUrl: './manage-referral-product.component.html',
  styleUrls: ['./manage-referral-product.component.scss']
})
export class ManageReferralProductComponent implements OnInit {


  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfProductToDisplay: any = [];
  listOfProduct: any = [];

  userDetails: any;
  isLoading: boolean = true;


  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.fetchReferralProduct();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
        private clipboard: Clipboard,
  ) { }

  async fetchReferralProduct() {
    this.listOfProductToDisplay = [];
    this.listOfProduct = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchReferralProduct}`;
      const param = {};
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfProduct = data['result'];
          this.listOfProductToDisplay = [...this.listOfProduct];
          this.isLoading = false;
        } else {
          this.listOfProduct = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfProduct = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfProductToDisplay = [];
      this.listOfProduct.forEach((product: any, index: number) => {
        if (
          String(product['plan'] == null || product['plan'] == undefined ? 'NA' : product['plan']['price'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(product['bookCollection'] == null || product['bookCollection'] == undefined ? 'NA' : product['bookCollection']['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(product['book'] == null || product['book'] == undefined ? 'NA' : product['book']['title'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(product);
        }
      });
      this.listOfProductToDisplay = tempSearchList;
    } else {
      this.listOfProductToDisplay = [];
      this.listOfProductToDisplay = this.listOfProduct;
    }
  }

  addProduct() {
    this.toggleOpened();
  }

  editProduct(product: any) {
    this.dataToEdit = product;
    this.toggleOpened();
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing product.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding product.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfProduct.findIndex(
          (influencer: any) => influencer['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfProduct.unshift(event);
          this.listOfProductToDisplay = [...this.listOfProduct];
          this.showSuccessToaster('Product added successfully', 'Success');
        } else {
          this.listOfProduct[index] = event;
          this.listOfProductToDisplay = [...this.listOfProduct];
          this.showSuccessToaster('Product Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  updateActiveStatus(product: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { product: product, source: 'Active Product' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          productId: res['value']['_id'],
          status: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.changeReferralProductStatus
          }`;
        await this.as
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfProductToDisplay.forEach((product: any, index: number) => {
                if (product['_id'] == res['value']['_id']) {
                  product.isActive = isActive;
                }
              });
              if (isActive) {
                this.showSuccessToaster(
                  'Product Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Product Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating product details. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  deleteProduct(product: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { product: product, source: 'Delete Product' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          productId: res['value']['_id'],
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.deleteReferralProduct
          }`;
        await this.as
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfProduct.forEach((influencer: any, i: number) => {
                if (influencer['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfProduct.splice(index, 1);
              this.listOfProductToDisplay = [...this.listOfProduct];

              this.showSuccessToaster('Product Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting product. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  getCommissionValue(data: any) {
    if (data['type'] == 'PERCENTAGE') {
      return `${data['value']}%`;
    } else {
      return `\u20b9${data['value']}`;
    }
  }

  async createLink(collectionId: string) {
    try {
      let URL = `${webApi.domain + webApi.endPoint.createDynamicLinkForInfluencer
        }`;

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });

      await this.as
        .postMethodWithToken({ collectionId }, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clipboard.copy(data['result']['shortLink']);
            this.showSuccessToaster('Link Copied', 'Success');
          } else {
            this.showErrorToaster('Error occur while creating link', 'Error');
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
