import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListGameCategoryComponent } from './list-game-category/list-game-category.component';
import { ListGameOptionComponent } from './list-game-option/list-game-option.component';
import { ListGameComponent } from './list-game/list-game.component';

const routes: Routes = [
  {
    path: '',
    component: ListGameOptionComponent,
  },
  {
    path: 'all-games',
    component: ListGameComponent,
  },
  {
    path: 'games-category',
    component: ListGameCategoryComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/game' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GameManagementRoutingModule {}
