import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-video',
  templateUrl: './add-edit-video.component.html',
  styleUrls: ['./add-edit-video.component.scss'],
})
export class AddEditVideoComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  defaultPdfImage: any;
  selectedPdfFile: any;
  selectedPdfFileName: any;

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();
  selectedLanguage: string = '';
  selectedPlatform: string = '';

  platform: any = ['APP', 'WEB', 'BOTH'];

  urlError: boolean = false;

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
  }
  async ngOnInit(): Promise<void> {
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.defaultPdfImage = '../../../../../assets/img/play.png';
    }
  }

  handlePickedFile(event: any) {
    this.selectedPdfFile = event.target.files[0];
    this.selectedPdfFileName = this.selectedPdfFile['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      // this.defaultPdfImage = event1.target.result;
      this.defaultPdfImage = '../../../../../assets/img/play.png';
    };
    reader.readAsDataURL(this.selectedPdfFile);
  }

  addEditVideo() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('videoId', this.dataToEdit['_id']);

      this.formdata.set(
        'videoLink',
        JSON.stringify(this.dataToEdit['videoLink'])
      );
    } else {
      this.formdata.set('videoId', '0');
    }

    if (this.selectedPdfFile != null) {
      this.formdata.set('videoLink', this.selectedPdfFile);
    }

    this.formdata.set('videoName', this.selectedPdfFileName);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateVideo}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
}
