<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Video
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">

    <div class="row">
        <div class="col-md-12">
            <div class="col-md-6">
                <label for="Content Image" class="label required">Upload Demo Video:</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultPdfImage!=null" src="{{defaultPdfImage}}" class="proofImage">
                                <label *ngIf="defaultPdfImage==null" class="imageLabel"> Upload
                                    <input (change)="handlePickedFile($event)" type="file" accept=".mp4">
                                </label>
                                <label *ngIf="defaultPdfImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="handlePickedFile($event)" type="file" accept=".mp4">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="save" style="float: right;margin-right: 1rem;margin-top: 1rem;">
                <input type="submit"
                    [ngClass]="{'save-btn-disabled': defaultPdfImage==null  ,'save-btn': defaultPdfImage!=null}"
                    value="{{'Save'}}" [disabled]=" defaultPdfImage==null " (click)="addEditVideo()" />
            </div>
        </div>


    </div>