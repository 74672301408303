<div class="main-container">
    <div class="row align-center" style="width: 100%;">
        <div class="top-heading col-md-3">
            Mehndi Design
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

            </div>
        </div>
    </div>

    <div style="height: 79vh;margin-top: 2em;">
        <div class="row" style="margin-right: 2em;">
            <div class="col-md-3" (click)="moveToCategory()">
                <div class="main-card">
                    <img class="img-card" src='https://i.ytimg.com/vi/R-cDKTgp7-k/maxresdefault.jpg' alt="State Image"
                        srcset="">
                    <div class="name-on-card">
                        DESIGN CATEGORIES
                    </div>
                </div>
            </div>


            <div class="col-md-3">
                <div class="main-card" (click)="moveToImages()">
                    <img class="img-card" src='https://upload.wikimedia.org/wikipedia/commons/7/78/Image.jpg'
                        alt="City Image" srcset="">
                    <div class="name-on-card">
                        DESIGN IMAGES
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>