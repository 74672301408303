import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-list-referral-product',
  templateUrl: './list-referral-product.component.html',
  styleUrls: ['./list-referral-product.component.scss']
})
export class ListReferralProductComponent implements OnInit {
  p: any = 1;
  count: any = 50;
  listOfProductToDisplay: any = [];
  listOfProduct: any = [];

  userDetails: any;
  isLoading: boolean = true;


  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.fetchReferralProduct();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private clipboard: Clipboard,
  ) { }

  async fetchReferralProduct() {
    this.listOfProductToDisplay = [];
    this.listOfProduct = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchReferralProduct}`;
      const param = {};
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfProduct = data['result'];
          this.listOfProductToDisplay = [...this.listOfProduct];
          this.isLoading = false;
        } else {
          this.listOfProduct = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfProduct = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfProductToDisplay = [];
      this.listOfProduct.forEach((product: any, index: number) => {
        if (
          String(product['plan'] == null || product['plan'] == undefined ? 'NA' : product['plan']['price'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(product['bookCollection'] == null || product['bookCollection'] == undefined ? 'NA' : product['bookCollection']['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(product['book'] == null || product['book'] == undefined ? 'NA' : product['book']['title'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(product);
        }
      });
      this.listOfProductToDisplay = tempSearchList;
    } else {
      this.listOfProductToDisplay = [];
      this.listOfProductToDisplay = this.listOfProduct;
    }
  }

  getCommissionValue(data: any) {
    if (data['type'] == 'PERCENTAGE') {
      return `${data['value']}%`;
    } else {
      return `\u20b9${data['value']}`;
    }
  }

  async createLink(productId: string) {
    try {
      let URL = `${webApi.domain + webApi.endPoint.createDynamicLinkForInfluencer}`;

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });

      await this.as
        .postMethodWithToken({ productId, role: 'Influencer' }, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clipboard.copy(data['result']['shortLink']);
            this.showSuccessToaster('Link Copied', 'Success');
          } else {
            this.showErrorToaster('Error occur while creating link', 'Error');
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
