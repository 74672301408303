<div *ngIf="data['source']=='Delete Contest'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Contest?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteContest()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='PublishUnPublish Contest'">
    <h1 mat-dialog-title style=" font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to {{
        this.data['contest']['isActive']?'unpublish':'publish' }} this Contest?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="updateContestStatus()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete Video'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Video?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteVideo()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Random Winner'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to make this participant as a
        winner?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="makeWinner()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='PublishUnPublish Video'">
    <h1 mat-dialog-title style=" font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to {{
        this.data['video']['isActive']?'unpublish':'publish' }} this Video?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="updateVideoStatus()">Yes</button>
    </div>
</div>