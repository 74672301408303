import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-change-contest-status-and-delete',
  templateUrl: './change-contest-status-and-delete.component.html',
  styleUrls: ['./change-contest-status-and-delete.component.scss']
})
export class ChangeContestStatusAndDeleteComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ChangeContestStatusAndDeleteComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteContest() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Contest',
      value: this.data['contest'],
    });
  }
  updateContestStatus() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Update Contest Status',
      value: this.data['contest'],
      status: !this.data['contest']['isActive']
    });
  }
  deleteVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Video',
      value: this.data['video'],
    });
  }
  makeWinner() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Random Winner',
      value: this.data['winner'],
    });
  }
  updateVideoStatus() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Update Video Status',
      value: this.data['video'],
      status: !this.data['video']['isActive']
    });
  }

}
