import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer-dashboard',
  templateUrl: './influencer-dashboard.component.html',
  styleUrls: ['./influencer-dashboard.component.scss']
})
export class InfluencerDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
