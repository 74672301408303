<div class="main-container">
    <div class="row align-center" style="width: 100%;">

        <div class="col-md-12">
            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                placeholder="Search for first name, last name, phone number or email..."
                (input)="inputDataChange($event.target)" (keydown.enter)="searchData($event.target)"
                (keydown)="searchUserWhileTyping($event.target)">
        </div>

    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <div *ngIf="!noUserFound" class="no-available">
            Start searching user by there first name, last name, phone number or email
        </div>
        <app-user-detail-dialog *ngIf="noUserFound" [userToView]="userToView" [isSingleUser]="isSingleUser">
        </app-user-detail-dialog>

    </div>


    <!-- <div class="main-container">
    <div class="row align-center" style="justify-content: space-between;">
        <div class="col-md-4 top-heading">

            User ({{listOfUserToDisplay.length}})
        </div>

        <div class="col-md-8">
            <div class="row align-center">
                <div class="col-md-4" style="text-align: right;display: flex;align-items: center;">
                    Select filter: <select style="width: 60%;" class="input-field" name="region" id="region"
                        aria-placeholder="Select Role" (change)="filter($event)">
                        <option value="Select Role" selected disabled>Select Role</option>
                        <option value="All" [selected]="selectedRole=='All'">All</option>
                        <option value="Subscribed">Subscribed</option>
                        <option value="Non Subscribed">Non Subscribed</option>
                    </select>
                </div>
                <div class="col-md-5" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchData($event.target)">
                </div>
                <div class="col-md-2" style="text-align: center;">
                    <button class="add-recycle-prod" (click)="addNewUser()">
                        Add User
                    </button>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading" style="height: 79vh;">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;">

            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-edit-user-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-edit-user-sidebar>
            </ng-sidebar>
            <div ng-sidebar-content>
                <div *ngIf="listOfUserToDisplay.length==0">
                    <div class="no-available">
                        No user available.
                    </div>
                </div>
                <div *ngIf="listOfUserToDisplay.length!=0">
                    <table class="table-style">
                        <thead class="table-heading">

                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="firstName" scope="col" class="table-heading-content">Name</th>
                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="phone" scope="col" class="table-heading-content">Phone</th>
                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="email" scope="col" class="table-heading-content">Email</th>
                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="createdAt" scope="col" class="table-heading-content">Onboarding Date
                            </th>
                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="isSubscribed" scope="col" class="table-heading-content">Subscribed
                            </th>
                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="susbscriptionEndDate" scope="col" class="table-heading-content">Subscription
                                end date
                            </th>

                            <th style="cursor: pointer;" [appSort]="listOfUserToDisplay" data-order="desc"
                                data-name="isActive" scope="col" class="table-heading-content">Suspend</th>
                            <th class="table-heading-content">Action</th>
                        </thead>
                        <div style="height: 0.6em;"> </div>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let user of listOfUserToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                                <th (click)="subscriptionDialogBox(user)" style="cursor: pointer;"
                                    class="table-data-content">{{user.firstName+' '+user.lastName}}</th>
                                <th (click)="subscriptionDialogBox(user)" style="cursor: pointer;"
                                    class="table-data-content">{{user.phone}}</th>
                                <th (click)="subscriptionDialogBox(user)" style="cursor: pointer;"
                                    class="table-data-content">{{user.email}}</th>
                                <th (click)="subscriptionDialogBox(user)" style="cursor: pointer;"
                                    class="table-data-content">{{user.createdAt | date}}</th>
                                <th (click)="subscriptionDialogBox(user)" *ngIf="user.isSubscribed"
                                    style="cursor: pointer;" class="table-data-content">Yes(
                                    &#8377;{{user.activeSubscription.plan.price}} )</th>
                                <th (click)="subscriptionDialogBox(user)" *ngIf="!user.isSubscribed"
                                    style="cursor: pointer;" class="table-data-content">No</th>
                                <th (click)="subscriptionDialogBox(user)" style="cursor: pointer;"
                                    class="table-data-content">
                                    <span *ngIf="user.susbscriptionEndDate=='N.A'">{{user.susbscriptionEndDate}}</span>
                                    <span *ngIf="user.susbscriptionEndDate!='N.A'">{{user.susbscriptionEndDate|
                                        date}}</span>
                                </th>

                                <th class="table-data-content" style="cursor: pointer;">
                                    <img *ngIf="!user.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-on"
                                        src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                        (click)="suspendUser(user,true)" slot="start" />
                                    <img *ngIf="user.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-off"
                                        src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                        (click)="suspendUser(user,false)" slot="start" />
                                </th>
                                <th class="table-data-content" style="text-align: center;padding-left: 1rem;">
                                    <img (click)="editUser(user)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/img/editing.png'>
                                    <img (click)="deleteUser(user)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/img/delete.png'>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </ng-sidebar-container>

    </div>
</div> -->