import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfluencerMainDashboardComponent } from './influencer-main-dashboard/influencer-main-dashboard.component';
import { ListReferralProductComponent } from './list-referral-product/list-referral-product.component';

const routes: Routes = [
  {
    path: '',
    component: InfluencerMainDashboardComponent,
  },
  {
    path: 'referral-product',
    component: ListReferralProductComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/influencerDashboard' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfluencerDashboardRoutingModule { }
