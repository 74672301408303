import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss'],
})
export class UserDetailDialogComponent implements OnInit {
  @Input() userToView: any;
  @Input() isSingleUser: any;
  userToDisplay: any;
  subsToDisplay: any;
  isLoading: boolean = true;
  userDetails: any;
  listOfUsersBook: any = [];
  listOfTransactions: any = [];

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    // dialogRef.disableClose = true;
    console.log(this.userToView);
    if (this.userToView != null) {
      if (!Array.isArray(this.userToView)) {
        this.userToDisplay = this.userToView;
        this.fetchUserBooks(this.userToDisplay['_id']);
        this.fetchUserTransactions(this.userToDisplay['_id']);
      }
    }
  }

  openSelectedUser(user: any) {
    this.userToDisplay = user;
    this.fetchUserBooks(user['_id']);
    this.fetchUserTransactions(user['_id']);
  }

  async fetchUserBooks(userId: any) {
    this.isLoading = true;
    this.listOfUsersBook = [];
    let param = { userId: userId };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchUserBooks}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfUsersBook = data['result'];
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.listOfUsersBook = [];
        }
      });
  }
  async fetchUserTransactions(userId: any) {
    this.isLoading = true;
    this.listOfUsersBook = [];
    let param = { user: userId };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchUserTransactions}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfTransactions = data['result'];
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.listOfTransactions = [];
        }
      });
  }
}
