<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} Category
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addContestCategoryForm" [formGroup]="addContestCategoryForm">

        <div class="row">
            <div class="col-md-12">
                <label for="title" class="label required">Title</label>
                <input type="text" placeholder="Title" id="title" formControlName="title" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="sponsorName" class="label">Sponsor Name</label>
                <input type="text" placeholder="Sponsor Name" id="sponsorName" formControlName="sponsorName"
                    class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="description" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="description" formControlName="description"
                    class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>

            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="Brand Image" class="label required">Brand Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultCategoryImage!=null" src="{{defaultCategoryImage}}"
                                    class="proofImage">
                                <label *ngIf="defaultCategoryImage==null" class="imageLabel"> Upload
                                    <input (change)="selectImage($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultCategoryImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="selectImage($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <label for="Image" class="label required">Video'</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultVideoImage!=null" src="{{defaultVideoImage}}" class="proofImage">
                                <label *ngIf="defaultVideoImage==null" class="imageLabel"> Upload

                                    <input (change)="selectVideo($event)" type="file" accept="video/*">
                                </label>
                                <label *ngIf="defaultVideoImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>

                                    <input (change)="selectVideo($event)" type="file" accept="video/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addContestCategoryForm.invalid || defaultCategoryImage==null ||defaultVideoImage==null   ,'save-btn':addContestCategoryForm.valid && defaultCategoryImage!=null &&defaultVideoImage!=null}"
                value="{{'Save'}}"
                [disabled]="addContestCategoryForm.invalid || defaultCategoryImage==null ||defaultVideoImage==null"
                (click)="addEditUser()" />
        </div>

    </form>
</div> 