<div style="height: 100vh;">

    <div class="sidebar-content">

        <!-- Top Data -->
        <div class="row align-center" style="width: 100%;">
            <div class="top-heading col-md-3">
                Pariticipant List
            </div>
            <div class="col-md-9">
                <div class="row align-center">
                    <div class="col-md-5" style="text-align: right;">
                        <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                            placeholder="Search Here" (input)="searchData($event.target)">
                    </div>
                    <div class="col-md-2" style="display: flex; align-items: center;">
                        <div *ngIf="isListView" (click)="changeView()"
                            style="color: blue;cursor: pointer;text-decoration: underline; ">
                            Grid view
                        </div>
                        <div *ngIf="!isListView" (click)="changeView()"
                            style="color: blue;cursor: pointer;text-decoration: underline; ">
                            List View
                        </div>
                        <div style="margin-left: 2em;">

                            <img *ngIf="!isFileDownload" (click)="downloadFile()"
                                src="../../../../../assets/img/file.png" style="height: 2em;cursor: pointer;">
                            <div *ngIf="isFileDownload">
                                <mat-progress-spinner mode="indeterminate" diameter="15" style="margin:0 auto;">
                                </mat-progress-spinner>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Table Data -->
        <div *ngIf="!isListView">

            <div class="display-flex" style="flex-wrap: wrap;">
                <div *ngFor="let contest of listOfContestVideoToDisplay" class="card-video">
                    <!-- <video style="height: 20em;width: 18em;" class="video" oncontextmenu="return false;" controls
                        controlsList="nodownload">
                        <source [src]="contest['video']" type="video/mp4">
                        Your browser does not support the video tag.
                    </video> -->
                    <img [src]="contest['thumbnail']" style="height: 20em;width: 18em;object-fit: cover;">
                    <div style="width: 100%;padding: 1em;">
                        <div style="display: flex;">
                            <div>
                                Baby Name:
                            </div>
                            <div>
                                {{contest.babyName}}
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div>
                                Gender:
                            </div>
                            <div>
                                {{contest.gender}}
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div>
                                Vote Count:
                            </div>
                            <div>
                                {{contest.voteCount}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="isListView">
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfContestVideoToDisplay.length==0">
                <div class="no-available">
                    No pariticipant available.
                </div>
            </div>
            <div *ngIf="listOfContestVideoToDisplay.length!=0">
                <table class="table-style">
                    <thead class="table-heading">

                        <th class="table-heading-content">{{contestType}} Name</th>
                        <th class="table-heading-content" *ngIf="contestType=='Baby'">Gender</th>
                        <th class="table-heading-content" *ngIf="contestType=='Baby'">Mother Name</th>
                        <th class="table-heading-content">Phone</th>
                        <th class="table-heading-content">Email</th>
                        <th class="table-heading-content">Vote</th>
                        <th class="table-heading-content">User</th>
                        <th class="table-heading-content">Hema Choice</th>
                        <th class="table-heading-content">Created At</th>
                        <th class="table-heading-content">Reported Count</th>
                        <th class="table-heading-content">View</th>
                        <th class="table-heading-content">ACTION</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let contest of listOfContestVideoToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">{{contest.name==null ||
                                contest.name==undefined?contest.babyName:contest.name}}</th>
                            <th class="table-data-content" *ngIf="contestType=='Baby'">{{contest.gender}}</th>
                            <th class="table-data-content" *ngIf="contestType=='Baby'">{{contest.parentName}}</th>
                            <th class="table-data-content">{{contest.user!= null &&
                                contest.user!=undefined?(contest.user['phone']):'NA'}}</th>
                            <th class="table-data-content">{{contest.user!= null &&
                                contest.user!=undefined?(contest.user['email']):'NA'}}</th>
                            <th class="table-data-content">{{contest.voteCount}}</th>
                            <th class="table-data-content">{{contest.user!= null &&
                                contest.user!=undefined?(contest.user['firstName']+' '+contest.user['lastName']):'NA'}}
                            </th>

                            <th class="table-data-content" *ngIf="contest._id==randomWinner"><span
                                    style="font-weight: bold;color: black;font-size: 16px;">Winner</span>
                            <th class="table-data-content" (click)="setRandomWinner(contest)"
                                *ngIf="contest._id!=randomWinner"><span
                                    style="color: blue;cursor: pointer;text-decoration: underline;">Make Winner</span>
                            </th>
                            <th class="table-data-content">{{contest.createdAt | date}}</th>
                            <th class="table-data-content">{{contest.reportCount}}</th>
                            <th class="table-data-content" (click)="viewVideo(contest)"><span
                                    style="color: blue;cursor: pointer;text-decoration: underline;">View</span></th>

                            <th class="table-data-content ">
                                <div style="display: contents;">

                                    <img *ngIf="!contest['isActive']" (click)="publishUnpublishContestVideo(contest)"
                                        class="status-icon-off"
                                        style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-off.svg'>
                                    <img *ngIf="contest['isActive']" (click)="publishUnpublishContestVideo(contest)"
                                        class="status-icon-on" style="height: 1.4rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../../assets/svgIcons/switch-on.svg'>
                                    <img title="Delete" (click)="deleteContestVideo(contest)"
                                        style="height: 1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div class="space-10"></div>

            </div>
        </div>
    </div>

</div>