<div *ngIf="data['source']=='Accept Employee'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to accept this Employee request?
    </div>
    <div>
        <form name="approveEmployeeForm" [formGroup]="approveEmployeeForm">
            <label for="role" class="label required">Select Role</label>
            <select id="role" name="role" class="input-style" formControlName="selectedRole">
                <option value="Select Role" selected disabled>Select Role</option>
                <option value="SUPERVISOR">Supervisor</option>
                <option value="SORTER">Sorter</option>
                <option value="GO-D">Go-D</option>
                <option value="RIDER">Rider</option>
                <option value="CITYHEAD">City head</option>
                <option value="STATEHEAD">State head</option>
                <option value="ADMIN">Admin</option>
                <option value="APPLICATION">Application</option>
            </select>
        </form>
    </div>
    <div mat-dialog-actions>
        <button class="canel-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="ok-btn" mat-button mat-dialog-close [disabled]="approveEmployeeForm.invalid" (click)="acceptEmployee()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Reject Employee'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to reject this Employee request?
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="rejectEmployee()">Yes</button>
    </div>
</div>