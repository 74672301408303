import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogContentComponent } from '../../content-management/active-delete-dialog-content/active-delete-dialog-content.component';

export interface Plan {
  _id: string;
  price: number;
  listingPrice: number;
  duration: number;
  rzpPlanId: string;
  createdAt: Date;
  isActive: boolean;
  creditToGive: number;
}
@Component({
  selector: 'app-list-plan',
  templateUrl: './list-plan.component.html',
  styleUrls: ['./list-plan.component.scss'],
})
export class ListPlanComponent implements OnInit {
  brandDetails: any;

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfPlanToDisplay: Array<Plan> = [];
  listOfPlan: Array<Plan> = [];
  listOfPlanOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];
  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('MERI-SAHELI-WEB')!);
    this.brandDetails = this.as.brandDetails;
    this.fetchPlans();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async fetchPlans() {
    this.listOfPlanToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchPlans}`;
      const param = {
        planId: 0,
        admin: true,
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfPlanToDisplay = data['result'];
          this.listOfPlan = [...this.listOfPlanToDisplay];
          this.isLoading = false;
        } else {
          this.listOfPlanToDisplay = [];
          this.listOfPlan = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfPlanToDisplay = [];
      this.listOfPlan = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfPlanToDisplay = [];
      this.listOfPlan.forEach((plan: any, index: number) => {
        if (
          String(plan['offerCode'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(plan['brand']['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(plan);
        }
      });
      this.listOfPlanToDisplay = tempSearchList;
    } else {
      this.listOfPlanToDisplay = [];
      this.listOfPlanToDisplay = this.listOfPlan;
    }
  }

  addNewPlan() {
    this.toggleOpened();
  }

  deletePlan(plan: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { plan: plan, source: 'Delete Plan' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          planId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusPlan
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfPlanToDisplay.forEach((plan: any, i: number) => {
                if (plan['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfPlanToDisplay.splice(index, 1);
              this.listOfPlan = [...this.listOfPlanToDisplay];

              this.showSuccessToaster('Plan Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting plan. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  editPlan(plan: any) {
    let planToEdit;
    this.listOfPlanToDisplay.forEach((pln: any) => {
      if (pln['_id'] == plan._id) {
        planToEdit = pln;
      }
    });
    this.dataToEdit = planToEdit;
    this.toggleOpened();
  }

  updateActiveStatus(plan: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { plan: plan, source: 'Active Plan' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogContentComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          planId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusPlan
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfPlanToDisplay.forEach((plan: any, index: number) => {
                if (plan['_id'] == res['value']['_id']) {
                  plan.isActive = isActive;
                }
              });
              if (isActive) {
                this.showSuccessToaster(
                  'Plan Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Plan Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating plan. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing plan.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding plan.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfPlanToDisplay.findIndex(
          (plan: any) => plan['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfPlanToDisplay.push(event);
          this.listOfPlan = [...this.listOfPlanToDisplay];
          this.showSuccessToaster('Plan added successfully', 'Success');
        } else {
          this.listOfPlanToDisplay[index] = event;
          this.listOfPlan = [...this.listOfPlanToDisplay];
          this.showSuccessToaster('Plan Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  gotoBrandPage() {
    this.as.gotoComponent('Brand Management');
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
