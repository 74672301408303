<div style="height: 100vh;">
    <ng-sidebar-container style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-image *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit"
                [listOfCategory]="listOfCategory" [selectedType]="selectedType">
            </app-add-image>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;margin-bottom: 2em;">
                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    Mehndi Design Image
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <div class="col-md-7" style="text-align: right;display: flex;align-items: center;">
                            Select Category: <select style="width: 60%;" class="input-field" name="region" id="region"
                                aria-placeholder="Select Category" (change)="onCategorySelectionChange($event)">
                                <option value="Select Category" selected disabled>Select Category</option>
                                <option value="All" [selected]="selectedCategory=='All'">All</option>
                                <option *ngFor="let data of listOfCategory" [value]="data['_id']">{{data['name']}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3" style="text-align: center;margin-right: 1.2rem;">
                            <!-- <button class="add-category" (click)="addCategory()">
                                Add Category
                            </button> -->
                            <button class="add-new-emp" (click)="addNewImages()">
                                Add New Images
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Data -->

            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfDesignImage.length==0 && !isLoading">
                <div class="no-available">
                    No image available.
                </div>
            </div>
            <div *ngIf="listOfDesignImage.length!=0 && !isLoading">
                <div class="row" style="margin-right: 2em;">
                    <div class="col-md-3" *ngFor="let data of listOfDesignImage">
                        <div class="main-card">
                            <img class="img-card" [src]="data['image']" alt="State Image" srcset="">
                            <div class="name-on-card">
                                <span class="name" [title]="data['name']"> {{data['name']}}</span>
                            </div>
                            <div class="delete-on-card" (click)="deleteImage(data,'Delete Image')">
                                <img style="height: 15px;" src="../../../../../assets/img/delete.png" alt="" srcset="">
                            </div>
                            <div class="hide-on-card" (click)="deleteImage(data,'Hide Image')">
                                <img style="height: 15px;"
                                    [src]="!data['isActive']? '../../../../../assets/img/eye-slash.png':'../../../../../assets/img/eye.png'"
                                    alt="" srcset="">
                            </div>
                        </div>
                    </div>
                </div>

                <div style="height: 10em;">

                </div>
            </div>
        </div>
    </ng-sidebar-container>

</div>