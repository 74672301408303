<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?"Edit": 'Add'}} User
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addUserForm" [formGroup]="addUserForm">

        <div class="row">
            <div class="col-md-6">
                <label for="firstName" class="label required">First name</label>
                <input type="text" placeholder="First name" id="firstName" formControlName="firstName" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="lastName" class="label required">Last name</label>
                <input type="text" placeholder="Last name" id="lastName" formControlName="lastName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="phone" class="label required">Phone Number</label>
                <input type="text" placeholder="Phone Number" id="phone" formControlName="phone" class="input-style" maxlength="10">
            </div>
            <div class="col-md-6">
                <label for="email" class="label required">Email</label>
                <input type="text" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <label for="gender" class="label required">Gender</label>
                <select id="gender" name="gender" class="input-style" formControlName="gender">
                    <option value="Select Gender" selected disabled>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="dob" class="label required">Date of birth</label>
                <input type="date" id="dob" formControlName="dob" class="input-style">

            </div>
        </div>






        <div class="save">
            <!-- <input *ngIf="dataToEdit==null" type="submit" [ngClass]="{'save-btn-disabled':addUserForm.invalid  || selectedImage==null,'save-btn':addUserForm.valid && selectedImage!=null}" value="{{'Save'}}" [disabled]="addUserForm.invalid  || selectedImage==null"
                (click)="addVendor()" /> -->
            <input type="submit" [ngClass]="{'save-btn-disabled':addUserForm.invalid  ,'save-btn':addUserForm.valid }" value="{{'Save'}}" [disabled]="addUserForm.invalid " (click)="addEditUser()" />

        </div>

    </form>
</div>