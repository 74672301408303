<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Image
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addMehndiImageForm" [formGroup]="addMehndiImageForm">
        <div class="row">
            <div class="col-md-12">
                <label for="category" class="label required">Select Category </label>
                <select id="category" name="category" class="input-style" formControlName="category">
                    <option value="Select Category" selected disabled>Select Category</option>
                    <option *ngFor="let data of listOfCategory" [value]="data['_id']">{{data['name']}}
                    </option>
                </select>
            </div>

        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="Image" class="label required">Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <div *ngIf="defaultDesignImage!=null" style="text-align: center;">
                                    <!-- <img src="{{defaultDesignImage}}" class="proofImage"> -->
                                    <span style="font-weight: 600;"> {{selectedDesignImageName}}</span>
                                    <br><span *ngIf="selectedDesigns.length>1">+ {{selectedDesigns.length>1?
                                        selectedDesigns.length-1
                                        +' more Selected':''}}</span>
                                </div>
                                <label *ngIf="defaultDesignImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*" multiple>
                                </label>
                                <label *ngIf="defaultDesignImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*" multiple>
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addMehndiImageForm.invalid|| defaultDesignImage==null   ,'save-btn':addMehndiImageForm.valid  && defaultDesignImage!=null}"
                value="{{'Save'}}" [disabled]="addMehndiImageForm.invalid || defaultDesignImage==null  "
                (click)="addEditImage()" />
        </div>
    </form>
</div>