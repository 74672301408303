<div style="height: 100vh;">
    <!-- <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-help-video *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-help-video>
        </ng-sidebar> -->
    <div ng-sidebar-content class="sidebar-content">
        <!-- Top Data -->
        <div class="row align-center" style="justify-content: space-between;">

            <div class="col-md-3 top-heading" style="cursor: pointer;">
                <span style="font-size: 1.5rem;cursor: pointer;">
                    Transaction Log
                </span>
            </div>
            <div class="col-md-9">
                <div class="row align-center">
                    <div class="col-md-6"
                        style="text-align: right;display: flex;align-items: center;justify-content: end;padding-top: 15px;">
                        <mat-form-field appearance="outline">
                            <mat-label>Pick a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [value]="startDate"
                                    (dateChange)="dateChanged( 'start',$event)">
                                <input matEndDate placeholder="End date" [value]="endDate"
                                    (dateChange)="dateChanged( 'end',$event)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <div (click)="clearDateRange()"
                            style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                            Clear
                        </div>
                    </div>
                    <div class="col-md-5" style="text-align: right;">
                        <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                            [(ngModel)]="searchString" placeholder="Search Here" (input)="searchTransLog($event)">
                    </div>

                    <div class="col-md-1">
                        <div *ngIf="downloadReport">
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                        <div *ngIf="!downloadReport">

                            <img (click)="generateLogReport()" src="../../../../assets/img/download-circular-button.png"
                                style="height: 1.5em;cursor: pointer;" title="Download leads" alt="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Table Data -->

        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Success">
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="listOfTransactionToDisplay.length==0 && !isLoading">
                    <div class="no-available">
                        No transaction found
                    </div>
                </div>
                <div *ngIf="listOfTransactionToDisplay.length>0 && !isLoading">
                    <table class="table-style" cellspacing="0" cellpadding="0">
                        <thead class="table-heading">

                            <!-- <th style="cursor: pointer;text-align: left;padding-left:2rem;" class="table-heading-content">
                                Name</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Email</th> -->
                            <th style="cursor: pointer;" class="table-heading-content">
                                Email</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Mode</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Medium</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Status</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Type</th>

                            <th style="cursor: pointer;" class="table-heading-content"> Amount
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Medium
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Source
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Campaign
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Content
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Term
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> Date
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> Detail
                            </th>


                        </thead>
                        <!-- <div style="height: 0.6em;"> </div> -->
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let transa of listOfTransactionToDisplay | paginate: { itemsPerPage: count, currentPage: p };let i=index">

                                <!-- <th class="table-data-content">{{transa['user']['fullName']}}</th>
                                <th class="table-data-content">{{transa['user']['email']}}</th> -->
                                <th class="table-data-content">{{transa['user']==null || transa['user']==undefined?'NA':
                                    transa['user']['email']}}</th>
                                <th class="table-data-content">{{transa['transactionMode']}}</th>
                                <th class="table-data-content">{{transa['transactionMedium']}}</th>
                                <th class="table-data-content">{{transa['transactionStatus']}}</th>
                                <th class="table-data-content">{{transa['transactionType']}}</th>

                                <th class="table-data-content">{{transa['amount']}}</th>

                                <th class="table-data-content">{{transa['utm_medium']==null ||
                                    transa['utm_medium']==undefined?'NA':transa['utm_medium']}}</th>
                                <th class="table-data-content">{{transa['utm_source']==null ||
                                    transa['utm_source']==undefined?'NA':transa['utm_source']}}

                                    <img *ngIf="transa['transactionMode']=='MANUAL'"
                                        style="height: 1em;cursor: pointer;" src="../../../../assets/img/editing.png"
                                        (click)="editUTMSourceLog(transa)" alt="" srcset="">
                                </th>
                                <th style="max-width: 120px;  word-break: break-word;" class="table-data-content">
                                    {{transa['utm_campaign']==null ||
                                    transa['utm_campaign']==undefined?'NA':transa['utm_campaign']}}</th>
                                <th class="table-data-content">{{transa['utm_content']==null ||
                                    transa['utm_content']==undefined?'NA':transa['utm_content']}}</th>
                                <th class="table-data-content">{{transa['utm_term']==null ||
                                    transa['utm_term']==undefined?'NA':transa['utm_term']}}</th>
                                <th class="table-data-content">{{transa.createdAt |date: 'dd/MM/yyyy hh:mm a'}}</th>
                                <th class="table-data-content">
                                    <img (click)="viewEditLog(transa)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                    <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Failed">
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="listOfTransactionToDisplay.length==0 && !isLoading">
                    <div class="no-available">
                        No transaction found
                    </div>
                </div>
                <div *ngIf="listOfTransactionToDisplay.length>0 && !isLoading">
                    <table class="table-style" cellspacing="0" cellpadding="0">
                        <thead class="table-heading">

                            <!-- <th style="cursor: pointer;text-align: left;padding-left:2rem;" class="table-heading-content">
                                Name</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Email</th> -->
                            <th style="cursor: pointer;" class="table-heading-content">
                                Email</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Mode</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Medium</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Status</th>
                            <th style="cursor: pointer;" class="table-heading-content">
                                Type</th>

                            <th style="cursor: pointer;" class="table-heading-content"> Amount
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Medium
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Source
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Campaign
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Content
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> UTM Term
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> Date
                            </th>
                            <th style="cursor: pointer;" class="table-heading-content"> Detail
                            </th>


                        </thead>
                        <!-- <div style="height: 0.6em;"> </div> -->
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let transa of listOfTransactionToDisplay | paginate: { itemsPerPage: count, currentPage: p };let i=index">

                                <!-- <th class="table-data-content">{{transa['user']['fullName']}}</th>
                                <th class="table-data-content">{{transa['user']['email']}}</th> -->
                                <th class="table-data-content">{{transa['user']==null || transa['user']==undefined?'NA':
                                    transa['user']['email']}}</th>
                                <th class="table-data-content">{{transa['transactionMode']}}</th>
                                <th class="table-data-content">{{transa['transactionMedium']}}</th>
                                <th class="table-data-content">{{transa['transactionStatus']}}</th>
                                <th class="table-data-content">{{transa['transactionType']}}</th>

                                <th class="table-data-content">{{transa['amount']}}</th>

                                <th class="table-data-content">{{transa['utm_medium']==null ||
                                    transa['utm_medium']==undefined?'NA':transa['utm_medium']}}</th>
                                <th class="table-data-content">{{transa['utm_source']==null ||
                                    transa['utm_source']==undefined?'NA':transa['utm_source']}}
                                    <img *ngIf="transa['transactionMode']=='MANUAL'"
                                        style="height: 1em;cursor: pointer;" src="../../../../assets/img/editing.png"
                                        (click)="editUTMSourceLog(transa)" alt="" srcset="">
                                </th>
                                <th style="max-width: 120px;  word-break: break-word;" class="table-data-content">
                                    {{transa['utm_campaign']==null ||
                                    transa['utm_campaign']==undefined?'NA':transa['utm_campaign']}}</th>
                                <th class="table-data-content">{{transa['utm_content']==null ||
                                    transa['utm_content']==undefined?'NA':transa['utm_content']}}</th>
                                <th class="table-data-content">{{transa['utm_term']==null ||
                                    transa['utm_term']==undefined?'NA':transa['utm_term']}}</th>
                                <th class="table-data-content">{{transa.createdAt |date: 'dd/MM/yyyy hh:mm a'}}</th>
                                <th class="table-data-content">
                                    <img (click)="viewEditLog(transa)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                    <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>



    </div>
    <!-- </ng-sidebar-container> -->
</div>