<div class="main-container">
    <div class="row align-center" style="width: 100%;margin: 0px;" *ngIf="!isLoading">
        <div class="top-heading col-md-5">
            <span style="font-size: large;
            color: #1f5ce1;cursor: pointer;" (click)="goBack()">
                Influencer
            </span>
            <mat-icon>keyboard_arrow_right</mat-icon>
            <span>
                {{influencerDetail['firstName']+' '+influencerDetail['lastName']}}
            </span>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 86vh;width: 100%;overflow: auto;" *ngIf="!isLoading">

        <!-- FOS Info Card -->
        <div class="row content-card">
            <div class="col-md-12">
                <div class="entity-card-fos" style="justify-content: space-between;align-items: center;display: flex;">
                    <div class="primary-data">
                        <div title="{{influencerDetail['firstName']+' '+influencerDetail['lastName']}}"
                            class="fos-circle-div"
                            [ngClass]="getClassOfCircleDiv(getInitialLetters(influencerDetail['firstName']+' '+influencerDetail['lastName']))">
                            {{getInitialLetters(influencerDetail['firstName']+' '+influencerDetail['lastName'])}}
                        </div>
                        <div style="margin-left: 1em;">
                            <div class="fos-name">{{influencerDetail['firstName']+' '+influencerDetail['lastName']}}
                            </div>
                            <div class="fos-role">{{influencerDetail['phone']}}</div>
                            <div class="fos-role">{{influencerDetail['email']}}</div>

                        </div>
                    </div>
                    <div class="related-account-data">
                        <div style="margin-bottom: 10px;
                        font-size: smaller;
                        font-weight: 600;
                        color: grey;">
                            Parent Influencer
                        </div>
                        <div class="row">
                            <div *ngIf="influencerDetail['parent']==null || influencerDetail['parent']==undefined"
                                class="col-md-12" style="display: flex;justify-content: start;align-items: center;">
                                Not available
                            </div>
                            <div *ngIf="influencerDetail['parent']!=null && influencerDetail['parent']!=undefined"
                                class="col-md-12" style="display: flex;justify-content: start;align-items: center;">

                                <div
                                    [title]="influencerDetail['parent']['firstName']+' '+influencerDetail['parent']['lastName']">
                                    <!-- [ngClass]="getClassOfCircleDiv(getInitialLetters(influencerDetail['parent']['firstName']+' '+influencerDetail['parent']['lastName']))" -->
                                    {{influencerDetail['parent']['firstName']+'
                                    '+influencerDetail['parent']['lastName']}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="fos-tab-style">
                    {{influencerDetail['role']}}
                </div>
            </div>
        </div>

        <!-- Product Info Card -->
        <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-md-12">
                <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedIndex">
                    <mat-tab label="Product">
                        <div *ngIf="listOfProduct.length==0 && !isLoading">
                            <div class="no-available">
                                No product found
                            </div>
                        </div>
                        <div *ngIf="listOfProduct.length>0 && !isLoading">
                            <table class="table-style">
                                <thead class="table-heading">
                                    <th style="cursor: pointer;" data-order="desc" data-name="type" scope="col"
                                        class="table-heading-content">
                                        Type</th>
                                    <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                                        Product</th>
                                    <th style="cursor: pointer;" class="table-heading-content">
                                        L1 Commission</th>
                                    <th style="cursor: pointer;" class="table-heading-content">
                                        L2 Commission</th>
                                    <!-- <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                                Email</th> -->

                                    <th style="cursor: pointer;" data-order="desc" data-name="createdAt" scope="col"
                                        class="table-heading-content">Created Date
                                    </th>
                                    <th class="table-heading-content">Create & Copy</th>
                                </thead>
                                <div style="height: 0.6em;"> </div>
                                <tbody>
                                    <tr class="table-data" *ngFor="let product of listOfProduct ">

                                        <th class="table-data-content">{{product.type}} </th>
                                        <th class="table-data-content">
                                            {{product.type=='BOOK'?product.book['title']:(product.type=='COLLECTION'?product.bookCollection['name']:product.plan['price'])}}
                                        </th>
                                        <th class="table-data-content">
                                            {{product.singleLvlCommission==null?'N.A.':getCommissionValue(product.singleLvlCommission)}}
                                        </th>
                                        <th class="table-data-content">
                                            {{product.doubleLvlCommission==null?'N.A.':getCommissionValue(product.doubleLvlCommission)}}
                                        </th>
                                        <th class="table-data-content">{{product.createdAt |date}}</th>
                                        <th class="table-data-content"
                                            style=" text-decoration: underline;color: blue;cursor: pointer;"
                                            (click)="createLink(product._id)">Create Link
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="space-10">

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="User Transaction">
                        <div *ngIf="listOfUser.length==0 && !isLoading">
                            <div class="no-available">
                                No user found
                            </div>
                        </div>
                        <div *ngIf="listOfUser.length>0 && !isLoading">
                            <table class="table-style">
                                <thead class="table-heading">
                                    <th style="cursor: pointer;" class="table-heading-content">Name</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Email</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Phone</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Product Type</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Commission</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Product</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Product Amount</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Payment Status</th>
                                    <th style="cursor: pointer;" class="table-heading-content">Created Date</th>
                                </thead>
                                <div style="height: 0.6em;"> </div>
                                <tbody>
                                    <tr class="table-data" *ngFor="let product of listOfUser">
                                        <th class="table-data-content">{{product.user['firstName']}}
                                            {{product.user['lastName']}} </th>
                                        <th class="table-data-content">{{product.user['email']}}</th>
                                        <th class="table-data-content">{{product.user['phone']}}</th>
                                        <th class="table-data-content">{{product['productType']}}</th>
                                        <th class="table-data-content">{{'\u20b9'+product['commission']}}</th>
                                        <th class="table-data-content">{{getProductTitle(product)}}</th>
                                        <th class="table-data-content">{{'\u20b9'+product['productPurchasePrice']}}</th>
                                        <th class="table-data-content" [ngStyle]="{'color':product.transaction['transactionStatus']=='SUCCESS'?'green':'red'}">{{product.transaction['transactionStatus']}}</th>
                                        <th class="table-data-content">{{product.createdAt |date}}</th>

                                    </tr>
                                </tbody>
                            </table>

                            <div class="space-10">

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Sub-Influencer">
                        <div *ngIf="listOfSubInfluencer.length==0 && !isLoading">
                            <div class="no-available">
                                No Sub Influencer found
                            </div>
                        </div>
                        <div *ngIf="listOfSubInfluencer.length>0 && !isLoading">
                            <table class="table-style">
                                <thead class="table-heading">
                                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                        class="table-heading-content">
                                        First Name</th>
                                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                        class="table-heading-content">
                                        Last Name</th>
                                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                        class="table-heading-content">
                                        Phone</th>
                                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                        class="table-heading-content">
                                        Email</th>
                                    <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                                        class="table-heading-content">Created Date
                                    </th>

                                    <!-- <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                                        class="table-heading-content">View Details
                                    </th> -->

                                </thead>
                                <div style="height: 0.6em;"> </div>
                                <tbody>
                                    <tr class="table-data" *ngFor="let influencer of listOfSubInfluencer ">

                                        <th class="table-data-content">{{influencer.firstName}}</th>
                                        <th class="table-data-content">{{influencer.lastName}}</th>
                                        <th class="table-data-content">{{influencer.phone}}</th>
                                        <th class="table-data-content">{{influencer.email}}</th>
                                        <th class="table-data-content">{{influencer.createdAt |date}}</th>


                                        <!-- <th style=" text-decoration: underline;color: blue;cursor: pointer;" (click)="gotoInfluencerDetail(influencer)"
                                            class="table-data-content">View Details</th> -->


                                    </tr>
                                </tbody>
                            </table>

                            <div class="space-10">

                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>