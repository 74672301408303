<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">

        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-video *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-edit-video>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;">
                <div class="col-md-4 top-heading">
                    Video
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <!-- <div class="col-md-8" style="text-align: right;">
                                <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                                    (input)="searchData($event.target)">
                            </div> -->
                        <div class="col-md-2" style="text-align: center;margin-right: 1.2rem;">
                            <button class="add-new-emp" (click)="addNewVideo()">
                                Add Video
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfVideoToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No video available.
                </div>
            </div>
            <div *ngIf="listOfVideoToDisplay.length!=0 && !isLoading">

                <table class="table-style">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            VideoName</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            VideoLink</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Created At</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Active</th>
                        <th class="table-heading-content">Action</th>

                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data" *ngFor="let video of listOfVideoToDisplay ">

                            <th class="table-data-content">{{video.videoName}}</th>
                            <th class="table-data-content">{{video.videoLink}}</th>
                            <th class="table-data-content">{{video.createdAt |date}}</th>
                            <th class="table-data-content">
                                <img *ngIf="video.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(video,false)" slot="start" />
                                <img *ngIf="!video.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(video,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editVideo(video)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>

                                    <img title="View" (click)="deleteVideo(video)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>


            </div>
        </div>
    </ng-sidebar-container>


</div>